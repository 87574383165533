import { Injectable } from '@angular/core';
import { ApiUrlConstants } from '../../constants/api-url-constants';
import { httpConfig, HttpService } from '../http-service/http.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    baseUrlApi = ApiUrlConstants.AUTH_BASE_URL;
    httpConfig: httpConfig;

    constructor(
        private _httpService: HttpService
    ) {
    }

    /**
     * Register as Venue and Vendor
     * @param input 
     * @returns 
     */
    addBusiness(input: any) {
        this.httpConfig = {
            url: this.baseUrlApi + '/signup',
            method: 'post',
            data: input
        }
        return this._httpService.init(this.httpConfig);
    }
}
