import {Component, ViewEncapsulation} from '@angular/core';
import {CommonConstants, RoutesConstants} from '@coreconstant';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {noWhitespaceValidator} from '../core/services/common-service/common.service';
import {lastValueFrom} from 'rxjs';
import {ResponseHandlerService} from '../core/services/common-service/response-handler.service';
import {GeneralService} from '../core/services/api-service/general.service';
import {RouterModule} from '@angular/router';
import {SeoService} from '../core/services/common-service/seo.service';
import {RecaptchaModule} from 'ng-recaptcha-2';

@Component({
    selector: 'app-submit-wedding',
    imports: [RouterModule, ReactiveFormsModule, RecaptchaModule],
    templateUrl: './submit-wedding.component.html',
    styleUrls: ['../how-it-works/contact-us/contact-us.component.scss', './submit-wedding.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubmitWeddingComponent {
    routesConstants = RoutesConstants
    commonConstants = CommonConstants
    submitWeddingForm: FormGroup;
    submitted: boolean = false;
    isSubmitAndShowSuccess: boolean = false;

    constructor(
        private _formBuilder: FormBuilder,
        private _responseHandlerService: ResponseHandlerService,
        private _generalService: GeneralService,
        private _seoService: SeoService,
    ) {
        this.setMetaTags()
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Submit Your Real Wedding to Wedshed | Wedding Inspiration',
            description: 'Share your real wedding on Wedshed! Submit your wedding details and inspire couples planning their big day with beautiful, authentic ideas.',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.SUBMIT_WEDDING,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    ngOnInit() {
        this.initializeForm();
    }

    initializeForm() {
        this.submitWeddingForm = this._formBuilder.group({
            name: [null, [Validators.required, noWhitespaceValidator]],
            partnerName: [null, [Validators.required, noWhitespaceValidator]],
            emailAddress: [null, [Validators.required, Validators.pattern(this.commonConstants.EMAIL_REGEX)]],
            venueName: [null, [Validators.required]],
            photographerName: ['', [Validators.required, noWhitespaceValidator]],
            message: [''],
            isPublishedAnywhere: [null, [Validators.required]],
            isPermittedByPhotographer: [null, [Validators.required]],
            photographLink: ['', [Validators.required, noWhitespaceValidator]],
            linkPassword: [''],
            recaptcha: ['', Validators.required],
        });
    }

    onCaptchaResolved(captchaResponse: string) {
        this.submitWeddingForm.patchValue({ recaptcha: captchaResponse });
    }

    submit() {
        this.submitted = true;
        if (this.submitWeddingForm.invalid) {
            this.submitWeddingForm.markAllAsTouched();
            this.submitWeddingForm.markAsDirty();
            return
        }

        const flattenedValues = {
            ...this.submitWeddingForm.value,
        }
        let addUpdateRecord = this._generalService.realWeddingEnquiry(flattenedValues)
        lastValueFrom(addUpdateRecord).then((response: any) => {
            if (response["status"] == true) {
                this.isSubmitAndShowSuccess = true
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error);
        });
    }
}
