<div class="modal-header">
    <h4 class="modal-title">
        <i class="modal-title-icon"><img src="assets/images/list-icon.png" width="52" height="51"
                alt="Cursor click icon"></i>
        Waitlist = joined
    </h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="closeModal()" title="Close">
        <img src="assets/images/close-icon.svg" width="13" height="13" alt="Close icon">
    </button>
</div>

<div class="modal-body">
    <p>Thank you for subscribing! We’ll be in touch with any juicy news.</p>

    <p>All the best,<br /> Mel and Amy 👋</p>
</div>

<div class="modal-footer">
    <button type="button" class="secondary-btn" (click)="closeModal()">Close</button>
</div>
