<section class="about-title-wrap">
    <div class="container">
        <h1 class="section-title">Wedding planning, made easy.</h1>
    </div>
</section>

<section class="about-us-wrap about-founders">
    <div class="container">
        <div class="about-us-img">
            <div class="about-us-img-inner">
                <img [src]="imagePathConstants.HOW_IT_WORKS + '/melany-and-amy.png'" width="906" height="1064"
                    alt="Wedshed founders" appSetImageDimensions>
            </div>
        </div>
        <div class="about-us-info">
            <label>Meet the founders 👋</label>
            <h2>Melany & Amy</h2>
            <p>We’re Mel and Amy – two Aussie women who firmly believe that weddings should be about the people
                involved, not the traditions and spectacle that so many people feel the pressure of.</p>
            <p>Wedshed is our way of helping like-minded and loved-up humans plan a wedding that’s deeply reflective of
                who they are.</p>
        </div>
    </div>
</section>

<section class="about-us-chit-chat">
    <div class="container">
        <label>Chit chat 🍷</label>
        <h2>Get to know us a little bit</h2>

        <div class="founders-chit-chat">
            <div class="about-us-chat-item">
                <p>Melany</p>
                <div class="about-chat-item-inner">
                    <img [src]="imagePathConstants.HOW_IT_WORKS + '/melany-chit-chat.png'" width="1008" height="884"
                        alt="Melany chit chat" appSetImageDimensions>
                </div>
            </div>
            <div class="about-us-chat-item">
                <p>Amy</p>
                <div class="about-chat-item-inner">
                    <img [src]="imagePathConstants.HOW_IT_WORKS + '/amy-chit-chat.png'" width="1008" height="884"
                        alt="Amy chit chat" appSetImageDimensions>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-us-wrap">
    <div class="container">
        <div class="about-us-info">
            <h2>Our wedding loving ladies and lads</h2>
            <p>We’re a small team of wed and tech-loving Aussie folk. We froth on how these two things intersect to make
                planning a special day more, well, special. And simple.</p>
            <p>We’re also the people behind the scenes at digital gift registry Gravy.</p>
        </div>
        <div class="about-us-img">
            <div class="about-us-img-inner">
                <img [src]="imagePathConstants.HOW_IT_WORKS + '/wedshed-team.png'" width="906" height="1064"
                    alt="Wedshed team" appSetImageDimensions>
            </div>
        </div>
    </div>
</section>

<section class="plan-with-wedshed">
    <div class="container">
        <h2 class="section-title">Why plan with Wedshed?</h2>
        <p class="title-desc">We’ve been in your shoes (i.e. damn excited to get married and eager to make it an amazing
            day). When we built Wedshed, we thought about all the things that we wish existed in a wedding platform when
            we were planning our weddings – and then designed our business with these (and you) in mind.</p>
        <ul>
            <li>
                <i class="offer-list-icon">
                    <img src="assets/images/community-focused-icon.png" width="72" height="72"
                        alt="Community focused icon">
                </i>
                <h3>We hand-pick wedding businesses</h3>
                <p>We only partner with venues we’d get hitched at ourselves, or services we’d recommend to our closest
                    mates.</p>
            </li>
            <li>
                <i class="offer-list-icon">
                    <img src="assets/images/advice-icon.png" width="52" height="52" alt="Advice icon">

                </i>
                <h3>We provide marketing support</h3>
                <p>We believe in a more personal approach, so we’ll keep up the conversation to help you plan your
                    wedding or market your business.</p>
            </li>
            <li>
                <i class="offer-list-icon">
                    <img src="assets/images/thumbs-up-down-icon.png" width="51" height="49" alt="Thumbs up down icon">
                </i>
                <h3>We try and test our advice</h3>
                <p>We tackle common wedding planning queries with complete transparency, from wedding money-saving tips,
                    to calculating how much alcohol you’ll need per guest.</p>
            </li>
            <li>
                <i class="offer-list-icon">
                    <img src="assets/images/fill-heart-icon.svg" width="32" height="35" alt="Heart icon">
                </i>
                <h3>We’re here for you</h3>
                <p>We’re not anonymous. We’re Mel, Amy and a small team wedding-loving ladies (and lads too). We’re here
                    for you from start to finish, supporting meaningful partnerships between couples and their venues
                    and vendors</p>
            </li>
        </ul>
    </div>
</section>

<section class="testimonials-wrap">
    <div class="container">
        <h2>See what people are saying about us</h2>

        <!-- <div class="testimonials-info">
            <h3 class="section-title">Two Aussie friends have dreamed up a brilliant website - to help brides have the
                day of their dreams.</h3>
            <ul>
                <li class="active"><img
                        [src]="imagePathConstants.HOME + '/sydney-morning-herald.svg?tr=w-228,h-25,q-100,f-webp'"
                        width="228" height="25" alt="sydney-morning-herald"></li>
                <li><img [src]="imagePathConstants.HOME + '/today.svg?tr=w-41,h-31,q-100,f-webp'" width="41" height="31"
                        alt="today"></li>
                <li><img [src]="imagePathConstants.HOME + '/collective-hub.svg?tr=w-149,h-25,q-100,f-webp'" width="149"
                        height="25" alt="collective-hub"></li>
                <li><img [src]="imagePathConstants.HOME + '/broadsheet.svg?tr=w-199,h-19,q-100,f-webp'" width="199"
                        height="19" alt="broadsheet"></li>
                <li><img [src]="imagePathConstants.HOME + '/buzzfeed.svg?tr=w-123,h-23,q-100,f-webp'" width="123"
                        height="23" alt="buzzfeed"></li>
                <li><img [src]="imagePathConstants.HOME + '/marie-claire.svg?tr=w-143,h-23,q-100,f-webp'" width="143"
                        height="23" alt="marie-claire"></li>
                <li><img [src]="imagePathConstants.HOME + '/daily-mail.svg?tr=w-178,h-30,q-100,f-webp'" width="178"
                        height="30" alt="daily-mail"></li>
                <li><img [src]="imagePathConstants.HOME + '/hello-may.svg?tr=w-135,h-20,q-100,f-webp'" width="135"
                        height="20" alt="hello-may"></li>
            </ul>
        </div> -->

        <div class="testimonials-info">
            <h3 class="section-title">{{ selectedTitle }}</h3>
            <ul>
                @for (item of testimonials; track $index;let i = $index) {
                <li [class.active]="selectedIndex == i" (click)="changeTitle(item?.title, i)">
                    <img [src]="imagePathConstants.HOME + item?.image" [width]="item?.width" [height]="item?.height"
                        [alt]="item?.title">
                </li>
                }
            </ul>
        </div>


    </div>
</section>

<section class="join-wedshed">
    <div class="container">
        <div class="join-wedshed-info">
            <h2 class="section-title">Are you ready to join Wedshed?</h2>
            <p>Creating your profile will only take a few minutes.</p>
            <p>Simply click below to create your account. If you get stuck or have questions, feel free to reach out to
                us!</p>
            <div class="join-wedshed-action">
                <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" type="button" class="primary-btn">Create your
                    couples profile</a>
                <a [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" type="button" class="secondary-btn">Create a
                    vendor or venue profile</a>
            </div>
        </div>
        <div class="join-wedshed-img">
            <!--<img [src]="imagePathConstants.HOW_IT_WORKS + '/wedding-ring.svg'" width="840" height="579" alt="wedding-ring" appSetImageDimensions>-->
            <img src="assets/images/wedding-ring.svg" width="420" height="290" alt="Wedding ring">
        </div>
    </div>
</section>
