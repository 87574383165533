import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { RoutesConstants } from './core/constants';
import { HomeComponent } from './home/home.component';
import { VenueListComponent } from './planning/venues/venue-list/venue-list.component';
import { VenueDetailComponent } from './planning/venues/venue-detail/venue-detail.component';
import { ImageGalleryComponent } from './planning/image-gallery/image-gallery.component';
import { VenueMapComponent } from './planning/venues/venue-map/venue-map.component';
import { VendorListComponent } from './planning/vendors/vendor-list/vendor-list.component';
import { VendorDetailComponent } from './planning/vendors/vendor-detail/vendor-detail.component';
import { PlanningHubComponent } from './planning/planning-hub/planning-hub.component';
import { SearchListingComponent } from './search-listing/search-listing.component';
import { CouplesComponent } from './how-it-works/couples/couples.component';
import { VendorsComponent } from './how-it-works/vendors/vendors.component';
import { VenuesComponent } from './how-it-works/venues/venues.component';
import { AboutComponent } from './how-it-works/about/about.component';
import { FaqsComponent } from './how-it-works/faqs/faqs.component';
import { ContactUsComponent } from './how-it-works/contact-us/contact-us.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { SubmitWeddingComponent } from './submit-wedding/submit-wedding.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RegionVenueListComponent } from './region-venue-list/region-venue-list.component';
import { WedspirationListComponent } from './wedspiration/wedspiration-list/wedspiration-list.component';
import { WedspirationDetailComponent } from './wedspiration/wedspiration-detail/wedspiration-detail.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { PageNotFoundComponent } from './core/component/common/page-not-found/page-not-found.component';
import {RemovePermanentlyComponent} from './core/component/common/remove-permanently/remove-permanently.component';

export const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: RoutesConstants.HOME,
                data: {
                    headerTitle: 'Wedshed'
                },
                component: HomeComponent
            },
            // Planning Section Routes Start
            // Venue Routes Start
            {
                path: RoutesConstants.VENUE_NAVIGATION,
                component: VenueListComponent,
                data: {
                    headerTitle: 'Wedshed - Venues'
                },
            },
            {
                path: RoutesConstants.VENUE_NAVIGATION + '/:slug',
                component: VenueDetailComponent,
                data: {
                    headerTitle: 'Wedshed - Venue'
                },
            },
            {
                path: RoutesConstants.VENUE_NAVIGATION + '/:slug'+ '/:' + RoutesConstants.PREVIEW,
                component: VenueDetailComponent,
                data: {
                    headerTitle: 'Wedshed - Venue'
                },
            },
            // Venue Routes End

            // Vender Routes Start
            {
                path: RoutesConstants.VENDOR_NAVIGATION,
                component: VendorListComponent,
                data: {
                    headerTitle: 'Wedshed - Vendors'
                },
            },
            {
                path: RoutesConstants.VENDOR_NAVIGATION + '/:slug',
                component: VendorDetailComponent,
                data: {
                    headerTitle: 'Wedshed - Vendor'
                },
            },
            {
                path: RoutesConstants.VENDOR_NAVIGATION + '/:slug' + '/:' + RoutesConstants.PREVIEW,
                component: VendorDetailComponent,
                data: {
                    headerTitle: 'Wedshed - Vendor'
                },
            },
            // Vender Routes End

            {
                path: RoutesConstants.HUB_NAVIGATION,
                component: PlanningHubComponent,
                data: {
                    headerTitle: 'Wedshed - Planning Hub'
                },
            },
            {
                path: RoutesConstants.SEARCH,
                component: SearchListingComponent,
                data: {
                    headerTitle: 'Wedshed - Search'
                },
            },
            // Planning Section Routes End

            // How it works Section Routes Start
            {
                path: RoutesConstants.HOW_IT_WORKS_COUPLES_NAVIGATION,
                component: CouplesComponent,
                data: {
                    headerTitle: 'Wedshed - Couples'
                },
            },
            {
                path: RoutesConstants.HOW_IT_WORKS_VENDORS_NAVIGATION,
                component: VendorsComponent,
                data: {
                    headerTitle: 'Wedshed - Vendors'
                },
            },
            {
                path: RoutesConstants.HOW_IT_WORKS_VENUES_NAVIGATION,
                component: VenuesComponent,
                data: {
                    headerTitle: 'Wedshed - Venues'
                },
            },
            {
                path: RoutesConstants.HOW_IT_WORKS_ABOUT_NAVIGATION,
                component: AboutComponent,
                data: {
                    headerTitle: 'Wedshed - About'
                },
            },
            {
                path: RoutesConstants.HOW_IT_WORKS_FAQS_NAVIGATION,
                component: FaqsComponent,
                data: {
                    headerTitle: 'Wedshed - FAQs'
                },
            },
            {
                path: RoutesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION,
                component: ContactUsComponent,
                data: {
                    headerTitle: 'Wedshed - Contact Us'
                },
            },
            // How it works Section Routes End

            {
                path: RoutesConstants.GET_IN_TOUCH,
                component: GetInTouchComponent,
                data: {
                    headerTitle: 'Wedshed - Get in touch'
                },
            },
            {
                path: RoutesConstants.SUBMIT_WEDDING,
                component: SubmitWeddingComponent,
                data: {
                    headerTitle: 'Wedshed - Submit wedding'
                },
            },
            {
                path: RoutesConstants.TERMS_CONDITION,
                component: TermsAndConditionsComponent,
                data: {
                    headerTitle: 'Wedshed - Terms And Conditions'
                },
            },
            {
                path: RoutesConstants.PRIVACY_POLICY,
                component: PrivacyPolicyComponent,
                data: {
                    headerTitle: 'Wedshed - Privacy Policy'
                },
            },
            {
                path: RoutesConstants.REGION_VENUE_NAVIGATION + '/:region',
                component: RegionVenueListComponent,
                data: {
                    headerTitle: 'Wedshed - Region Venue'
                },
            },

            // Wedspiration Routes Start (Always Put in the "LAST" for Route list)
            {
                path: RoutesConstants.WEDSPIRATION,
                component: WedspirationListComponent,
                data: {
                    headerTitle: 'Wedshed - Advice & Ideas'
                },
            },
            {
                path: RoutesConstants.WEDSPIRATION + '/:type',
                component: WedspirationListComponent,
                data: {
                    headerTitle: 'Wedshed - Advice & Ideas'
                },
            },
            {
                path: RoutesConstants.BLOG+ '/:type' + '/:slug',
                component: WedspirationDetailComponent,
                data: {
                    headerTitle: 'Wedshed - Advice & Ideas'
                },
            },
            {
                path: RoutesConstants.BLOG+ '/:type' + '/:slug' + '/:' + RoutesConstants.PREVIEW,
                component: WedspirationDetailComponent,
                data: {
                    headerTitle: 'Wedshed - Advice & Ideas'
                },
            },
            // Wedspiration Routes End
        ],
    },
    {
        path: RoutesConstants.SIGN_UP_NAVIGATION,
        component: SignUpComponent,
        data: {
            headerTitle: 'Wedshed - Sign Up'
        }
    },
    {
        path: RoutesConstants.PAGE_NOT_FOUND,
        component: PageNotFoundComponent,
        data: {
            headerTitle: 'Wedshed - Page Not Found'
        }
    },
    {
        path: RoutesConstants.PAGE_REMOVED_COMPLETETLY,
        component: RemovePermanentlyComponent,
        data: {
            headerTitle: 'Wedshed - Page Not Found'
        }
    },
    { path: '**', component: PageNotFoundComponent }
];
