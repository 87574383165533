.container {
    padding: 0 80px;

    @media screen and (max-width: 1360px) {
        padding: 0 40px;
    }

    @media screen and (max-width: 1280px) {
        padding: 0 16px;
    }
}

.back-btn {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.image-gallery {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;
    max-width: 896px;
    margin: 0 auto;
    padding-bottom: 135px;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: 1440px) {
        padding-bottom: 80px;
    }

    @media screen and (max-width: 991px) {
        padding-bottom: 48px;
    }

    @media screen and (max-width: 767px) {
        padding-bottom: 16px;
    }

    .gallery-item {
        border: 1px solid #272338;
        border-radius: 16px;
        //height: 334px;
        position: relative;
        padding: 0 0 75%;
        overflow: hidden;

        @media screen and (max-width: 767px) {
            padding: 0 0 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            position: absolute;
            top: 0;
            left: 0;
        }

        &:nth-child(3n-2) {
            grid-column: 1 / -1;
            padding: 0 0 51%;

            @media screen and (max-width: 767px) {
                padding: 0 0 87%;
            }
        }
    }
}

.main-head-responsive {
    color: #272338;
    padding: 12px 16px;
    border-bottom: 1px solid #272338;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        display: none;
    }

    .back-btn-title {
        font-family: Aeonik, sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 25.2px;
        color: #272338;
        margin: 0 auto;
    }
}