<section class="page-banner-wrap">
    <div class="container">
        <div class="left-content">
            <h1>A happily ever before and after</h1>
            <p>Weddings are about good times, so why should the lead-up be any different? Wedshed exists to help make
                the planning process stress-free, personal and - dare we say it - fun.</p>
            <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" type="button" class="primary-btn">Head to your
                planning hub</a>
        </div>
        <div class="banner-image">
            <div class="banner-image-inner">
                <img [src]="imagePathConstants.HOW_IT_WORKS + '/couple.png'" width="1392" height="924" alt="Happy couple"
                    appSetImageDimensions>
            </div>
        </div>
    </div>
</section>

@if(!isFooterVisible) {
<div class="mob-create-profile-btn">
    <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" type="button" class="primary-btn">Create your couples
        profile</a>
</div>
}

<section class="how-it-works-wrap">
    <div class="container">
        <div class="left-sticky-panel">
            <div class="left-sticky-menu">
                <ul>
                    <li [class.active]="activeSection === 'howItWorks'">
                        <span (click)="scrollToSection('howItWorks')">1. How it works for couples</span>
                    </li>
                    <li [class.active]="activeSection === 'whatWeOffer'">
                        <span (click)="scrollToSection('whatWeOffer')">2. What we offer</span>
                    </li>
                    <li [class.active]="activeSection === 'successStories'">
                        <span (click)="scrollToSection('successStories')">3. Success stories</span>
                    </li>
                    @if(faqList && faqList.length > 0) {
                    <li [class.active]="activeSection === 'faqs'">
                        <span (click)="scrollToSection('faqs')">4. FAQs</span>
                    </li>
                    }
                </ul>
                <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" class="primary-btn">Sign up to start planning</a>
            </div>
        </div>

        <div class="right-scroll-panel" (scroll)="onScroll()">
            <div id="howItWorks" class="how-it-works-step content-section">
                <h2 class="section-title">How it works for couples</h2>
                <div class="how-it-works-list">
                    <div class="how-it-works-item">
                        <div class="how-it-works-img">
                            <img [src]="imagePathConstants.HOW_IT_WORKS + '/sign-up-to-planning-hub.png'" width="480"
                                height="318" alt="Match with venues and vendors" appSetImageDimensions>
                        </div>
                        <h3>Match-make with venues and vendors</h3>
                        <p>Discover and enquire with your wedding dream team via our directory of curated creatives (we
                            only recommend businesses we’d use for our own big days).</p>
                        <div class="how-it-works-links">
                            <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION]" class="link-btn">
                                Search venues here
                                <img src="assets/images/arrow-right-blue-icon.svg" width="13" height="10"
                                    alt="Right arrow icon">
                            </a>
                            <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION]" class="link-btn">
                                Meet your vendors here
                                <img src="assets/images/arrow-right-blue-icon.svg" width="13" height="10"
                                    alt="Right arrow icon">
                            </a>
                        </div>
                    </div>

                    <div class="how-it-works-item">
                        <div class="how-it-works-img">
                            <img [src]="imagePathConstants.HOW_IT_WORKS + '/sign-up-to-planning-hub.png'" width="480"
                                height="318" alt="Sign up to planning hub" appSetImageDimensions>
                        </div>
                        <h3>Sign up to your own free planning hub (coming soon! ✨)</h3>
                        <p>Save favourites, communicate with venues and vendors and access a whole swag of incredible
                            planning resources like your budget and to-do’s.</p>
                        <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" class="link-btn">
                            Planning Hub
                            <img src="assets/images/arrow-right-blue-icon.svg" width="13" height="10" alt="Right arrow icon">
                        </a>
                    </div>

                    <div class="how-it-works-item">
                        <div class="how-it-works-img">
                            <img [src]="imagePathConstants.HOW_IT_WORKS + '/free-digital-registry.png'" width="480"
                                height="318" alt="Free digital registry" appSetImageDimensions>
                        </div>
                        <h3>Build your free digital registry</h3>
                        <p>Ask for anything (and we mean *anything*) using Gravy - including cash and gifts of human
                            effort, like petsitting.</p>
                        <a href="{{commonConstants.PLANNING_REGISTRY_LINK}}" rel="noopener noreferrer nofollow" class="link-btn" target="_blank">
                            Take a look at Gravy
                            <img src="assets/images/arrow-right-blue-icon.svg" width="13" height="10" alt="arrow-right">
                        </a>
                    </div>

                    <div class="how-it-works-item">
                        <div class="how-it-works-img">
                            <img [src]="imagePathConstants.HOW_IT_WORKS + '/shop-wedding-essentials.png'" width="480"
                                height="318" alt="Shop all wedding essentials" appSetImageDimensions>
                        </div>
                        <h3>Shop all the wedding essentials (and fun things that aren't)</h3>
                        <p>From eco-confetti to earrings, shop our curated range of wedding accessories guaranteed to
                            glow-up your big day.</p>
                        <a href="{{commonConstants.WEDSHED_STORE_LINK}}" rel="noopener noreferrer nofollow" class="link-btn" target="_blank">
                            Check out our shop
                            <img src="assets/images/arrow-right-blue-icon.svg" width="13" height="10" alt="arrow-right">
                        </a>
                    </div>

                    <div class="how-it-works-item">
                        <div class="how-it-works-img">
                            <img [src]="imagePathConstants.HOW_IT_WORKS + '/buy-and-sell-via-wedshare.png'" width="480"
                                height="318" alt="Buy and sell via wedshare" appSetImageDimensions>
                        </div>
                        <h3>Buy and sell pre-loved goods</h3>
                        <p>Buy, sell and rent pre-loved wedding items via Wedshare. Good for the planet and good for
                            your wallet.</p>
                        <a href="{{commonConstants.PLANNING_WEDSHARE_LINK}}" class="link-btn" target="_blank">
                            Check out Wedshare
                            <img src="assets/images/arrow-right-blue-icon.svg" width="13" height="10" alt="arrow-right">
                        </a>
                    </div>
                </div>
            </div>

            <div id="whatWeOffer" class="what-we-offer content-section">
                <h2 class="section-title">Let us do the heavy lifting</h2>
                <p class="section-des">Join Wedshed (free!) for access to sanity-saving features that will help you plan
                    your big day.</p>

                <ul>
                    <li>
                        <i class="offer-list-icon">
                            <img src="assets/images/click-icon.png" width="63" height="63" alt="Click icon">
                        </i>
                        <h3>Everything you need in one place</h3>
                        <p>Manage your budget, access planning resources and save favourite articles and businesses from
                            within your own personalised planning hub.</p>
                    </li>
                    <li>
                        <i class="offer-list-icon">
                            <img src="assets/images/streamline-icon.png" width="66" height="66" alt="Streamline icon">
                        </i>
                        <h3>Steamlined communication (coming soon! ✨)</h3>
                        <p>Consolidate your comms with venues and vendors all in one place so you can keep track of your
                            convos.</p>
                    </li>
                    <li>
                        <i class="offer-list-icon">
                            <img src="assets/images/thumbs-up-icon.png" width="53" height="53" alt="Thumbs up icon">
                        </i>
                        <h3>Customisable checklist (coming soon! ✨)</h3>
                        <p>Where to start and what to do? We’ve got you. Customise your own planning checklist so you
                            never feel overwhelmed.</p>
                    </li>
                    <li>
                        <i class="offer-list-icon">
                            <img src="assets/images/advice-icon.png" width="52" height="52" alt="Advice icon">
                        </i>
                        <h3>Advice we’d give our besties</h3>
                        <p>Money-saving tips, managing relationship dynamics, calculating your booze stocks - we’ll give
                            you advice without the BS.</p>
                    </li>
                </ul>
            </div>

            <div id="successStories" class="success-stories content-section">
                <h2 class="section-title">Hear it from other couples</h2>

                <div class="success-stories-item">
                    <div class="success-stories-img">
                        <img [src]="imagePathConstants.HOW_IT_WORKS + '/couple-review-cass-and-bede.png?tr=w-80,h-80,q-100,lossless-1,f-webp,e-sharpen-50'"
                            width="80" height="80" alt="Cass and bede">
                        Cass and Bede
                    </div>
                    <div class="success-stories-info">
                        <p>For the last two years, your site has been an absolute amazing source of information, support
                            and advice. I came here on the recommendation of a friend and have been pointing all my
                            engaged mates to Wedshed along the way. Thank you for all that your team does! Lots of love,
                            a Bride to Be, eeeeek!</p>
                    </div>
                </div>

                <div class="success-stories-item">
                    <div class="success-stories-img">
                        <img [src]="imagePathConstants.HOW_IT_WORKS + '/couple-review-alex-and-frankie.png?tr=w-80,h-80,q-100,lossless-1,f-webp,e-sharpen-50'"
                            width="80" height="80" alt="Alex and frankie">
                        Alex and Frankie
                    </div>
                    <div class="success-stories-info">
                        <p>THANK YOU for sharing information that is so sincerely useful and relevant. Honestly it is so
                            genuine and beyond the generic Google-able advice we see elsewhere. We have loved planning
                            our wedding using Wedshed and constantly refer people to it!</p>
                    </div>
                </div>

                <div class="success-stories-item">
                    <div class="success-stories-img">
                        <img [src]="imagePathConstants.HOW_IT_WORKS + '/couple-review-luke-and-chloe.png?tr=w-80,h-80,q-100,lossless-1,f-webp,e-sharpen-50'"
                            width="80" height="80" alt="Luke and chloe">
                        Chloe and Luke
                    </div>
                    <div class="success-stories-info">
                        <p>You guys are honestly gems. I hope people tell you that on the daily! Wedshed and your
                            Wedchat FB group has been the biggest support for us - I know for a fact your platform has
                            helped us plan a better wedding and with less stress. And save money along the way, boom!
                            Thank you so much. ❤️</p>
                    </div>
                </div>

                <!-- Not in current Phase -->
                <!-- <button class="show-more-btn show-less">Show less
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.04 6.51953V18.2197" stroke="#7093FF" stroke-miterlimit="10"
                            stroke-linecap="round" />
                        <path d="M8.04004 14.2207L12.04 18.2207L16.04 14.2207" stroke="#7093FF" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button> -->
            </div>

            @if(faqList && faqList.length > 0) {
            <div id="faqs" class="questions-answered content-section couples-faqs">
                <h2 class="section-title">Your questions answered</h2>

                <div class="accordion" id="qandaList">
                    @for (item of faqList; track $index) {
                    <div class="accordion-item">
                        <h3 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#qanda' + item.id" aria-expanded="false"
                                [attr.aria-controls]="'qanda' + item.id">
                                {{item.faqQuestion}}
                                <span class="faqs-arrow"></span>
                            </button>
                        </h3>
                        <div [id]="'qanda' + item.id" class="accordion-collapse collapse"
                            [ngClass]="{'show': $index == 0}" data-bs-parent="#qandaList">
                            <div class="accordion-body" [innerHTML]="item.faqAnswer| safeHtml"></div>
                        </div>
                    </div>
                    }
                </div>

                <div class="questions-answered-action">
                    <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION]" type="button"
                        class="secondary-btn">View more in our FAQs <img src="assets/images/arrow-right-icon.svg"
                            width="13" height="10" alt="Right icon">
                    </a>
                    <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION]" type="button"
                        class="secondary-btn">Still have questions? Contact us <img
                            src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="Right icon">
                    </a>
                </div>
            </div>
            }
        </div>
    </div>
</section>

<section class="bottom-banner-wrap">
    <div class="container">
        <div class="bottom-banner-info">
            <h2 class="section-title">Start planning from the comfort of your living room</h2>
            <p>Creating your profile will only take a few minutes and will help narrow down your search. Simply click
                the button below to create your account. If you get stuck or have questions, simply reach out to us.</p>
            <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" type="button" class="primary-btn">Create your
                couples profile today</a>
        </div>
        <div class="bottom-banner-img">
            <div class="bottom-banner-inner">
                <img [src]="imagePathConstants.HOW_IT_WORKS + '/start-planning.png'" width="1404" height="918"
                    alt="Start planning" appSetImageDimensions>
            </div>
        </div>
    </div>
</section>
