import {Component, ViewEncapsulation} from '@angular/core';
import {CommonConstants, CountryCodes, RoutesConstants} from '@coreconstant';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {GeneralService} from '../core/services/api-service/general.service';
import {lastValueFrom} from 'rxjs';
import {ResponseHandlerService} from '../core/services/common-service/response-handler.service';
import {RequestParamModel} from '../core/models/request-param-model';
import {cannotContainSpace} from '../core/services/common-service/common.service';
import {AuthenticationService} from '../core/services/api-service/authentication.service';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {IMaskModule} from 'angular-imask';
import {NgSelectModule} from '@ng-select/ng-select';
import {CommonModule} from '@angular/common';
import {SeoService} from '../core/services/common-service/seo.service';
import {RecaptchaModule} from 'ng-recaptcha-2';

@Component({
    selector: 'app-sign-up',
    imports: [RouterModule, IMaskModule, NgSelectModule, CommonModule, ReactiveFormsModule,RecaptchaModule],
    templateUrl: './sign-up.component.html',
    styleUrl: './sign-up.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class SignUpComponent {
    routesConstants = RoutesConstants
    commonConstants = CommonConstants
    countryCodes = CountryCodes
    requestParamModel!: RequestParamModel;
    signUpForm: FormGroup;
    currentStep = 0;
    fragment: string = this.commonConstants.BUSINESS_TYPE_VENUE
    steps = ['Step 1', 'Step 2', 'Step 3'];
    countryList = []
    regionList = []
    serviceTypes = []
    businessGoals = []
    selectedBusinessTypes = []
    isNextOrSubmit: boolean = false
    selectedServiceType = this.commonConstants.BUSINESS_TYPE_VENUE
    isFirstChangeToService = false
    isSubmitAndShowSuccess: boolean = false

    constructor(
        private readonly _formBuilder: FormBuilder,
        private _generalService: GeneralService,
        private _responseHandlerService: ResponseHandlerService,
        private _authenticationService: AuthenticationService,
        private _activatedRoute: ActivatedRoute,
        private _seoService: SeoService,
    ) {
        this.setMetaTags()
        this.requestParamModel = new RequestParamModel();
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Sign Up | Wedshed',
            description: 'Sign Up | Wedshed',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.SIGN_UP_NAVIGATION,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    ngOnInit() {
        this._activatedRoute.fragment.subscribe(fragment => {
            if (fragment) {
                this.fragment = fragment;
                if (fragment == this.commonConstants.BUSINESS_TYPE_SERVICE)
                    this.selectedServiceType = fragment
            }
        });

        this.signUpForm = this._formBuilder.group({
            step1: this._formBuilder.group({
                businessType: [this.fragment, [Validators.required]],
                name: ['', Validators.required],
                countryId: [null, [Validators.required]],
                stateId: ['', Validators.required],
                regionId: [null, Validators.required],
                serviceType: [null, this.fragment == this.commonConstants.BUSINESS_TYPE_SERVICE ? [Validators.required] : []],
                yearsInBusiness: [null, Validators.required],
                annualBookingNumbers: [null, Validators.required],
                isGettingStarted: [false],
                websiteUrl: ['', [Validators.pattern(this.commonConstants.WEBSITE_REGEX)]],
                instagramUserName: ['', cannotContainSpace],
            }),
            step2: this._formBuilder.group({
                achieveReason: ['', Validators.required],
            }),
            step3: this._formBuilder.group({
                firstName: ['', Validators.required],
                emailAddress: ['', [Validators.required, Validators.pattern(this.commonConstants.EMAIL_REGEX)]],
                countryIdForMobile: [this.commonConstants.AUSTRALIA_COUNTRY_CODE, Validators.required],
                mobileNumber: ['', [Validators.required, Validators.pattern(this.commonConstants.MOBILE_REGEX)]],
                description: [''],
                recaptcha: ['', Validators.required],
            }),
        });

        this.getCountries()
        this.getBusinessGoals()
    }

    onCaptchaResolved(captchaResponse: string) {
        this.signUpForm.get('step3')?.patchValue({ recaptcha: captchaResponse });
    }

    // Get the current step's FormGroup
    get getCurrentGroup() {
        const steps = ['step1', 'step2', 'step3'];
        return this.signUpForm.get(steps[this.currentStep])
    }


    /**
     * Change validation based on the business type
     * @param event
     */
    businessTypeChange(event: any) {
        if (event.target.value == this.commonConstants.BUSINESS_TYPE_VENUE) {
            this.selectedServiceType = this.commonConstants.BUSINESS_TYPE_VENUE;
            this.getCurrentGroup.get('serviceType').clearValidators();
            this.getCurrentGroup.get('serviceType').updateValueAndValidity();

        } else if (event.target.value == this.commonConstants.BUSINESS_TYPE_SERVICE) {
            if (!this.isFirstChangeToService) {
                this.isFirstChangeToService = true
                this.getServiceTypes()
            }
            this.selectedServiceType = this.commonConstants.BUSINESS_TYPE_SERVICE;
            this.getCurrentGroup.get('serviceType').setValidators([Validators.required]);
            this.getCurrentGroup.get('serviceType').updateValueAndValidity();
        }
    }

    addBusinessType(businessTypeId: number) {
        if (this.selectedBusinessTypes.includes(businessTypeId)) {
            this.selectedBusinessTypes = this.selectedBusinessTypes.filter(id => id != businessTypeId); // Remove if already selected
        } else {
            // if (this.selectedBusinessTypes.length <= 4) {
            this.selectedBusinessTypes.push(businessTypeId); // Add to selected list
            // }
        }
        this.getCurrentGroup.get('achieveReason').setValue(this.selectedBusinessTypes);
    }

    /**
     * Change validation based on the getting started field
     * @param event
     */
    isGettingStartedChange(event: any) {
        if (event.target.checked) {
            this.getCurrentGroup.get('yearsInBusiness').clearValidators();
            this.getCurrentGroup.get('annualBookingNumbers').clearValidators();
        } else {
            this.getCurrentGroup.get('yearsInBusiness').setValidators([Validators.required]);
            this.getCurrentGroup.get('annualBookingNumbers').setValidators([Validators.required]);
        }
        this.getCurrentGroup.get('yearsInBusiness').updateValueAndValidity();
        this.getCurrentGroup.get('annualBookingNumbers').updateValueAndValidity();
    }

    /**
     * Get the country list
     */
    getCountries() {
        var getListing = this._generalService.getCountries()
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.countryList = res["data"]
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Call API for regions based on the country id
     */
    onSelectCountry() {
        let step1Values = this.signUpForm.controls['step1'] as FormGroup
        let selectedCountryId = step1Values.controls['countryId']?.value;
        step1Values.controls['stateId'].setValue(null);
        step1Values.controls['regionId'].setValue(null);

        delete this.requestParamModel.pageSize;
        this.requestParamModel.removeDefaultFilter('countryId');
        this.requestParamModel.addDefaultFilter('countryId', selectedCountryId, 'number');

        var getRegions = this._generalService.getStatesAndRegions(this.requestParamModel)
        lastValueFrom(getRegions).then((res: any) => {
            if (res["status"]) {
                this.regionList = res["data"]
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Set the State id based on the region data
     */
    onSelectRegion() {
        let step1Values = this.signUpForm.controls['step1'] as FormGroup
        let selectedRegionId = step1Values.controls['regionId']?.value;

        let selectedRegionData = this.regionList.find(region => region.id == selectedRegionId)
        if (selectedRegionData && selectedRegionData.stateId) {
            step1Values.controls['stateId'].setValue(selectedRegionData.stateId);
        }
    }

    /**
     * Get the Service types
     */
    getServiceTypes() {
        var getServiceTypes = this._generalService.getServiceTypes()
        lastValueFrom(getServiceTypes).then((res: any) => {
            if (res["status"]) {
                this.serviceTypes = res["data"]
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    getBusinessGoals() {
        var getBusinessGoals = this._generalService.getBusinessGoals()
        lastValueFrom(getBusinessGoals).then((res: any) => {
            if (res["status"]) {
                this.businessGoals = res["data"]
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Click on the next button
     */
    nextStep() {
        this.isNextOrSubmit = true
        const currentGroup = this.getCurrentGroup;

        // Mark current step's controls as touched
        if (currentGroup.invalid) {
            currentGroup.markAllAsTouched(); // Show validation errors for the current step
        } else {
            this.currentStep++; // Move to the next step
        }
    }

    /**
     * Click on the prev button
     */
    prevStep() {
        this.currentStep--;
    }

    /**
     * Click on the submit and store the data
     * @returns
     */
    onSubmit() {
        this.isNextOrSubmit = true

        if (this.signUpForm.invalid) {
            this.signUpForm.markAllAsTouched(); // Show validation errors for the current step
            return false;
        }

        const step1Values = this.signUpForm.get('step1').value;
        const step2Values = this.signUpForm.get('step2').value;
        const step3Values = this.signUpForm.get('step3').value;

        const flattenedValues = {
            ...step1Values,
            ...step2Values,
            ...step3Values
        };

        let addUpdateRecord = this._authenticationService.addBusiness(flattenedValues)
        lastValueFrom(addUpdateRecord).then((response: any) => {
            if (response["status"] == true) {
                // this._responseHandlerService.showSuccessMessageGeneral(response["message"]);
                this.isSubmitAndShowSuccess = true
            } else {
                this._responseHandlerService.showErrorMessageGeneral(response["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error);
        });
    }
}
