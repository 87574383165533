<div class="modal-header">
    <h4 class="modal-title">
        <i class="modal-title-icon"><img src="assets/images/cursor-click-icon.png" width="72" height="51"
                alt="Cursor click icon"></i>
        Join the waitlist
    </h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="closeModal()" title="Close">
        <img src="assets/images/close-icon.svg" width="13" height="13" alt="Close icon">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="joinForm">
        <div class="form-group">
            <label>Email address</label>
            <input class="form-control" type="email" placeholder="Enter email" formControlName="email">

            @if (submitted) {
            @if (joinForm.controls['email']?.touched &&
            joinForm.controls['email']?.hasError('required')) {
            <div class="error">
                Email address is required
            </div>
            }
            @else if (joinForm.controls['email']?.touched &&
            !joinForm.controls['email']?.hasError('required') &&
            joinForm.controls['email']?.hasError('pattern')) {
            <div class="error">
                Email address is not a valid
            </div>
            }
            }
        </div>

        <label class="custom-radio accept-checkbox">
            <input type="checkbox" formControlName="isSendEmail">
            <span class="checkmark"></span>
            I consent to Wedshed sending me marketing emails relating to the upcoming planning hub. <a
                href="javascript:void(0)">Learn more</a>
        </label>

    </form>
</div>

<div class="modal-footer">
    <button type="button" class="primary-btn" (click)="onSubmit()">Join
    </button>
    <button type="button" class="secondary-btn" (click)="closeModal()">Close</button>
</div>
