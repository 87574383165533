import { Injectable } from '@angular/core';
import { ApiUrlConstants } from '../../constants/api-url-constants';
import { httpConfig, HttpService } from '../http-service/http.service';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {

    baseUrlApi = ApiUrlConstants.COMMON_BASE_URL;
    searchBaseUrlApi = ApiUrlConstants.SEARCH_BASE_URL;
    generalBaseUrlApi = ApiUrlConstants.GENERAL_BASE_URL
    httpConfig: httpConfig;

    constructor(
        private _httpService: HttpService
    ) {
    }

    /**
     * Get system settings
     * @returns 
     */
    getSystemSettings() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-settings',
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get Countries
     * @returns 
     */
    getCountries() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-countries',
            method: 'get',
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get states
     * @returns 
     */
    getStates() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-states',
            method: 'get',
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get states and regions
     * @param input 
     * @returns 
     */
    getStatesAndRegions(input) {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-state-regions',
            method: 'get',
            filterParameters: input,
            isRequestModelUsed: true,
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get service types
     * @returns 
     */
    getServiceTypes() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-vendor-types',
            method: 'get',
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get business goals
     * @returns 
     */
    getBusinessGoals() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-business-goals',
            method: 'get',
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get venue styles
     * @returns 
     */
    getVenueStyles() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-venue-styles',
            method: 'get',
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get facilities
     * @returns 
     */
    getFacilities() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-venue-facilities',
            method: 'get',
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get accommodations
     * @returns 
     */
    getAccommodation() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-venue-accommodations',
            method: 'get',
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get the search data of the Term (Header section)
     * @param keyword 
     * @returns 
     */
    getSearchResult(keyword) {
        this.httpConfig = {
            url: this.searchBaseUrlApi + '/' + keyword,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get the search data of the search page
     * @param keyword 
     * @param input 
     * @returns 
     */
    getSearchAllResult(input) {
        this.httpConfig = {
            url: this.searchBaseUrlApi + '/all-results',
            method: 'get',
            filterParameters: input,
            isRequestModelUsed: true,
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get Blogs Categories
     * @returns 
     */
    getBlogCategories() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-blog-categories',
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Join the NewsLetter
     * @param input 
     * @returns 
     */
    joinTheNewsLetter(input: any) {
        this.httpConfig = {
            url: this.generalBaseUrlApi + '/user-newsletter',
            method: 'post',
            data: input
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get the enquiry reasons
     * @returns 
     */
    getEnquiryReasons() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-enquiry-reasons',
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * create Contact enquiry
     * @param input 
     * @returns 
     */
    createContactEnquiry(input: any) {
        this.httpConfig = {
            url: this.baseUrlApi + '/create-contact-enquiry',
            method: 'post',
            data: input
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * create real wedding enquiry
     * @param input 
     * @returns 
     */
    realWeddingEnquiry(input: any) {
        this.httpConfig = {
            url: this.baseUrlApi + '/create-real-wedding-enquiry',
            method: 'post',
            data: input
        }
        return this._httpService.init(this.httpConfig);
    }
}
