//======================== Page Title Start ========================
.page-title-wrap {
    padding: 40px 0;
    background-color: #272338;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-bottom: 1px solid #272338;
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #E6E6E6;
    position: relative;
    min-height: 300px;
    align-items: center;
    display: flex;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        pointer-events: none;
    }

    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    .container {
        position: relative;
        padding-left: 80px;
        padding-right: 80px;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .featured-label {
        font-family: Aeonik, sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        padding: 4px 10px;
        color: #272338;
        background: #94B3A5;
        border: 1px solid #272338;
        border-radius: 30px;
        margin: 0 0 24px;
        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 18px;
            padding: 3px 10px;
            margin: 0 0 16px;
        }
    }

    .page-title-info {
        max-width: 1000px;
        margin: 0;
    }

    h1 {
        font-family: Livorno, sans-serif;
        font-size: 50px;
        line-height: 60px;
        font-weight: 400;
        color: #fff;
        letter-spacing: -0.01em;
        margin: 0 0 8px;
        text-shadow: -1px -1px 0 #272338, 1px -1px 0 #272338, -1px 1px 0 #272338, 1px 1px 0 #272338;

        @media screen and (max-width: 1280px) {
            font-size: 44px;
            line-height: 54px;
        }
        @media screen and (max-width: 991px) {
            font-size: 40px;
            line-height: 48px;
        }
    }

    .region-location {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color: #fff;
        margin: 0 0 16px;
    }

    p {
        margin: 0;
    }
}

//Showing Results
.showing-results-wrap {
    padding: 16px 0;
    background: #fff;
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #4D4D4D;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #272338;
}

.venues-section {
    padding: 40px 0;
}
