import {Pipe, PipeTransform} from '@angular/core';
import {isValidValue} from '../services/common-service/common.service';

@Pipe({
    name: 'dateFormatter',
    standalone: true
})
export class DateFormatterPipe implements PipeTransform {

    transform(value: any): string {
        let dateValue = isValidValue(value?.publishedAt) ? value?.publishedAt : value?.createdAt;
        const date = new Date(dateValue);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', {month: 'long'});
        const year = date.getFullYear();

        return `${day} ${month}, ${year}`;


    }

}
