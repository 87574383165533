import {Component, ViewEncapsulation} from '@angular/core';
import {SeoService} from '../core/services/common-service/seo.service';
import {RoutesConstants} from '@coreconstant';

@Component({
    selector: 'app-privacy-policy',
    imports: [],
    templateUrl: './privacy-policy.component.html',
    styleUrl: './privacy-policy.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class PrivacyPolicyComponent {
    routesConstants = RoutesConstants

    constructor(
        private _seoService: SeoService,
    ) {
        this.setMetaTags()
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Privacy Policy | Wedshed',
            description: 'Privacy Policy | Wedshed',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.PRIVACY_POLICY,
        }
        this._seoService.setMetaTags(metaTagObject)
    }
}
