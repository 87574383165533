import { environment } from "src/environments/environment";

export class CommonConstants {
    public static IMAGE_KIT_URL = environment.imageKitUrl;
    public static GOOGLE_CAPTCHA_KEY = environment.googleRecaptchaKey;

    public static AUSTRALIAN_MOBILE_NUMBER_REGEX = /^(?:\+?61|0)(?:4|\(04\))(?:[ ]?\d){8}$/;
    public static AUSTRALIA_COUNTRY_CODE = '+61'

    public static MOBILE_REGEX = /^\d{10}$/;
    public static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$#!%*?&]{8,}$/;
    public static EMAIL_REGEX = /^(?!.*@(yopmail|mailinator)\.com$)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public static WEBSITE_REGEX = /^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    public static LOGO_PATH = 'assets/images/wedshed-logo.svg'

    public static COMMON_ERROR_MESSAGE = 'We apologize for the inconvenience. An error occurred, please try again later.';

    public static LINK_COPY = 'Link copied to clipboard';
    public static JOIN_NEWSLETTER = 'Joined the Newsletter';
    public static SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later';

    public static DEFAULT_NO_IMAGE = 'assets/images/placeholder-image.svg';
    public static DEFAULT_USER_IMAGE = 'assets/images/default-user.svg';
    public static ALL_STYLE_ICON = 'assets/images/all.svg';

    public static DISPLAY_DATE_FORMAT = "DD/MM/YYYY hh:mm A"
    public static DATE_FORMAT_TYPE_MOMENT = 'DD/MM/YYYY'

    public static IMAGE_ALLOWED_FILE_TYPE = ["jpg", "png", "jpeg", "svg"];
    public static IMAGE_UPLOAD_SIZE = 6000000;
    public static IMAGE_SIZE_MB = 6;

    public static PROFILE_IMAGE_ALLOWED_FILE_TYPE = ["jpg", "png", "jpeg", "svg"];
    public static PROFILE_IMAGE_UPLOAD_SIZE = 10000000;
    public static PROFILE_IMAGE_SIZE_MB = 10;

    public static BUSINESS_TYPE_SERVICE = 'service';
    public static BUSINESS_TYPE_VENUE = 'venue';

    // 3rd party links
    public static PLANNING_REGISTRY_LINK = 'https://www.givewithgravy.com';
    public static PLANNING_WEDSHARE_LINK = 'https://wedshare.com.au';
    public static WEDSHED_STORE_LINK = 'https://wedshed.store';
    public static COLLECTION_GUIDES_LINK = this.WEDSHED_STORE_LINK + '/collections/guides';
    public static COLLECTION_WEDDINGTEES_LINK = this.WEDSHED_STORE_LINK + '/collections/weddingtees';
    public static COLLECTION_ACCESSORIES_LINK = this.WEDSHED_STORE_LINK + '/collections/accessories';
    public static COLLECTION_ALL_LINK = this.WEDSHED_STORE_LINK + '/collections/all';
    public static OUR_BOOK_LINK = 'https://www.amazon.com.au/Get-Wed-complete-planning-wedding/dp/1761069152/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr='
    public static WEDSHED_VOW_WRITING_LINK = this.WEDSHED_STORE_LINK + '/collections/guides/products/wedshed-ebook-the-vow-writing-guide';
    public static WEDSHED_WEDDING_PRIORITIES_LINK = this.WEDSHED_STORE_LINK + '/collections/guides/products/wedshed-ebook-set-your-wedding-priorities';

    public static MAX_SHOW_COUNT_FILTER = 3
    public static PAGE_SIZE_COUNT = 20
    public static BLOG_PAGE_SIZE_COUNT = 24
    public static SHOW_FIRST_BLOG_COUNT = 8
    public static ALL_OPTION_PAGE_SIZE_COUNT = 8
    public static GOOGLE_MAP_URL_TO_OPEN = 'https://www.google.com/maps/search/?api=1&query='
    public static DEFAULT_SHOW_IMAGES_VENUE_VENDOR = 5
    public static DEFAULT_SHOW_VENDOR_TYPE_COUNT = 2

    // Global Search modules
    public static MODULE_ALL = 'all';
    public static MODULE_VENUES = 'venues';
    public static MODULE_VENDORS = 'vendors';
    public static MODULE_ARTICLES = 'articles';

    // Stay 22 map url and configurations
    public static STAY22_MAP_URL = 'https://www.stay22.com/embed/gm'
    public static STAY22_URL_AID_PARAMATER = 'wedshed' //wedshed-figtreebyronbay
    public static STAY22_MAP_CONFIGURATIONS_URL = '&maincolor=7093ff&loadingbarcolor=7093ff&markerimage=' + environment.websiteUrl + '/assets/images/logo_white_small.svg&showgmapsicon=true&hideshare=true&hidesettings=true&hidelanguage=true&hidespatial=true&hidenavbar=true&hidebrandlogo=true&hidemapattribution=true&disableautohover=true&adults=2'
    public static STAY22_MAP_CONFIGURATIONS_URL_DETAIL_PAGE = '&hidesettings=false&hidesearchbar=true&hideguestpicker=true&hidecheckinout=true&hidepricefilter=true&rentalapi=false&skipotasrp=true&hideroomtypefilter=true&hidestarrating=true&nopop=true&viewmode=map'

    // FAQ Category
    public static OVERVIEW_FAQ = 1
    public static VENDORS_FAQ = 2
    public static VENUES_FAQ = 3
    public static COUPLES_FAQ = 4

    public static HEADER_OFFSET = 60

    public static ENQUIRY_EMAIL = 'hello@wedshed.com.au'
    public static ENQUIRY_EMAIL_SUBJECT = 'Venue Management enquiry'

    public static FRONTEND_PREFIX = 'https://wedshed.com.au/'
    public static FRONTEND_PREFIX_WITH_WWW = 'https://www.wedshed.com.au/';

    public static BLOG_PINTEREST_URL = 'https://www.pinterest.com/pin/create/button'
}
