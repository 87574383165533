import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'safeHtml',
    standalone: true
})

export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }

    transform(value) {
        if (!value) return value;
        // Replace non-breaking spaces (&nbsp;) with regular spaces
        const sanitizedValue = value.replace(/&nbsp;/g, ' ');
        return this.sanitized.bypassSecurityTrustHtml(sanitizedValue);
    }
}
