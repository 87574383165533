//======================== Page Title Start ========================
.page-title-wrap {
    background: #272338;
    border-bottom: 1px solid #272338;
    padding: 73px 0;
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #E2E0EB;
    text-align: center;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }
    @media screen and (max-width: 480px) {
        padding: 32px 26px;
        height: 526px;
        align-items: center;
        display: flex;
    }
    @media screen and (max-width: 380px) {
        height: calc(100dvh - 178px);
        max-height: 526px;
    }

    .page-title-info {
        max-width: 550px;
        margin: 0 auto;
    }

    h1 {
        font-size: 48px;
        line-height: 52px;
        margin: 0 auto 16px;
        color: #fff;
        text-align: center;
        display: inline-flex;
        position: relative;

        @media screen and (max-width: 767px) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 8px;
        }

        @media screen and (max-width: 400px) {
            font-size: 38px;
            line-height: 46px;
        }

        @media screen and (max-width: 380px) {
            font-size: 36px;
            line-height: 44px;
        }

        @media screen and (max-width: 360px) {
            font-size: 34px;
            line-height: 42px;
        }
        @media screen and (max-width: 359px) {
            font-size: 30px;
            line-height: 38px;
        }

        .title-top-icon {
            position: absolute;
            top: -19px;
            left: -30px;
            display: inline-flex;
        }

        .title-bottom-icon {
            position: absolute;
            right: -16px;
            bottom: -22px;
            display: inline-flex;

            @media screen and (max-width: 480px) {
                right: -30px;
                bottom: -15px;
            }
        }
    }

    p {
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #E2E0EB;
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
    }

    .primary-btn {
        margin: 32px 0 0;
        border-color: transparent;

        @media screen and (max-width: 767px) {
            margin: 24px 0 0;
        }

        &:hover {
            border-color: #fff;
            color: #fff;
        }
    }
}

//======================== Page Title End ========================

//What's to come
.whats-to-come {
    padding: 48px 0 0;
    text-align: center;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        padding: 32px 0 0;

        .section-title {
            margin-bottom: 16px;
        }
    }

    .whats-come-list {
        margin-top: 48px;

        @media screen and (min-width: 1440px) {
            background: linear-gradient(90deg, #A8AED6 50%, #A9BEFF 50%);
        }

        @media screen and (max-width: 767px) {
            margin-top: 32px;
        }

        .whats-come-inner {
            border: 1px solid #272338;
            flex-wrap: wrap;
            display: flex;
            max-width: 1440px;
            margin: 0 auto;

            @media screen and (max-width: 480px) {
                border-right: none;
                border-left: none;
            }

            .whats-come-item {
                flex: 1;
                padding: 24px;
                font-family: Aeonik, sans-serif;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                color: #272338;
                text-align: center;
                position: relative;

                @media screen and (max-width: 991px) {
                    flex: 0 0 50%;
                }

                @media screen and (max-width: 480px) {
                    flex: 0 0 100%;
                    padding: 32px 24px 16px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    bottom: -1px;
                    left: -1px;
                    border: 1px solid #272338;
                    pointer-events: none;

                    @media screen and (max-width: 480px) {
                        border-right: none;
                        border-left: none;
                        right: 0;
                        left: 0;
                    }
                }

                &:first-child {
                    background: #A8AED6;
                }

                &:nth-child(2) {
                    background: #94B3A5;
                }

                &:nth-child(3) {
                    background: #EFB7A6;
                }

                &:last-child {
                    background: #A9BEFF;
                }

                .whats-come-item-image {
                    display: inline-flex;
                    border-radius: 16px;

                    img {
                        width: 100%;
                        height: auto;
                        max-width: 100%;
                        vertical-align: middle;
                        border-radius: 16px;
                        border: 1px solid #272338;
                    }
                }

                h3 {
                    font-family: Aeonik, sans-serif;
                    font-size: 22px;
                    line-height: 26px;
                    font-weight: 500;
                    color: #272338;
                    letter-spacing: -0.01em;
                    text-align: center;
                    margin: 16px 0 8px;
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}

// Stay in Loop
.stay-in-loop {
    background: #FFE6D5;
    padding: 84px 0;

    @media screen and (max-width: 1100px) {
        padding: 60px 0;
    }

    @media screen and (max-width: 991px) {
        padding: 48px 0;
    }

    .container {
        padding-left: 80px;
        padding-right: 80px;
        gap: 32px 24px;
        justify-content: space-between;
        align-items: center;
        display: flex;

        @media screen and (max-width: 1200px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1100px) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @media screen and (max-width: 575px) {
            flex-wrap: wrap;
            flex-direction: column;
            text-align: center;
        }
    }

    .stay-in-loop-info {
        max-width: 560px;
        flex: 1;
    }

    .section-title {
        margin-bottom: 12px;

        @media screen and (max-width: 767px) {
            font-size: 26px;
            line-height: 31px;
        }
    }

    .section-info {
        font-size: 16px;
        line-height: 22px;
        color: #272338;
    }

    .primary-btn {
        margin-top: 32px;

        @media screen and (max-width: 767px) {
            margin-top: 24px;
        }
    }

    .stay-in-loop-img {
        //width: 44%;
        width: 460px;
        min-width: 460px;
        max-width: 100%;

        @media screen and (max-width: 991px) {
            width: 300px;
            min-width: 300px;
        }

        @media screen and (max-width: 480px) {
            width: 100%;
            min-width: 100%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

::ng-deep .modal-dialog {
    //&.join-waitlist-modal {
    //    max-width: 436px;
    //
    //    @media screen and (max-width: 767px) {
    //        max-width: 100%;
    //    }
    //}

    &.waitlist-modal {
        //max-width: 436px;
        //
        //@media screen and (max-width: 767px) {
        //    max-width: 100%;
        //}

        .modal-body {
            text-align: center;

            p {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .modal-footer {
            display: none;

            @media screen and (max-width: 767px) {
                display: block;
            }
        }
    }
}
