import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
    selector: '[appSetImageDimensions]',
    standalone: true,
})
export class SetImageDimensionsDirective {

    @Input() isProductListPage: boolean = false;

    private resizeTimeout: any;
    private lastWidth: number | null = null;
    private lastHeight: number | null = null;
    private isResizing: boolean = false;
    private sizeMultiplication = 2
    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) { }

    ngAfterViewInit() {
        const imgElement = this.el.nativeElement;

        // Add the load event listener to the image to detect when it has been fully loaded
        imgElement.onload = () => this.updateImageSrcAfterResize();

        // If the image is already loaded (for non-lazy loading images), call updateImageSrcAfterResize directly
        if (imgElement.complete) {
            this.updateImageSrcAfterResize();
        }
    }

    // @HostListener('window:resize')
    // onWindowResize() {
    //   if (this.isResizing) {
    //     return; // Prevent resizing logic if already being handled
    //   }
    //   // Debounce the resize event to prevent excessive execution
    //   if (this.resizeTimeout) {
    //     clearTimeout(this.resizeTimeout);
    //   }
    //
    //   this.isResizing = true;
    //
    //   this.resizeTimeout = setTimeout(() => {
    //     this.isResizing = false;
    //     // this.updateImageSrcAfterResize();
    //   }, 500); // 200ms debounce time
    // }

    private updateImageSrcAfterResize(): void {
        const imgElement = this.el.nativeElement;
        let parentElement = imgElement.parentElement;

        // while (parentElement && parentElement.tagName !== 'DIV') {
        //   parentElement = parentElement.parentElement;
        // }

        if (parentElement && imgElement.complete) {
            // Use getBoundingClientRect to get more accurate dimensions
            // const parentRect = parentElement.parentElement.getBoundingClientRect();
            const parentRect = this.isProductListPage ? parentElement.parentElement.getBoundingClientRect() : parentElement.getBoundingClientRect();
            const width = Math.round(parentRect.width);
            const height = Math.round(parentRect.height);

            if (this.isProductListPage && height > 0 && (height !== this.lastHeight)) {
                // this.lastWidth = width;
                this.lastHeight = height;

                // Set the width and height attributes on the image element
                // if(!this.isProductListPage) this.renderer.setAttribute(imgElement, 'width', `${width}`);
                this.renderer.setAttribute(imgElement, 'height', `${height}`);

                // Update the image src with the transformation string `?tr=w-${width},h-${height}`
                this.updateImageSrc(imgElement, width, height);
            } else if (!this.isProductListPage && width > 0 && (width !== this.lastWidth)) {
                this.lastWidth = width;
                // this.lastHeight = height;

                // Set the width and height attributes on the image element
                this.renderer.setAttribute(imgElement, 'width', `${width}`);
                // this.renderer.setAttribute(imgElement, 'height', `${height}`);

                // Update the image src with the transformation string `?tr=w-${width},h-${height}`
                this.updateImageSrc(imgElement, width, height);
            } else if (width > 0 && height > 0 && (width !== this.lastWidth || height !== this.lastHeight)) {
                this.lastWidth = width ;
                this.lastHeight = height;

                // Set the width and height attributes on the image element
                this.renderer.setAttribute(imgElement, 'width', `${width}`);
                this.renderer.setAttribute(imgElement, 'height', `${height}`);

                // Update the image src with the transformation string `?tr=w-${width},h-${height}`
                this.updateImageSrc(imgElement, width, height);
            }

            // Ensure valid width and height before setting attributes and updating the URL
            // if ((this.isProductListPage || (!this.isProductListPage && width > 0)) && height > 0 && (width !== this.lastWidth || height !== this.lastHeight)) {
            //   this.lastWidth = width;
            //   this.lastHeight = height;

            //   // Set the width and height attributes on the image element
            //   if(!this.isProductListPage) this.renderer.setAttribute(imgElement, 'width', `${width}`);
            //   this.renderer.setAttribute(imgElement, 'height', `${height}`);

            //   // Update the image src with the transformation string `?tr=w-${width},h-${height}`
            //   this.updateImageSrc(imgElement, width, height);
            // }
        }
    }

    private updateImageSrc(imgElement: HTMLImageElement, width: number, height: number): void {

        let originalSrc = imgElement.src;

        // Check if the transformation is already applied to avoid unnecessary updates
        if (!this.isTransformationApplied(originalSrc, width, height)) {
            width = width * this.sizeMultiplication
            height = height * this.sizeMultiplication
            // Remove any existing transformations (if present)
            originalSrc = this.removeTransformation(originalSrc);
            // Append the new transformation parameters `tr=w-${width},h-${height}`
            // const transformationParam = this.isProductListPage ? `tr=h-${height}` : `tr=w-${width},h-${height}`;
            const transformationParam = this.isProductListPage ? `tr=h-${height}` : `tr=w-${width}${height > 0 ? ',h-' + height : ''}`;
            const separator = originalSrc.includes('?') ? '&' : '?';
            let updatedSrc = `${originalSrc}${separator}${transformationParam}`;
            if (imgElement.alt === 'Outlet Flooring' || imgElement.alt === 'Outlet Walling') {
                updatedSrc = updatedSrc + ',fo-bottom';
            }
            updatedSrc = updatedSrc + ',q-100,f-webp,e-sharpen-50,lossless-1'

            // Set the updated src attribute on the image
            this.renderer.setAttribute(imgElement, 'src', updatedSrc);
        }
    }

    private removeTransformation(url: string): string {
        const urlObj = new URL(url);

        // Remove the "tr" query parameter if it exists
        if (urlObj.searchParams.has('tr')) {
            urlObj.searchParams.delete('tr');
        }

        return urlObj.toString();
    }

    private isTransformationApplied(url: string, width: number, height: number): boolean {
        const urlObj = new URL(url);
        width = width * this.sizeMultiplication
        height = height * this.sizeMultiplication
        // const transformationParam = this.isProductListPage ? `tr=h-${height}` : `tr=w-${width},h-${height}`;
        const transformationParam = this.isProductListPage ? `tr=h-${height}` : `tr=w-${width}${height > 0 ? ',h-' + height : ''}`;

        return urlObj.searchParams.has('tr') && urlObj.searchParams.get('tr') === transformationParam;
    }

    ngOnDestroy(): void {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
    }
}
