.single-page-title {
    padding: 48px 0;
    border-bottom: 1px solid #272338;
    font-family: Aeonik, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #272338;
    text-align: center;

    @media screen and (max-width: 991px) {
        padding: 32px 0;
    }

    @media screen and (max-width: 767px) {
        padding: 24px 0;
    }

    .container {
        max-width: 1072px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .section-title {
        margin: 0 0 16px;

        &:last-child {
            margin-bottom: 0;
        }

        @media screen and (max-width: 991px) {
            margin-bottom: 8px;
        }

        @media screen and (max-width: 359px) {
            font-size: 28px;
            line-height: 34px;
        }
    }

    p {
        margin: 0;
    }
}

.single-page-content {
    padding: 48px 0;
    background: #FFF5EE;
    font-family: Aeonik, sans-serif;
    @media screen and (max-width: 991px) {
        padding: 32px 0;
    }

    .container {
        max-width: 1072px;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.cms {
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #4D4D4D;

    p {
        margin: 0 0 24px;

        &:last-child {
            margin-bottom: 0;
        }

        @media screen and (max-width: 767px) {
            margin: 0 0 16px;
        }
    }

    h2, h3, h4, h5, h6 {
        font-family: Livorno, serif;
        font-weight: 400;
        color: #272338;
        letter-spacing: -0.01em;
        margin: 0 0 12px;
        @media screen and (max-width: 767px) {
            margin: 0 0 8px;
        }

        * {
            font-family: Livorno, serif;
        }

        a {
            font-weight: normal;
        }

        span strong, b {
            font-family: Livorno, serif;
            font-weight: normal;
        }
    }

    h2 {
        font-size: 32px;
        line-height: 38px;

        @media screen and (max-width: 991px) {
            font-size: 30px;
            line-height: 36px;
        }
    }

    h3 {
        font-size: 26px;
        line-height: 32px;
    }

    h4 {
        font-size: 22px;
        line-height: 28px;
    }

    h5 {
        font-size: 18px;
        line-height: 24px;
    }

    a {
        color: #7093FF;
        transition: all 0.35s ease 0s;

        &:hover {
            color: #272338;
        }
    }

    strong, b {
        font-weight: 500;
        color: #272338;
    }


    ul, ol {
        margin: 0 0 24px;
        padding: 0;
        list-style-type: none;

        &:last-child {
            margin-bottom: 0;
        }

        > li {
            position: relative;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            ol, ul {
                margin: 12px 0;
            }
        }
    }

    ul {
        > li {
            padding-left: 26px;

            &::before {
                content: "";
                position: absolute;
                top: 5px;
                left: 2px;
                width: 12px;
                height: 12px;
                display: inline-block;
                background: #272338;
                transform: rotate(45deg);
                border-radius: 2px;
            }

            &::after {
                content: "";
                position: absolute;
                top: 8px;
                left: 5px;
                width: 6px;
                height: 6px;
                border-radius: 10px;
                background: #FFFBF8;
                display: inline-block;
            }
        }
    }

    ol {
        counter-reset: li;

        > li {
            padding-left: 32px;

            &::before {
                content: "";
                position: absolute;
                top: 4px;
                left: 2px;
                width: 16px;
                height: 16px;
                display: inline-block;
                background: #272338;
                transform: rotate(45deg);
                border-radius: 2px;
                pointer-events: none;
            }

            &::after {
                content: counter(li);
                counter-increment: li;
                position: absolute;
                top: 6px;
                left: 2px;
                width: 16px;
                display: inline-block;
                font-size: 11px;
                line-height: 12px;
                font-weight: 500;
                color: #fff;
                text-align: center;
                height: auto;
                background: transparent;
                border-radius: 0;
                pointer-events: none;
            }
        }
    }

    .font-22 {
        font-size: 22px;
        line-height: 28px;
        @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    .mb-12 {
        margin-bottom: 12px;
    }
}
