import {Component, ViewEncapsulation} from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImagePathConstants, RoutesConstants } from '@coreconstant';
import { SetImageDimensionsDirective } from 'src/app/core/directives/set-image-dimensions.directive';
import {SeoService} from '../../core/services/common-service/seo.service';

@Component({
    selector: 'app-about',
    imports: [SetImageDimensionsDirective, RouterModule],
    templateUrl: './about.component.html',
    styleUrl: './about.component.scss',
    encapsulation: ViewEncapsulation.None
})

export class AboutComponent {
    imagePathConstants = ImagePathConstants
    routesConstants = RoutesConstants
    selectedIndex: number = 0;

    selectedTitle: string = "Two Aussie friends have dreamed up a brilliant website - to help brides have the day of their dreams.";
    testimonials = [
        { title: "Sydney Morning Herald", image: "/sydney-morning-herald.svg", width: 228, height: 25 },
        { title: "Today", image: "/today.svg", width: 41, height: 31 },
        { title: "Collective Hub", image: "/collective-hub.svg", width: 149, height: 25 },
        { title: "Broadsheet", image: "/broadsheet.svg", width: 199, height: 19 },
        { title: "BuzzFeed", image: "/buzzfeed.svg", width: 123, height: 23 },
        { title: "Marie Claire", image: "/marie-claire.svg", width: 143, height: 23 },
        { title: "Daily Mail", image: "/daily-mail.svg", width: 178, height: 30 },
        { title: "Hello May", image: "/hello-may.svg", width: 135, height: 20 }
    ];

    constructor(
        private _seoService: SeoService,
    ) {
        this.setMetaTags()
    }
    ngOnInit() { }

    setMetaTags() {
        let metaTagObject = {
            title: 'About Wedshed – Your Go-To Wedding Planning Platform',
            description: 'Wedshed simplifies wedding planning by offering a curated selection of unique venues and trusted vendors. Plan your perfect wedding effortlessly!',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.HOW_IT_WORKS_ABOUT_NAVIGATION,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    changeTitle(newTitle: string, index: number) {
        this.selectedTitle = `Two Aussie friends have dreamed up a brilliant website - to help brides have the day of their dreams.`;
        this.selectedIndex = index;
    }

}
