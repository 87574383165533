import {Component, Inject, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {isPlatformBrowser, ViewportScroller} from '@angular/common';
import { Title } from '@angular/platform-browser';
import { NgHttpLoaderComponent, Spinkit } from 'ng-http-loader';

@Component({
    selector: 'app-root',
    imports: [NgHttpLoaderComponent, RouterModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    title = 'Wedshed';
    public spinkit = Spinkit;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _router: Router,
        readonly viewportScroller: ViewportScroller
    ) {
    }

    ngOnInit() {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    this.scrollTo();  // Instant scroll
                }, 500);  // Small delay ensures iPhone processes it
            }
        });
    }

    scrollTo() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }
}
