.faqs-header {
    padding: 48px 0 24px;
    border-bottom: 1px solid #272338;
    font-family: Aeonik, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #272338;
    text-align: center;
    @media screen and (max-width: 991px) {
        padding: 40px 0 24px;
    }
    @media screen and (max-width: 767px) {
        padding: 24px 0;
    }

    .section-title {
        font-size: 40px;
        line-height: 48px;
        margin: 0 0 16px;
        @media screen and (max-width: 991px) {
            margin: 0 0 8px;
        }
    }

    p {
        margin: 0;
    }
}

.faqs-wrap {
    padding: 48px 0;
    font-family: Aeonik, sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #272338;
    word-break: break-word;
    @media screen and (max-width: 991px) {
        padding: 40px 0;
    }
    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }
    @media screen and (max-width: 767px) {
        background: #ffffff;
    }

    .container {
        padding-left: 80px;
        padding-right: 80px;
        gap: 48px;
        align-items: flex-start;
        display: flex;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @media screen and (max-width: 1199px) {
            gap: 32px;
        }

        @media screen and (max-width: 991px) {
            gap: 24px;
        }

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    .faqs-left-panel {
        width: 308px;
        min-width: 308px;
        position: sticky;
        top: 96px;

        &:empty {
            display: none;
        }

        @media screen and (max-width: 1199px) {
            top: 87px;
        }

        @media screen and (max-width: 991px) {
            top: 80px;
            width: 220px;
            min-width: 220px;
        }

        @media screen and (max-width: 767px) {
            position: initial;
            top: auto;
            width: calc(100% + 32px);
            min-width: 100%;
            gap: 8px;
            overflow: auto;
            margin: 0 -16px;
            padding: 0 16px;
            display: flex;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .faqs-group {
            margin: 0 0 16px;
            padding: 18px;
            border: 1px solid #272338;
            border-radius: 16px;
            transition: all 0.35s ease 0s;
            cursor: pointer;

            @media screen and (max-width: 767px) {
                margin: 0;
                padding: 17px 32px;
                white-space: nowrap;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                background: #EFB7A6;
                @media screen and (max-width: 767px) {
                    background: #FFE6D5;
                }
            }

            &.faqs-group-open {
                //background: #FFF5EE;
                background: #FFE6D5;
                cursor: default;
                @media screen and (max-width: 767px) {
                    background: #FFF5EE;
                }


                ul {
                    display: block;
                    @media screen and (max-width: 767px) {
                        display: none !important;
                    }
                }
            }

            h2 {
                font-family: Livorno, serif;
                font-size: 26px;
                line-height: 31px;
                font-weight: 400;
                letter-spacing: -0.01em;
                color: #272338;
                margin: 0;
            }

            ul {
                font-family: Aeonik, sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #4D4D4D;
                padding: 12px 0 0;
                margin: 12px 0 0;
                border-top: 1px solid #EFB7A6;
                display: none;

                @media screen and (max-width: 767px) {
                    display: none !important;
                }

                li {
                    margin: 0 0 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a, span {
                        color: #4D4D4D;
                        display: inline-block;
                        cursor: pointer;
                        transition: all 0.35s ease 0s;

                        &:hover {
                            color: #7093FF;
                        }

                        &.active {
                            color: #272338;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

    .faqs-right-panel {
        flex: 1;

        h3 {
            font-family: Livorno, serif;
            font-size: 26px;
            line-height: 31px;
            font-weight: 400;
            letter-spacing: -0.01em;
            color: #272338;
            margin: 0 0 24px;
            @media screen and (max-width: 991px) {
                margin: 0 0 16px;
            }

            @media screen and (max-width: 767px) {
                margin: 0 0 24px;
            }
        }
    }

    .accordion-item {
        margin: 0 0 16px;
        padding: 0 0 16px;
        border: none;
        border-bottom: 1px solid #272338;
        border-radius: 0;
        background: transparent;
        @media screen and (max-width: 991px) {
            margin: 0 0 14px;
            padding: 0 0 14px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .accordion-button {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #272338;
            background: transparent;
            border: none;
            border-radius: 0;
            padding: 0 30px 0 0;
            box-shadow: none;
            position: relative;
            transition: all 0.35s ease 0s;
            @media screen and (max-width: 767px) {
                line-height: 24px;
            }

            &:not(.collapsed) {
                color: #272338;
            }

            &:after {
                display: none;
            }

            .faqs-arrow {
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                transition: all 0.35s ease 0s;
                transform: rotateX(180deg);
                background-image: url("/assets/images/faq-down-icon.svg");
                background-position: center center;
                background-repeat: no-repeat;
                @media screen and (min-width: 767px) {
                    background-size: 10px auto;
                }
            }

            &.collapsed {
                .faqs-arrow {
                    transform: none;
                }
            }
        }

        .accordion-body {
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #272338;
            padding: 16px 24px;
            background: #FFEFE4;
            border-radius: 0 0 16px 16px;
            margin-top: 8px;
            @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 22px;
                padding: 12px 16px;
                margin-bottom: 2px;
            }
        }

        + h3 {
            margin-top: 32px;
            @media screen and (max-width: 991px) {
                margin-top: 24px;
            }
        }
    }

    .search-results-not-found {
        padding: 0;
    }
}

//Bottom Banner
.faqs-contact-wrap {
    padding: 60px 0 44px;
    background: #EFB7A6;
    border-top: 1px solid #272338;

    @media screen and (max-width: 991px) {
        padding: 40px 0;
    }

    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    .container {
        padding-left: 80px;
        padding-right: 0;
        gap: 100px;
        align-items: center;
        display: flex;
        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            gap: 70px;
        }

        @media screen and (max-width: 1280px) {
            padding-right: 16px;
            padding-left: 16px;
            gap: 50px;
        }

        @media screen and (max-width: 1199px) {
            gap: 36px;
        }

        @media screen and (max-width: 1100px) {
            gap: 24px;
        }

        @media screen and (max-width: 767px) {
            gap: 32px;
            align-items: normal;
            flex-direction: column;
        }
    }

    .faqs-contact-info {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #272338;
        max-width: 492px;
        @media screen and (max-width: 991px) {
            max-width: 392px;
        }

        @media screen and (max-width: 767px) {
            flex: 1;
            max-width: 100%;
            font-size: 16px;
            line-height: 22px;
        }

        .primary-btn {
            margin: 0 0 16px;
        }
    }

    .section-title {
        margin-bottom: 16px;
        @media screen and (max-width: 1100px) {
            font-size: 32px;
            line-height: 42px;
        }
        @media screen and (max-width: 991px) {
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 8px;
        }
    }

    p {
        margin: 0;
        @media screen and (max-width: 480px) {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }

            br {
                display: none;
            }
        }
    }

    .faqs-contact-img {
        flex: 1;
        text-align: center;
        @media screen and (max-width: 767px) {
            max-width: 100%;
        }

        img {
            width: auto;
            vertical-align: middle;
        }
    }
}

//Wedding Planning CSS
.wedding-plan-cta {
    font-family: Aeonik, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    color: #333333;
    background: #A8AED6;
    padding: 24px 0;
    border-top: 1px solid #6D629D;

    .container {
        gap: 24px;
        align-items: center;
        justify-content: center;
        display: flex;
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    .section-title {
        margin: 0 0 32px;
    }

    strong, b {
        font-weight: 700;
    }

    p {
        margin: 0;
    }

    a {
        color: #333333;

        &:hover {
            color: #272338;
        }
    }
}
