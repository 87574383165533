//About title wrap
.about-title-wrap {
    padding: 48px 0;
    text-align: center;
    border-bottom: 1px solid #272338;

    .section-title {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 0;
    }

    @media screen and (max-width: 991px) {
        padding: 32px 0;
    }

    @media screen and (max-width: 767px) {
        padding: 24px 0;
    }
}

.about-us-wrap {
    padding: 48px 0;
    background: #FFF5EE;
    border-bottom: 1px solid #272338;
    font-family: Aeonik, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #4D4D4D;

    @media screen and (max-width: 991px) {
        padding: 32px 0;
    }

    .container {
        max-width: 1072px;
        padding-left: 16px;
        padding-right: 16px;
        gap: 64px;
        align-items: center;
        display: flex;

        @media screen and (max-width: 991px) {
            gap: 32px;
        }

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    label {
        font-family: Aeonik, sans-serif;
        background: #ffffff;
        padding: 6px 12px;
        border: 1px solid #272338;
        border-radius: 30px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #272338;
        margin: 0 0 24px;
        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
            margin: 0 0 16px;
        }
    }

    h2 {
        font-family: Livorno, sans-serif;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.01em;
        color: #272338;
        margin: 0 0 8px;
    }

    p {
        margin-bottom: 22px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .about-us-img {
        width: 453px;
        min-width: 453px;
        max-width: 100%;

        @media screen and (max-width: 991px) {
            width: 360px;
            min-width: 360px;
        }

        @media screen and (max-width: 767px) {
            order: 2;
            width: 100%;
            min-width: 100%;
        }

        .about-us-img-inner {
            position: relative;
            border-radius: 16px;
            overflow: hidden;
            padding-bottom: 117.44%;

            img {
                width: 100%;
                height: 100%;
                border: 1px solid #272338;
                border-radius: 16px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                object-fit: cover;
                object-position: bottom center;
            }
        }

    }

    .about-us-info {
        @media screen and (max-width: 767px) {
            order: 1;
        }
    }

    @media screen and (max-width: 767px) {
        &.about-founders {
            padding-bottom: 0;

            .about-us-img {
                .about-us-img-inner {
                    border-radius: 16px 16px 0 0;
                    padding-bottom: 109.05%;

                    img {
                        border-radius: 16px 16px 0 0;
                        object-position: top center;
                        height: 150%;
                        max-height: inherit;
                    }
                }
            }
        }
    }
}

.about-us-chit-chat {
    text-align: center;
    background: #FFE6D5;
    padding: 48px 0;
    border-bottom: 1px solid #272338;

    @media screen and (max-width: 991px) {
        padding: 32px 0;
    }

    .container {
        max-width: 1072px;
        padding-left: 16px;
        padding-right: 16px;
    }

    label {
        font-family: Aeonik, sans-serif;
        background: #ffffff;
        padding: 6px 12px;
        border: 1px solid #272338;
        border-radius: 30px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #272338;
        margin: 0 0 16px;
        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    h2 {
        text-align: center;
        font-family: Livorno, sans-serif;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.01em;
        color: #272338;
        margin: 0 0 24px;
    }

    .founders-chit-chat {
        font-family: Aeonik, sans-serif;
        font-weight: 500;
        font-size: 22px;
        line-height: 31px;
        color: #272338;
        gap: 32px;
        align-items: center;
        display: flex;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            font-size: 20px;
            line-height: 28px;
        }

        p {
            margin: 0;
        }

        .about-us-chat-item {
            flex: 1;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }

        .about-chat-item-inner {
            position: relative;
            border-radius: 16px;
            overflow: hidden;
            margin-top: 16px;
            padding-bottom: 87.7%;
            @media screen and (max-width: 767px) {
                margin-top: 8px;
            }

            img {
                width: 100%;
                height: 100%;
                border: 1px solid #272338;
                border-radius: 16px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                object-fit: cover;
                object-position: bottom center;
            }
        }
    }
}

.plan-with-wedshed {
    padding: 52px 0;
    background: #FFE6D5;
    position: relative;
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #4D4D4D;

    @media screen and (max-width: 991px) {
        padding: 32px 0;
    }

    .container {
        max-width: 1072px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .section-title {
        margin-bottom: 8px;
        text-align: center;
        @media screen and (max-width: 767px) {
            text-align: left;
            margin-bottom: 16px;
        }
    }

    .title-desc {
        max-width: 745px;
        margin: 0 auto;
        text-align: center;
        @media screen and (max-width: 767px) {
            text-align: left;
        }
    }

    p {
        margin-bottom: 0;
    }

    ul {
        margin: 48px 0 0;
        gap: 18px 32px;
        flex-wrap: wrap;
        align-items: flex-start;
        display: flex;
        @media screen and (max-width: 991px) {
            margin: 32px 0 0;
        }

        @media screen and (max-width: 767px) {
            margin-top: 24px;
        }

        li {
            position: relative;
            padding-left: 88px;
            min-height: 72px;
            gap: 2px;
            flex-direction: column;
            justify-content: center;
            width: calc(50% - 16px);
            display: flex;

            @media screen and (max-width: 991px) {
                width: 100%;
            }

            @media screen and (max-width: 480px) {
                padding-left: 0;
                min-height: initial;
                &:first-child {
                    .offer-list-icon {
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;

                .offer-list-icon {
                    img {
                        max-width: 30px;
                        max-height: 30px;
                    }
                }
            }

            h3 {
                font-family: Aeonik, sans-serif;
                font-size: 18px;
                line-height: 25px;
                font-weight: 500;
                color: #272338;
                margin: 0;
            }

            .offer-list-icon {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #272338;
                border-radius: 72px;
                width: 72px;
                height: 72px;
                position: absolute;
                top: 0;
                left: 0;
                @media screen and (max-width: 480px) {
                    width: 60px;
                    height: 60px;
                    position: relative;
                    top: auto;
                    left: auto;
                    margin: 0 0 6px;
                    img {
                        max-width: 44px;
                        max-height: 44px;
                        width: auto;
                        height: auto;
                    }
                }
            }

            &:nth-child(4n + 1) {
                .offer-list-icon {
                    background: #A8AED6;
                }
            }

            &:nth-child(4n + 2) {
                .offer-list-icon {
                    background: #EFB7A6;
                }
            }

            &:nth-child(4n + 3) {
                .offer-list-icon {
                    background: #94B3A5;
                }
            }

            &:nth-child(4n + 4) {
                .offer-list-icon {
                    background: #7093FF;
                }
            }
        }
    }
}

//======= Testimonials =======
.testimonials-wrap {
    padding: 92px 0;
    background: #272338;
    text-align: center;
    color: #FFFFFF;

    @media screen and (max-width: 991px) {
        padding: 48px 0;
    }

    @media screen and (max-width: 767px) {
        border-top: 1px solid #4E0A24;
    }

    .container {
        max-width: 1072px;
        padding-left: 16px;
        padding-right: 16px;
    }

    h2 {
        font-family: Aeonik, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 48px;
        text-transform: uppercase;
        text-align: center;
    }

    .section-title {
        font-size: 40px;
        line-height: 48px;
        color: #fff;
        text-align: center;
        margin: 0;
        position: relative;
        padding: 0 0 0 32px;
        @media screen and (max-width: 767px) {
            font-size: 38px;
            line-height: 46px;
            padding: 28px 0 0;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 25px;
            height: 18px;
            background-image: url("/assets/images/quote-icon.svg");
            background-repeat: no-repeat;
            background-position: center;
            pointer-events: none;
            @media screen and (max-width: 767px) {
                right: 0;
                margin: 0 auto;
            }
        }
    }

    .testimonials-info {
        ul {
            margin: 48px 0 0;
            gap: 12px;
            align-items: center;
            justify-content: center;
            display: flex;

            @media screen and (max-width: 1024px) {
                gap: 16px;
                flex-wrap: wrap;
            }

            li {
                cursor: pointer;

                img {
                    opacity: 0.5;
                    transition: all 0.35s ease 0s;
                }

                &.active, &:hover {
                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

//Bottom Banner
.join-wedshed {
    background: #94B3A5;
    padding: 94px 0 80px;

    @media screen and (max-width: 1360px) {
        padding: 60px 0;
    }

    @media screen and (max-width: 991px) {
        padding: 48px 0;
    }

    @media screen and (max-width: 991px) {
        padding: 32px 0;
    }

    .container {
        max-width: 1166px;
        padding-left: 16px;
        padding-right: 16px;
        gap: 24px;
        align-items: center;
        display: flex;

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }

    .join-wedshed-info {
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #4D4D4D;
        flex: 1;

        @media screen and (max-width: 991px) {
            width: 100%;
        }

        .offer-label {
            font-family: Aeonik, sans-serif;
            background: #272338;
            padding: 5px 10px;
            border: none;
            border-radius: 30px;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #fff;
            margin: 0 0 16px;
        }
    }

    .section-title {
        margin-bottom: 8px;
        @media screen and (max-width: 1100px) {
            font-size: 32px;
            line-height: 42px;
        }
        @media screen and (max-width: 767px) {
            line-height: 38px;
        }
    }

    p {
        margin: 0;
        max-width: 437px;
        @media screen and (max-width: 767px) {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .join-wedshed-action {
        margin: 32px 0 0;
        gap: 8px;
        flex-wrap: wrap;
        display: flex;
    }

    .join-wedshed-img {
        width: 420px;
        max-width: 100%;

        img {
            vertical-align: middle;
        }
    }
}
