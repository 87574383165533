.page-title-wrap {
    background: #272338;
    color: #C5C0D8;

    .section-title {
        color: #FFFFFF;
    }
}

.main-filter-wrap {
    width: 1248px;
    background: #272338;

    .main-filter-head {
        background: #272338;
        color: #fff;
        border-color: #423B5E;
    }

    .main-filter-inner {
        .filter-item {
            width: 25%;
            max-width: 25%;

            @media screen and (max-width: 767px) {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    .main-filter-action {
        background: #272338;
        border-top-color: #423B5E;

        .secondary-btn {
            color: #fff;
        }

        .primary-btn {
            &:hover {
                background: #fff;
                color: #272338;

                img {
                    filter: brightness(0);
                }
            }
        }

    }
}

.filter-item-btn {
    .filter-btn-label {
        color: #6D629D;

        @media screen and (max-width: 767px) {
            color: #423B5E;
        }
    }
}

.featured-venues-section {
    &.featured-vendor-list {
        min-height: 536px;

        @media screen and (max-width: 1440px) {
            min-height: 534px;
        }

        @media screen and (max-width: 1366px) {
            min-height: 515px;
        }

        @media screen and (max-width: 1280px) {
            min-height: 506px;
        }

        @media screen and (max-width: 1200px) {
            min-height: 486px;
        }

        @media screen and (max-width: 1100px) {
            min-height: 445px;
        }

        @media screen and (max-width: 350px) {
            min-height: 420px;
        }
    }
}
