import {Component, inject, TemplateRef, ViewEncapsulation} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {RoutesConstants} from "@coreconstant";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {SeoService} from '../../core/services/common-service/seo.service';

@Component({
    selector: 'app-contact-us',
    imports: [RouterModule, ReactiveFormsModule],
    templateUrl: './contact-us.component.html',
    styleUrl: './contact-us.component.scss',
    encapsulation: ViewEncapsulation.None
})

export class ContactUsComponent {
    protected readonly routesConstants = RoutesConstants;
    private modalService = inject(NgbModal);

    constructor(
        private _seoService: SeoService,
    ) {
        this.setMetaTags()
    }
    ngOnInit() { }

    setMetaTags() {
        let metaTagObject = {
            title: 'Contact Wedshed for Wedding Planning Solutions',
            description: 'Have questions or need support with your wedding planning? Connect with the Wedshed team to find the best wedding venues, services, and tips for a stress-free experience.',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    openVerticallyCentered(content: TemplateRef<any>, modalClass: string) {
        this.modalService.open(content, {centered: true, modalDialogClass: modalClass});
    }
}
