import {Component, ViewEncapsulation} from '@angular/core';
import {CommonConstants, RoutesConstants} from '@coreconstant';
import {RouterModule} from '@angular/router';

@Component({
    selector: 'app-remove-permanently',
    imports: [RouterModule],
    templateUrl: './remove-permanently.component.html',
    styleUrl: './remove-permanently.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class RemovePermanentlyComponent {
    commonConstants = CommonConstants
    routesConstants = RoutesConstants
}
