import {Component, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImagePathConstants} from 'src/app/core/constants/image-path-constants';
import {SetImageDimensionsDirective} from 'src/app/core/directives/set-image-dimensions.directive';
import {JoinWaitlistComponent} from 'src/app/modal/join-waitlist/join-waitlist.component';
import {WaitlistSubscribedComponent} from 'src/app/modal/waitlist-subscribed/waitlist-subscribed.component';
import {SeoService} from '../../core/services/common-service/seo.service';
import {RoutesConstants} from '@coreconstant';

@Component({
    selector: 'app-planning-hub',
    imports: [SetImageDimensionsDirective],
    templateUrl: './planning-hub.component.html',
    styleUrl: './planning-hub.component.scss',
    providers: [NgbActiveModal],
    encapsulation: ViewEncapsulation.None
})

export class PlanningHubComponent {
    imagePathConstants = ImagePathConstants
    routesConstants = RoutesConstants;
    constructor(
        private _modalService: NgbModal,
        private _seoService: SeoService,
        public _activeModal: NgbActiveModal
    ) {
        this.setMetaTags()
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Plan Your Dream Wedding with Wedshed',
            description: 'From finding the perfect venue to organising the finer details, Wedshed’s Planning Hub is your ultimate guide to stress-free wedding planning.',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.PLANNING,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    /**
     * Open the Join wait list modal
     */
    joinWaitlistModal() {
        const JoinWaitlistModal = this._modalService.open(JoinWaitlistComponent, {
            centered: true,
            backdrop: 'static',
            keyboard: false,
            modalDialogClass: 'join-waitlist-modal'
        });

        JoinWaitlistModal.result.then(
            (result) => {
                if (result == 'success')
                    this.openSuccessModal()
            },
            (reason) => {
            }
        );
    }

    /**
     * When the join successfully then open the success modal
     */
    openSuccessModal() {
        const waitlistSubscribedModal = this._modalService.open(WaitlistSubscribedComponent, {
            centered: true,
            backdrop: 'static',
            keyboard: false,
            modalDialogClass: 'waitlist-modal'
        });
    }
}
