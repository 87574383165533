<section class="enquire-cta">
    <div class="container">
        <div class="enquire-cta-info">
            <h2 class="section-semi-title">✨ Save venues, enquire, budget and plan well when you sign up</h2>
            <div class="enquire-cta-action">
                <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" class="primary-btn">Sign up</a>
                <!--<a href="{{businessPortalUrl}}" class="secondary-btn" target="_blank" rel="noopener noreferrer nofollow">Log in</a>-->
            </div>
        </div>
    </div>
</section>
