import { Injectable } from '@angular/core';
import { ApiUrlConstants } from '../../constants/api-url-constants';
import { httpConfig, HttpService } from '../http-service/http.service';

@Injectable({
    providedIn: 'root'
})
export class VendorService {

    baseUrlApi = ApiUrlConstants.VENDOR_BASE_URL;
    httpConfig: httpConfig;

    constructor(
        private _httpService: HttpService
    ) {
    }

    /**
     * Get vendor list
     * @param input 
     * @returns 
     */
    getVendorList(input) {
        this.httpConfig = {
            url: this.baseUrlApi,
            method: 'get',
            filterParameters: input,
            isRequestModelUsed: true,
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get single vendor details
     * @param input 
     * @returns 
     */
    getVendorDetails(slug) {
        this.httpConfig = {
            url: this.baseUrlApi + '/' + slug,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get the vendor details and images of the selected vendor
     * @param slug 
     * @returns 
     */
    getVendorAndImages(slug) {
        this.httpConfig = {
            url: this.baseUrlApi + '/images/' + slug,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get Other local vendor categories
     * @param slug 
     * @returns 
     */
    getLocalVendorCategories(slug) {
        this.httpConfig = {
            url: this.baseUrlApi + '/relevant-vendors' + '/' + slug,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }
}
