<section class="vendor-banner-wrap">
    <div class="container">
        <div class="left-content">
            <h1>Be a part of <span>many special days</span></h1>
            <p>We’re bringing together the most creative and talented wedding folk on one platform for couples to
                discover.</p>
            <a [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" [fragment]="'service'" class="
                primary-btn">Create your vendor
                profile</a>
        </div>
        <div class="banner-right-img">
            <img [src]="imagePathConstants.HOW_IT_WORKS + '/vendor-banner-image.png'" width="1176" height="936"
                alt="vendor-banner-image" appSetImageDimensions>
        </div>
    </div>
</section>

@if(!isFooterVisible) {
<div class="mob-create-profile-btn">
    <a [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" type="button" class="primary-btn">Create your vendor
        profile</a>
</div>
}

<section class="how-it-works-wrap">
    <div class="container">
        <div class="left-sticky-panel">
            <div class="left-sticky-menu">
                <ul>
                    <li [class.active]="activeSection === 'howItWorks'">
                        <span (click)="scrollToSection('howItWorks')">1. How it works for vendors</span>
                    </li>
                    <li [class.active]="activeSection === 'whatSets'">
                        <span (click)="scrollToSection('whatSets')">2. What sets us apart</span>
                    </li>
                    <li [class.active]="activeSection === 'ourStatistics'">
                        <span (click)="scrollToSection('ourStatistics')">3. Our statistics</span>
                    </li>
                    <li [class.active]="activeSection === 'successStories'">
                        <span (click)="scrollToSection('successStories')">4. Success stories</span>
                    </li>
                    <li [class.active]="activeSection === 'pricing'">
                        <span (click)="scrollToSection('pricing')">5. Pricing</span>
                    </li>
                    @if (faqList && faqList.length > 0) {
                    <li [class.active]="activeSection === 'faqs'">
                        <span (click)="scrollToSection('faqs')">6. FAQs</span>
                    </li>
                    }
                </ul>
                <a [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" [fragment]="'service'" class="primary-btn">Create your vendor profile</a>
            </div>
        </div>

        <div class="right-scroll-panel" (scroll)="onScroll()">
            <div id="howItWorks" class="how-it-works-step content-section">
                <h2 class="section-title">How it works for vendors</h2>
                <div class="how-it-works-list">
                    <div class="how-it-works-item">
                        <div class="how-it-works-img">
                            <img [src]="imagePathConstants.HOW_IT_WORKS + '/apply-for-a-profile.png'" width="480"
                                height="318" alt="Apply for a profile" appSetImageDimensions>
                        </div>
                        <h3><span>1.</span> Apply for a profile</h3>
                        <p>Join our curated directory, create your own profile and manage your Wedshed online presence
                            anytime.</p>
                    </div>

                    <div class="how-it-works-item">
                        <div class="how-it-works-img">
                            <img [src]="imagePathConstants.HOW_IT_WORKS + '/communicate-with-couples.png'" width="480"
                                height="318" alt="Communicate with couples" appSetImageDimensions>
                        </div>
                        <h3><span>2.</span> Communicate with couples</h3>
                        <p>All enquiries are sent directly to your inbox - we step out and let you work your magic.</p>
                    </div>

                    <div class="how-it-works-item">
                        <div class="how-it-works-img">
                            <img [src]="imagePathConstants.HOW_IT_WORKS + '/book-beautiful-weddings.png'" width="480"
                                height="318" alt="Book beautiful weddings" appSetImageDimensions>
                        </div>
                        <h3><span>3.</span> Book beautiful weddings</h3>
                        <p>Completely commission-free. And bask in the good feels of making a couple’s dream day happen.
                        </p>
                    </div>

                    <div class="how-it-works-item">
                        <div class="how-it-works-img">
                            <img [src]="imagePathConstants.HOW_IT_WORKS + '/bump-up-your-listing.png'" width="480"
                                height="318" alt="Bump up your listing" appSetImageDimensions>
                        </div>
                        <h3><span>4.</span> Bump up your listing</h3>
                        <p>For more visibility, upgrade your profile to a ‘featured’ placement whenever you want an
                            extra boost.</p>
                    </div>

                    <div class="how-it-works-item">
                        <div class="how-it-works-img">
                            <img [src]="imagePathConstants.HOW_IT_WORKS + '/track-analytics.png'" width="480"
                                height="318" alt="Track analytics" appSetImageDimensions>
                        </div>
                        <h3><span>5.</span> Track analytics</h3>
                        <p>Get data and insights on couples and track your return on investment within your business
                            hub.</p>
                    </div>
                </div>
            </div>

            <div id="whatSets" class="what-sets-wrap content-section">
                <h2 class="section-title">What sets us apart</h2>
                <ul>
                    <li>
                        <i class="offer-list-icon">
                            <img src="assets/images/community-focused-icon.png" width="72" height="72"
                                alt="Community focused">
                        </i>
                        <h3>Community focused</h3>
                        <p>Creating a sense of community among our business partners is important. Join our closed
                            Wedshed Collective Facebook group to connect with other Sheddies.</p>
                    </li>
                    <li>
                        <i class="offer-list-icon">
                            <img src="assets/images/click-icon.png" width="63" height="63" alt="Click icon">
                        </i>
                        <h3>Qualified traffic</h3>
                        <p>We capture couples right at the start of their wedding planning journey. Our vendors benefits
                            from the flow-on effect, which means you’re spending your marketing dollars right when it
                            counts.</p>
                    </li>
                    <li>
                        <i class="offer-list-icon">
                            <img src="assets/images/advice-icon.png" width="52" height="52" alt="Advice icon">
                        </i>
                        <h3>Human connection + industry experience</h3>
                        <p>We’re not anonymous. We’re Mel, Amy and a small team of digital-savvy, wedding-loving, Aussie
                            lasses and lads that are on hand 24/7 to chat to you about your business. Hi.</p>
                    </li>
                    <li>
                        <i class="offer-list-icon">
                            <img src="assets/images/streamline-icon.png" width="66" height="66" alt="Streamline icon">
                        </i>
                        <h3>Awesome couples</h3>
                        <p>Less bridezilla, more bridechilla. We attract down to earth couples that believe weddings are
                            about love, family and community. Basically, the kind of people you want to work with.</p>
                    </li>
                    <li>
                        <i class="offer-list-icon">
                            <img src="assets/images/shared-resources-icon.png" width="46" height="41"
                                alt="Shared resources icon">
                        </i>
                        <h3>Shared resources</h3>
                        <p>From eBooks and eNewsletters to checklists and consults, we’re in regular communication with
                            you. We’re constantly learning, analysing and improving – and we pass these insights on.</p>
                    </li>
                    <li>
                        <i class="offer-list-icon">
                            <img src="assets/images/handpicked-icon.png" width="36" height="49" alt="Handpicked icon">
                        </i>
                        <h3>Handpicked partners</h3>
                        <p>We only partner with businesses we’d recommend to our closest mates. We know being exclusive
                            establishes trust and authenticity with our couples and benefits our vendors big time – so
                            we’re a bit fussy.</p>
                    </li>
                </ul>
            </div>

            <div id="ourStatistics" class="our-statistics-wrap content-section">
                <h2 class="section-title">Our statistics</h2>
                <ul>
                    <li>
                        <h3>2.5m+</h3>
                        <p>Lovers helped</p>
                        <img src="assets/images/statistics-star-icon.svg" width="39" height="39"
                            alt="Statistics star icon" class="star-icon">
                    </li>
                    <li>
                        <h3>24k+</h3>
                        <p>Couples-only FB Group</p>
                        <img src="assets/images/statistics-star-icon.svg" width="39" height="39"
                            alt="Statistics star icon" class="star-icon">
                    </li>
                    <li>
                        <h3>26k+</h3>
                        <p>Newsletter subscribers</p>
                        <img src="assets/images/statistics-star-icon.svg" width="39" height="39"
                            alt="Statistics star icon" class="star-icon">
                    </li>
                    <li>
                        <h3>315+</h3>
                        <p>Wedshed venues</p>
                        <img src="assets/images/statistics-star-icon.svg" width="39" height="39"
                            alt="Statistics star icon" class="star-icon">
                    </li>
                    <li>
                        <h3>60k+</h3>
                        <p>Unique monthly users</p>
                        <img src="assets/images/statistics-star-icon.svg" width="39" height="39"
                            alt="Statistics star icon" class="star-icon">
                    </li>
                    <li>
                        <h3>17k+</h3>
                        <p>Direct enquiries annually</p>
                    </li>
                    <li>
                        <h3>325+</h3>
                        <p>Wedshed vendors</p>
                        <img src="assets/images/statistics-star-icon.svg" width="39" height="39"
                            alt="Statistics star icon" class="star-icon">
                    </li>
                    <li>
                        <h3>74k+</h3>
                        <p>Instagram followers</p>
                        <img src="assets/images/statistics-star-icon.svg" width="39" height="39"
                            alt="Statistics star icon" class="star-icon">
                    </li>
                </ul>
            </div>

            <div id="successStories" class="success-stories content-section">
                <h2 class="section-title">Hear it from other vendors</h2>

                <div class="success-stories-item">
                    <div class="success-stories-img">
                        <img [src]="imagePathConstants.HOW_IT_WORKS + '/vendor-review-gemma-nichols-makeup.jpeg?tr=w-80,h-80,q-100,lossless-1,f-webp,e-sharpen-50'" width="80" height="80" alt="Gemma nichols makeup">
                        Gemma Nichols Makeup
                        <a href="https://www.wedshed.com.au/planning/vendors/gemma-nichols-hair-makeup">Gemma Nichols Hair & Makeup</a>
                    </div>
                    <div class="success-stories-info">
                        <h3>Thank you!</h3>
                        <p>Just want to say a big thank you. I’ve never “advertised” before because we do well on Google. But I was drawn to your site and thought why not. The ad paid for itself that first day and I have had at least 5 great quality bookings from it already and the clients all seem so lovely and are the right fit. What a great investment. I can’t wait to get to know the Wedshed family better.</p>
                    </div>
                </div>

                <div class="success-stories-item">
                    <div class="success-stories-img">
                        <img [src]="imagePathConstants.HOW_IT_WORKS + '/vendor-review-james-white-photography.jpg?tr=w-80,h-80,q-100,lossless-1,f-webp,e-sharpen-50'" width="80" height="80" alt="James white photography">
                        James White Photography
                        <a href="https://www.wedshed.com.au/planning/vendors/james-white-photography-hunter-valley-nsw">James White Photography</a>
                    </div>
                    <div class="success-stories-info">
                        <p>Not sure what’s going on with your site but I’m now getting 1-2 enquiries a day from ‘Wedshed’… I’ve officially booked 3 weddings and have at least 15 open enquiries now from your directory. So yeah, whatever you’re doing please keep it up!!! ❤️🤙🤘</p>
                    </div>
                </div>

                <div class="success-stories-item">
                    <div class="success-stories-img">
                        <img [src]="imagePathConstants.HOW_IT_WORKS + '/vendor-review-alex-marks-photography.png?tr=w-80,h-80,q-100,lossless-1,f-webp,e-sharpen-50'" width="80" height="80" alt="Alex marks photography">
                        Alex Marks Photography
                        <a href="https://www.wedshed.com.au/planning/vendors/alex-marks-photography-australian-wedding-photographer">Alex Marks Photography</a>
                    </div>
                    <div class="success-stories-info">
                        <p>I feel like you guys are onto something great here and we’re stoked to be a part of that :)</p>
                    </div>
                </div>

                <!-- Not in current Phase -->
                <!-- <button class="show-more-btn show-less">Show less
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.04 6.51953V18.2197" stroke="#7093FF" stroke-miterlimit="10"
                            stroke-linecap="round" />
                        <path d="M8.04004 14.2207L12.04 18.2207L16.04 14.2207" stroke="#7093FF" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button> -->
            </div>

            <div id="pricing" class="pricing-wrap content-section">
                <h2 class="section-title">Pricing</h2>
                <p class="section-des">We’re the market leaders when it comes to building trust with couples and we want
                    to send qualified, savvy and deadset amazing couples your way.</p>

                <div class="pricing-info">
                    <div class="pricing-info-item">
                        <p>Annual <span class="save">(save 25%)</span></p>
                        <h3><span>$</span>720</h3>
                        <div class="most-popular">
                            <img src="assets/images/most-popular.svg" width="113" height="110" alt="Most popular">
                        </div>
                    </div>

                    <div class="pricing-info-item">
                        <p>Monthly</p>
                        <h3><span>$</span>80</h3>
                    </div>

                    <div class="pricing-info-item pricing-package-info">
                        <p>Both packages include:</p>
                        <ul>
                            <li>A vendor listing for the duration of your subscription</li>
                            <li>Customisable vendor profile</li>
                            <li>Flexible ability to upgrade to ‘Featured’ placements anytime</li>
                            <li>Randomised directory presence - so everyone has time at the top</li>
                            <li>Overview of success stats</li>
                            <li>Go-to team if you need help any time</li>
                            <li>GST? Already included</li>
                            <li>Regular advice and industry insights sent to your inbox</li>
                            <li>Ability to book in additional marketing, any time</li>
                        </ul>
                    </div>
                </div>
            </div>

            @if (faqList && faqList.length > 0) {
            <div id="faqs" class="questions-answered content-section ">
                <h2 class="section-title">Your questions answered</h2>

                <div class="accordion" id="qandaList">
                    @for (item of faqList; track $index) {
                    <div class="accordion-item">
                        <h3 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#qanda' + item.id" aria-expanded="false"
                                [attr.aria-controls]="'qanda' + item.id">
                                {{ item.faqQuestion }}
                                <span class="faqs-arrow"></span>
                            </button>
                        </h3>
                        <div [id]="'qanda' + item.id" class="accordion-collapse collapse"
                            [ngClass]="{'show': $index == 0}" data-bs-parent="#qandaList">
                            <div class="accordion-body" [innerHTML]="item.faqAnswer | safeHtml"></div>
                        </div>
                    </div>
                    }
                </div>

                <div class="questions-answered-action">
                    <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION]" type="button"
                        class="secondary-btn">View more in our FAQs <img src="assets/images/arrow-right-icon.svg"
                            width="13" height="10" alt="Right icon">
                    </a>
                    <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION]" type="button"
                        class="secondary-btn">Still have questions? Contact us <img
                            src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="Right icon">
                    </a>
                </div>
            </div>
            }
        </div>
    </div>
</section>

<section class="join-vendors-wrap">
    <div class="container">
        <h2 class="section-title">Join
            <span>
                <img src="assets/images/counting.svg" width="112" height="27" alt="counting-icon" class="counting-icon">
                325
                <img src="assets/images/title-line-icon.png" width="141" height="21" alt="title-line-icon"
                    class="line-icon">
            </span>
            other incredible vendors on Wedshed today
        </h2>
        <a [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" [fragment]="'service'" class=" primary-btn">Create
            your vendor profile</a>
    </div>
</section>
