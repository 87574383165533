import {Component, ViewEncapsulation} from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoutesConstants } from '@coreconstant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-venue-vendor-footer',
  imports: [RouterModule],
  templateUrl: './venue-vendor-footer.component.html',
  styleUrl: './venue-vendor-footer.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class VenueVendorFooterComponent {
  routesConstants = RoutesConstants
  businessPortalUrl = environment.businessPortalUrl;
}
