export class RoutesConstants {
    // Authentication Routes
    public static SIGN_UP = "sign-up";
    public static BUSINESS = "business";
    public static SIGN_UP_NAVIGATION = this.SIGN_UP + "/" + this.BUSINESS;

    // Home Routes
    public static HOME = '';
    public static HOME_NAVIGATION = this.HOME;

    // Planning Routes
    public static PLANNING = 'planning';
    public static VENUES = 'venues';
    public static VENDORS = 'vendors';
    public static GALLERY = 'gallery';
    public static STAY = 'stay';
    public static HUB = 'hub';
    public static SEARCH = 'search';
    public static VENUE_NAVIGATION = this.PLANNING + '/' + this.VENUES;
    public static VENDOR_NAVIGATION = this.PLANNING + '/' + this.VENDORS;
    public static HUB_NAVIGATION = this.PLANNING + '/' + this.HUB;

    // How it works Routes
    public static HOW_IT_WORKS = 'how-it-works';
    public static HOW_IT_WORKS_COUPLES = 'couples';
    public static HOW_IT_WORKS_VENDORS = 'vendors';
    public static HOW_IT_WORKS_VENUES = 'venues';
    public static HOW_IT_WORKS_ABOUT = 'about';
    public static HOW_IT_WORKS_FAQS = 'faqs';
    public static HOW_IT_WORKS_CONTACT_US = 'contact-us';
    public static HOW_IT_WORKS_COUPLES_NAVIGATION = this.HOW_IT_WORKS + '/' + this.HOW_IT_WORKS_COUPLES;
    public static HOW_IT_WORKS_VENDORS_NAVIGATION = this.HOW_IT_WORKS + '/' + this.HOW_IT_WORKS_VENDORS;
    public static HOW_IT_WORKS_VENUES_NAVIGATION = this.HOW_IT_WORKS + '/' + this.HOW_IT_WORKS_VENUES;
    public static HOW_IT_WORKS_ABOUT_NAVIGATION = this.HOW_IT_WORKS + '/' + this.HOW_IT_WORKS_ABOUT;
    public static HOW_IT_WORKS_FAQS_NAVIGATION = this.HOW_IT_WORKS + '/' + this.HOW_IT_WORKS_FAQS;
    public static HOW_IT_WORKS_CONTACT_US_NAVIGATION = this.HOW_IT_WORKS + '/' + this.HOW_IT_WORKS_CONTACT_US;

    public static GET_IN_TOUCH = 'get-in-touch';
    public static SUBMIT_WEDDING = 'submit-wedding';
    public static WEDSPIRATION = 'advice-ideas';
    public static BLOG = 'advice-ideas';
    public static TERMS_CONDITION = 'terms-and-conditions';
    public static PRIVACY_POLICY = 'privacy-policy';

    public static REGION = 'region';
    public static VENUE = 'venue';
    public static REGION_VENUE_NAVIGATION = this.REGION + '/' + this.VENUE

    // 404 Page
    public static PAGE_NOT_FOUND = "404";
    public static PAGE_NOT_FOUND_NAVIGATION = this.PAGE_NOT_FOUND;

    public static PAGE_REMOVED_COMPLETETLY = "410";
    public static PAGE_REMOVED_COMPLETETLY_NAVIGATION = this.PAGE_REMOVED_COMPLETETLY;

    // Common params
    public static PREVIEW = "preview";
}
