import {Component, ViewEncapsulation} from '@angular/core';
import {RoutesConstants} from '@coreconstant';
import {SeoService} from '../core/services/common-service/seo.service';

@Component({
    selector: 'app-terms-and-conditions',
    imports: [],
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['../privacy-policy/privacy-policy.component.scss', './terms-and-conditions.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TermsAndConditionsComponent {
    routesConstants = RoutesConstants

    constructor(
        private _seoService: SeoService,
    ) {
        this.setMetaTags()
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Terms and Conditions | Wedshed',
            description: 'Terms and Conditions | Wedshed',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.TERMS_CONDITION,
        }
        this._seoService.setMetaTags(metaTagObject)
    }
}
