.breadcrumb-wrap {
    font-family: Aeonik, sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    padding: 13px 0;
    color: #423B5E;
    background-color: #FFE6D5;

    @media screen and (max-width: 767px) {
        display: none;
    }

    .container {
        padding-left: 80px;
        padding-right: 80px;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        gap: 10px;
        align-items: center;
        display: flex;

        li {
            display: inline-flex;
        }
    }

    a {
        font-weight: 500;
        color: #272338;

        &:hover {
            color: #7093FF;
        }
    }
}

.blog-mob-header {
    padding: 15px 58px;
    border-bottom: 1px solid #272338;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: #272338;
    position: relative;
    background: #FFF5EE;
    text-align: center;
    min-height: 56px;

    @media screen and (min-width: 768px) {
        display: none;
    }

    .blog-back-btn {
        height: 32px;
        width: 32px;
        position: absolute;
        left: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
        display: inline-flex;
    }
}

.blog-details-banner {
    background-color: #FFE6D5;
    padding: 12px 0 32px;
    border-bottom: 1px solid #272338;
    word-break: break-word;
    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    .container {
        padding-left: 80px;
        padding-right: 80px;
        gap: 64px;
        align-items: center;
        display: flex;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @media screen and (max-width: 1280px) {
            gap: 40px;
        }
        @media screen and (max-width: 1200px) {
            gap: 32px;
        }

        @media screen and (max-width: 991px) {
            gap: 24px;
        }

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    .left-side-panel {
        flex: 1;
        max-width: 544px;
        @media screen and (max-width: 767px) {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
        }

        p {
            margin: 0;
        }

        .section-title {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .sub-title {
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #423B5E;
            display: block;
            margin: 0 0 4px;
            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        .section-content {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: #4D4D4D;
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;

            ::ng-deep p {
                margin: 0;
            }
        }
    }

    .right-side-panel {
        width: 665px;
        min-width: 665px;
        height: 360px;
        @media screen and (max-width: 1200px) {
            width: 600px;
            min-width: 600px;
        }
        @media screen and (max-width: 1100px) {
            width: 500px;
            min-width: 500px;
            height: 300px;
        }
        @media screen and (max-width: 991px) {
            width: 50%;
            min-width: 50%;
            height: 250px;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            min-width: 100%;
            height: auto;
        }

        img {
            width: 100%;
            height: 100%;
            max-height: 360px;
            object-fit: cover;
            object-position: center center;
            border: 1px solid #272338;
            border-radius: 20px;
            @media screen and (max-width: 767px) {
                border-radius: 16px;
                min-height: 280px;
            }
        }
    }
}

.blog-details-wrap {
    padding: 40px 0;
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #4D4D4D;
    word-break: break-word;

    @media screen and (max-width: 767px) {
        padding: 0 0 32px;
    }

    .container {
        max-width: 1072px;
        padding: 0 16px;
        display: flex;
        gap: 32px;

        @media screen and (max-width: 991px) {
            gap: 24px;
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 0;
        }
    }

    .left-side-panel {
        @media screen and (min-width: 768px) {
            flex: 0 0 240px;
            position: sticky;
            top: 80px;
            align-self: flex-start;
        }
        @media screen and (min-width: 991px) {
            top: 94px;
            flex: 0 0 271px;
        }

        @media screen and (max-width: 767px) {
            margin: 0 -16px 32px;
            padding: 24px 16px;
            border-bottom: 1px solid #272338;
        }

        .blog-sidebar-card {
            padding: 16px;
            border: 1px solid #272338;
            border-radius: 16px;
            margin-bottom: 8px;
            @media screen and (max-width: 767px) {
                //margin: 0 -16px 24px;
                //padding: 0 16px 24px;
                //border: none;
                //border-bottom: 1px solid #272338;
                //border-radius: 0;

                margin: 0;
                padding: 0 16px 0;
                border: none;
                //border-bottom: 1px solid #272338;
                border-radius: 0;
            }

            .card-section {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                .card-title {
                    font-family: Aeonik, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                    color: #272338;
                    margin: 0 0 12px;
                    display: block;
                }

                .card-label {
                    font-family: Aeonik, sans-serif;
                    font-size: 15px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #272338;
                    margin: 0 0 4px;
                    min-height: 24px;
                    align-items: center;
                    gap: 4px;
                    display: flex;


                    &:last-child {
                        margin: 0;
                    }

                    img {
                        margin-bottom: auto;
                    }

                    @media screen and (max-width: 767px) {
                        font-size: 16px;
                        min-height: 32px;
                        img {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }

                .head-info-action {
                    gap: 6px;
                    align-items: center;
                    display: flex;

                    .secondary-btn {
                        font-size: 14px;
                        line-height: 18px;
                        padding: 10px 16px;
                        gap: 8px;

                        @media screen and (max-width: 767px) {
                            min-width: 44px;
                            width: 44px;
                            height: 44px;
                            padding: 0;
                            border-radius: 40px;
                            align-items: center;
                            justify-content: center;

                            span {
                                display: none;
                            }

                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        @media screen and (max-width: 359px) {
                            min-width: 38px;
                            width: 38px;
                            height: 38px;
                        }

                        &.icon-btn {
                            min-width: 40px;
                            width: 40px;
                            height: 40px;
                            padding: 0;
                            border-radius: 40px;
                            align-items: center;
                            justify-content: center;

                            @media screen and (max-width: 767px) {
                                min-width: 48px;
                                width: 48px;
                                height: 48px;
                            }

                            @media screen and (max-width: 359px) {
                                min-width: 38px;
                                width: 38px;
                                height: 38px;
                            }

                            img {
                                width: 24px;
                                height: 24px;
                                @media screen and (max-width: 767px) {
                                    width: 26px;
                                    height: 26px;
                                }
                            }
                        }

                        &.saved {
                            svg {
                                fill: #272338;
                            }
                        }
                    }
                }

                .card-label-wrap {
                    gap: 4px;
                    flex-wrap: wrap;
                    display: flex;

                    @media screen and (max-width: 767px) {
                        gap: 8px;
                    }

                    .secondary-btn {
                        display: inline-block;
                        padding: 6px 12px;
                        font-family: Aeonik, sans-serif;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #272338;
                        margin: 0;
                        text-align: left;
                        border-radius: 50px;

                        &:hover {
                            background: #FFE6D5;
                        }
                    }
                }
            }
        }

        .blog-sidebar-ads {
            margin: 16px 0 0;
            //border-radius: 16px;
            overflow: hidden;

            @media screen and (max-width: 767px) {
                margin: 24px -16px 0;
                //border-radius: 0;
                border-top: 1px solid #272338;
                padding: 24px 16px 0;
            }

            a {
                border-radius: 16px;
                overflow: hidden;
                display: block;

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: middle;
                    border: 1px solid #272338;
                    border-radius: 16px;
                }
            }
        }

        .blog-card-list {
            list-style: none;
            margin: 0;
            padding: 0;

            .card-wrap {
                border: 1px solid #272338;
                border-radius: 16px;
                padding: 12px 16px;
                margin: 0 0 8px;
                display: flex;
                align-items: center;
                gap: 16px;

                &:last-child {
                    margin-bottom: 0;
                }

                .card-img {
                    background-color: #EFB7A6;
                    width: 32px;
                    min-width: 32px;
                    height: 32px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                }

                .card-details {
                    .label {
                        font-family: Aeonik, sans-serif;
                        font-size: 12px;
                        line-height: 14px;
                        font-weight: 500;
                        color: #666666;
                        display: block;
                        margin: 0 0 2px;
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }

                    .link {
                        font-family: Aeonik, sans-serif;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #7093FF;
                        display: inline-block;
                        text-decoration: none;
                        margin: 0 0 2px;
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }

                    .card-dec {
                        font-family: Aeonik, sans-serif;
                        font-size: 10px;
                        line-height: 14px;
                        font-weight: 500;
                        color: #4D4D4D;
                        display: block;
                        @media screen and (max-width: 767px) {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }

                    .card-content {
                        font-family: Aeonik, sans-serif;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 400;
                        color: #272338;
                        display: block;
                        margin: 0;
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
}

//======================== Start CSS - Other Articles ========================
.other-articles-wrap {
    border-top: 1px solid #272338;
    border-bottom: 1px solid #272338;
    padding: 32px 0 48px;

    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    &.dusty-bg {
        background: #EFB7A6;
    }

    .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        margin: 0 0 10px;
        flex-wrap: wrap;
        @media screen and (max-width: 767px) {
            margin-bottom: 18px;
        }

        .section-semi-title {
            font-size: 32px;
            line-height: 38px;
            margin: 0;

            //@media screen and (max-width: 767px) {
            //    font-size: 26px;
            //    line-height: 31px;
            //}
        }

        .secondary-btn {
            gap: 8px;

            @media screen and (min-width: 768px) {
                padding: 9px 16px;
                font-size: 14px;
                line-height: 20px;
            }

            @media screen and (max-width: 480px) {
                width: 100%;
            }

            img {
                position: relative;
                right: 0;
                transition: all 0.35s ease 0s;
            }

            &:hover {
                img {
                    right: -4px;
                }
            }
        }
    }

    .card-list {
        margin-bottom: 42px;

        @media screen and (max-width: 767px) {
            margin-bottom: 32px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .card-main {
            .slider-custom {
                margin: 0 0 12px;
            }

            &:hover {
                border-color: transparent;
                background: transparent;
            }
        }
    }
}

//======================== END CSS - Other Articles ========================

//======================= Looking for more ideas? Section Css Start =======================
.more-ideas-wrap {
    background: #A8AED6;
    padding: 60px 0;

    @media screen and (max-width: 1280px) {
        padding: 50px 0;
    }

    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    .container {
        padding-left: 80px;
        padding-right: 80px;
        gap: 24px 48px;
        display: flex;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
            gap: 24px 32px;
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }

    .section-title {
        margin-bottom: 12px;
        @media screen and (max-width: 767px) {
            margin-bottom: 8px;
        }
    }

    .section-info {
        max-width: 526px;

        @media screen and (max-width: 991px) {
            max-width: 100%;
        }
    }

    .lets-lift-header {
        flex: 1;

        strong, b {
            font-weight: 500;
        }
    }

    .lets-lift-links {
        flex: 1;
        gap: 8px;
        flex-wrap: wrap;
        align-items: flex-start;
        display: flex;

        .secondary-btn {
            font-size: 14px;
            line-height: 18px;
            padding: 10px 16px;

            @media screen and (max-width: 359px) {
                width: 100%;
            }
        }
    }
}

//======================= Looking for more ideas? Section Css End =======================
