.venues-map-wrap {
    padding: 0 0 48px;

    @media screen and (max-width: 767px) {
        padding: 0;
    }

    .back-btn {
        .mob-back-icon {
            display: none;
        }

        .mob-back-text {
            display: none;
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            color: #272338;
            position: initial;
            left: auto;
        }

        @media screen and (max-width: 767px) {
            padding: 16px 56px;
            margin: 0 -16px 24px;
            position: relative;
            text-align: center;
            border-bottom: 1px solid #272338;

            button {
                position: absolute;
                left: 16px;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 32px;

                span {
                    display: none;
                }

                img {
                    display: none;

                    &.mob-back-icon {
                        display: inline-block;
                    }
                }
            }

            .mob-back-text {
                display: block;
            }
        }
    }

    .venues-map-head {
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #4D4D4D;
        gap: 16px 24px;
        justify-content: space-between;
        align-items: flex-start;
        display: flex;

        @media screen and (max-width: 991px) {
            flex-wrap: wrap;
        }

        @media screen and (max-width: 480px) {
            flex-direction: column;
        }

        p {
            margin: 0;
        }

        .venues-map-head-title {
            .section-title {
                margin: 0 0 8px;
            }

            @media screen and (max-width: 480px) {
                br {
                    display: none;
                }
            }
        }

        .secondary-btn {
            gap: 8px;
            font-size: 14px;
            line-height: 18px;
            padding: 9px 16px;
            white-space: nowrap;
            min-width: 154px;
            margin: 4px 0 0;

            @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 20px;
                padding: 11px 16px;
            }

            @media screen and (max-width: 480px) {
                margin-top: 0;
                width: 100%;
            }

            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    .venues-map-box {
        margin-top: 24px;
        border-radius: 16px;
        border: 1px solid #272338;
        overflow: hidden;
        height: 487px;

        @media screen and (max-width: 767px) {
            height: 430px;
            margin: 24px -16px 0;
            border-radius: 0;
            border: none;
            border-top: 1px solid #272338;
        }

        img,
        iframe {
            border: none;
            outline: none;
            padding: 0;
            vertical-align: middle;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}
