//======================= banner-section css Start =======================
.banner-section {
    background-color: #272338;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        background-image: url("https://ik.imagekit.io/wedshed/production/wed-assets/home/responsive-banner-img.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 480px;
        padding: 0 0 24px;
        display: flex;
        align-items: flex-end;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.2);
            background: linear-gradient(0deg, rgba(39, 35, 56, 1) 0%, rgba(0, 0, 0, 0) 100%);
            pointer-events: none;
            min-height: 70%;
        }
    }

    .container {
        padding-left: 80px;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
        }

        @media screen and (max-width: 767px) {
            position: relative;
        }
    }

    .banner-section-inner {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        min-height: 573px;

        @media screen and (max-width: 1440px) {
            min-height: 570px;
        }

        @media screen and (max-width: 1366px) {
            min-height: 520px;
        }

        @media screen and (max-width: 1200px) {
            min-height: 460px;
        }

        @media screen and (max-width: 991px) {
            min-height: 350px;
        }

        @media screen and (max-width: 767px) {
            min-height: initial;
        }

        .banner-content {
            .page-title {
                font-family: Livorno, serif;
                font-size: 60px;
                line-height: 60px;
                font-weight: 400;
                letter-spacing: -0.01em;
                color: #fff;
                margin: 0 0 42px 0;
                max-width: 420px;

                @media screen and (max-width: 1399px) {
                    font-size: 50px;
                    line-height: 50px;
                    max-width: 340px;
                }

                @media screen and (max-width: 1199px) {
                    font-size: 40px;
                    line-height: 44px;
                    max-width: 290px;
                }

                @media screen and (max-width: 768px) {
                    margin-bottom: 16px;
                }

                @media screen and (max-width: 767px) {
                    text-shadow: -1px -1px 0 #272338, 1px -1px 0 #272338, -1px 1px 0 #272338, 1px 1px 0 #272338;
                }
            }

            .primary-btn {
                &:hover {
                    border-color: #FFFFFF;
                }
            }
        }

        .image-gallery {
            display: flex;
            align-items: flex-end;
            gap: 15px;
            margin-right: -50px;
            width: calc(100% - 379px);

            @media screen and (max-width: 1199px) {
                margin-right: -20px;
            }

            @media screen and (max-width: 767px) {
                display: none;
            }

            .column {
                flex: 1;
                display: flex;
                gap: 15px;
                flex-direction: column;

                img {
                    width: 100%;
                    height: auto;
                    border-radius: 10px;
                    border: 2px solid #16141F;
                    object-fit: cover;
                    object-position: center center;

                    &:nth-child(2) {
                        border-radius: 10px 10px 0 0;
                        border-bottom: 0;
                    }
                }

                &:first-child {
                    img {
                        border-radius: 10px 10px 0 0;
                        border-bottom: 0;
                    }
                }

                &:nth-child(3) {
                    @media screen and (max-width: 991px) {
                        display: none;
                    }

                    img {
                        &:first-child {
                            border-radius: 0 0 10px 10px;
                            border-top: 0;
                        }
                    }
                }

                &:last-child {
                    @media screen and (max-width: 1199px) {
                        display: none;
                    }
                }
            }
        }
    }
}

//======================= banner-section css End =======================

//======================= Trending css Start =======================
.trending-section {
    background: #FFFBF8;
    padding: 60px 0;
    border-top: 1px solid #FFE6D5;
    border-bottom: 1px solid #FFE6D5;

    @media screen and (max-width: 1280px) {
        padding: 50px 0;
    }

    @media screen and (max-width: 991px) {
        padding: 40px 0;
    }

    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    .trending-section-inner {
        display: flex;
        align-items: center;
        gap: 48px;
        padding: 0 0 0 40px;

        @media screen and (max-width: 1399px) {
            gap: 28px;
            padding: 0 0 0 40px;
        }

        @media screen and (max-width: 1360px) {
            padding: 0;
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
            gap: 20px;
        }

        @media screen and (max-width: 575px) {
            gap: 16px;
        }

        .left-side {
            max-width: 375px;

            @media screen and (max-width: 1280px) {
                max-width: 345px;
            }

            @media screen and (max-width: 991px) {
                text-align: center;
                width: 100%;
            }

            @media screen and (max-width: 575px) {
                text-align: left;
                max-width: inherit;
            }

            .section-title {
                margin: 0 0 12px;
            }

            .link {
                font-weight: 500;
                text-decoration: none;
                color: #333333;
            }
        }

        .right-side {
            flex: 1;

            @media screen and (max-width: 991px) {
                width: 100%;
            }

            .trending-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 8px;

                @media screen and (max-width: 575px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                .trending-link {
                    display: inline-flex;
                    align-items: center;
                    gap: 16px;
                    border: 1px solid #272338;
                    padding: 16px;
                    border-radius: 16px;
                    text-decoration: none;
                    transition: all 0.35s ease 0s;

                    &:hover {
                        background-color: #FFE6D5;
                    }

                    .trending-image {
                        border: 1px solid #272338;
                        width: 60px;
                        height: 60px;
                        min-width: 60px;
                        border-radius: 60px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 60px;
                            object-fit: cover;
                            object-position: center center;
                        }
                    }

                    .trending-content {
                        display: inline-block;
                        flex: 1;

                        .label {
                            display: block;
                            font-family: Aeonik, sans-serif;
                            font-size: 12px;
                            line-height: 14px;
                            font-weight: 500;
                            margin: 0 0 4px;
                            color: #808080;
                        }

                        .trending-title {
                            font-family: Aeonik, sans-serif;
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 500;
                            color: #272338;
                            display: block;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

//======================= Trending css End =======================

//======= Plan Section Start =======
.plan-wrap {
    padding: 48px 0 0;
    background: #FFF5EE;
    overflow: hidden;

    @media screen and (max-width: 991px) {
        padding: 40px 0 0;
    }

    @media screen and (max-width: 767px) {
        padding: 16px 0 0;
    }

    .plan-header {
        margin-bottom: 48px;
        text-align: center;

        @media screen and (max-width: 991px) {
            margin-bottom: 40px;
        }

        @media screen and (max-width: 767px) {
            margin-bottom: 16px;
        }

        .section-info {
            //max-width: 700px;
            max-width: 650px;
            margin: 0 auto;

            @media screen and (max-width: 991px) {
                max-width: 640px;
            }
        }
    }

    .plan-listing {
        background: #FFE6D5;

        .container {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1px;
            //background: #EFB7A6;
            //background: #FFF5EE;
            padding: 0 1px;
            //max-width: 100%;
            max-width: 1440px;
            overflow: hidden;

            @media screen and (max-width: 767px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        h3 {
            margin: 0;
            font-family: Aeonik, sans-serif;
            font-size: 22px;
            line-height: 26px;
            font-weight: 500;
            letter-spacing: -0.01em;
            text-align: center;
            color: #272338;
            //background: #FFE6D5;

            @media screen and (max-width: 991px) {
                font-size: 16px;
                line-height: 20px;
            }

            .plan-listing-image {
                display: inline-flex;
                border-radius: 16px;
                width: 100%;
                padding-bottom: 85.175%;
                background: #FFF5EE;
                position: relative;
                vertical-align: top;

                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border: 1px solid #272338;
                    border-radius: 16px;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    object-fit: cover;
                    object-position: center center;
                }
            }

            .plan-listing-title {
                margin-top: 18px;
                font-weight: 500;
                display: inline-flex;
                position: relative;
                transition: all 0.35s ease 0s;

                @media screen and (max-width: 991px) {
                    margin-top: 13px;
                }

                &:after {
                    content: "";
                    position: relative;
                    top: 0;
                    right: 17px;
                    bottom: 0;
                    margin: auto 0;
                    width: 0;
                    height: 12px;
                    background-image: url(/assets/images/arrow-right-icon.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    transition: all 0.35s ease 0s;
                    opacity: 0;
                    display: inline-flex;
                    visibility: hidden;
                }
            }

            a {
                display: block;
                color: #272338;
                padding: 24px 22px;
                height: 100%;
                width: 100%;
                cursor: pointer;
                position: relative;
                transition: all 0.35s ease 0s;

                &:before {
                    content: '';
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    bottom: -1px;
                    left: -1px;
                    border: 1px solid #FFF5EE;
                    pointer-events: none;
                    transition: all 0.35s ease 0s;
                }

                @media screen and (max-width: 991px) {
                    padding: 16px 16px 18px;
                }

                &:hover {
                    background: #EFB7A6;

                    &:before {
                        border-color: #EFB7A6;
                    }

                    span {
                        &:after {
                            opacity: 1;
                            visibility: visible;
                            right: 0;
                            width: 17px;
                            margin-left: 15px;

                            @media screen and (max-width: 991px) {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

//======= Plan Section END =======

//======================= Services Info Section Css Start =======================
.services-info-wrap {
    padding: 40px 0;

    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    &.planning-hub {
        background-color: #A8AED6;
        border-top: 1px solid #6D629D;
        border-bottom: 1px solid #6D629D;
    }

    &.check-out-gravy {
        background-color: #FFE6D5;
        border-top: 1px solid #EFB7A6;
        border-bottom: 1px solid #EFB7A6;
    }

    &.view-regions {
        background-color: #94B3A5;
        border-top: 1px solid #82A193;
        border-bottom: 1px solid #82A193;
    }

    &.view-our-venues {
        background-color: #EFB7A6;
        border-top: 1px solid #CB8570;
        border-bottom: 1px solid #CB8570;
    }

    .services-info-inner {
        display: flex;
        align-items: center;
        gap: 116px;
        position: relative;

        @media screen and (max-width: 1399px) {
            gap: 80px;
        }

        @media screen and (max-width: 1199px) {
            gap: 60px;
        }

        @media screen and (max-width: 991px) {
            gap: 40px;
        }

        @media screen and (max-width: 767px) {
            flex-direction: column-reverse;
            gap: 24px;
        }

        .section-pagination {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;

            @media screen and (max-width: 1023px) {
                display: none;
            }

            .dot {
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 30px;
                background-color: #EFB7A6;
                border: 2px solid #272338;
                cursor: pointer;

                &.active {
                    background-color: #272338;
                    height: 40px;
                }
            }
        }

        .services-info-left {
            border-radius: 20px;
            max-width: 665px;
            width: 50%;

            @media screen and (max-width: 767px) {
                width: 100%;
                max-width: inherit;
                border-radius: 16px;
            }

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                object-position: center center;
                border-radius: 20px;
                border: 1px solid #272338;

                @media screen and (max-width: 767px) {
                    border-radius: 16px;
                }
            }
        }

        .services-info-right {
            max-width: 420px;

            @media screen and (max-width: 767px) {
                max-width: inherit;

                .section-title {
                    margin-bottom: 12px;
                }
            }

            .right-side-action {
                align-items: center;
                gap: 8px;
                flex-wrap: wrap;
                display: flex;

                @media screen and (max-width: 480px) {

                    .primary-btn,
                    .secondary-btn {
                        padding-left: 16px;
                        padding-right: 16px;
                    }
                }

                @media screen and (max-width: 359px) {

                    .primary-btn,
                    .secondary-btn {
                        width: 100%;
                    }
                }
            }

            .section-info {
                margin: 0 0 32px;

                @media screen and (max-width: 767px) {
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}

//======================= Services Info Section Css End =======================

//======================= Featured in Section Css Start =======================
.featured-wrap {
    background: #661735;
    padding: 60px 0;
    border-top: 1px solid #4E0A24;
    border-bottom: 1px solid #4E0A24;
    text-align: center;

    @media screen and (max-width: 1280px) {
        padding: 50px 0;
    }

    @media screen and (max-width: 991px) {
        padding: 40px 0;
    }

    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    .container {
        max-width: 930px;
    }

    h2 {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #FFFFFF;
        text-transform: uppercase;
        margin: 0 0 24px;
    }

    .featured-logos {
        align-items: center;
        justify-content: center;
        gap: 16px 24px;
        flex-wrap: wrap;
        display: flex;

        @media screen and (max-width: 767px) {
            gap: 24px 20px;

            img {
                &:nth-child(1) {
                    order: 5;
                }

                &:nth-child(2) {
                    order: 6;
                }

                &:nth-child(3) {
                    order: 4;
                }

                &:nth-child(4) {
                    order: 7;
                }

                &:nth-child(5) {
                    order: 8;
                }

                &:nth-child(6) {
                    order: 3;
                }

                &:nth-child(7) {
                    order: 2;
                }

                &:nth-child(8) {
                    order: 1;
                }
            }
        }
    }
}

//======================= Featured in Section Css End =======================

//======================= Let’s lift Section Css Start =======================
.lets-lift-wrap {
    background: #A8AED6;
    padding: 60px 0;

    @media screen and (max-width: 1280px) {
        padding: 50px 0;
    }

    @media screen and (max-width: 991px) {
        padding: 40px 0;
    }

    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    .container {
        padding-left: 80px;
        padding-right: 80px;
        gap: 24px 48px;
        display: flex;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
            gap: 24px 32px;
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }

    .section-title {
        margin-bottom: 12px;
    }

    .section-info {
        max-width: 526px;

        @media screen and (max-width: 991px) {
            max-width: 100%;
        }

        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .lets-lift-header {
        flex: 1;
    }

    .lets-lift-links {
        flex: 1;
        gap: 8px;
        align-items: flex-start;
        flex-wrap: wrap;
        display: flex;

        .secondary-btn {
            font-size: 14px;
            line-height: 18px;
            padding: 10px 16px;

            @media screen and (max-width: 359px) {
                width: 100%;
            }
        }
    }
}

//======================= Let’s lift Section Css End =======================

//======================= planning your wedding Section Css Start =======================
.planning-wedding {
    background-color: #FFE6D5;
    border-top: 1px solid #272338;
    border-bottom: 1px solid #272338;

    .container {
        padding-right: 0;

        @media screen and (max-width: 767px) {
            padding-right: 16px;
        }
    }

    .planning-wedding-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 24px;
        }

        .planning-wedding-content {
            max-width: 580px;

            @media screen and (max-width: 767px) {
                max-width: 320px;
                padding: 32px 0 0;
                text-align: center;
            }
        }

        .right-side-image {
            text-align: right;

            img {
                width: 100%;
                height: auto;
            }
        }

        .section-title {
            margin: 0 0 32px;

            @media screen and (max-width: 991px) {
                font-size: 26px;
                font-weight: 400;
                line-height: 31.2px;
                letter-spacing: -0.01em;
            }

            @media screen and (max-width: 767px) {
                margin: 0 0 24px;
            }

            strong {
                font-family: Livorno, serif;
                color: #E2763A;
                font-weight: 400;
            }
        }
    }
}

//======================= planning your wedding Section Css End =======================
