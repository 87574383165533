<!--Planning Hub page Title -->
<section class="page-title-wrap">
    <div class="container">
        <div class="page-title-info">
            <h1 class="section-title">
                <span class="title-top-icon"><img src="assets/images/title-top-star.svg" width="36" height="46"
                        alt="Star icon"></span>
                Plan your special day all in one (super cool) place
                <span class="title-bottom-icon"><img src="assets/images/title-bottom-star.svg" width="32" height="32"
                        alt="Star icon"></span>
            </h1>
            <p>Some exciting things are brewing here at Wedshed. Our Wedding Planning Hub is in the works - your
                soon-to-be one stop wedding management tool.</p>
            <button type="button" class="primary-btn" (click)="joinWaitlistModal()">Join the waitlist</button>
        </div>
    </div>
</section>

<!--What's to Come-->
<section class="whats-to-come">
    <div class="container">
        <h2 class="section-title">What’s to come</h2>
        <p class="section-info">In a nutshell, a fun and free dedicated space for you to keep all your planning in
            check.
            💅</p>
    </div>

    <div class="whats-come-list">
        <div class="whats-come-inner">
            <div class="whats-come-item">
                <div class="whats-come-item-image">
                    <img [src]="imagePathConstants.PLANNING_HUB + '/message-businesses.jpg'" width="640" height="532"
                        alt="Wedding inbox for inquiries & tracking" appSetImageDimensions loading="lazy">
                </div>
                <h3>Message businesses</h3>
                <p>An inbox dedicated to wedding-related queries and goodness. Enquire and keep track of your message
                    threads with businesses you are interested in.</p>
            </div>
            <div class="whats-come-item">
                <div class="whats-come-item-image">
                    <img [src]="imagePathConstants.PLANNING_HUB + '/save-listings.jpg'" width="640" height="532"
                        alt="Browse Wedshed venues & vendors, save in planning hub" appSetImageDimensions loading="lazy">
                </div>
                <h3>Save listings</h3>
                <p>Browse through our Wedshed venues and vendors to find the matches of your dreams. Save and collate
                    your favourites in your planning hub.</p>
            </div>
            <div class="whats-come-item">
                <div class="whats-come-item-image">
                    <img [src]="imagePathConstants.PLANNING_HUB + '/wedding-page.jpg'" width="640" height="532"
                        alt="Your planning hub for storing wedding details" appSetImageDimensions loading="lazy">
                </div>
                <h3>Create your wedding page</h3>
                <p>A page you can keep coming back to throughout the whole planning journey, where you can store
                    everything related to your big day and the lead up.</p>
            </div>
            <div class="whats-come-item">
                <div class="whats-come-item-image">
                    <img [src]="imagePathConstants.PLANNING_HUB + '/do-manageable.jpg'" width="640" height="532"
                        alt="Customizable wedding checklist to stay on track" appSetImageDimensions loading="lazy">
                </div>
                <h3>Make to do’s manageable</h3>
                <p>The ultimate tick list to keep you on track and in control of your planning. Start with our template
                    and customise it to suit your big day.</p>
            </div>
        </div>
    </div>
</section>

<!-- Stay in the loop -->
<section class="stay-in-loop">
    <div class="container">
        <div class="stay-in-loop-info">
            <h2 class="section-title">Stay in the loop</h2>
            <p class="section-info">Sign up and we’ll let you know as soon as our Wedding Planning Hub has launched (and
                is ready for your plans).</p>
            <button type="button" class="primary-btn" (click)="joinWaitlistModal()">Join the waitlist</button>
        </div>
        <div class="stay-in-loop-img">
            <img [src]="imagePathConstants.PLANNING_HUB + '/laptop-loading.png'" width="762" height="438"
                alt="Businesses message" appSetImageDimensions loading="lazy">
        </div>
    </div>
</section>
