import {Component, ViewEncapsulation} from '@angular/core';
import {CommonConstants, CountryCodes, RoutesConstants} from '@coreconstant';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {noWhitespaceValidator} from '../core/services/common-service/common.service';
import {GeneralService} from '../core/services/api-service/general.service';
import {lastValueFrom} from 'rxjs';
import {ResponseHandlerService} from '../core/services/common-service/response-handler.service';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {IMaskModule} from 'angular-imask';
import {SeoService} from '../core/services/common-service/seo.service';
import {RecaptchaModule} from 'ng-recaptcha-2';

@Component({
    selector: 'app-get-in-touch',
    imports: [RouterModule, FormsModule, ReactiveFormsModule, NgSelectModule, IMaskModule,RecaptchaModule],
    templateUrl: './get-in-touch.component.html',
    styleUrls: ['../how-it-works/contact-us/contact-us.component.scss', './get-in-touch.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GetInTouchComponent {
    routesConstants = RoutesConstants
    commonConstants = CommonConstants
    getInTouchDetailForm: FormGroup;
    enquiryReasons: any;
    countryCodes = CountryCodes;
    submitted: boolean = false;
    isSubmitAndShowSuccess: boolean = false

    constructor(
        private _formBuilder: FormBuilder,
        private _seoService: SeoService,
        private _generalService: GeneralService,
        private _responseHandlerService: ResponseHandlerService
    ) {
        this.setMetaTags()
    }

    ngOnInit() {
        this.getEnquiryReasons();
        this.initializeForm();
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Get In Touch | Wedshed',
            description: 'Get In Touch | Wedshed',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.GET_IN_TOUCH,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    initializeForm() {
        this.getInTouchDetailForm = this._formBuilder.group({
            contactName: ["", [Validators.required, noWhitespaceValidator]],
            countryCode: [this.commonConstants.AUSTRALIA_COUNTRY_CODE, [Validators.required]],
            contactNumber: [null, [Validators.required, Validators.pattern(this.commonConstants.MOBILE_REGEX)]],
            contactEmail: ["", [Validators.required, Validators.pattern(this.commonConstants.EMAIL_REGEX)]],
            enquiryReasonId: [null, Validators.required],
            contactMessage: ["", Validators.required],
            recaptcha: ['', Validators.required],
        });
    }

    onCaptchaResolved(captchaResponse: string) {
        this.getInTouchDetailForm.patchValue({ recaptcha: captchaResponse });
    }

    getEnquiryReasons() {
        var getListing = this._generalService.getEnquiryReasons()
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.enquiryReasons = res["data"]
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    submit() {
        this.submitted = true;
        if (this.getInTouchDetailForm.invalid) {
            this.getInTouchDetailForm.markAllAsTouched();
            this.getInTouchDetailForm.markAsDirty();
            return
        }
        const flattenedValues = {
            ...this.getInTouchDetailForm.value,
        }
        let addUpdateRecord = this._generalService.createContactEnquiry(flattenedValues)
        lastValueFrom(addUpdateRecord).then((response: any) => {
            if (response["status"] == true) {
                this.isSubmitAndShowSuccess = true
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error);
        });
    }
}
