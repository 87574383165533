export class CountryCodes {
    public static CODE =
        [
            {
                "name": "Andorra",
                "code": "AD",
                "emoji": "🇦🇩",
                "dialCode": "+376"
            },
            {
                "name": "United Arab Emirates",
                "code": "AE",
                "emoji": "🇦🇪",
                "dialCode": "+971"
            },
            {
                "name": "Afghanistan",
                "code": "AF",
                "emoji": "🇦🇫",
                "dialCode": "+93"
            },
            {
                "name": "Antigua & Barbuda",
                "code": "AG",
                "emoji": "🇦🇬",
                "dialCode": "+1268"
            },
            {
                "name": "Anguilla",
                "code": "AI",
                "emoji": "🇦🇮",
                "dialCode": "+1264"
            },
            {
                "name": "Albania",
                "code": "AL",
                "emoji": "🇦🇱",
                "dialCode": "+355"
            },
            {
                "name": "Armenia",
                "code": "AM",
                "emoji": "🇦🇲",
                "dialCode": "+374"
            },
            {
                "name": "Angola",
                "code": "AO",
                "emoji": "🇦🇴",
                "dialCode": "+244"
            },
            {
                "name": "Antarctica",
                "code": "AQ",
                "emoji": "🇦🇶",
                "dialCode": "+672"
            },
            {
                "name": "Argentina",
                "code": "AR",
                "emoji": "🇦🇷",
                "dialCode": "+54"
            },
            {
                "name": "American Samoa",
                "code": "AS",
                "emoji": "🇦🇸",
                "dialCode": "+1684"
            },
            {
                "name": "Austria",
                "code": "AT",
                "emoji": "🇦🇹",
                "dialCode": "+43"
            },
            {
                "name": "Australia",
                "code": "AU",
                "emoji": "🇦🇺",
                "dialCode": "+61"
            },
            {
                "name": "Aruba",
                "code": "AW",
                "emoji": "🇦🇼",
                "dialCode": "+297"
            },
            {
                "name": "Åland Islands",
                "code": "AX",
                "emoji": "🇦🇽",
                "dialCode": "+358"
            },
            {
                "name": "Azerbaijan",
                "code": "AZ",
                "emoji": "🇦🇿",
                "dialCode": "+994"
            },
            {
                "name": "Bosnia & Herzegovina",
                "code": "BA",
                "emoji": "🇧🇦",
                "dialCode": "+387"
            },
            {
                "name": "Barbados",
                "code": "BB",
                "emoji": "🇧🇧",
                "dialCode": "+1246"
            },
            {
                "name": "Bangladesh",
                "code": "BD",
                "emoji": "🇧🇩",
                "dialCode": "+880"
            },
            {
                "name": "Belgium",
                "code": "BE",
                "emoji": "🇧🇪",
                "dialCode": "+32"
            },
            {
                "name": "Burkina Faso",
                "code": "BF",
                "emoji": "🇧🇫",
                "dialCode": "+226"
            },
            {
                "name": "Bulgaria",
                "code": "BG",
                "emoji": "🇧🇬",
                "dialCode": "+359"
            },
            {
                "name": "Bahrain",
                "code": "BH",
                "emoji": "🇧🇭",
                "dialCode": "+973"
            },
            {
                "name": "Burundi",
                "code": "BI",
                "emoji": "🇧🇮",
                "dialCode": "+257"
            },
            {
                "name": "Benin",
                "code": "BJ",
                "emoji": "🇧🇯",
                "dialCode": "+229"
            },
            {
                "name": "St. Barthélemy",
                "code": "BL",
                "emoji": "🇧🇱",
                "dialCode": "+590"
            },
            {
                "name": "Bermuda",
                "code": "BM",
                "emoji": "🇧🇲",
                "dialCode": "+1441"
            },
            {
                "name": "Brunei",
                "code": "BN",
                "emoji": "🇧🇳",
                "dialCode": "+673"
            },
            {
                "name": "Bolivia",
                "code": "BO",
                "emoji": "🇧🇴",
                "dialCode": "+591"
            },
            {
                "name": "Brazil",
                "code": "BR",
                "emoji": "🇧🇷",
                "dialCode": "+55"
            },
            {
                "name": "Bahamas",
                "code": "BS",
                "emoji": "🇧🇸",
                "dialCode": "+1242"
            },
            {
                "name": "Bhutan",
                "code": "BT",
                "emoji": "🇧🇹",
                "dialCode": "+975"
            },
            {
                "name": "Botswana",
                "code": "BW",
                "emoji": "🇧🇼",
                "dialCode": "+267"
            },
            {
                "name": "Belarus",
                "code": "BY",
                "emoji": "🇧🇾",
                "dialCode": "+375"
            },
            {
                "name": "Belize",
                "code": "BZ",
                "emoji": "🇧🇿",
                "dialCode": "+501"
            },
            {
                "name": "Canada",
                "code": "CA",
                "emoji": "🇨🇦",
                "dialCode": "+1"
            },
            {
                "name": "Cocos (Keeling) Islands",
                "code": "CC",
                "emoji": "🇨🇨",
                "dialCode": "+61"
            },
            {
                "name": "Congo - Kinshasa",
                "code": "CD",
                "emoji": "🇨🇩",
                "dialCode": "+243"
            },
            {
                "name": "Central African Republic",
                "code": "CF",
                "emoji": "🇨🇫",
                "dialCode": "+236"
            },
            {
                "name": "Congo - Brazzaville",
                "code": "CG",
                "emoji": "🇨🇬",
                "dialCode": "+242"
            },
            {
                "name": "Switzerland",
                "code": "CH",
                "emoji": "🇨🇭",
                "dialCode": "+41"
            },
            {
                "name": "Côte d’Ivoire",
                "code": "CI",
                "emoji": "🇨🇮",
                "dialCode": "+225"
            },
            {
                "name": "Cook Islands",
                "code": "CK",
                "emoji": "🇨🇰",
                "dialCode": "+682"
            },
            {
                "name": "Chile",
                "code": "CL",
                "emoji": "🇨🇱",
                "dialCode": "+56"
            },
            {
                "name": "Cameroon",
                "code": "CM",
                "emoji": "🇨🇲",
                "dialCode": "+237"
            },
            {
                "name": "China",
                "code": "CN",
                "emoji": "🇨🇳",
                "dialCode": "+86"
            },
            {
                "name": "Colombia",
                "code": "CO",
                "emoji": "🇨🇴",
                "dialCode": "+57"
            },
            {
                "name": "Costa Rica",
                "code": "CR",
                "emoji": "🇨🇷",
                "dialCode": "+506"
            },
            {
                "name": "Cuba",
                "code": "CU",
                "emoji": "🇨🇺",
                "dialCode": "+53"
            },
            {
                "name": "Cape Verde",
                "code": "CV",
                "emoji": "🇨🇻",
                "dialCode": "+238"
            },
            {
                "name": "Christmas Island",
                "code": "CX",
                "emoji": "🇨🇽",
                "dialCode": "+61"
            },
            {
                "name": "Cyprus",
                "code": "CY",
                "emoji": "🇨🇾",
                "dialCode": "+357"
            },
            {
                "name": "Czechia",
                "code": "CZ",
                "emoji": "🇨🇿",
                "dialCode": "+420"
            },
            {
                "name": "Germany",
                "code": "DE",
                "emoji": "🇩🇪",
                "dialCode": "+49"
            },
            {
                "name": "Djibouti",
                "code": "DJ",
                "emoji": "🇩🇯",
                "dialCode": "+253"
            },
            {
                "name": "Denmark",
                "code": "DK",
                "emoji": "🇩🇰",
                "dialCode": "+45"
            },
            {
                "name": "Dominica",
                "code": "DM",
                "emoji": "🇩🇲",
                "dialCode": "+1767"
            },
            {
                "name": "Dominican Republic",
                "code": "DO",
                "emoji": "🇩🇴",
                "dialCode": "+1849"
            },
            {
                "name": "Algeria",
                "code": "DZ",
                "emoji": "🇩🇿",
                "dialCode": "+213"
            },
            {
                "name": "Ecuador",
                "code": "EC",
                "emoji": "🇪🇨",
                "dialCode": "+593"
            },
            {
                "name": "Estonia",
                "code": "EE",
                "emoji": "🇪🇪",
                "dialCode": "+372"
            },
            {
                "name": "Egypt",
                "code": "EG",
                "emoji": "🇪🇬",
                "dialCode": "+20"
            },
            {
                "name": "Eritrea",
                "code": "ER",
                "emoji": "🇪🇷",
                "dialCode": "+291"
            },
            {
                "name": "Spain",
                "code": "ES",
                "emoji": "🇪🇸",
                "dialCode": "+34"
            },
            {
                "name": "Ethiopia",
                "code": "ET",
                "emoji": "🇪🇹",
                "dialCode": "+251"
            },
            {
                "name": "Finland",
                "code": "FI",
                "emoji": "🇫🇮",
                "dialCode": "+358"
            },
            {
                "name": "Fiji",
                "code": "FJ",
                "emoji": "🇫🇯",
                "dialCode": "+679"
            },
            {
                "name": "Falkland Islands",
                "code": "FK",
                "emoji": "🇫🇰",
                "dialCode": "+500"
            },
            {
                "name": "Micronesia",
                "code": "FM",
                "emoji": "🇫🇲",
                "dialCode": "+691"
            },
            {
                "name": "Faroe Islands",
                "code": "FO",
                "emoji": "🇫🇴",
                "dialCode": "+298"
            },
            {
                "name": "France",
                "code": "FR",
                "emoji": "🇫🇷",
                "dialCode": "+33"
            },
            {
                "name": "Gabon",
                "code": "GA",
                "emoji": "🇬🇦",
                "dialCode": "+241"
            },
            {
                "name": "United Kingdom",
                "code": "GB",
                "emoji": "🇬🇧",
                "dialCode": "+44"
            },
            {
                "name": "Grenada",
                "code": "GD",
                "emoji": "🇬🇩",
                "dialCode": "+1473"
            },
            {
                "name": "Georgia",
                "code": "GE",
                "emoji": "🇬🇪",
                "dialCode": "+995"
            },
            {
                "name": "French Guiana",
                "code": "GF",
                "emoji": "🇬🇫",
                "dialCode": "+594"
            },
            {
                "name": "Guernsey",
                "code": "GG",
                "emoji": "🇬🇬",
                "dialCode": "+44"
            },
            {
                "name": "Ghana",
                "code": "GH",
                "emoji": "🇬🇭",
                "dialCode": "+233"
            },
            {
                "name": "Gibraltar",
                "code": "GI",
                "emoji": "🇬🇮",
                "dialCode": "+350"
            },
            {
                "name": "Greenland",
                "code": "GL",
                "emoji": "🇬🇱",
                "dialCode": "+299"
            },
            {
                "name": "Gambia",
                "code": "GM",
                "emoji": "🇬🇲",
                "dialCode": "+220"
            },
            {
                "name": "Guinea",
                "code": "GN",
                "emoji": "🇬🇳",
                "dialCode": "+224"
            },
            {
                "name": "Guadeloupe",
                "code": "GP",
                "emoji": "🇬🇵",
                "dialCode": "+590"
            },
            {
                "name": "Equatorial Guinea",
                "code": "GQ",
                "emoji": "🇬🇶",
                "dialCode": "+240"
            },
            {
                "name": "Greece",
                "code": "GR",
                "emoji": "🇬🇷",
                "dialCode": "+30"
            },
            {
                "name": "South Georgia & South Sandwich Islands",
                "code": "GS",
                "emoji": "🇬🇸",
                "dialCode": "+500"
            },
            {
                "name": "Guatemala",
                "code": "GT",
                "emoji": "🇬🇹",
                "dialCode": "+502"
            },
            {
                "name": "Guam",
                "code": "GU",
                "emoji": "🇬🇺",
                "dialCode": "+1671"
            },
            {
                "name": "Guinea-Bissau",
                "code": "GW",
                "emoji": "🇬🇼",
                "dialCode": "+245"
            },
            {
                "name": "Guyana",
                "code": "GY",
                "emoji": "🇬🇾",
                "dialCode": "+595"
            },
            {
                "name": "Hong Kong SAR China",
                "code": "HK",
                "emoji": "🇭🇰",
                "dialCode": "+852"
            },
            {
                "name": "Honduras",
                "code": "HN",
                "emoji": "🇭🇳",
                "dialCode": "+504"
            },
            {
                "name": "Croatia",
                "code": "HR",
                "emoji": "🇭🇷",
                "dialCode": "+385"
            },
            {
                "name": "Haiti",
                "code": "HT",
                "emoji": "🇭🇹",
                "dialCode": "+509"
            },
            {
                "name": "Hungary",
                "code": "HU",
                "emoji": "🇭🇺",
                "dialCode": "+36"
            },
            {
                "name": "Indonesia",
                "code": "ID",
                "emoji": "🇮🇩",
                "dialCode": "+62"
            },
            {
                "name": "Ireland",
                "code": "IE",
                "emoji": "🇮🇪",
                "dialCode": "+353"
            },
            {
                "name": "Israel",
                "code": "IL",
                "emoji": "🇮🇱",
                "dialCode": "+972"
            },
            {
                "name": "Isle of Man",
                "code": "IM",
                "emoji": "🇮🇲",
                "dialCode": "+44"
            },
            {
                "name": "India",
                "code": "IN",
                "emoji": "🇮🇳",
                "dialCode": "+91"
            },
            {
                "name": "British Indian Ocean Territory",
                "code": "IO",
                "emoji": "🇮🇴",
                "dialCode": "+246"
            },
            {
                "name": "Iraq",
                "code": "IQ",
                "emoji": "🇮🇶",
                "dialCode": "+964"
            },
            {
                "name": "Iran",
                "code": "IR",
                "emoji": "🇮🇷",
                "dialCode": "+98"
            },
            {
                "name": "Iceland",
                "code": "IS",
                "emoji": "🇮🇸",
                "dialCode": "+354"
            },
            {
                "name": "Italy",
                "code": "IT",
                "emoji": "🇮🇹",
                "dialCode": "+39"
            },
            {
                "name": "Jersey",
                "code": "JE",
                "emoji": "🇯🇪",
                "dialCode": "+44"
            },
            {
                "name": "Jamaica",
                "code": "JM",
                "emoji": "🇯🇲",
                "dialCode": "+1876"
            },
            {
                "name": "Jordan",
                "code": "JO",
                "emoji": "🇯🇴",
                "dialCode": "+962"
            },
            {
                "name": "Japan",
                "code": "JP",
                "emoji": "🇯🇵",
                "dialCode": "+81"
            },
            {
                "name": "Kenya",
                "code": "KE",
                "emoji": "🇰🇪",
                "dialCode": "+254"
            },
            {
                "name": "Kyrgyzstan",
                "code": "KG",
                "emoji": "🇰🇬",
                "dialCode": "+996"
            },
            {
                "name": "Cambodia",
                "code": "KH",
                "emoji": "🇰🇭",
                "dialCode": "+855"
            },
            {
                "name": "Kiribati",
                "code": "KI",
                "emoji": "🇰🇮",
                "dialCode": "+686"
            },
            {
                "name": "Comoros",
                "code": "KM",
                "emoji": "🇰🇲",
                "dialCode": "+269"
            },
            {
                "name": "St. Kitts & Nevis",
                "code": "KN",
                "emoji": "🇰🇳",
                "dialCode": "+1869"
            },
            {
                "name": "North Korea",
                "code": "KP",
                "emoji": "🇰🇵",
                "dialCode": "+850"
            },
            {
                "name": "South Korea",
                "code": "KR",
                "emoji": "🇰🇷",
                "dialCode": "+82"
            },
            {
                "name": "Kuwait",
                "code": "KW",
                "emoji": "🇰🇼",
                "dialCode": "+965"
            },
            {
                "name": "Cayman Islands",
                "code": "KY",
                "emoji": "🇰🇾",
                "dialCode": "+ 345"
            },
            {
                "name": "Kazakhstan",
                "code": "KZ",
                "emoji": "🇰🇿",
                "dialCode": "+77"
            },
            {
                "name": "Laos",
                "code": "LA",
                "emoji": "🇱🇦",
                "dialCode": "+856"
            },
            {
                "name": "Lebanon",
                "code": "LB",
                "emoji": "🇱🇧",
                "dialCode": "+961"
            },
            {
                "name": "St. Lucia",
                "code": "LC",
                "emoji": "🇱🇨",
                "dialCode": "+1758"
            },
            {
                "name": "Liechtenstein",
                "code": "LI",
                "emoji": "🇱🇮",
                "dialCode": "+423"
            },
            {
                "name": "Sri Lanka",
                "code": "LK",
                "emoji": "🇱🇰",
                "dialCode": "+94"
            },
            {
                "name": "Liberia",
                "code": "LR",
                "emoji": "🇱🇷",
                "dialCode": "+231"
            },
            {
                "name": "Lesotho",
                "code": "LS",
                "emoji": "🇱🇸",
                "dialCode": "+266"
            },
            {
                "name": "Lithuania",
                "code": "LT",
                "emoji": "🇱🇹",
                "dialCode": "+370"
            },
            {
                "name": "Luxembourg",
                "code": "LU",
                "emoji": "🇱🇺",
                "dialCode": "+352"
            },
            {
                "name": "Latvia",
                "code": "LV",
                "emoji": "🇱🇻",
                "dialCode": "+371"
            },
            {
                "name": "Libya",
                "code": "LY",
                "emoji": "🇱🇾",
                "dialCode": "+218"
            },
            {
                "name": "Morocco",
                "code": "MA",
                "emoji": "🇲🇦",
                "dialCode": "+212"
            },
            {
                "name": "Monaco",
                "code": "MC",
                "emoji": "🇲🇨",
                "dialCode": "+377"
            },
            {
                "name": "Moldova",
                "code": "MD",
                "emoji": "🇲🇩",
                "dialCode": "+373"
            },
            {
                "name": "Montenegro",
                "code": "ME",
                "emoji": "🇲🇪",
                "dialCode": "+382"
            },
            {
                "name": "St. Martin",
                "code": "MF",
                "emoji": "🇲🇫",
                "dialCode": "+590"
            },
            {
                "name": "Madagascar",
                "code": "MG",
                "emoji": "🇲🇬",
                "dialCode": "+261"
            },
            {
                "name": "Marshall Islands",
                "code": "MH",
                "emoji": "🇲🇭",
                "dialCode": "+692"
            },
            {
                "name": "North Macedonia",
                "code": "MK",
                "emoji": "🇲🇰",
                "dialCode": "+389"
            },
            {
                "name": "Mali",
                "code": "ML",
                "emoji": "🇲🇱",
                "dialCode": "+223"
            },
            {
                "name": "Myanmar (Burma)",
                "code": "MM",
                "emoji": "🇲🇲",
                "dialCode": "+95"
            },
            {
                "name": "Mongolia",
                "code": "MN",
                "emoji": "🇲🇳",
                "dialCode": "+976"
            },
            {
                "name": "Macao SAR China",
                "code": "MO",
                "emoji": "🇲🇴",
                "dialCode": "+853"
            },
            {
                "name": "Northern Mariana Islands",
                "code": "MP",
                "emoji": "🇲🇵",
                "dialCode": "+1670"
            },
            {
                "name": "Martinique",
                "code": "MQ",
                "emoji": "🇲🇶",
                "dialCode": "+596"
            },
            {
                "name": "Mauritania",
                "code": "MR",
                "emoji": "🇲🇷",
                "dialCode": "+222"
            },
            {
                "name": "Montserrat",
                "code": "MS",
                "emoji": "🇲🇸",
                "dialCode": "+1664"
            },
            {
                "name": "Malta",
                "code": "MT",
                "emoji": "🇲🇹",
                "dialCode": "+356"
            },
            {
                "name": "Mauritius",
                "code": "MU",
                "emoji": "🇲🇺",
                "dialCode": "+230"
            },
            {
                "name": "Maldives",
                "code": "MV",
                "emoji": "🇲🇻",
                "dialCode": "+960"
            },
            {
                "name": "Malawi",
                "code": "MW",
                "emoji": "🇲🇼",
                "dialCode": "+265"
            },
            {
                "name": "Mexico",
                "code": "MX",
                "emoji": "🇲🇽",
                "dialCode": "+52"
            },
            {
                "name": "Malaysia",
                "code": "MY",
                "emoji": "🇲🇾",
                "dialCode": "+60"
            },
            {
                "name": "Mozambique",
                "code": "MZ",
                "emoji": "🇲🇿",
                "dialCode": "+258"
            },
            {
                "name": "Namibia",
                "code": "NA",
                "emoji": "🇳🇦",
                "dialCode": "+264"
            },
            {
                "name": "New Caledonia",
                "code": "NC",
                "emoji": "🇳🇨",
                "dialCode": "+687"
            },
            {
                "name": "Niger",
                "code": "NE",
                "emoji": "🇳🇪",
                "dialCode": "+227"
            },
            {
                "name": "Norfolk Island",
                "code": "NF",
                "emoji": "🇳🇫",
                "dialCode": "+672"
            },
            {
                "name": "Nigeria",
                "code": "NG",
                "emoji": "🇳🇬",
                "dialCode": "+234"
            },
            {
                "name": "Nicaragua",
                "code": "NI",
                "emoji": "🇳🇮",
                "dialCode": "+505"
            },
            {
                "name": "Netherlands",
                "code": "NL",
                "emoji": "🇳🇱",
                "dialCode": "+31"
            },
            {
                "name": "Norway",
                "code": "NO",
                "emoji": "🇳🇴",
                "dialCode": "+47"
            },
            {
                "name": "Nepal",
                "code": "NP",
                "emoji": "🇳🇵",
                "dialCode": "+977"
            },
            {
                "name": "Nauru",
                "code": "NR",
                "emoji": "🇳🇷",
                "dialCode": "+674"
            },
            {
                "name": "Niue",
                "code": "NU",
                "emoji": "🇳🇺",
                "dialCode": "+683"
            },
            {
                "name": "New Zealand",
                "code": "NZ",
                "emoji": "🇳🇿",
                "dialCode": "+64"
            },
            {
                "name": "Oman",
                "code": "OM",
                "emoji": "🇴🇲",
                "dialCode": "+968"
            },
            {
                "name": "Panama",
                "code": "PA",
                "emoji": "🇵🇦",
                "dialCode": "+507"
            },
            {
                "name": "Peru",
                "code": "PE",
                "emoji": "🇵🇪",
                "dialCode": "+51"
            },
            {
                "name": "French Polynesia",
                "code": "PF",
                "emoji": "🇵🇫",
                "dialCode": "+689"
            },
            {
                "name": "Papua New Guinea",
                "code": "PG",
                "emoji": "🇵🇬",
                "dialCode": "+675"
            },
            {
                "name": "Philippines",
                "code": "PH",
                "emoji": "🇵🇭",
                "dialCode": "+63"
            },
            {
                "name": "Pakistan",
                "code": "PK",
                "emoji": "🇵🇰",
                "dialCode": "+92"
            },
            {
                "name": "Poland",
                "code": "PL",
                "emoji": "🇵🇱",
                "dialCode": "+48"
            },
            {
                "name": "St. Pierre & Miquelon",
                "code": "PM",
                "emoji": "🇵🇲",
                "dialCode": "+508"
            },
            {
                "name": "Pitcairn Islands",
                "code": "PN",
                "emoji": "🇵🇳",
                "dialCode": "+872"
            },
            {
                "name": "Puerto Rico",
                "code": "PR",
                "emoji": "🇵🇷",
                "dialCode": "+1939"
            },
            {
                "name": "Palestinian Territories",
                "code": "PS",
                "emoji": "🇵🇸",
                "dialCode": "+970"
            },
            {
                "name": "Portugal",
                "code": "PT",
                "emoji": "🇵🇹",
                "dialCode": "+351"
            },
            {
                "name": "Palau",
                "code": "PW",
                "emoji": "🇵🇼",
                "dialCode": "+680"
            },
            {
                "name": "Paraguay",
                "code": "PY",
                "emoji": "🇵🇾",
                "dialCode": "+595"
            },
            {
                "name": "Qatar",
                "code": "QA",
                "emoji": "🇶🇦",
                "dialCode": "+974"
            },
            {
                "name": "Réunion",
                "code": "RE",
                "emoji": "🇷🇪",
                "dialCode": "+262"
            },
            {
                "name": "Romania",
                "code": "RO",
                "emoji": "🇷🇴",
                "dialCode": "+40"
            },
            {
                "name": "Serbia",
                "code": "RS",
                "emoji": "🇷🇸",
                "dialCode": "+381"
            },
            {
                "name": "Russia",
                "code": "RU",
                "emoji": "🇷🇺",
                "dialCode": "+7"
            },
            {
                "name": "Rwanda",
                "code": "RW",
                "emoji": "🇷🇼",
                "dialCode": "+250"
            },
            {
                "name": "Saudi Arabia",
                "code": "SA",
                "emoji": "🇸🇦",
                "dialCode": "+966"
            },
            {
                "name": "Solomon Islands",
                "code": "SB",
                "emoji": "🇸🇧",
                "dialCode": "+677"
            },
            {
                "name": "Seychelles",
                "code": "SC",
                "emoji": "🇸🇨",
                "dialCode": "+248"
            },
            {
                "name": "Sudan",
                "code": "SD",
                "emoji": "🇸🇩",
                "dialCode": "+249"
            },
            {
                "name": "Sweden",
                "code": "SE",
                "emoji": "🇸🇪",
                "dialCode": "+46"
            },
            {
                "name": "Singapore",
                "code": "SG",
                "emoji": "🇸🇬",
                "dialCode": "+65"
            },
            {
                "name": "St. Helena",
                "code": "SH",
                "emoji": "🇸🇭",
                "dialCode": "+290"
            },
            {
                "name": "Slovenia",
                "code": "SI",
                "emoji": "🇸🇮",
                "dialCode": "+386"
            },
            {
                "name": "Svalbard & Jan Mayen",
                "code": "SJ",
                "emoji": "🇸🇯",
                "dialCode": "+47"
            },
            {
                "name": "Slovakia",
                "code": "SK",
                "emoji": "🇸🇰",
                "dialCode": "+421"
            },
            {
                "name": "Sierra Leone",
                "code": "SL",
                "emoji": "🇸🇱",
                "dialCode": "+232"
            },
            {
                "name": "San Marino",
                "code": "SM",
                "emoji": "🇸🇲",
                "dialCode": "+378"
            },
            {
                "name": "Senegal",
                "code": "SN",
                "emoji": "🇸🇳",
                "dialCode": "+221"
            },
            {
                "name": "Somalia",
                "code": "SO",
                "emoji": "🇸🇴",
                "dialCode": "+252"
            },
            {
                "name": "Suriname",
                "code": "SR",
                "emoji": "🇸🇷",
                "dialCode": "+597"
            },
            {
                "name": "South Sudan",
                "code": "SS",
                "emoji": "🇸🇸",
                "dialCode": "+211"
            },
            {
                "name": "São Tomé & Príncipe",
                "code": "ST",
                "emoji": "🇸🇹",
                "dialCode": "+239"
            },
            {
                "name": "El Salvador",
                "code": "SV",
                "emoji": "🇸🇻",
                "dialCode": "+503"
            },
            {
                "name": "Syria",
                "code": "SY",
                "emoji": "🇸🇾",
                "dialCode": "+963"
            },
            {
                "name": "Eswatini",
                "code": "SZ",
                "emoji": "🇸🇿",
                "dialCode": "+268"
            },
            {
                "name": "Turks & Caicos Islands",
                "code": "TC",
                "emoji": "🇹🇨",
                "dialCode": "+1649"
            },
            {
                "name": "Chad",
                "code": "TD",
                "emoji": "🇹🇩",
                "dialCode": "+235"
            },
            {
                "name": "Togo",
                "code": "TG",
                "emoji": "🇹🇬",
                "dialCode": "+228"
            },
            {
                "name": "Thailand",
                "code": "TH",
                "emoji": "🇹🇭",
                "dialCode": "+66"
            },
            {
                "name": "Tajikistan",
                "code": "TJ",
                "emoji": "🇹🇯",
                "dialCode": "+992"
            },
            {
                "name": "Tokelau",
                "code": "TK",
                "emoji": "🇹🇰",
                "dialCode": "+690"
            },
            {
                "name": "Timor-Leste",
                "code": "TL",
                "emoji": "🇹🇱",
                "dialCode": "+670"
            },
            {
                "name": "Turkmenistan",
                "code": "TM",
                "emoji": "🇹🇲",
                "dialCode": "+993"
            },
            {
                "name": "Tunisia",
                "code": "TN",
                "emoji": "🇹🇳",
                "dialCode": "+216"
            },
            {
                "name": "Tonga",
                "code": "TO",
                "emoji": "🇹🇴",
                "dialCode": "+676"
            },
            {
                "name": "Turkey",
                "code": "TR",
                "emoji": "🇹🇷",
                "dialCode": "+90"
            },
            {
                "name": "Trinidad & Tobago",
                "code": "TT",
                "emoji": "🇹🇹",
                "dialCode": "+1868"
            },
            {
                "name": "Tuvalu",
                "code": "TV",
                "emoji": "🇹🇻",
                "dialCode": "+688"
            },
            {
                "name": "Taiwan",
                "code": "TW",
                "emoji": "🇹🇼",
                "dialCode": "+886"
            },
            {
                "name": "Tanzania",
                "code": "TZ",
                "emoji": "🇹🇿",
                "dialCode": "+255"
            },
            {
                "name": "Ukraine",
                "code": "UA",
                "emoji": "🇺🇦",
                "dialCode": "+380"
            },
            {
                "name": "Uganda",
                "code": "UG",
                "emoji": "🇺🇬",
                "dialCode": "+256"
            },
            {
                "name": "United States",
                "code": "US",
                "emoji": "🇺🇸",
                "dialCode": "+1"
            },
            {
                "name": "Uruguay",
                "code": "UY",
                "emoji": "🇺🇾",
                "dialCode": "+598"
            },
            {
                "name": "Uzbekistan",
                "code": "UZ",
                "emoji": "🇺🇿",
                "dialCode": "+998"
            },
            {
                "name": "Vatican City",
                "code": "VA",
                "emoji": "🇻🇦",
                "dialCode": "+379"
            },
            {
                "name": "St. Vincent & Grenadines",
                "code": "VC",
                "emoji": "🇻🇨",
                "dialCode": "+1784"
            },
            {
                "name": "Venezuela",
                "code": "VE",
                "emoji": "🇻🇪",
                "dialCode": "+58"
            },
            {
                "name": "British Virgin Islands",
                "code": "VG",
                "emoji": "🇻🇬",
                "dialCode": "+1284"
            },
            {
                "name": "U.S. Virgin Islands",
                "code": "VI",
                "emoji": "🇻🇮",
                "dialCode": "+1340"
            },
            {
                "name": "Vietnam",
                "code": "VN",
                "emoji": "🇻🇳",
                "dialCode": "+84"
            },
            {
                "name": "Vanuatu",
                "code": "VU",
                "emoji": "🇻🇺",
                "dialCode": "+678"
            },
            {
                "name": "Wallis & Futuna",
                "code": "WF",
                "emoji": "🇼🇫",
                "dialCode": "+681"
            },
            {
                "name": "Samoa",
                "code": "WS",
                "emoji": "🇼🇸",
                "dialCode": "+685"
            },
            {
                "name": "Yemen",
                "code": "YE",
                "emoji": "🇾🇪",
                "dialCode": "+967"
            },
            {
                "name": "Mayotte",
                "code": "YT",
                "emoji": "🇾🇹",
                "dialCode": "+262"
            },
            {
                "name": "South Africa",
                "code": "ZA",
                "emoji": "🇿🇦",
                "dialCode": "+27"
            },
            {
                "name": "Zambia",
                "code": "ZM",
                "emoji": "🇿🇲",
                "dialCode": "+260"
            },
            {
                "name": "Zimbabwe",
                "code": "ZW",
                "emoji": "🇿🇼",
                "dialCode": "+263"
            }
        ]
}

