import { CommonModule } from '@angular/common';
import {Component, HostListener, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonConstants, RoutesConstants } from '@coreconstant';
import { lastValueFrom } from 'rxjs';
import { GeneralService } from 'src/app/core/services/api-service/general.service';
import { TerritoryService } from 'src/app/core/services/api-service/territory.service';
import { CommonBehaviorChangeService } from 'src/app/core/services/common-service/common-behavior-change.service';
import { ResponseHandlerService } from 'src/app/core/services/common-service/response-handler.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  imports: [RouterModule, ReactiveFormsModule, CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  routesConstants = RoutesConstants
  commonConstants = CommonConstants

  currentYear = (new Date()).getFullYear()
  businessPortalUrl = environment.businessPortalUrl;
  settingData: any
  socialLinks: { [key: string]: string } = {};
  territoryList: any;
  joinForm!: FormGroup;
  submitted: boolean = false;
  footerTitle: any;
  showButton = false;

  constructor(private _commonBehaviorChangeService: CommonBehaviorChangeService, private _territoryService: TerritoryService, private _responseHandlerService: ResponseHandlerService, private readonly _formBuilder: FormBuilder, private _generalService: GeneralService) {
  }

  ngOnInit() {
    this.initializeForm();

    this._commonBehaviorChangeService.settings$
      .subscribe(data => {
        this.settingData = data;

        if (this.settingData && this.settingData.length > 0) {
          const footerData = this.settingData.find((item: any) => item.section == 'footer');
          this.footerTitle = footerData?.title;

          this.settingData.forEach(platform => {
            if (platform.section == 'social_media' && platform.title && platform.url)
              this.socialLinks[platform.title] = platform.url;
          });
          // const platforms = ['Facebook', 'Instagram', 'Pinterest', 'Tiktok'];
          // platforms.forEach(platform => {
          //   const link = data?.find((item: any) => item?.title == platform)?.url || '';
          //   if (link) this.socialLinks[platform] = link;
          // });
        }
      });
    this.getTerritoryList();
  }

  /**
   * Get Territory Lists
   */
  getTerritoryList() {
    var getListing = this._territoryService.getTerritoryList()
    lastValueFrom(getListing).then((res: any) => {
      if (res["status"]) {
        this.territoryList = res["data"];
      } else {
        this._responseHandlerService.showErrorMessageGeneral(res["message"])
      }
    }, (error: any) => {
      this._responseHandlerService.handleAPIErrorResponse(error)
    });
  }

  /**
   * Initialize Form for the newsletter
   */
  initializeForm() {
    this.joinForm = this._formBuilder.group({
      email: ['', [Validators.pattern(this.commonConstants.EMAIL_REGEX)]],
    });
  }

  /**
   * Submit the newsletter form
   * @returns
   */
  onSubmit() {
    this.submitted = true;
    this.joinForm.markAllAsTouched();
    this.joinForm.markAsDirty();

    if (this.joinForm.invalid)
      return false;

    let addUpdateRecord = this._generalService.joinTheNewsLetter(this.joinForm.value)
    lastValueFrom(addUpdateRecord).then((response: any) => {
      if (response["status"] == true) {
        this.joinForm.reset()
        this._responseHandlerService.showSuccessMessageGeneral(response["message"] ?? this.commonConstants.JOIN_NEWSLETTER)
      } else {
        this._responseHandlerService.showErrorMessageGeneral(response["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
      }
    }, (error: any) => {
      this._responseHandlerService.handleAPIErrorResponse(error);
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // Show button when scrolled down 300px
    this.showButton = window.scrollY > 200;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
