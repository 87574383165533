@if(headerTitle){
<div class="top-strip">
    <div class="container">
        <a [href]="headerURL" target="_blank" class="top-strip-info">
            <img src="assets/images/heart-icon.svg" width="16" height="14" alt="Heart icon">
            {{headerTitle}}
            <img src="assets/images/heart-icon.svg" width="16" height="14" alt="Heart icon">
        </a>
    </div>
</div>
}
<header class="header-wrap" [class.header-sticky]="isSticky">
    <nav class="main-menu-wrap">
        <div class="container">
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false"
                aria-label="Toggle navigation" (dblclick)="stopDoubleClick($event)">
                <span></span>
            </button>

            <a class="logo" [routerLink]="['/'+ routesConstants.HOME_NAVIGATION]">
                <img src="{{commonConstants.LOGO_PATH}}" width="136" height="29" alt="Wedshed logo">
            </a>

            <div class="main-menu-inner" [ngClass]="{'search-box-open': isSearchOpen}">
                <div class="collapse navbar-collapse" id="navbarMenu">
                    <ul class="main-menu">
                        <li class="menu-item dropdown">
                            <div class="menu-link dropdown-toggle" [class.active]="firstSegment === '/'+routesConstants.PLANNING" role="button" data-bs-toggle="dropdown" aria-expanded="false">Planning</div>
                            <div class="dropdown-menu">
                                <div class="dropdown-menu-container">
                                    <div class="mob-back-link">
                                        <span class="mob-back-btn">
                                            <img src="assets/images/arrow-left-icon.svg" width="17" height="13"
                                                alt="Arrow left icon">
                                        </span>
                                        Planning
                                    </div>
                                    <div class="dropdown-menu-links">
                                        <ul>
                                            <li><a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION]" class="active"
                                                    routerLinkActive="active">Venues</a></li>
                                            <li><a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION]"
                                                    routerLinkActive="active">Vendors</a>
                                            </li>
                                            <!-- <li><a href="#">Regions</a></li>
                                            <li><a href="#">Honeymoons</a></li>
                                            <li><a href="#">Elopement + Micro weddings</a></li> -->
                                            <li><a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]"
                                                    routerLinkActive="active">Planning hub</a>
                                            </li>
                                            <li><a href="{{commonConstants.PLANNING_REGISTRY_LINK}}"
                                                    target="_blank" rel="noopener noreferrer nofollow">Registry</a></li>
                                            <li><a href="{{commonConstants.PLANNING_WEDSHARE_LINK}}"
                                                    target="_blank" rel="noopener noreferrer nofollow">Wedshare</a></li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-menu-box">
                                        <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/this-day-forward']">
                                            <span>Vendor of the week</span>
                                            <div class="menu-box-title">This Day Forward - Sydney, NSW</div>
                                            <div class="dropdown-menu-box-img">
                                                <img [src]="imagePathConstants.MENU + '/vendor-of-the-month.webp?tr=w-659,h-450,q-100,lossless-1,f-webp,e-sharpen-50'"
                                                    width="659" height="450" alt="Vendor of the week" appSetImageDimensions>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="dropdown-menu-box">
                                        <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/mcguigan-house  ']">
                                            <span>Venue of the week</span>
                                            <div class="menu-box-title">McGuigan House - Hunter Valley, NSW</div>
                                            <div class="dropdown-menu-box-img">
                                                <img [src]="imagePathConstants.MENU + '/venue-of-the-month.jpg?tr=w-659,h-450,q-100,lossless-1,f-webp,e-sharpen-50'"
                                                    width="659" height="450" alt="Venue of the week" appSetImageDimensions>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="menu-item dropdown">
                            <div class="menu-link dropdown-toggle" [class.active]="firstSegment === '/'+routesConstants.HOW_IT_WORKS" role="button" data-bs-toggle="dropdown" aria-expanded="false">How it works</div>
                            <div class="dropdown-menu">
                                <div class="dropdown-menu-container">
                                    <div class="mob-back-link">
                                        <span class="mob-back-btn">
                                            <img src="assets/images/arrow-left-icon.svg" width="17" height="13"
                                                alt="Left arrow icon">
                                        </span>
                                        How it works
                                    </div>
                                    <div class="dropdown-menu-links">
                                        <ul>
                                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_COUPLES_NAVIGATION]"
                                                    routerLinkActive="active">Couples</a>
                                            </li>
                                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_VENDORS_NAVIGATION]"
                                                    routerLinkActive="active">Vendors</a>
                                            </li>
                                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_VENUES_NAVIGATION]"
                                                    routerLinkActive="active">Venues</a>
                                            </li>
                                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_ABOUT_NAVIGATION]"
                                                    routerLinkActive="active">About</a></li>
                                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION]"
                                                    routerLinkActive="active">FAQs</a>
                                            </li>
                                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION]"
                                                    routerLinkActive="active">Contact
                                                    us</a></li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-menu-box">
                                        <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION]">
                                            <span>HOW IT WORKS</span>
                                            <div class="menu-box-title">Learn about how this works if you’re a vendor</div>
                                            <div class="dropdown-menu-box-img">
                                                <img [src]="imagePathConstants.MENU + '/how-it-works-1.png?tr=w-659,h-450,q-100,lossless-1,f-webp,e-sharpen-50'"
                                                    width="659" height="450" alt="How it works" appSetImageDimensions>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="dropdown-menu-box">
                                        <a href="{{commonConstants.PLANNING_REGISTRY_LINK}}" rel="noopener noreferrer nofollow" target="_blank">
                                            <span>FEATURED PRODUCT</span>
                                            <div class="menu-box-title">Gravy: Ask for cash and good deeds with zero awks</div>
                                            <div class="dropdown-menu-box-img">
                                                <img [src]="imagePathConstants.MENU + '/how-it-works-2.png?tr=w-659,h-450,q-100,lossless-1,f-webp,e-sharpen-50'"
                                                    width="659" height="450" alt="Featured product" appSetImageDimensions>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="menu-item">
                            <a class="menu-link" [class.active]="firstSegment === '/'+routesConstants.WEDSPIRATION" aria-current="page" [routerLink]="['/'+ routesConstants.WEDSPIRATION]">Advice & Ideas</a>
                        </li>
                        <li class="menu-item dropdown">
                            <div class="menu-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Shop</div>
                            <div class="dropdown-menu">
                                <div class="dropdown-menu-container">
                                    <div class="mob-back-link">
                                        <span class="mob-back-btn">
                                            <img src="assets/images/arrow-left-icon.svg" width="17" height="13"
                                                alt="Left arrow icon">
                                        </span>
                                        Shop
                                    </div>
                                    <div class="dropdown-menu-links">
                                        <ul>
                                            <li><a href="{{commonConstants.COLLECTION_GUIDES_LINK}}" class="active"
                                                    target="_blank" rel="noopener noreferrer nofollow">Guides and Worksheets</a></li>
                                            <li><a href="{{commonConstants.COLLECTION_WEDDINGTEES_LINK}}"
                                                    target="_blank" rel="noopener noreferrer nofollow">Wedding
                                                    Tees</a>
                                            </li>
                                            <li><a href="{{commonConstants.COLLECTION_ACCESSORIES_LINK}}"
                                                    target="_blank" rel="noopener noreferrer nofollow">Accessories</a>
                                            </li>
                                            <li><a href="{{commonConstants.COLLECTION_ALL_LINK}}" target="_blank" rel="noopener noreferrer nofollow">Shop
                                                    all</a></li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-menu-box">
                                        <a href="{{commonConstants.WEDSHED_VOW_WRITING_LINK}}" target="_blank" rel="noopener noreferrer nofollow">
                                            <span>MOST POPULAR</span>
                                            <div class="menu-box-title">Wedshed eGuide: The Vow-Writing Guide</div>
                                            <div class="dropdown-menu-box-img">
                                                <img [src]="imagePathConstants.MENU + '/shop-1.svg?tr=w-659,h-450,q-100,lossless-1,e-sharpen-50'"
                                                    width="659" height="450" alt="Most popular" appSetImageDimensions>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="dropdown-menu-box">
                                        <a href="{{commonConstants.WEDSHED_WEDDING_PRIORITIES_LINK}}" target="_blank" rel="noopener noreferrer nofollow">
                                            <span>WEDSHED’S PICK</span>
                                            <div class="menu-box-title">Wedshed eGuide: Set Your Wedding Priorities</div>
                                            <div class="dropdown-menu-box-img">
                                                <img [src]="imagePathConstants.MENU + '/shop-2.png?tr=w-659,h-450,lossless-1,q-100,f-webp,e-sharpen-50'"
                                                    width="659" height="450" alt="Wedshed pick" appSetImageDimensions>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>

                    <!--                    <div class="header-btn">-->
                    <!--                        <a href="{{businessPortalUrl}}" class="secondary-btn" target="_blank" rel="noopener noreferrer nofollow">Log in</a>-->
                    <!--                        <a [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" class="primary-btn">Sign up</a>-->
                    <!--                    </div>-->
                </div>

                <div class="menu-action">
                    @if(!isMobileView) {
                    <div class="main-search-box">
                        <button #searchIcon type="button" class="search-icon-btn" (click)="manageSearchIcon()">
                            <img src="assets/images/header-search-icon.svg" width="32" height="32" alt="Search icon">
                        </button>
                        <form role="search">
                            <button class="search-btn" type="button">
                                <img src="assets/images/search-icon.svg" width="24" height="25" alt="Search icon">
                            </button>
                            <input #searchTextbox class="form-control" type="text" inputmode="search" enterkeyhint="search" placeholder="Start typing"
                                aria-label="Search" (click)="searchBoxClick()" (keyup)="searchValue($event)"
                                [(ngModel)]="searchInput" [ngModelOptions]="{standalone: true}">
                            <!-- (keyup)="searchValueChange($event)" -->
                            @if(isShowCancelIcon) {
                            <button class="search-close-btn" type="button" (click)="clearSearchOnClose()">
                                <img src="assets/images/close-icon.svg" width="13" height="13" alt="Close icon">
                            </button>
                            }
                        </form>
                    </div>
                    }

                    <!--                    <div class="header-btn">-->
                    <!--                        <a href="{{businessPortalUrl}}" class="secondary-btn" target="_blank" rel="noopener noreferrer nofollow">Log in</a>-->
                    <!--                        <a [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" class="primary-btn">Sign up</a>-->
                    <!--                    </div>-->
                    <!-- <button type="button" class="cart-btn active">
                        <img src="assets/images/cart-icon.svg" width="30" height="31" alt="cart-icon">
                    </button> -->
                    <button class="mob-search-btn" type="button" (click)="searchToggleMobile()">
                        <img src="assets/images/header-search-icon.svg" width="32" height="32" alt="Search icon">
                    </button>
                </div>
            </div>
        </div>

        <!-- Mobile Search Box -->
        @if(isMobileView) {
        <div class="main-search-box mob-search-box" [class.hide-search-box]="!isShowSearchIcon">
            <form role="search">
                <button class="search-btn" type="button">
                    <img src="assets/images/search-icon.svg" width="24" height="25" alt="Search icon">
                </button>
                <input #searchTextbox class="form-control" type="text" enterkeyhint="search" placeholder="Start typing" aria-label="Search"
                    (click)="searchBoxClick()" [(ngModel)]="searchInput" (keyup)="searchValue($event)"
                    [ngModelOptions]="{standalone: true}">
                @if(isShowCancelIcon) {
                <button class="search-close-btn" type="button" (click)="clearSearchOnClose()">
                    <img src="assets/images/close-icon.svg" width="13" height="13" alt="Close icon">
                </button>
                }
            </form>
        </div>
        }

        <!-- Search panel -->
        <div #searchContainer class="main-search-panel" [class.hide-search]="isHideSearchSection">
            <app-search-panel [searchKeyword]="searchKeyword" [searchData]="searchData"
                (closeSearch)="closeSearchSection($event)"
                (clearSearch)="clearSearchSection($event)"></app-search-panel>
        </div>
    </nav>
</header>
