import {Component, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonConstants } from '@coreconstant';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { HubService } from 'src/app/core/services/api-service/hub.service';
import { ResponseHandlerService } from 'src/app/core/services/common-service/response-handler.service';

@Component({
  selector: 'app-join-waitlist',
  imports: [ReactiveFormsModule],
  templateUrl: './join-waitlist.component.html',
  styleUrl: './join-waitlist.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class JoinWaitlistComponent {
  joinForm!: FormGroup;
  submitted: boolean = false;
  commonConstants = CommonConstants

  constructor(
    private readonly _formBuilder: FormBuilder,
    private _responseHandlerService: ResponseHandlerService,
    private _hubService: HubService,
    private _modalService: NgbModal,
    public _activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.initializeForm();
  }

  /**
   * Summary: This method is used to initialize the join a waitlist form
   */
  initializeForm() {
    this.joinForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.commonConstants.EMAIL_REGEX)]],
      isSendEmail: [false]
    });
  }

  onSubmit() {
    this.submitted = true;
    this.joinForm.markAllAsTouched();
    this.joinForm.markAsDirty();

    if (this.joinForm.invalid)
      return false;

    let addUpdateRecord = this._hubService.joinTheWaitList(this.joinForm.value)
    lastValueFrom(addUpdateRecord).then((response: any) => {
      if (response["status"] == true) {
        this._activeModal.close("success");
      } else {
        this._responseHandlerService.showErrorMessageGeneral(response["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
      }
    }, (error: any) => {
      this._responseHandlerService.handleAPIErrorResponse(error);
    });
  }

  closeModal() {
    this._activeModal.close();
  }
}
