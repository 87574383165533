<!--Contact us Title/Banner Start-->
<section class="contact-us-title">
    <div class="container">
        <h1 class="section-title">Contact us</h1>
        <p>Have a question or some feedback? Just feeling loved up and need to gush to someone? We'd love to hear from
            you.</p>
    </div>
</section>
<!--Contact us Title/Banner END-->

<!--Main Contact us section Start-->
<section class="contact-us-wrap">
    <div class="container">
        <div class="contact-us-inner">
            <a [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" class="secondary-btn">
                <span><i>🏡</i> List your venue</span>
                <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="Right arrow icon">
            </a>
            <a [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" [fragment]="'service'" class="secondary-btn">
                <span><i>💐</i> Join our Vendor Directory</span>
                <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="Right arrow icon">
            </a>
            <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" type="button" class="secondary-btn">
                <span><i>❤️</i> Create your couples profile</span>
                <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="Right arrow icon">
            </a>
            <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION]" class="secondary-btn">
                <span><i>👀</i> Check our FAQs</span>
                <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="Right arrow icon">
            </a>
            <a [routerLink]="['/'+ routesConstants.SUBMIT_WEDDING]" class="secondary-btn">
                <span><i>💍</i> Submit a Real Wedding</span>
                <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="Right arrow icon">
            </a>
            <a [routerLink]="['/'+ routesConstants.GET_IN_TOUCH]" class="secondary-btn">
                <span><i>👋</i> Get in touch</span>
                <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="Right arrow icon">
            </a>
        </div>
    </div>
</section>
<!--Main Contact us section Start-->

<!--FAQs section-->
<section class="faqs-cta">
    <div class="container">
        <h2 class="section-title">Wondering if your question is frequently asked?</h2>
        <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION]" class="primary-btn">View FAQs</a>
    </div>
</section>

<!--Wedding Planning section-->
<section class="wedding-plan-cta">
    <div class="container">
        <img src="assets/images/book-icon.svg" width="43" height="40" alt="Book icon">
        <p>Click here to receive your free e-book: <strong>7 simple steps to kickstart your wedding planning</strong></p>
        <img src="assets/images/book-icon.svg" width="43" height="40" alt="Book icon">
    </div>
</section>


<!-- Start Subscribe to our newsletter - Modal -->
<ng-template #newsletter let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Subscribe to our newsletter 🗞️</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')" title="Close">
            <img src="assets/images/close-icon.svg" width="13" height="13" alt="Close icon">
        </button>
    </div>

    <div class="modal-body">
        <form>
            <div class="form-group-row">
                <div class="form-group">
                    <label>First name</label>
                    <input class="form-control" type="email" placeholder="Enter first name">
                </div>
                <div class="form-group">
                    <label>Email address</label>
                    <input class="form-control" type="email" placeholder="Enter email">
                </div>
            </div>

            <div class="form-group-row">
                <div class="form-group">
                    <label>How did you hear about us?</label>
                    <input class="form-control" type="email" placeholder="Select">
                </div>
                <div class="form-group">
                    <label>Wedding planning stage</label>
                    <input class="form-control" type="email" placeholder="Select">
                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button type="button" class="primary-btn" (click)="openVerticallyCentered(subscribed, 'subscribed-wrap')">
            Subscribe
        </button>
        <button type="button" class="secondary-btn" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
<!-- END Subscribe to our newsletter - Modal -->


<!-- Subscribed - Modal -->
<ng-template #subscribed let-modal>
    <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')" title="Close">
        <img src="assets/images/close-icon.svg" width="13" height="13" alt="Close icon">
    </button>

    <div class="modal-body">
        <div class="subscribed-info">
            <div class="newsletter-icon">
                <img src="assets/images/newsletter-icon.png" width="69" height="68" alt="Newsletter icon">
            </div>
            <h2>Subscribed</h2>
            <p>Thank you for subscribing! There will be some tried-and-tested wedding advice, articles and inspiration
                coming to your inbox real soon.</p>
            <p>All the best,<br /> Mel and Amy 👋</p>
        </div>
    </div>
</ng-template>
<!-- END Subscribed - Modal -->
