<form [formGroup]="enquireVenueVendorForm" class="venue-enquire">
    <div class="modal-header">
        <h4 class="modal-title">{{ title == 'venue-enquire' ? 'Message venue 👋' : 'Message vendor 👋' }}</h4>
        <button type="button" class="close-btn" aria-label="Close" title="Close" (click)="closeModal({status:false})">
            <img src="assets/images/close-icon.svg" width="13" height="13" alt="Close icon">
        </button>
    </div>

    <div class="modal-body">
        <div class="vendor-message-box">
            <div class="vendor-message-img">
                <img src="{{commonConstants.DEFAULT_USER_IMAGE}}" width="42" height="42" alt="Vendor img">
                <img
                    [src]="listingLogo ? commonConstants.IMAGE_KIT_URL + listingLogo : commonConstants.DEFAULT_USER_IMAGE"
                    width="42" height="42" alt="Vendor img" (error)="setDefaultImage($event)">
            </div>
            <div class="vendor-message-info">
                <p><strong>Sending message to {{ ownerName }}</strong></p>
                <p>Owner of {{ venueName }}</p>
            </div>
        </div>

        <div class="form-group">
            <label>Your first name</label>
            <input class="form-control" type="text" placeholder="Enter first name" formControlName="first_name"
                   required>
            @if (submitted) {
                @if (enquireVenueVendorForm.controls['first_name'].hasError('required')) {
                    <div class="error">
                        First name is required
                    </div>
                }
                @if (!enquireVenueVendorForm.controls['first_name'].hasError('required') &&
                enquireVenueVendorForm.controls['first_name'].hasError('whitespace')) {
                    <div class="error">
                        Field value cannot be empty or contain only whitespace.
                    </div>
                }
            }
        </div>

        <div class="form-group">
            <label>Dates</label>
            <div class="form-icon-input">
                <img src="assets/images/calendar-icon.svg" width="24" height="24" alt="Calendar icon">
                <input type="text" class="form-control" readonly formControlName="dates" (click)="toggleDatepicker()">
            </div>
            @if (submitted) {
                @if (enquireVenueVendorForm.controls['dates'].hasError('required')) {
                    <div class="error">
                        Date is required
                    </div>
                }
            }

            @if (isDatepickerOpen) {
                <div class="datepicker-wrap" #datepickerContainer>
                    <ngb-datepicker #dp [minDate]="minDate" [showWeekNumbers]="false" outsideDays="hidden"
                                    [displayMonths]="1" [dayTemplate]="t"
                                    (dateSelect)="selectOne($event)"></ngb-datepicker>

                    <ng-template #t let-date let-currentMonth="currentMonth" let-selected="selected"
                                 let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.bg-primary]="isSelected(date)">
                        {{ date.day }}
                    </span>
                    </ng-template>

                    <div class="datepicker-action">
                        <span>{{ modelList?.length }} date selected</span>
                        <button (click)="clearSelection()" class="secondary-btn">Clear</button>
                        <button (click)="saveDateSelection()" class="primary-btn">Save</button>
                    </div>
                </div>
            }
        </div>

        <div class="form-group">
            <label class="custom-radio">
                <input type="checkbox" formControlName="flexible">
                <span class="checkmark"></span>We’re flexible
            </label>
        </div>

        <div class="form-group">
            <label>Mobile number</label>
            <div class="mobile-no-input">
                <ng-select [items]="countryCodes.CODE" bindLabel="name" class="form-control-ng-select"
                           bindValue="dialCode" formControlName="countryIdForMobile">
                    <ng-template ng-label-tmp let-item="item">
                        {{ item.emoji }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        {{ item.name }}
                    </ng-template>
                </ng-select>

                <input class="form-control" type="text" placeholder="Enter mobile number"
                       [imask]="{mask: '0000 000 000',lazy:false}" [unmask]="true" formControlName="mobile">
            </div>
            @if (submitted) {
                @if (enquireVenueVendorForm.get('mobile')?.touched &&
                enquireVenueVendorForm.get('mobile')?.hasError('required')) {
                    <div class="error">
                        Mobile number is required
                    </div>
                } @else if (enquireVenueVendorForm.get('mobile')?.touched &&
                !enquireVenueVendorForm.get('mobile')?.hasError('required') &&
                enquireVenueVendorForm.get('mobile')?.hasError('pattern')) {
                    <div class="error">
                        Mobile number is not a valid
                    </div>
                }
            }
        </div>

        <div class="form-group">
            <label>Email address</label>
            <input class="form-control" type="email" placeholder="example@email.com" formControlName="email">
            @if (submitted) {
                @if (enquireVenueVendorForm.get('email')?.touched &&
                enquireVenueVendorForm.get('email')?.hasError('required')) {
                    <div class="error">
                        Email is required
                    </div>
                } @else if (enquireVenueVendorForm.get('email')?.touched &&
                !enquireVenueVendorForm.get('email')?.hasError('required') &&
                enquireVenueVendorForm.get('email')?.hasError('pattern')) {
                    <div class="error">
                        Email is not a valid
                    </div>
                }
            }
        </div>

        <div class="form-group">
            <label>Send a message</label>
            <textarea class="form-control" placeholder="Type your message here" formControlName="message"></textarea>
        </div>

        <div class="form-group mb-0">
            <re-captcha
                (resolved)="onCaptchaResolved($event)"
                [siteKey]="commonConstants.GOOGLE_CAPTCHA_KEY">
            </re-captcha>
            @if (submitted) {
                @if (enquireVenueVendorForm.controls['recaptcha'].hasError('required')) {
                    <div class="error">
                        Please verify the CAPTCHA.
                    </div>
                }
            }
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="primary-btn" (click)="submit()">{{
                title == 'venue-enquire' ? 'Enquire with venue'
                    : 'Enquire with vendor'
            }}
        </button>
        <button type="button" class="secondary-btn" (click)="closeModal({status:false})">Cancel</button>
    </div>
</form>
