import {Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import Splide from '@splidejs/splide';
import {GeneralService} from '../core/services/api-service/general.service';
import {ResponseHandlerService} from '../core/services/common-service/response-handler.service';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import {CommonConstants, RoutesConstants} from '@coreconstant';
import {RequestParamModel} from '../core/models/request-param-model';
import _ from 'lodash'
import {SetImageDimensionsDirective} from '../core/directives/set-image-dimensions.directive';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {SeoService} from '../core/services/common-service/seo.service';

@Component({
    selector: 'app-search-listing',
    imports: [SetImageDimensionsDirective, RouterModule, NgbTooltipModule],
    templateUrl: './search-listing.component.html',
    styleUrl: './search-listing.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class SearchListingComponent implements OnInit {
    routesConstants = RoutesConstants
    commonConstants = CommonConstants
    moduleName: string;
    searchKeyword: string;
    searchData: any;
    totalResultCount: any;
    venuesCount = 0;
    vendorsCount = 0;
    blogsCount = 0;
    venueCurrentPage = 0;
    venuePageCount = 0;
    vendorCurrentPage = 0;
    vendorPageCount = 0;
    blogCurrentPage = 0;
    blogPageCount = 0;
    venues: any = [];
    vendors: any = [];
    blogs: any = [];
    selectedModuleCount: any = 0
    requestParamModel!: RequestParamModel;
    isGetResponse: boolean = false

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _generalService: GeneralService,
        private _responseHandlerService: ResponseHandlerService,
        private _activatedRoute: ActivatedRoute,
        private _seoService: SeoService,
        private _router: Router
    ) {

        this.requestParamModel = new RequestParamModel();
        this.requestParamModel.pageSize = this.commonConstants.PAGE_SIZE_COUNT
        this.requestParamModel.moduleName = this.commonConstants.MODULE_ALL
        this.requestParamModel.pageIndex = 1

        this._activatedRoute.queryParams.subscribe(params => {
            this.moduleName = params['type']
            if (this.searchKeyword !== params['search']) {
                this.searchKeyword = params['search']
                this.resetAllValues()
                this.getSearchResult()
            }
        });
        this.setMetaTags()
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Search Results | Wedshed',
            description: 'Search Results | Wedshed',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.SEARCH,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    ngOnInit(): void {
        // this.getSearchResult()
    }

    sliderInit() {
        if (isPlatformBrowser(this.platformId)) {
            const splides = document.querySelectorAll('.box-slider');

            splides.forEach((element) => {
                // Check if Splide is already initialized
                if (!element.classList.contains('splide-initialized')) {
                    new Splide(element as HTMLElement, {
                        type: 'loop'
                    }).mount();

                    // Mark it as initialized
                    element.classList.add('splide-initialized');
                }
            });
        }
    }

    getSearchResult() {
        this.requestParamModel.removeFilter('search')
        this.requestParamModel.setFilter('search', this.searchKeyword, 'number')

        var getData = this._generalService.getSearchAllResult(this.requestParamModel)
        lastValueFrom(getData).then((res: any) => {
            if (res["status"]) {
                this.searchData = res["data"];
                this.isGetResponse = true

                if (this.searchData) {
                    if (this.searchData?.venues && !_.isEmpty(this.searchData.venues) && (this.requestParamModel.moduleName == this.commonConstants.MODULE_ALL || this.requestParamModel.moduleName == this.commonConstants.MODULE_VENUES)) {
                        if (this.searchData?.venues?.records)
                            this.venues = this.venues.concat(this.searchData?.venues?.records)
                        this.venuesCount = this.searchData?.venues?.totalCount
                        this.venueCurrentPage = this.searchData?.venues?.currentPage
                        this.venuePageCount = this.searchData?.venues?.pageCount
                    }

                    if (this.searchData?.vendors && !_.isEmpty(this.searchData.vendors) && (this.requestParamModel.moduleName == this.commonConstants.MODULE_ALL || this.requestParamModel.moduleName == this.commonConstants.MODULE_VENDORS)) {
                        if (this.searchData?.vendors?.records)
                            this.vendors = this.vendors.concat(this.searchData?.vendors?.records)
                        this.vendorsCount = this.searchData?.vendors?.totalCount
                        this.vendorCurrentPage = this.searchData?.vendors?.currentPage
                        this.vendorPageCount = this.searchData?.vendors?.pageCount
                    }

                    if (this.searchData?.blogs && !_.isEmpty(this.searchData.blogs) && (this.requestParamModel.moduleName == this.commonConstants.MODULE_ALL || this.requestParamModel.moduleName == this.commonConstants.MODULE_ARTICLES)) {
                        if (this.searchData?.blogs?.records)
                            this.blogs = this.blogs.concat(this.searchData?.blogs?.records)
                        this.blogsCount = this.searchData?.blogs?.totalCount
                        this.blogCurrentPage = this.searchData?.blogs?.currentPage
                        this.blogPageCount = this.searchData?.blogs?.pageCount
                    }

                    setTimeout(() => {
                        this.sliderInit()
                    })
                }
                this.totalResultCount = this.venuesCount + this.vendorsCount + this.blogsCount
                this.getSelectedModuleCount()

            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    loadMoreData(currentModulePageIndex: number) {
        this.requestParamModel.pageIndex = currentModulePageIndex + 1
        this.requestParamModel.moduleName = this.moduleName
        this.getSearchResult()
    }

    resetAllValues() {
        this.requestParamModel.pageSize = this.commonConstants.PAGE_SIZE_COUNT
        this.requestParamModel.moduleName = this.commonConstants.MODULE_ALL
        this.requestParamModel.pageIndex = 1

        this.venues = [];
        this.vendors = [];
        this.blogs = [];
    }

    changeTab(tab) {
        this.moduleName = tab
        this.getSelectedModuleCount()
        this._router.navigate([], {
            relativeTo: this._activatedRoute,
            queryParams: {type: this.moduleName},
            queryParamsHandling: 'merge'
        });
        setTimeout(() => {
            this.sliderInit()
        })
    }

    getSelectedModuleCount() {
        switch (this.moduleName) {
            case this.commonConstants.MODULE_VENUES:
                this.selectedModuleCount = this.venuesCount;
                break;
            case this.commonConstants.MODULE_VENDORS:
                this.selectedModuleCount = this.vendorsCount;
                break;
            case this.commonConstants.MODULE_ARTICLES:
                this.selectedModuleCount = this.blogsCount;
                break;
            default:
                this.selectedModuleCount = this.totalResultCount;
        }
    }

    setDefaultImage(event: Event) {
        let image = this.commonConstants.DEFAULT_NO_IMAGE;
        (event.target as HTMLImageElement).src = image
    }
}
