<!--======================= Page Title Section HTML Start =======================-->
<section class="page-title-wrap" [class.device-filter-open]="isClassToggled" [class.page-title-sticky]="isSticky">
    <div class="container">
        <div class="page-title-info">
            <h1 class="section-title">Find a vendor</h1>
            <p>Get ready to meet your wedding dream team.</p>
        </div>

        <!-- Mobile Filter Start -->
        <div class="main-mob-filter" [class.selected]="isFilterApply">
            <button type="button" class="filter-item-btn" (click)="toggleClass()">
                <span class="filter-btn-label">
                    {{
                        selectedCountryNames.length > 0 ?
                            firstSomeCountries.join(', ') : 'Vendor Location'
                    }}
                    @if (selectedCountryNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                        <span>+{{ selectedCountryNames.length - firstSomeCountries.length }}</span>
                    }
                </span>
                <span class="filter-btn-data">
                    {{
                        selectedRegionNames.length > 0 ?
                            firstSomeRegions.join(', ') : 'Anywhere'
                    }}
                    @if (selectedRegionNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                        <span>+{{ selectedRegionNames.length - firstSomeRegions.length }}</span>
                    }
                </span>
            </button>

            <button class="secondary-btn" (click)="toggleClass()">
                <img src="assets/images/filter-list-icon.svg" width="28" height="28" alt="filter-list-icon">
            </button>
        </div>
        <!-- Mobile Filter End -->

        <!-- Main Filter Start -->
        <div #vendorFilterWrap class="main-filter-wrap" [class.filter-open]="filterOpen !== ''">
            <div class="main-filter-head">
                <span class="filter-back-btn" (click)="removeClass()">
                    <img src="assets/images/back-circle-icon.svg" width="32" height="32" alt="back-circle-icon">
                </span>
                Vendor search
            </div>

            <div class="main-filter-inner">
                <div class="filter-item" [class.filter-show]="filterOpen == 'country'">
                    <button type="button" class="filter-item-btn" (click)="toggleBtnClassForFilter('country')">
                        <span class="filter-btn-label">Country</span>
                        <span class="filter-btn-data" [class.selected]="selectedCountryNames.length > 0">
                            {{
                                selectedCountryNames.length > 0 ?
                                    firstSomeCountries.join(', ') : 'Select a country'
                            }}
                            @if (selectedCountryNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                                <span>+{{ selectedCountryNames.length - firstSomeCountries.length }}</span>
                            }
                        </span>
                    </button>

                    <div class="filter-item-dropdown">
                        @for (country of countryList; track country; let j = $index) {
                            <div class="map-filter" [class.selected]="selectedCountryIds.includes(country.id)"
                                 (click)="onSelectCountry(country)">
                                <img
                                    [src]="country.imagePath ? commonConstants.IMAGE_KIT_URL + country.imagePath + '?tr=w-72,h-72,q-100' : commonConstants.DEFAULT_NO_IMAGE"
                                    width="72" height="72" alt="{{country.name}}-map" (error)="setDefaultImage($event)">
                                {{ country.name }}
                            </div>
                        }

                        <!-- Mobile Next Button -->
                        <div class="filter-next-btn" (click)="toggleBtnClassForFilter('state')">
                            <button type="button" class="secondary-btn">Next <img src="assets/images/arrow-down.svg"
                                                                                  width="25" height="24"
                                                                                  alt="arrow-down-icon"></button>
                        </div>
                    </div>
                </div>

                <div class="filter-item" [class.filter-show]="filterOpen == 'state'">
                    <button type="button" class="filter-item-btn" (click)="toggleBtnClassForFilter('state')">
                        <span class="filter-btn-label">State / PROVINCE</span>
                        <span class="filter-btn-data"
                              [class.selected]="selectedStateNames.length > 0">{{
                                selectedStateNames.length > 0 ?
                                    firstSomeStates.join(', ') : 'Select a state/province'
                            }}
                            @if (selectedStateNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                                <span>+{{ selectedStateNames.length - firstSomeStates.length }}</span>
                            }
                        </span>
                    </button>

                    <div class="filter-item-dropdown province-filter">
                        @if (filterStateList && filterStateList.length > 0) {
                            @for (state of filterStateList; track state; let j = $index) {
                                <div class="map-filter" [class.selected]="selectedStateIds.includes(state.id)"
                                     (click)="onSelectState(state)">
                                    <img
                                        [src]="state.imagePath ? commonConstants.IMAGE_KIT_URL + state.imagePath + '?tr=w-72,h-72,q-100' : commonConstants.DEFAULT_NO_IMAGE"
                                        width="72" height="72" alt="{{state.name}}-map"
                                        (error)="setDefaultImage($event)">
                                    {{ state.name }}
                                </div>
                            }
                        } @else {
                            <div>No state found in selected country</div>
                        }

                        <!-- Mobile Next Button -->
                        <div class="filter-next-btn" (click)="toggleBtnClassForFilter('region')">
                            <button type="button" class="secondary-btn">Next <img src="assets/images/arrow-down.svg"
                                                                                  width="25" height="24"
                                                                                  alt="arrow-down-icon"></button>
                        </div>
                    </div>
                </div>

                <div class="filter-item" [class.filter-show]="filterOpen == 'region'">
                    <button type="button" class="filter-item-btn" (click)="toggleBtnClassForFilter('region')">
                        <span class="filter-btn-label">Region</span>
                        <span class="filter-btn-data" [class.selected]="selectedRegionNames.length > 0">
                            {{
                                selectedRegionNames.length > 0 ?
                                    firstSomeRegions.join(', ') : 'Select a region'
                            }}
                            @if (selectedRegionNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                                <span>+{{ selectedRegionNames.length - firstSomeRegions.length }}</span>
                            }
                        </span>
                    </button>

                    <div class="filter-item-dropdown region-filter">
                        <div class="region-filter-inner">
                            @if (!isRegionListEmpty && filterStateList && filterStateList.length > 0) {
                                @for (state of filterStateList; track state; let j = $index) {
                                    @if (state.regionList && state.regionList.length > 0) {
                                        <div class="region-title">{{ state.name }}</div>
                                        <div class="filter-region-list">
                                            @for (region of state.regionList; track region; let k = $index) {
                                                <label [class.selected]="selectedRegionIds.includes(region.id)"
                                                       (click)="onSelectRegion(region)">{{ region.name }}</label>
                                            }
                                        </div>
                                    }
                                }
                            } @else {
                                <div>No region found in selected country/state</div>
                            }
                        </div>

                        <!-- Mobile Next Button -->
                        <div class="filter-next-btn" (click)="toggleBtnClassForFilter('vendorType')">
                            <button type="button" class="secondary-btn">Next <img src="assets/images/arrow-down.svg"
                                                                                  width="25" height="24"
                                                                                  alt="arrow-down-icon"></button>
                        </div>
                    </div>
                </div>

                <div class="filter-item" [class.filter-show]="filterOpen == 'vendorType'">
                    <button type="button" class="filter-item-btn" (click)="toggleBtnClassForFilter('vendorType')">
                        <span class="filter-btn-label">Vendor types</span>
                        <span class="filter-btn-data" [class.selected]="selectedVendorTypeNames.length > 0">
                            {{
                                selectedVendorTypeNames.length > 0 ?
                                    firstSomeVendorTypes.join(', ') : 'Select vendor types'
                            }}
                            @if (selectedVendorTypeNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                                <span>+{{ selectedVendorTypeNames.length - firstSomeVendorTypes.length }}</span>
                            }
                        </span>
                    </button>

                    <div class="filter-item-dropdown region-filter">
                        <div class="region-filter-inner">
                            <div class="filter-region-list">
                                @for (vendorType of vendorTypeList; track vendorType; let j = $index) {
                                    <label [class.selected]="selectedVendorTypeIds.includes(vendorType.id)"
                                           (click)="onSelectVendorType(vendorType)">{{ vendorType.name }}</label>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-filter-action">
                <button class="secondary-btn" (click)="clearAll()">Clear all</button>
                <button class="primary-btn" (click)="searchData()">
                    <img src="assets/images/search-white-icon.svg" width="16" height="17" alt="search-white-icon">
                    <span>Search</span>
                </button>
            </div>
        </div>
        <!-- Main Filter End -->
    </div>
</section>
<div class="filter-overlay" [class.show]="filterOpen !== ''"></div>
<!--======================= Page Title Section HTML End =======================-->

<!--======================= No Result Found HTML Start =======================-->
@if (isGetResponse) {
    @if (isFilterApply && totalCount > 0) {
        <section class="showing-results-wrap">
            <div class="container">
                Showing {{ totalCount }} {{ totalCount == 1 ? 'vendor' : 'vendors' }}
                <button class="secondary-btn" (click)="clearSearch()">Clear search</button>
            </div>
        </section>
    }

    @if (isFilterApply && totalCount == 0) {
        <section class="search-results-not-found">
            <div class="container">
                <p class="emoji-icon">😞</p>
                <p class="search-title">Hmmm.</p>
                <p>There were no results for <strong>your search</strong></p>
                <a href="" class="clear-search-btn" (click)="clearSearch()">Clear search</a>
            </div>
        </section>
    }

    @if (totalCount == 0 && !isFilterApply) {
        <section class="search-results-not-found">
            <div class="container">
                <p class="emoji-icon">😞</p>
                <p class="search-title">Uh oh</p>
                <p>There were no results for vendor</p>
            </div>
        </section>
    }
}
<!--======================= No Result Found HTML End =======================-->

<!--======================= Browse other options HTML Start =======================-->
<!-- Not in current Phase -->
<!-- <section class="browse-other-options">
    <div class="container">
        <div class="section-semi-title"><span>BROWSE OTHER OPTIONS</span>Check out other vendors who service these
            regions</div>
        <p><a href="/">Sydney</a>, <a href="/">Hunter Valley Region</a>, and <a href="/">South Coast</a></p>
    </div>
</section> -->
<!--======================= Browse other options HTML End =======================-->


<!--======================= Featured Vendors Section HTML Start =======================-->
@if (!isFilterApply){
<section class="featured-venues-section featured-vendor-list">
    @if (featuredVendors && featuredVendors.length > 0) {
        <div class="container">
            <h2 class="section-semi-title">Featured Vendors</h2>
            <div #parentSlider id="parent-slider" class="splide">
                <div class="splide__track">
                    <ul class="splide__list">
                        @for (featuredVendor of featuredVendors; track featuredVendor; let i = $index) {
                            <li class="splide__slide">
                                <div class="card-main">
                                    <div class="slider-custom">
                                        <label class="featured-label">Featured</label>
                                        <!-- Not in current Phase -->
                                        <!-- <button type="button" class="heart-icon">
                                            <img src="assets/images/heart-icon-solid.svg" width="26" height="26"
                                                alt="Heart Icon" title="favorite">
                                        </button> -->
                                        @if (featuredVendor.vendor_images && featuredVendor.vendor_images.length
                                        > 0) {
                                            <div class="slider-inner">
                                                <div class="splide featured-image-slider">
                                                    <div class="splide__track">
                                                        <ul class="splide__list">
                                                            @for (image of featuredVendor.vendor_images; track image; let j =
                                                                $index) {
                                                                <li class="splide__slide">
                                                                    <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ featuredVendor.slug]"
                                                                       class="image-box">
                                                                        <img
                                                                            [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                                            width="256" height="250"
                                                                            alt={{featuredVendor.serviceName}}
                                                                            (error)="setDefaultImage($event)"
                                                                            appSetImageDimensions
                                                                            loading="lazy">
                                                                    </a>
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        } @else {
                                            <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ featuredVendor.slug]"
                                               class="image-box">
                                                <img src="{{commonConstants.DEFAULT_NO_IMAGE}}" width="256"
                                                     height="250"
                                                     alt="image-not-found" loading="lazy">
                                            </a>
                                        }
                                    </div>
                                    <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ featuredVendor.slug]"
                                       class="card-details">
                                        <div class="card-title">
                                            <h3 class="title">{{ featuredVendor?.serviceName }}</h3>
                                            <!-- Not in current Phase -->
                                            <!-- <div class="right-side-rating">
                                                <span class="rating">4.5</span>
                                                <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                            </div> -->
                                        </div>
                                        @if (featuredVendor?.regions || featuredVendor?.states) {
                                            <span class="address">{{ featuredVendor.regions?.name }},
                                                {{ featuredVendor?.states?.name }}</span>
                                        }
                                        <!-- Not in current Phase -->
                                        <!-- <div class="card-footer">
                                            <span class="price">From $5,000</span>
                                            <span class="dot">&middot;</span>
                                            <span class="guests">22 guests</span>
                                        </div> -->
                                        @if (featuredVendor.vendor_types && featuredVendor.vendor_types.length > 0) {
                                            <div class="card-category">
                                                @for (type of featuredVendor.vendor_types.slice(0,
                                                    commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT); track type; let type_index =
                                                    $index) {
                                                    <button type="button" class="secondary-btn"
                                                            [ngbTooltip]="type?.name">{{ type?.name }}
                                                    </button>
                                                }
                                                @if ((featuredVendor.vendor_types.length -
                                                    commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT) > 0) {
                                                    <div class="care-more-category">+{{
                                                            featuredVendor.vendor_types.length -
                                                            commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT
                                                        }}
                                                        <div class="care-more-category-box">
                                                            @for (remainingType of
                                                                featuredVendor.vendor_types.slice(commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT);
                                                                track
                                                                    remainingType; ) {
                                                                <button type="button"
                                                                        class="secondary-btn">{{ remainingType?.name }}
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </a>
                                </div>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    }
</section>
}
<!--======================= Featured Vendors Section HTML End =======================-->

<!--======================= Vendors Section HTML Start =======================-->
@if (vendorList && vendorList.length > 0) {
    <section class="venues-section">
        <div class="container">
            <div class="card-list">
                @for (vendor of vendorList; track vendor; let i = $index) {
                    <div class="card-main">
                        <div class="slider-custom">
                            <!-- Not in current Phase -->
                            <!-- <button type="button" class="heart-icon">
                                <img src="assets/images/heart-icon-solid.svg" width="26" height="26" alt="Heart Icon"
                                    title="favorite">
                            </button> -->

                            @if (vendor.vendor_images && vendor.vendor_images.length > 0) {
                                <div class="slider-inner">
                                    <div class="splide box-slider">
                                        <div class="splide__track">
                                            <ul class="splide__list">
                                                @for (image of vendor.vendor_images; track image; let j = $index) {
                                                    <li class="splide__slide">
                                                        <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]"
                                                           class="image-box">
                                                            <img
                                                                [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                                width="256" height="250" alt={{vendor.serviceName}}
                                                                (error)="setDefaultImage($event)"
                                                                appSetImageDimensions loading="lazy">
                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            } @else {
                                <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]"
                                   class="image-box">
                                    <img src="{{commonConstants.DEFAULT_NO_IMAGE}}" width="256" height="250"
                                         alt="image-not-found">
                                </a>
                            }
                        </div>
                        <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]"
                           class="card-details">
                            <div class="card-title">
                                <h2 class="title">{{ vendor.serviceName }}</h2>
                                <!-- Not in current Phase -->
                                <!-- <div class="right-side-rating">
                                    <span class="rating">4.5</span>
                                    <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                </div> -->
                            </div>
                            @if (vendor?.regions || vendor?.states) {
                                <span class="address">{{ vendor?.regions?.name }},
                                    {{ vendor?.states?.name }}</span>
                            }
                            @if (vendor.vendor_types && vendor.vendor_types.length > 0) {
                                <div class="card-category">
                                    @for (type of vendor.vendor_types.slice(0,
                                        commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT); track type; let type_index = $index) {
                                        <button type="button" class="secondary-btn"
                                                [ngbTooltip]="type?.name">{{ type?.name }}
                                        </button>
                                    }
                                    @if ((vendor.vendor_types.length - commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT) > 0) {
                                        <div class="care-more-category">+{{
                                                vendor.vendor_types.length -
                                                commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT
                                            }}
                                            <div class="care-more-category-box">
                                                @for (remainingType of
                                                    vendor.vendor_types.slice(commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT); track
                                                    remainingType; ) {
                                                    <button type="button"
                                                            class="secondary-btn">{{ remainingType?.name }}
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </a>
                    </div>
                }
            </div>
            @if (currentPage != pageCount) {
                <div class="see-more">
                    <button type="button" class="secondary-btn" (click)="loadMoreVendors()">See more vendors
                        <img src="assets/images/arrow-down.svg" width="25" height="24" alt="arrow-down-icon">
                    </button>
                </div>
            }
        </div>
    </section>
}
<!--======================= Vendors Section HTML End =======================-->

<!--======================= Vendor Articles Section HTML Start =======================-->
@if (vendorInspirationList && vendorInspirationList.length > 0) {
    <section class="venues-articles-section blogs-list dusty-bg">
        <div class="container">
            <div class="section-header">
                <h2 class="section-semi-title">Need some vendor inspiration?</h2>
                <!--<button type="button" class="secondary-btn" [routerLink]=" ['/'+ routesConstants.WEDSPIRATION]">View all
                    vendor articles
                    <img src="assets/images/arrow-right.svg" width="21" height="20" alt="arrow-right-icon">
                </button>-->
            </div>
            <div class="card-list">
                @for (inspiration of vendorInspirationList; track inspiration; let i = $index) {
                    <div class="card-main">
                        <div class="slider-custom">
                            <a [routerLink]=" ['/'+ routesConstants.BLOG+ '/'+ inspiration.blog_categories[0].slug + '/'+ inspiration.slug]"
                               class="image-box">
                                <img
                                    [src]="inspiration.featureImagePath ? commonConstants.IMAGE_KIT_URL + inspiration.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                    width="256" height="250" [alt]="inspiration?.imageAltText && inspiration.imageAltText.trim() !== ''
                                    ? inspiration.imageAltText
                                    : inspiration?.title"
                                    (error)="setDefaultImage($event)"
                                    appSetImageDimensions loading="lazy">
                            </a>
                        </div>
                        <a [routerLink]=" ['/'+ routesConstants.BLOG+ '/'+ inspiration.blog_categories[0].slug + '/'+ inspiration.slug]"
                           class="card-details">
                            @if (inspiration.blog_categories && inspiration.blog_categories.length > 0) {
                                <span class="card-label">{{ inspiration.blog_categories[0].name }}</span>
                            }
                            <h3 class="card-title-inner">{{ inspiration.title }}</h3>
                            <span class="card-dec" [innerHTML]="inspiration?.excerpt"></span>
                            <!-- Not in current Phase -->
                            <!-- <div class="card-category">
                                <button type="button" class="secondary-btn">Inspiration and advice 💡</button>
                                <button type="button" class="secondary-btn">Budget 💰</button>
                            </div> -->
                        </a>
                    </div>
                }
            </div>
        </div>
    </section>
}
<!--======================= Vendor Articles Section HTML End =======================-->

<!--======================= Sign-up and Log-in Section HTML Start =======================-->
<app-venue-vendor-footer></app-venue-vendor-footer>
<!--======================= Sign-up and Log-in Section HTML End =======================-->
