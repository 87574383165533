.search-panel-wrap {
    //position: absolute;
    //top: 100%;
    //right: 0;
    //left: 0;
    //background: #FFFBF8;
    //border-top: 1px solid #272338;
    //
    //@media screen and (max-width: 767px) {
    //    height: calc(100dvh - 87px);
    //    padding-bottom: 52px;
    //    overflow-x: auto;
    //}

    //&.show-search {
    //    display: block;
    //}

    .container {
        padding-left: 16px;
        padding-right: 16px;
    }

    .close-search-btn {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        text-align: center;
        color: #272338;
        cursor: pointer;
        padding: 10px 16px;
        border-top: 1px solid #272338;
        border-bottom: 1px solid #272338;
        background: #FFFBF8;

        @media screen and (max-width: 767px) {
            position: fixed;
            top: auto;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 2;
            font-size: 16px;
            line-height: 20px;
            padding: 14px 16px;
        }
    }

    .search-by-keywords {
        text-align: center;
        padding: 48px 0;

        @media screen and (max-width: 767px) {
            padding: 16px 0;
        }


        .search-by-keywords-title {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            text-align: center;
            color: #4D4D4D;
            margin: 0 0 24px;

            @media screen and (max-width: 767px) {
                margin: 0 0 20px;
            }
        }

        .popular-keywords {
            justify-content: center;
            flex-wrap: wrap;
            gap: 8px;
            display: flex;

            .secondary-btn {
                border-radius: 30px;
                font-size: 14px;
                line-height: 18px;
                padding: 6px 12px;
            }
        }
    }

    .showing-results {
        background: #CDD2EE;
        padding: 11px 0 10px;
        border-bottom: 1px solid #272338;
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #272338;
        text-align: center;
        position: sticky;
        top: 0;
        z-index: 2;
        min-height: 40px;

        strong {
            font-weight: 500;
            margin-right: 8px;
        }

        a, .view-all {
            color: #272338;
            font-weight: 500;
            padding-bottom: 1px;
            border-bottom: 1px solid #272338;
            display: inline-block;
            cursor: pointer;
            transition: all 0.35s ease 0s;

            &:hover {
                //color: #7093FF;
                border-bottom-color: transparent;
            }
        }
    }

    .search-results-not-found {
        min-height: 356px;

        @media screen and (max-width: 767px) {
            min-height: inherit;
        }
    }

    .search-result-list {
       min-height: 356px;

        .container {
            padding-left: 0;
            padding-right: 0;
            display: flex;
            flex-wrap: wrap;
            gap:0;
        }

        .search-result-box {
            padding: 24px;
            border-right: 2px solid #8A81B1;
            width: 33.33%;
            max-width: 33.33%;
            min-height: 356px;

            @media screen and (max-width: 991px) {
                padding: 16px;
            }

            @media screen and (max-width: 767px) {
                width: 50%;
                max-width: 50%;
                border-right-color: #272338;
                border-bottom: 2px solid #272338;
                min-height: initial;

                &:nth-child(2) {
                    border-right: none;
                }
            }

            @media screen and (max-width: 575px) {
                width: 100% !important;
                max-width: 100% !important;
                border-right: none;
            }

            &:last-child {
                border-right: none;

                @media screen and (max-width: 767px) {
                    width: 70%;
                    max-width: 70%;
                    border-bottom: none;
                }
            }

            .search-category {
                font-family: Aeonik, sans-serif;
                font-size: 12px;
                line-height: 15px;
                font-weight: 500;
                color: #574E7E;
                margin: 0 0 13px;
                text-transform: uppercase;

                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            .secondary-btn {
                margin-top: 16px;
                font-size: 14px;
                line-height: 20px;
                padding: 9px 20px 9px 16px;
                gap: 11px;

                img {
                    position: relative;
                    right: 0;
                    transition: all 0.35s ease 0s;
                }

                &:hover {
                    img {
                        right: -4px;
                    }
                }

                @media screen and (max-width: 991px) {
                    margin-top: 8px;
                }

                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 20px;
                    padding: 11px 20px;
                    gap: 13px;
                }
            }
        }

        .search-result-item {
            font-family: Aeonik, sans-serif;
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            color: #4D4D4D;
            margin-bottom: 8px;

            @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 16px;
            }

            .search-result-item-inner {
                display: inline-flex;
                color: #4D4D4D;
                min-height: 48px;
                padding-left: 60px;
                flex-direction: column;
                position: relative;
                justify-content: center;
                gap: 4px;
                max-width: 100%;
                cursor: pointer;

                > img {
                    width: 48px;
                    height: 48px;
                    border-radius: 50px;
                    border: 1px solid #272338;
                    object-fit: cover;
                    object-position: center center;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                }
            }

            p {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 100%;
                text-transform: uppercase;
            }

            h3 {
                font-family: Aeonik, sans-serif;
                font-size: 16px;
                line-height: 21px;
                font-weight: 500;
                color: #272338;
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 100%;
            }
        }
    }
}
