import {Component, ViewEncapsulation} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-waitlist-subscribed',
  imports: [],
  templateUrl: './waitlist-subscribed.component.html',
  styleUrl: './waitlist-subscribed.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class WaitlistSubscribedComponent {

  constructor(public _activeModal: NgbActiveModal) { }

  closeModal() {
    this._activeModal.close();
  }

}
