import { Injectable } from '@angular/core';
import { httpConfig, HttpService } from '../http-service/http.service';
import { ApiUrlConstants } from '@coreconstant';

@Injectable({
  providedIn: 'root'
})
export class EnquireService {

  venueBaseUrlApi = ApiUrlConstants.VENUE_BASE_URL;
  vendorBaseUrlApi = ApiUrlConstants.VENDOR_BASE_URL;
  httpConfig: httpConfig;

  constructor(private _httpService: HttpService) { }

  /**
   * Add venue Enquire
   * @param input 
   * @returns 
   */
  addVenueEnquire(input) {
    this.httpConfig = {
      url: this.venueBaseUrlApi + '/enquires',
      data: input,
      method: 'post',
    }
    return this._httpService.init(this.httpConfig);
  }

  /**
   * Add vendor Enquire
   * @param input 
   * @returns 
   */
  addVendorEnquire(input) {
    this.httpConfig = {
      url: this.vendorBaseUrlApi + '/enquires',
      data: input,
      method: 'post',
    }
    return this._httpService.init(this.httpConfig);
  }
}
