<!--======================= Page Title Section HTML Start =======================-->
<section class="page-title-wrap">
    <!-- style="background-image: url(assets/images/region-bg.webp)" -->
    <div class="container">
        <div class="page-title-info">
            <!-- Not in current Phase -->
            <!-- <label class="featured-label">Featured region</label> -->
            <h1>{{territoryDetails?.title}}</h1>
            @if(territoryDetails?.regions?.name) {
            <p class="region-location">{{territoryDetails?.regions?.name}}</p>
            }
            <p>{{territoryDetails?.description}}</p>
        </div>
    </div>
</section>

<!--======================= Showing Result HTML Start =======================-->
@if(totalCount > 0) {
<section class="showing-results-wrap">
    <div class="container">Showing {{totalCount}} {{ totalCount == 1 ? 'Venue' : 'Venues' }}</div>
</section>
}
<!--======================= Showing Result HTML End =======================-->

<!--======================= No Result Found HTML Start =======================-->
@if(isResponseReceived && totalCount == 0) {
<section class="search-results-not-found">
    <div class="container">
        <p class="emoji-icon">😞</p>
        <p class="search-title">Uh oh</p>
        <p>We currently don't have any venues in this region - please check back in later!</p>
    </div>
</section>
}
<!--======================= No Result Found HTML End =======================-->

<!--======================= Venues Section HTML Start =======================-->
@if(regionVenueList && regionVenueList.length > 0) {
<section class="venues-section">
    <div class="container">
        <div class="card-list">
            @for (venue of regionVenueList; track venue; let i = $index) {
            <div class="card-main">
                <div class="slider-custom">
                    <!-- Not in current Phase -->
                    <!-- <label class="featured-label">Featured</label> -->
                    <!-- <button type="button" class="heart-icon">
                            <img src="assets/images/heart-icon-solid.svg" width="26" height="26" alt="Heart Icon" title="favorite">
                        </button> -->
                    @if(venue.venue_images && venue.venue_images.length > 0) {
                    <div class="slider-inner">
                        <div class="splide box-slider">
                            <div class="splide__track">
                                <ul class="splide__list">
                                    @for (image of venue.venue_images; track image; let j = $index) {
                                    <li class="splide__slide">
                                        <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ venue.slug]"
                                            class="image-box">
                                            <img [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                width="256" height="250" alt="venue-image"
                                                (error)="setDefaultImage($event)" appSetImageDimensions loading="lazy">
                                        </a>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    } @else {
                    <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ venue.slug]" class="image-box">
                        <img src="{{commonConstants.DEFAULT_NO_IMAGE}}" width="256" height="250" alt="image-not-found"
                            loading="lazy">
                    </a>
                    }

                </div>
                <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ venue.slug]" class="card-details">
                    <div class="card-title">
                        <h2 class="title">{{venue.venueName}}</h2>
                        <!-- Not in current Phase -->
                        <!-- <div class="right-side-rating">
                                 <span class="new">New</span>
                                <span class="rating">4.5</span>
                                <img src="assets/images/star-icon.svg" width="21" height="20" alt="star-icon">
                            </div> -->
                    </div>
                    <span class="address">{{venue.regions?.name}},
                        {{venue.states?.name}}</span>
                    <!-- Not in current Phase -->
                    <!-- <div class="card-footer">
                            <span class="price">From $5,000</span>
                            <span class="dot">&middot;</span>
                            <span class="guests">22 guests</span>
                        </div> -->
                </a>
            </div>
            }
        </div>
        @if(currentPage != pageCount) {
        <div class="see-more">
            <button type="button" class="secondary-btn" (click)="loadMoreVenues()">See more venues
                <img src="assets/images/arrow-down.svg" width="25" height="24" alt="arrow-down-icon">
            </button>
        </div>
        }
    </div>
</section>
}
<!--======================= Venues Section HTML End =======================-->
