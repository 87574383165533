import {
    Component,
    ElementRef,
    HostListener,
    inject,
    Inject,
    PLATFORM_ID,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import Splide from '@splidejs/splide';
import {NgbModal, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {VenueVendorFooterComponent} from '../../venue-vendor-footer/venue-vendor-footer.component';
import {EnquireVenueVendorComponent} from "../../../modal/enquire-venue-vendor/enquire-venue-vendor.component";
import {VenueService} from 'src/app/core/services/api-service/venue.service';
import {lastValueFrom} from 'rxjs';
import {ResponseHandlerService} from 'src/app/core/services/common-service/response-handler.service';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {CommonConstants, RoutesConstants} from '@coreconstant';
import {Router} from '@angular/router';
import {GeneralService} from 'src/app/core/services/api-service/general.service';
import {DomSanitizer} from '@angular/platform-browser';
import {SetImageDimensionsDirective} from 'src/app/core/directives/set-image-dimensions.directive';
import {ImagePathConstants} from 'src/app/core/constants/image-path-constants';
import {SeoService} from '../../../core/services/common-service/seo.service';
import {SafeHtmlPipe} from 'src/app/core/pipes/safe-html.pipe';
import {ImageGalleryComponent} from '../../image-gallery/image-gallery.component';
import {VenueMapComponent} from '../venue-map/venue-map.component';

@Component({
    selector: 'app-venue-detail',
    imports: [VenueVendorFooterComponent, SetImageDimensionsDirective, RouterModule, NgbTooltipModule, SafeHtmlPipe, ImageGalleryComponent, VenueMapComponent],
    templateUrl: './venue-detail.component.html',
    styleUrl: './venue-detail.component.scss',
    providers: [SafeHtmlPipe],
    encapsulation: ViewEncapsulation.None
})

export class VenueDetailComponent {
    commonConstants = CommonConstants
    routesConstants = RoutesConstants
    imagePathConstants = ImagePathConstants
    currentUrl: any
    venueDetails: any
    slug: any
    accommodation: any
    facilities: any
    uniqueVenueFacilitiesIds: any = [0]
    uniqueVenueAccommodationIds: any = [0]
    vendorList: any
    firstImage: any
    remainingImages: any = []
    remainingAndDefaultImages: any = []
    moreImageCount: number;
    isAboutExpanded: boolean = false;
    isOwnerDescExpanded: boolean = false;
    isFacilitiesExpanded: boolean = false;
    isAccommodationExpanded: boolean = false;
    isFeaturedDataExpanded: boolean = false;
    isMoreOwnerDesc: boolean = false;
    isMoreAboutDesc: boolean = false;
    @ViewChild('aboutContainer', {static: false}) aboutContainer!: ElementRef;
    @ViewChild('ownerDescContainer', {static: false}) ownerDescContainer!: ElementRef;
    isMobile: boolean = false
    mapUrl: any;
    isGalleryLoaded: boolean = false;
    isMapLoeded: boolean = false;
    isPreview: boolean = false;


    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _modalService: NgbModal,
        private _venueService: VenueService,
        private _responseHandlerService: ResponseHandlerService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _generalService: GeneralService,
        private _seoService: SeoService,
        private _sanitizer: DomSanitizer
    ) {
        this.onResize()
        this.currentUrl = this._router.url;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (isPlatformBrowser(this.platformId)) {
            this.isMobile = window.innerWidth < 768;
        }
    }

    ngOnInit(): void {
        const params = this._activatedRoute.snapshot.params;
        if (params && params['slug']) {
            this.slug = params['slug']
        }
        if (params && params['preview'] && params['preview'] == 'preview') {
            this.isPreview = true;
        }
        this.getRemainingImages()
        this.getFacilities();
        this.getAccommodation();
        this.getVenueDetails()
        this.getVendorsOfCurrentVenue()
    }

    setMetaTags() {
        let metaTagObject: any = {
            title: this.venueDetails['metaTitle'],
            description: this.venueDetails['metaDescription'],
            ogImageUrl: (this.firstImage?.imagePath ? this.firstImage.imagePath : ''),
            ogWebUrl: this.routesConstants.VENUE_NAVIGATION + '/' + this.slug,
        }
        if (this.isPreview) {
            metaTagObject.robots = 'noindex, nofollow'
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    private modalService = inject(NgbModal);

    // ngAfterViewInit() {
    //   setTimeout(() => {
    //     this.checkDescContent()
    //   })
    // }

    checkDescContent() {
        if (this.ownerDescContainer) {
            const element = this.ownerDescContainer.nativeElement;
            this.isMoreOwnerDesc = element.scrollHeight > element.clientHeight
        }

        if (this.aboutContainer) {
            const element = this.aboutContainer.nativeElement;
            this.isMoreAboutDesc = element.scrollHeight > element.clientHeight
        }
    }

    /**
     * Get Venue details
     */
    getVenueDetails() {
        var getDetails = this._venueService.getVenueDetails(this.slug)
        lastValueFrom(getDetails).then((res: any) => {
            if (res["status"]) {
                this.venueDetails = res["data"]
                if (this.venueDetails) {
                    if (!this.isPreview && this.venueDetails.statusTypeId != 3) {
                        this._router.navigate(['/' + this.routesConstants.VENUE_NAVIGATION]);
                        return;
                    } else {
                        if (this.venueDetails.addressLatitude && this.venueDetails.addressLongitude) {
                            let url = this.commonConstants.STAY22_MAP_URL + '?aid=' + this.commonConstants.STAY22_URL_AID_PARAMATER + '&lat=' + this.venueDetails.addressLatitude + 'lng=' + this.venueDetails.addressLongitude
                            if (this.venueDetails.venueAddress) {
                                url += '&address=' + this.venueDetails.venueAddress
                            }
                            url += this.commonConstants.STAY22_MAP_CONFIGURATIONS_URL + this.commonConstants.STAY22_MAP_CONFIGURATIONS_URL_DETAIL_PAGE
                            this.mapUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url);
                        }

                        if (this.venueDetails.added_facilities && this.venueDetails.added_facilities.length > 0) {
                            this.uniqueVenueFacilitiesIds = [...new Set(this.venueDetails.added_facilities.map(item => item.venueFacilitiesId))];
                        }

                        if (this.venueDetails.added_accommodations && this.venueDetails.added_accommodations.length > 0) {
                            this.uniqueVenueAccommodationIds = [...new Set(this.venueDetails.added_accommodations.map(item => item.venueAccommodationId))];
                        }

                        if (this.venueDetails.venue_images && this.venueDetails.venue_images.length > 0) {
                            this.firstImage = this.venueDetails.venue_images[0]
                            this.remainingImages = this.venueDetails.venue_images.slice(1);

                            this.moreImageCount = this.venueDetails.venue_images.length - this.commonConstants.DEFAULT_SHOW_IMAGES_VENUE_VENDOR
                        }

                        this.getRemainingImages()
                        setTimeout(() => {
                            this.checkDescContent()
                        })
                    }
                    this.setMetaTags()
                }
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            console.log("INSIDE MAIN ERROR")
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Used to show the remaining and default images
     * @returns
     */
    getRemainingImages() {
        this.remainingAndDefaultImages = []
        this.remainingAndDefaultImages = [...this.remainingImages];

        // Add default images if less than 4
        while (this.remainingAndDefaultImages.length < 4) {
            this.remainingAndDefaultImages.push({
                imagePath: this.commonConstants.DEFAULT_NO_IMAGE,
                id: -this.remainingAndDefaultImages.length,
            });
        }
        this.remainingAndDefaultImages = this.remainingAndDefaultImages.slice(0, 4);
    }

    getFormattedTime(time: any): string {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        const period = hours >= 12 ? 'pm' : 'am';
        const adjustedHours = hours > 12 ? hours - 12 : hours;

        const paddedHours = adjustedHours < 10 ? `0${adjustedHours}` : adjustedHours;
        const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${paddedHours}:${paddedMinutes}${period}`;
    }

    /**
     * Get all facilities
     */
    getFacilities() {
        var getListing = this._generalService.getFacilities()
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.facilities = res["data"]
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Get all accommodation
     */
    getAccommodation() {
        var getListing = this._generalService.getAccommodation()
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.accommodation = res["data"]
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Get Vendors who service this venue
     */
    getVendorsOfCurrentVenue() {
        var getVendorList = this._venueService.getVendorsOfCurrentVenue(this.slug)
        lastValueFrom(getVendorList).then((res: any) => {
            if (res["status"]) {
                this.vendorList = res["data"]
                setTimeout(() => {
                    this.vendorImageSlider()
                })
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Image slider of the vendor list
     */
    vendorImageSlider() {
        if (isPlatformBrowser(this.platformId)) {
            const splides = document.querySelectorAll('.box-slider');

            splides.forEach((element) => {
                new Splide(element as HTMLElement, {
                    type: 'loop'
                }).mount();
            });
        }
    }


    /**
     * Copy the URL to the clipboard
     */
    copyUrl() {
        const input = document.createElement('input');
        input.value = window.location.origin + this.currentUrl;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        this._responseHandlerService.showSuccessMessageGeneral(this.commonConstants.LINK_COPY)
    }


    openVerticallyCentered(content: TemplateRef<any>, modalClass: string) {
        this.modalService.open(content, {centered: true, modalDialogClass: modalClass});
    }

    openEnquireVenueModal(title: any) {
        const addUpdateBlogCategoryModal = this._modalService.open(EnquireVenueVendorComponent, {
            backdrop: 'static',
            keyboard: false,
            size: 'md' //'sm' | 'md' | 'lg' | 'xl' | 'xxl'
        });
        addUpdateBlogCategoryModal.componentInstance.title = title;
        addUpdateBlogCategoryModal.componentInstance.owner_id = this.venueDetails.ownerId;
        addUpdateBlogCategoryModal.componentInstance.venueId = this.venueDetails?.id;
        addUpdateBlogCategoryModal.componentInstance.venueName = this.venueDetails?.venueName;
        let ownerName = this.venueDetails?.venueHostedBy && this.venueDetails?.venueHostedBy != '' ?
            this.venueDetails?.venueHostedBy : this.venueDetails?.users?.firstName + ' ' + this.venueDetails?.users?.lastName
        addUpdateBlogCategoryModal.componentInstance.ownerName = ownerName;
        addUpdateBlogCategoryModal.componentInstance.listingLogo = this.venueDetails?.listingLogo;

        addUpdateBlogCategoryModal.result.then(
            (result) => {
                if (result?.status) {
                    // this.getBlogCategoryList();
                }
            }
        );
    }

    /**
     * For About content
     */
    toggleContent() {
        this.isAboutExpanded = !this.isAboutExpanded;
    }

    /**
     * For Owner content
     */
    toggleOwnerContent() {
        this.isOwnerDescExpanded = !this.isOwnerDescExpanded;
    }

    /**
     * For Featured data
     */
    toggleFeaturedData() {
        this.isFeaturedDataExpanded = !this.isFeaturedDataExpanded;
    }

    /**
     * For Facilities and Accommodation section
     * @param section
     */
    toggleFacilitiesAccommodation(section: any) {
        if (section == 'facilities') {
            this.isFacilitiesExpanded = !this.isFacilitiesExpanded;
        }
        if (section == 'accommodation') {
            this.isAccommodationExpanded = !this.isAccommodationExpanded;
        }
    }

    toggleGallery(flag: boolean) {
        this.isGalleryLoaded = flag;
    }

    toggeleMap(flag: boolean) {
        this.isMapLoeded = flag;
    }

    setDefaultImage(event: Event) {
        let image = this.commonConstants.DEFAULT_NO_IMAGE;
        (event.target as HTMLImageElement).src = image
    }
}
