//::ng-deep {
//    app-header {
//        display: none;
//    }
//
//    app-footer {
//        display: none;
//    }
//}

.auth-header {
    padding: 18px 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;

    @media screen and (max-width: 991px) {
        display: none;
    }

    .container {
        padding-left: 80px;
        padding-right: 80px;
        max-width: 2240px;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    a {
        display: inline-block;
    }
}

.auth-wrap {
    position: relative;
    min-height: 100dvh;
    //gap: 90px;
    display: flex;

    @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
        padding: 32px 0 0;
        gap: 40px 0;
    }

    .auth-left-panel {
        width: 645px;
        min-width: 645px;
        padding-top: 65px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: inline-flex;
        position: sticky;
        top: 0;
        align-self: start;
        min-height: 100dvh;

        @media screen and (max-width: 1360px) {
            width: 40%;
            min-width: 40%;
        }

        @media screen and (max-width: 991px) {
            position: relative;
            width: 100%;
            min-width: 100%;
            //flex-direction: column-reverse;
            align-items: flex-start;
            overflow: hidden;
            padding-top: 0;
            min-height: initial;
        }

        &:before {
            content: '';
            background: #94B3A5;
            border-right: 2px solid #272338;
            position: absolute;
            top: 0;
            right: 26px;
            bottom: 0;
            left: 0;
            z-index: -1;
            pointer-events: none;

            @media screen and (max-width: 991px) {
                border: none;
                border-top: 2px solid #272338;
                top: 48px;
                right: 0;
            }

            @media screen and (max-width: 575px) {
                top: 67px;
            }
        }

        .auth-logo {
            padding: 32px 16px 20px;
            width: 100%;
            z-index: 1;
            display: none;

            a {
                display: inline-block;
            }

            @media screen and (max-width: 991px) {
                display: block;
            }
        }

        .auth-vector {
            img {
                height: auto;

                @media screen and (max-width: 575px) {
                    margin-left: -30px;
                    max-width: inherit;
                    width: calc(100% + 71px);
                }
            }
        }
    }

    .auth-right-panel {
        flex: 1;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        padding: 60px 90px;

        @media screen and (max-width: 1440px) {
            justify-content: flex-start;
        }

        @media screen and (max-width: 1280px) {
            padding: 30px 60px;
        }

        @media screen and (max-width: 991px) {
            padding: 0 24px;
            justify-content: center;
        }

        @media screen and (max-width: 767px) {
            padding: 0 16px;
        }

        .auth-box {
            .auth-action {
                margin-top: 64px;

                @media screen and (max-width: 767px) {
                    margin-top: 24px;
                }
            }
        }
    }
}

.auth-box {
    max-width: 494px;
    width: 100%;

    .auth-box-head {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #272338;
        margin-bottom: 24px;

        .section-title {
            margin-bottom: 0;
        }

        p {
            margin: 8px 0 0;
        }
    }

    form {
        margin: 0;
    }

    .form-group {
        >label {
            align-items: center;
            gap: 4px;
            display: flex;

            .sign-in-label {
                margin: 0 0 0 auto;

                @media screen and (max-width: 767px) {
                    display: none;
                }
            }

            small {
                font-family: Aeonik, sans-serif;
                font-size: 12px;
                line-height: 14px;
                font-weight: 400;
                color: #4D4D4D;
                margin: 0 0 0 auto;
            }

            a {
                font-weight: 700;
                color: #7093FF;

                &:hover {
                    color: #272338;
                }
            }
        }
    }

    .primary-btn {
        width: 100%;
        margin-top: 8px;
        gap: 10px;

        img {
            width: 13px;
            height: 10px;
            position: relative;
            right: 0;
            transition: all 0.35s ease 0s;
            filter: brightness(10);
        }

        &[disabled] {
            background: #F5F5F5;
            color: #CCCCCC;
            cursor: not-allowed;

            img {
                opacity: 0.3;
                filter: invert(1);
            }
        }

        &:hover:not([disabled]) {
            img {
                right: -4px;
            }
        }
    }

    .auth-forgot,
    .auth-links {
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        margin: 16px 0 0;
        color: #272338;

        strong {
            font-weight: 500;
        }

        a {
            color: #272338;
            gap: 4px;
            align-items: center;
            display: inline-flex;

            &:hover {
                color: #7093FF;
            }
        }

        p {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .auth-links {
        margin: 45px 0 0;

        @media screen and (max-width: 767px) {
            margin-top: 24px;
        }

        p {
            gap: 8px;
            align-items: center;
            flex-wrap: wrap;
            display: flex;
        }

        svg {
            transition: all 0.35s ease 0s;
            position: relative;
            right: 0;

            path {
                transition: all 0.35s ease 0s;
            }
        }

        a {
            font-weight: 500;
            color: #7093FF;

            &:hover {
                color: #272338;

                svg {
                    right: -4px;

                    path {
                        stroke: #272338;

                    }
                }
            }
        }

        &.acc-links {
            font-weight: 500;
            margin-top: 24px;

            p {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

    }

    .auth-action {
        gap: 24px;
        margin-top: 52px;
        align-items: center;
        justify-content: space-between;
        display: flex;

        @media screen and (max-width: 1366px) {
            margin-top: 48px;
        }

        .secondary-btn {
            gap: 10px;
            min-height: 44px;
            min-width: 72px;

            &[disabled] {
                background: #F5F5F5;
                color: #CCCCCC;
                cursor: not-allowed;

                img {
                    opacity: 0.3;
                }
            }

            img {
                width: 13px;
                height: 10px;
                position: relative;
                left: 0;
                transition: all 0.35s ease 0s;
            }

            &:hover:not([disabled]) {
                img {
                    left: -4px;
                }
            }

            &:last-child {
                img {
                    left: auto;
                    right: 0;
                }

                &:hover:not([disabled]) {
                    img {
                        right: -4px;
                    }
                }
            }

            @media screen and (max-width: 767px) {
                span {
                    display: none;
                }
            }
        }

        .primary-btn {
            width: auto;
            margin: 0;
            min-height: 44px;
            white-space: nowrap;

            &:first-child {
                width: 100%;
            }

            @media screen and (max-width: 991px) {
                &:not(:first-child) {
                    min-width: 190px;
                }
            }

            @media screen and (max-width: 480px) {
                &:not(:first-child) {
                    min-width: inherit;
                    flex: 1;
                }
            }
        }

        .auth-pagination {
            display: flex;
            align-items: center;
            gap: 8px;

            span {
                width: 12px;
                height: 12px;
                background: #FFE6D5;
                border: 1px solid #272338;
                border-radius: 40px;
                transition: all 0.35s ease 0s;

                &.active {
                    background: #272338;
                }
            }
        }
    }

    .form-group-box {
        padding: 16px;
        border: 1px solid #272338;
        border-radius: 16px;
        background: #fff;
        margin-bottom: 12px;

        h3, .form-group-box-title {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            margin: 0 0 8px;
            color: #272338;
        }

        .form-group {
            margin-bottom: 0;

            +.form-group {
                margin-top: 8px;
            }
        }

        .form-group-col-2 {
            gap: 8px;
            flex-wrap: wrap;
            display: flex;
            margin-bottom: 8px;

            .form-group {
                flex: 1;
                margin: 0;

                @media screen and (max-width: 480px) {
                    flex: auto;
                    width: 100%;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .form-group-radio-2 {
        gap: 8px;
        flex-wrap: wrap;
        display: flex;

        .custom-radio {
            flex: 1;

            @media screen and (max-width: 480px) {
                flex: auto;
                width: 100%;
            }
        }
    }

    .insta-field {
        .at-icon {
            position: absolute;
            top: 32px;
            left: 18px;
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #272338;

            @media screen and (max-width: 480px) {
                top: 36px;
            }
        }

        .form-control {
            padding-left: 34px;
        }
    }

    .auth-add-another {
        margin-top: 24px;

        .secondary-btn {
            gap: 12px;
            padding-right: 16px;
            padding-left: 20px;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .auth-achieve-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        label {
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #272338;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            padding: 6px 12px;
            border: 1px solid #272338;
            border-radius: 30px;
            background: #FFFFFF;
            transition: all 0.35s ease 0s;
            cursor: pointer;

            &:hover {
                background: #A9BEFF;
            }

            &.active {
                background: #272338;
                color: #fff;
            }
        }
    }
}

.auth-step-wrap {
    padding: 130px 16px 64px;
    position: relative;

    @media screen and (max-width: 1366px) {
        padding: 100px 16px 44px;
    }

    @media screen and (max-width: 1200px) {
        padding-top: 70px;
    }

    @media screen and (max-width: 991px) {
        padding: 32px 16px 116px;
        min-height: 100dvh;
    }

    .auth-box {
        margin: 0 auto;
        text-align: left;
        z-index: 990;
        position: relative;
    }

    .auth-logo {
        display: none;
        position: absolute;
        padding: 20px 16px;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;

        a {
            display: inline-block;
        }

        @media screen and (max-width: 991px) {
            display: block;
        }
    }
}

.notification-box {
    font-family: Aeonik, sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #4D4D4D;
    text-align: center;
    border: 1px solid #272338;
    border-radius: 16px;
    width: 100%;
    max-width: 434px;
    margin: 0 auto;
    padding: 32px;
    background: #fff;

    @media screen and (max-width: 480px) {
        padding: 32px 24px;
        font-size: 16px;
        line-height: 22px;
    }

    .notification-box-icon {
        text-align: center;
        margin: 0 0 16px;
    }

    h2 {
        font-family: Livorno, serif;
        font-size: 32px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: -0.01em;
        text-align: center;
        color: #272338;
        margin: 0 0 16px;
    }

    p {
        margin: 0;

        +p {
            margin-top: 16px;
        }
    }

    .primary-btn {
        width: 100%;
        margin-top: 24px;
    }

    .notification-box-action {
        margin: 24px 0 0;
        display: flex;
        flex-wrap: wrap;
        gap: 10px 8px;

        p {
            margin: 0;
            width: 100%;
        }

        .secondary-btn {
            flex: 1;

            @media screen and (max-width: 480px) {
                flex: auto;
                width: 100%;
            }
        }
    }
}
