.search-results-head {
    text-align: center;
    padding: 32px 0 0;
    border-bottom: 1px solid #272338;

    @media screen and (max-width: 767px) {
        padding-top: 16px;
    }
}

.search-tabs {
    justify-content: center;
    display: flex;
    gap: 2px;

    @media screen and (max-width: 575px) {
        margin: 0 -16px;
    }

    .btn {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        padding: 16px;
        border-radius: 0;
        border: none;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        font-family: Aeonik, sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        text-align: center;
        color: #272338;
        position: relative;
        transition: all 0.35s ease 0s;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: auto;
            right: 0;
            height: 2px;
            width: 0;
            background: #7093FF;
            transition: all 0.35s ease 0s;
        }

        svg {
            path {
                transition: all 0.35s ease 0s;
            }
        }

        &.active {
            font-weight: 500;
        }

        &.active,
        &:hover {
            &::before {
                width: 100%;
                right: auto;
                left: 0;
            }

            svg {
                path {
                    stroke: #7093FF;
                }
            }
        }

        @media screen and (max-width: 575px) {
            flex: 1;
            padding: 16px 4px;
        }
    }
}

//Search result section
.search-results-list {
    padding: 24px 0;
    border-bottom: 1px solid #272338;

    .section-semi-title {
        margin-bottom: 10px;

        @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.article-results-list {
    padding: 24px 0 48px;
    border-bottom: 1px solid #272338;

    @media screen and (max-width: 991px) {
        padding-bottom: 32px;
    }

    .section-semi-title {
        margin-bottom: 10px;

        @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    .see-more {
        margin-top: 60px;

        @media screen and (max-width: 991px) {
            margin-top: 26px;
        }
    }

}

.search-results-not-found {
    border-bottom: 1px solid #272338;
}

// Quick Action section
.quick-action-wrap {
    background: linear-gradient(90deg, #EFB7A6 50%, #A8AED6 50%);
    //border-top: 1px solid #272338;
    position: relative;

    @media screen and (max-width: 575px) {
        background: transparent;
    }

    .container {
        display: flex;
        padding-left: 0;
        padding-right: 0;

        @media screen and (max-width: 575px) {
            flex-direction: column;
        }
    }

    .quick-action-item {
        flex: 1;
        padding: 40px;
        text-align: center;

        @media screen and (max-width: 767px) {
            padding: 24px 16px;
        }

        @media screen and (max-width: 575px) {
            background: #EFB7A6;
        }

        + .quick-action-item {
            border-left: 1px solid #272338;

            @media screen and (max-width: 575px) {
                border: none;
                border-top: 1px solid #272338;
                background: #A8AED6;
            }
        }

        h3 {
            font-family: Aeonik, sans-serif;
            font-size: 22px;
            line-height: 26px;
            font-weight: 500;
            letter-spacing: -0.01em;
            color: #272338;
            margin: 0 0 16px;

            @media screen and (max-width: 767px) {
                font-size: 20px;
                line-height: 26px;
            }
        }

        .secondary-btn {
            gap: 12px;

            img {
                position: relative;
                right: 0;
                transition: all 0.35s ease 0s;
            }

            &:hover {
                img {
                    right: -4px;
                }
            }
        }
    }
}
