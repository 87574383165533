//Venue Banner
.vendor-banner-wrap {
    &.venue-banner {
        background: #94B3A5;

        .container {
            gap: 0;
            @media screen and (max-width: 1280px) {
                gap: 24px;
            }
            @media screen and (max-width: 991px) {
                align-items: flex-end;
            }
        }

        .left-content {
            color: #4D4D4D;
            @media screen and (max-width: 1366px) {
                max-width: 475px;
            }
            @media screen and (max-width: 1100px) {
                max-width: 360px;
            }
            @media screen and (max-width: 991px) {
                max-width: 304px;
            }
            @media screen and (max-width: 767px) {
                max-width: 100%;
            }
        }

        h1 {
            color: #272338;
        }

        .primary-btn {
            border-color: #272338;

            &:hover {
                border-color: #272338;
            }
        }

        .banner-right-img {
            width: 803px;
            margin: 0;
            @media screen and (max-width: 1360px) {
                width: 737px;
            }
            @media screen and (max-width: 1280px) {
                width: 728px;
            }
            @media screen and (max-width: 1200px) {
                width: 615px;
            }
            @media screen and (max-width: 767px) {
                width: 100%;
            }

            @media screen and (max-width: 480px) {
                padding-bottom: 75%;
            }
        }
    }
}

.how-it-works-wrap {
    .how-it-works-step {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: -100vw;
            bottom: 0;
            left: -100vw;
            background: #FFE6D5;
            border-bottom: 1px solid #272338;
            z-index: -1;
            pointer-events: none;
        }
    }

    .what-sets-wrap {
        &:before {
            display: none;
        }
    }
}

.join-vendors-wrap {
    &.join-venues {
        background: #EFB7A6;
    }
}

//Bottom Banner
.venue-managed-wrap {
    background: #A8AED6;
    padding: 80px 0;
    overflow: hidden;

    @media screen and (max-width: 1100px) {
        padding: 60px 0;
    }
    @media screen and (max-width: 991px) {
        padding: 40px 0;
    }

    @media screen and (max-width: 767px) {
        padding: 32px 0 0;
    }

    .container {
        padding-right: 80px;
        padding-left: 80px;
        gap: 60px;
        align-items: center;
        display: flex;
        @media screen and (max-width: 1360px) {
            padding-right: 40px;
            padding-left: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-right: 16px;
            padding-left: 16px;
        }

        @media screen and (max-width: 1200px) {
            gap: 32px;
        }

        @media screen and (max-width: 767px) {
            gap: 8px;
            flex-direction: column;
            align-items: initial;
        }
    }

    .venue-managed-info {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #272338;
        width: 714px;
        max-width: 100%;

        @media screen and (max-width: 1200px) {
            width: 600px;
        }
        @media screen and (max-width: 1100px) {
            width: 500px;
        }
        @media screen and (max-width: 991px) {
            width: 390px;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            font-size: 16px;
            line-height: 22px;
        }

        .offer-label {
            font-family: Aeonik, sans-serif;
            background: #272338;
            padding: 5px 10px;
            border: none;
            border-radius: 30px;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #fff;
            margin: 0 0 16px;
        }

        .secondary-btn {
            margin-top: 24px;
        }
    }

    .section-title {
        margin-bottom: 8px;
        @media screen and (max-width: 1100px) {
            font-size: 32px;
            line-height: 38px;
        }
    }

    p {
        margin: 0;
        max-width: 471px;
        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }

    .venue-managed-img {
        flex: 1;
        text-align: center;
        @media screen and (max-width: 480px) {
            text-align: right;
            margin-bottom: -40px;
        }

        img {
            width: 269px;
            max-width: 100%;
            vertical-align: middle;
        }
    }
}
