import {Component, ElementRef, HostListener, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {CommonConstants, CountryCodes} from '@coreconstant';
import {NgbActiveModal, NgbDate, NgbDateStruct, NgbDatepicker, NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {IMaskModule} from 'angular-imask';
import {lastValueFrom} from 'rxjs';
import {EnquireService} from 'src/app/core/services/api-service/enquire.service';
import {noWhitespaceValidator} from 'src/app/core/services/common-service/common.service';
import {ResponseHandlerService} from 'src/app/core/services/common-service/response-handler.service';
import {RecaptchaModule} from 'ng-recaptcha-2';

@Component({
    selector: 'app-enquire-venue-vendor',
    imports: [NgbDatepickerModule, ReactiveFormsModule, NgSelectModule, IMaskModule, RecaptchaModule],
    templateUrl: './enquire-venue-vendor.component.html',
    styleUrl: './enquire-venue-vendor.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class EnquireVenueVendorComponent {
    enquireVenueVendorForm: FormGroup;
    @Input() owner_id: any;
    selectedDates: NgbDate[] = [];
    modelList: Array<NgbDateStruct> = [];
    formattedSelectedDates = '';
    isDatepickerOpen: boolean = false;
    countryCodes = CountryCodes
    submitted: boolean = false;
    commonConstants = CommonConstants;
    @Input() title: any;
    @Input() venueId: any;
    @Input() vendorId: any;
    @Input() venueName: any;
    @Input() ownerName: any;
    @Input() listingLogo: any;
    minDate: any
    @ViewChild('datepickerContainer', {static: false}) datepickerContainer: ElementRef;

    constructor(private _formBuilder: FormBuilder, private _activeModal: NgbActiveModal, private _responseHandlerService: ResponseHandlerService, private _enquireService: EnquireService, private _eRef: ElementRef) {
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (this.datepickerContainer && !this.datepickerContainer.nativeElement.contains(event.target)) {
            this.isDatepickerOpen = false;
        }
    }

    ngOnInit() {
        this.initializeForm();

        let date = new Date()
        this.minDate = {year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate()}
    }

    initializeForm() {
        this.enquireVenueVendorForm = this._formBuilder.group({
            first_name: [null, [Validators.required, noWhitespaceValidator]],
            dates: [null, [Validators.required]],
            mobile: [null, [Validators.required, Validators.pattern(this.commonConstants.MOBILE_REGEX)]],
            email: [null, [Validators.required, Validators.pattern(this.commonConstants.EMAIL_REGEX)]],
            message: [''],
            countryIdForMobile: [this.commonConstants.AUSTRALIA_COUNTRY_CODE, Validators.required],
            flexible: [false],
            recaptcha: ['', Validators.required],
        });
        this.trackFormControlValueChanged();
    }

    onCaptchaResolved(captchaResponse: string) {
        this.enquireVenueVendorForm.patchValue({ recaptcha: captchaResponse });
    }

    trackFormControlValueChanged() {
        const datesField = this.enquireVenueVendorForm.get('dates');
        this.enquireVenueVendorForm.get('flexible').valueChanges.subscribe(
            (mode) => {
                if (mode == true) {
                    datesField.clearValidators();
                    datesField.reset();
                } else {
                    datesField.setValidators([Validators.required]);
                }
                datesField.updateValueAndValidity();
            });
    }

    toggleDatepicker() {
        this.isDatepickerOpen = !this.isDatepickerOpen;
    }

    closeModal(data) {
        this._activeModal.close(data);
    }

    selectOne(date: NgbDate) {
        const index = this.modelList.findIndex((d: any) => d.equals(date));
        if (index == -1) {
            if (this.modelList?.length < 5) {
                this.modelList.push(date);
            }
        } else {
            this.modelList.splice(index, 1);
        }
        this.updateFormattedDates();
    }

    private updateFormattedDates() {
        const formattedDates = this.modelList
            .map(d => {
                const date = new Date(d.year, d.month - 1, d.day);
                return date.toLocaleString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'});
            })
            .join(', ');

        // Update the form control value
        this.enquireVenueVendorForm.controls['dates'].setValue(formattedDates);
    }

    isSelected(date: NgbDate): boolean {
        return this.modelList.some((d: any) => d.equals(date));
    }

    clearSelection() {
        this.modelList = [];
        this.formattedSelectedDates = '';
        this.enquireVenueVendorForm.controls['dates'].setValue('');
    }

    saveDateSelection() {
        this.isDatepickerOpen = false;
    }

    submit() {
        this.submitted = true;
        if (this.enquireVenueVendorForm.invalid) {
            this.enquireVenueVendorForm.markAllAsTouched();
            this.enquireVenueVendorForm.markAsDirty();
            return
        }

        if (this.title == 'venue-enquire') {
            const flattenedValues = {
                ...this.enquireVenueVendorForm.value,
                ownerId: this.owner_id,
                venue: this.venueId
            }
            let addUpdateRecord = this._enquireService.addVenueEnquire(flattenedValues)
            lastValueFrom(addUpdateRecord).then((response: any) => {
                if (response["status"] == true) {
                    this._responseHandlerService.showSuccessMessageGeneral(response["message"]);
                    this._activeModal.close();
                } else {
                    this._responseHandlerService.showErrorMessageGeneral(response["message"])
                }
            }, (error: any) => {
                this._responseHandlerService.handleAPIErrorResponse(error);
            });
        } else {
            const flattenedValues = {
                ...this.enquireVenueVendorForm.value,
                ownerId: this.owner_id,
                vendor: this.vendorId
            }
            let addUpdateRecord = this._enquireService.addVendorEnquire(flattenedValues)
            lastValueFrom(addUpdateRecord).then((response: any) => {
                if (response["status"] == true) {
                    this._responseHandlerService.showSuccessMessageGeneral(response["message"]);
                    this._activeModal.close();
                } else {
                    this._responseHandlerService.showErrorMessageGeneral(response["message"])
                }
            }, (error: any) => {
                this._responseHandlerService.handleAPIErrorResponse(error);
            });
        }
    }

    setDefaultImage(event: Event) {
        let image = this.commonConstants.DEFAULT_NO_IMAGE;
        (event.target as HTMLImageElement).src = image
    }
}
