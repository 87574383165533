import {Component, EventEmitter, Inject, Input, Output, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonConstants, RoutesConstants } from '@coreconstant';
import { lastValueFrom } from 'rxjs';
import { VenueService } from 'src/app/core/services/api-service/venue.service';
import { ResponseHandlerService } from 'src/app/core/services/common-service/response-handler.service';
import { SeoService } from '../../../core/services/common-service/seo.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'app-venue-map',
    imports: [RouterModule],
    templateUrl: './venue-map.component.html',
    styleUrl: './venue-map.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class VenueMapComponent {

    @Input() slug?: string = '';
    @Output() backToParent = new EventEmitter<void>();

    commonConstants = CommonConstants
    routesConstants = RoutesConstants
    venueDetails: any
    mapUrl: any;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _activatedRoute: ActivatedRoute,
        private _venueService: VenueService,
        private _responseHandlerService: ResponseHandlerService,
        private _seoService: SeoService,
        private _sanitizer: DomSanitizer
    ) {
        this.setMetaTags()
    }

    ngOnInit(): void {
        this.getVenueDetails()
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Wedshed - Venues Accomodations',
            description: 'Wedshed - Venues Accomodations',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.VENUE_NAVIGATION,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    /**
     * Get venue details
     */
    getVenueDetails() {
        var getDetails = this._venueService.getVenueAndImages(this.slug)
        lastValueFrom(getDetails).then((res: any) => {
            if (res["status"]) {
                this.venueDetails = res["data"]

                if (this.venueDetails.addressLatitude && this.venueDetails.addressLongitude) {
                    let url = this.commonConstants.STAY22_MAP_URL + '?aid=' + this.commonConstants.STAY22_URL_AID_PARAMATER + '&lat=' + this.venueDetails.addressLatitude + 'lng=' + this.venueDetails.addressLongitude
                    if (this.venueDetails.venueAddress) {
                        url += '&address=' + this.venueDetails.venueAddress
                    }
                    url += this.commonConstants.STAY22_MAP_CONFIGURATIONS_URL
                    this.mapUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url);
                }
                this.scrollToTop()
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Copy map URL
     */
    copyMapUrl() {
        const input = document.createElement('input');
        input.value = this.commonConstants.GOOGLE_MAP_URL_TO_OPEN + this.venueDetails?.addressLatitude + ',' + this.venueDetails?.addressLongitude
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        this._responseHandlerService.showSuccessMessageGeneral(this.commonConstants.LINK_COPY)
    }

    scrollToTop(){
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    goBack() {
        this.backToParent.emit();
    }
}
