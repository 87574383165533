<section class="single-page-title">
    <div class="container">
        <h1 class="section-title">Privacy Policy</h1>
    </div>
</section>

<section class="single-page-content cms">
    <div class="container">
        <p>This following document sets forth the Privacy Policy for WedShed (<a href="https://www.wedshed.com.au/" target="_blank">https://www.wedshed.com.au</a> and <a href="https://portal.wedshed.com.au/" target="_blank" rel="noopener noreferrer nofollow">https://portal.wedshed.com.au</a>). As a business, WedShed is committed to and bound by the Australian Privacy Principles set out in the Privacy Act 1988 (Commonwealth). We will only operate and collect or use information in accordance with the Act and this Privacy Policy.</p>

        <h2 class="font-22">1. Purpose of Policy</h2>
        <p>The Privacy Policy applies to the collection, use, disclosure and handling of personal information by WedShed. WedShed owns and operates the sites <a href="https://www.wedshed.com.au/" target="_blank">https://www.wedshed.com.au</a> and <a href="https://portal.wedshed.com.au/" target="_blank" rel="noopener noreferrer nofollow">https://portal.wedshed.com.au</a>; WedShed is an online platform where users can view information and directly contact the venue’s property owner/manager/lessee or business owner to arrange a wedding. The term ‘users’ or ‘you’ is used in this Privacy Policy to refer to those individuals who engage or use WedShed (‘we’ or ‘us’). By providing information to us when subscribing, sending enquiries or otherwise using the site, you expressly agree to this Privacy Policy.</p>

        <h2 class="font-22">2. Collection of your personal information</h2>
        <p>The type of information that we collect and hold about you depends on the type of dealings you have with us. For example, when you fill out a contact form, we need to know your name and email address. If you are a venue owner or business and are listing a venue or business on WedShed, we need to know your contact details, the address of the venue or business as well as credit card information which is handled and protected by Stripe.</p>

        <h2 class="font-22">3. How do we collect personal information?</h2>
        <p class="mb-12">We collect personal information in a lawful way that complies with the Privacy Act.</p>
        <p class="mb-12"><strong>Information may be collected from you by:</strong></p>
        <ul>
            <li>Direct email – if you have correspondence with us (WedShed) or any of our partnering venues</li>
            <li>Over the telephone</li>
            <li>Through written communications</li>
            <li>When you complete forms or submit information on our site</li>
            <li>From third parties, such as outlets that choose to advertise with</li>
            <li>Our social networks (Facebook, Instagram, Twitter, Pinterest)</li>
            <li>From our own records, of your use of our service</li>
        </ul>
        <p>While we do not use cookies on our site, third parties that we contract with and partnering venues may use cookies on their separate sites.</p>

        <h2 class="font-22">4. Why do we collect, hold, use and/or disclose your personal information</h2>
        <p class="mb-12"><strong>The main purposes for which we would collect, hold, use and/or disclose personal information are:</strong></p>

        <ul>
            <li>To identify you and verify your identity</li>
            <li>To communicate with you about our business</li>
            <li>To contact you occasionally with critical user information or service updates</li>
            <li>To assist our partners that you are dealing with through WedShed. For example, we need to pass on personal information from users, such as contact details, to venue owners/managers/lessees so that you are able to book/arrange property viewings/communicate</li>
            <li>To customise, measure and improve our services, content and advertising</li>
            <li>To tailor the site based on demographic and consumer trends</li>
            <li>To prevent activity we determine to be potentially unlawful, illegal or harmful and to enforce our Privacy Policy and Term and Conditions</li>
        </ul>

        <p>If you choose to send a message and contact one of our partnering venues on the site, your personal information, including your email address and any other information you supply will be visible to that partnering venue or business so that they can reply directly to you. Enquiries sent via the site are also available for review to a limited number of people at WedShed.</p>
        <p>We may de-identify and aggregate your personal information and of others for our own statistical purposes. Provided that it remains de-identified (i.e. anonymous), we may disclose that aggregated information to third parties or use it for marketing or research purposes.</p>

        <h2 class="font-22">5. Who do we disclose your personal information with?</h2>
        <p>We may disclose your personal information or data to enforce our policies or where we are permitted to do so by applicable law, such as in response to a valid legally-compliant request by a law enforcement or governmental authority, or in connection with actual or proposed litigation, or to protect our property, people and other rights of interest.</p>

        <p class="mb-12"><strong>We may also share your information with:</strong></p>

        <ul>
            <li>Venue owners/managers/lessees that you have opted to contact</li>
            <li>Any third party you have asked us to share your personal data with – such as Facebook if you have asked us to connect with your Facebook account</li>
            <li>Any contracted services providers we engage, including/;</li>
            <li>Information technology services</li>
            <li>Marketing and communications agencies</li>
            <li>Printers and distributors or direct marketing materials</li>
            <li>External business advisors such as auditor, lawyers</li>
        </ul>

        <h2 class="font-22">6. Accessing your personal information</h2>
        <p>You have a right to access your personal information, subject to exceptions allowed by law. If you would like to do so, please let us know. You may be required to put your request in writing for security purposes.</p>

        <h2 class="font-22">7. Website security</h2>
        <p class="mb-12"><strong>WedShed implements measures to protect the personal information that we hold, including:</strong></p>
        <ul>
            <li>Mandatory password protection on all computers (changed regularly)</li>
            <li>Secure hard copy document, electronic storage and hardware disposal procedures</li>
            <li>Firewall and antivirus/malware software</li>
            <li>Training and internal policies</li>
        </ul>

        <p>While no system is completely secure, we believe the measures implemented by the site reduce our vulnerability to security problems to a level appropriate to the type of data involved.</p>

        <h2 class="font-22">8. Changes to this Privacy Policy</h2>
        <p>We may make changes to this Privacy Policy at any time and without prior notice at our sole discretion, in alignment with the Privacy Act. Any new policy will automatically be effective when it is published on the site. We encourage you to return here regularly to view our most up-to-date Privacy Policy.</p>

        <h2 class="font-22">9. Contact</h2>
        <p>If you have any questions or concerns about our collection, use or disclosure of personal information, or feel we have not complied with this Privacy Policy please contact us: <a href="mailto:hello@wedshed.com.au">hello&#64;wedshed.com.au</a></p>

    </div>
</section>
