.breadcrumb-wrap {
    font-family: Aeonik, sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    padding: 13px 0;
    color: #423B5E;

    @media screen and (max-width: 767px) {
        display: none;
    }

    .container {
        padding-left: 80px;
        padding-right: 80px;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        gap: 10px;
        align-items: center;
        display: flex;

        li {
            display: inline-flex;
        }
    }

    a {
        font-weight: 500;
        color: #272338;

        &:hover {
            color: #7093FF;
        }
    }
}

.details-head-wrap {
    padding: 16px 0 32px;
    border-bottom: 1px solid #272338;

    @media screen and (max-width: 991px) {
        padding: 8px 0 24px;
    }

    @media screen and (max-width: 767px) {
        padding-top: 0;
    }

    .container {
        padding-left: 80px;
        padding-right: 80px;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @media screen and (max-width: 767px) {
            gap: 24px;
            flex-direction: column-reverse;
            display: flex;
        }
    }

    .details-head-info {
        margin: 0 0 24px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 991px) {
            margin-bottom: 16px;
        }

        @media screen and (max-width: 767px) {
            margin-bottom: 0;
        }
    }

    .details-head-info-left {
        .section-title {
            gap: 16px;
            flex-wrap: wrap;
            align-items: center;
            display: flex;

            @media screen and (max-width: 480px) {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 12px;
            }

            .featured-label {
                font-family: Aeonik, sans-serif;
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
                text-align: center;
                color: #fff;
                background: #661735;
                border: 1px solid #272338;
                padding: 4px 10px;
                border-radius: 30px;
                margin: 0;
                vertical-align: middle;
            }
        }

        ul {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #423B5E;

            li {
                margin-bottom: 4px;
                padding: 0 0 0 28px;
                position: relative;
                min-height: 24px;
                display: flex;
                align-items: center;

                &:last-child {
                    margin-bottom: 0;
                }

                > img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .location-link {
                    color: #423B5E;
                    cursor: pointer;
                    position: relative;
                    display: inline-block;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 17px;
                        bottom: 0;
                        width: 0;
                        height: 12px;
                        margin: auto;
                        background-image: url(/assets/images/arrow-right-icon.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        transition: all 0.35s ease 0s;
                        opacity: 0;
                        display: inline-flex;
                        visibility: hidden;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        border-bottom: 1px solid #272338;
                        transition: all 0.35s ease 0s;
                        width: 0;
                    }

                    &:hover {
                        font-weight: 500;
                        color: #272338;

                        &:after {
                            opacity: 1;
                            visibility: visible;
                            right: -30px;
                            width: 17px;
                        }

                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .details-head-info-action {
        gap: 8px;
        align-items: flex-start;
        display: inline-flex;

        @media screen and (max-width: 767px) {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 13px 16px;
            background: #fff;
            z-index: 9;
            border-top: 1px solid #272338;
            border-bottom: 1px solid #272338;
            box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
            align-items: center;
        }

        .primary-btn {
            font-size: 14px;
            line-height: 18px;
            padding: 10px 16px;
            gap: 8px;
            white-space: nowrap;

            @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 20px;
                padding: 11px 16px;
                margin-left: auto;
            }

            @media screen and (max-width: 480px) {
                flex: 1;
                padding: 11px 10px;
            }
            @media screen and (max-width: 359px) {
                font-size: 14px;
                padding: 8px 10px;
            }
        }

        .secondary-btn {
            font-size: 14px;
            line-height: 18px;
            padding: 10px 16px;
            gap: 8px;

            @media screen and (max-width: 767px) {
                min-width: 44px;
                width: 44px;
                height: 44px;
                padding: 0;
                border-radius: 40px;
                align-items: center;
                justify-content: center;

                span {
                    display: none;
                }

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            @media screen and (max-width: 359px) {
                min-width: 38px;
                width: 38px;
                height: 38px;
            }

            &.icon-btn {
                min-width: 40px;
                width: 40px;
                height: 40px;
                padding: 0;
                border-radius: 40px;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 767px) {
                    min-width: 44px;
                    width: 44px;
                    height: 44px;
                }

                @media screen and (max-width: 359px) {
                    min-width: 38px;
                    width: 38px;
                    height: 38px;
                }

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            &.saved {
                svg {
                    fill: #272338;
                }
            }
        }
    }

    .details-top-gallery {
        position: relative;
        gap: 16px;
        display: flex;

        @media screen and (max-width: 991px) {
            gap: 8px;
        }

        @media screen and (max-width: 767px) {
            margin: 0 -16px;
        }

        .mob-back-to-list {
            position: absolute;
            top: 16px;
            left: 16px;
            z-index: 1;
            margin: 0;
            padding: 0;
            border: none;
            background: transparent;
            border-radius: 0;
            outline: none;
            box-shadow: none;
            cursor: pointer;
            display: none;

            @media screen and (max-width: 767px) {
                display: inline-flex;
            }
        }

        .secondary-btn {
            position: absolute;
            right: 14px;
            bottom: 14px;
            padding: 9px 16px;
            gap: 8px;
            z-index: 1;
            font-size: 14px;
            line-height: 20px;

            .mob-img-counter {
                display: none;
            }

            @media screen and (max-width: 991px) {
                right: 10px;
                bottom: 10px;
                border-radius: 30px;
                background: rgba(255, 255, 255, 0.88);
                padding: 6px 10px;
                gap: 4px;
            }

            @media screen and (max-width: 767px) {
                right: 16px;
                bottom: 16px;
                border-radius: 30px;
                background: rgba(255, 255, 255, 0.8);
                padding: 4px 12px;
                font-size: 12px;
                line-height: 16px;

                span,
                img {
                    display: none;
                }

                .mob-img-counter {
                    display: inline-block;
                }
            }
        }

        .details-gallery-img {
            position: relative;
            padding-bottom: 80%;

            @media screen and (max-width: 767px) {
                padding-bottom: 100%;
            }

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                object-fit: cover;
                object-position: center center;
                width: 100%;
                height: 100%;
            }
        }

        .details-gallery-left {
            flex: 1;
            border-radius: 16px 0 0 16px;
            border: 1px solid #272338;
            overflow: hidden;

            img {
                border-radius: 16px 0 0 16px;
            }

            @media screen and (max-width: 767px) {
                border-radius: 0;
                border: none;
                border-bottom: 1px solid #272338;

                img {
                    border-radius: 0;
                }
            }
        }

        .details-gallery-right {
            width: calc(50% - 32px);

            @media screen and (max-width: 767px) {
                display: none;
            }

            .details-gallery-img {
                padding-bottom: 85.9%;
                height: 100%;
            }

            ul {
                gap: 16px;
                height: 100%;
                //align-items: flex-start;
                flex-wrap: wrap;
                display: flex;

                @media screen and (max-width: 991px) {
                    gap: 8px;
                }

                li {
                    width: calc(50% - 8px);
                    position: relative;
                    border: 1px solid #272338;
                    overflow: hidden;

                    @media screen and (max-width: 991px) {
                        width: calc(50% - 4px);
                    }

                    .video-icon {
                        background: rgba(0, 0, 0, 0.2);
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1;
                    }

                    &:nth-child(2) {
                        border-radius: 0 16px 0 0;

                        img {
                            border-radius: 0 16px 0 0;
                        }

                        .video-icon {
                            border-radius: 0 16px 0 0;
                        }
                    }

                    &:nth-child(4) {
                        border-radius: 0 0 16px 0;

                        img {
                            border-radius: 0 0 16px 0;
                        }
                    }
                }
            }
        }
    }
}

.details-content-wrap {
    padding: 32px 0 48px;
    word-break: break-word;

    @media screen and (max-width: 991px) {
        padding: 24px 0 32px;
    }

    @media screen and (max-width: 767px) {
        padding: 0 0 16px;
    }

    .container {
        padding-left: 156px;
        padding-right: 156px;
        gap: 32px 48px;
        align-items: flex-start;
        display: flex;

        @media screen and (max-width: 1360px) {
            padding-left: 116px;
            padding-right: 116px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 92px;
            padding-right: 92px;
        }

        @media screen and (max-width: 1200px) {
            padding-left: 40px;
            padding-right: 40px;
            gap: 32px 32px;
        }

        @media screen and (max-width: 1100px) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @media screen and (max-width: 991px) {
            gap: 32px 24px;
        }

        @media screen and (max-width: 767px) {
            flex-direction: column-reverse;
            padding-left: 0;
            padding-right: 0;
            gap: 24px;
        }
    }

    .details-section-title {
        font-family: Aeonik, sans-serif;
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #272338;
        margin: 0 0 8px;
    }

    .details-left-panel {
        flex: 1;
        word-break: break-word;

        @media screen and (max-width: 767px) {
            width: 100%;
            flex: 0 0 100%;
            padding: 0 16px;
        }

        .user-review-box {
            background: #FFF5EE;
            margin: 0 0 32px;
            padding: 15px 24px;
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            color: #272338;
            border: 1px solid #272338;
            border-radius: 16px;
            width: 520px;
            max-width: 100%;
            gap: 4px 16px;
            align-items: center;
            display: flex;
            position: relative;

            @media screen and (max-width: 991px) {
                width: 100%;
                margin: 0 0 24px;
            }

            @media screen and (max-width: 480px) {
                padding: 15px 24px 15px 82px;
                align-items: flex-start;
                flex-direction: column;
            }

            .user-review-info {
                gap: 16px;
                align-items: center;
                flex: 1;
                display: flex;

                img {
                    width: 42px;
                    min-width: 42px;
                    height: 42px;
                    border: 1px solid #272338;
                    border-radius: 42px;
                    object-fit: cover;
                    object-position: center;

                    @media screen and (max-width: 480px) {
                        position: absolute;
                        left: 24px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }

                .approved-review {
                    margin: 4px 0 0;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 500;
                    color: #661735;
                    text-transform: uppercase;
                    gap: 2px;
                    align-items: center;
                    display: flex;
                }
            }

            .user-reviews {
                display: inline-flex;
                align-items: center;
                white-space: nowrap;
                gap: 8px;
            }

            .more-review {
                font-family: Aeonik, sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
                color: #E2763A;
                margin: 0;
                padding: 1px 0 0;
                background: transparent;
                border: none;
                border-bottom: 1px solid #E2763A;
                border-radius: 0;
                outline: none;
                cursor: pointer;
                box-shadow: none;
                text-decoration: none;
                transition: all 0.35s ease 0s;

                &:hover {
                    color: #7093FF;
                    border-bottom-color: transparent;
                }
            }
        }

        .details-content-box {
            margin: 0 0 32px;
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #4D4D4D;

            @media screen and (max-width: 991px) {
                margin-bottom: 24px;
            }

            @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 22px;
            }

            p {
                margin: 0 0 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .details-content-desc {
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
                overflow: hidden;

                &.show-more-desc {
                    display: block;
                }

                ::ng-deep {
                    h2, h3, h4, h5, h6 {
                        font-family: Livorno, serif;
                        font-weight: 400;
                        color: #272338;
                        letter-spacing: -0.01em;
                        margin: 0 0 12px;

                        * {
                            font-family: Livorno, serif;
                        }

                        a {
                            font-weight: normal;
                        }

                        span, strong, b {
                            font-family: Livorno, serif;
                            font-weight: normal;
                        }
                    }

                    h2 {
                        font-size: 22px;
                        line-height: 28px;
                    }

                    h3 {
                        font-size: 18px;
                        line-height: 24px;
                    }

                    h4 {
                        font-size: 16px;
                        line-height: 22px;
                    }

                    a {
                        color: #7093FF;
                        transition: all 0.35s ease 0s;

                        &:hover {
                            color: #272338;
                        }
                    }

                    strong, b {
                        font-weight: 500;
                        color: #272338;
                    }

                    p {
                        margin: 0 0 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:empty {
                            display: none;
                        }
                    }

                    ul, ol {
                        margin: 0 0 24px;
                        padding: 0;
                        list-style-type: none;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        > li {
                            position: relative;
                            margin-bottom: 12px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            p {
                                margin-bottom: 12px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }

                            ol, ul {
                                margin: 12px 0;
                            }
                        }
                    }

                    ul {
                        > li {
                            padding-left: 26px;

                            &::before {
                                content: "";
                                position: absolute;
                                top: 5px;
                                left: 2px;
                                width: 12px;
                                height: 12px;
                                display: inline-block;
                                background: #272338;
                                transform: rotate(45deg);
                                border-radius: 2px;
                            }

                            &::after {
                                content: "";
                                position: absolute;
                                top: 8px;
                                left: 5px;
                                width: 6px;
                                height: 6px;
                                border-radius: 10px;
                                background: #FFFBF8;
                                display: inline-block;
                            }
                        }
                    }

                    ol {
                        counter-reset: li;

                        > li {
                            padding-left: 32px;

                            &::before {
                                content: "";
                                position: absolute;
                                top: 4px;
                                left: 2px;
                                width: 16px;
                                height: 16px;
                                display: inline-block;
                                background: #272338;
                                transform: rotate(45deg);
                                border-radius: 2px;
                                pointer-events: none;
                            }

                            &::after {
                                content: counter(li);
                                counter-increment: li;
                                position: absolute;
                                top: 6px;
                                left: 2px;
                                width: 16px;
                                display: inline-block;
                                font-size: 11px;
                                line-height: 12px;
                                font-weight: 500;
                                color: #fff;
                                text-align: center;
                                height: auto;
                                background: transparent;
                                border-radius: 0;
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }

        .read-more-btn {
            margin-top: 8px;
            padding: 0;
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #7093FF;
            background: transparent;
            border: none;
            box-shadow: none;
            outline: none;
            gap: 4px;
            align-items: center;
            display: inline-flex;
            transition: all 0.35s ease 0s;

            @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 22px;

                svg {
                    width: 32px;
                    height: 32px;
                }
            }

            svg {
                transition: all 0.35s ease 0s;

                path {
                    transition: all 0.35s ease 0s;
                }
            }

            &:hover {
                color: #661735;

                svg {
                    path {
                        stroke: #661735;
                    }
                }
            }

            &.read-less {
                svg {
                    transform: rotateX(180deg);
                }
            }
        }

        .pricing-inclusions {
            margin: 0 0 32px;

            @media screen and (max-width: 991px) {
                margin-bottom: 24px;
            }

            .pricing-lock-box {
                padding: 20px 16px;
                background: rgba(255, 245, 238, 0.8);
                backdrop-filter: blur(10px);
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                text-align: center;
                color: #423B5E;
                border: 1px solid #272338;
                border-radius: 16px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                display: flex;

                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 22px;
                    padding: 16px 24px;
                }

                @media screen and (max-width: 480px) {
                    min-height: 349px;
                }

                p {
                    margin: 0 auto;
                    max-width: 377px;
                }

                .primary-btn {
                    padding: 9px 16px;
                    font-size: 14px;
                    line-height: 20px;
                    margin: 16px 0 0;

                    @media screen and (max-width: 767px) {
                        padding: 11px 24px;
                        font-size: 16px;
                        line-height: 20px;
                        background: #FFFFFF;
                        color: #272338;

                        &:hover {
                            background: #A9BEFF;
                            color: #272338;
                            border-color: #272338;
                        }
                    }
                }

                + .pricing-inclusions-info {
                    margin-top: 16px;
                }
            }

            .pricing-inclusions-info {
                background: #FFF5EE;
                padding: 24px;
                border: 1px solid #272338;
                border-radius: 16px;
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #423B5E;

                @media screen and (max-width: 991px) {
                    padding: 20px;
                }

                @media screen and (max-width: 767px) {
                    padding: 16px;
                    font-size: 16px;
                    line-height: 22px;
                }

                label,
                h3 {
                    font-family: Aeonik, sans-serif;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500;
                    color: #272338;
                    margin: 0;
                }

                h3 {
                    margin-bottom: 12px;

                    @media screen and (max-width: 767px) {
                        margin-bottom: 16px;
                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                .details-section-title {
                    font-size: 18px;
                    line-height: 25px;
                    margin-bottom: 16px;
                }

                p {
                    margin: 0 0 4px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                ul {
                    display: flex;
                    gap: 16px 12px;
                    flex-wrap: wrap;
                    margin: 0 0 24px;
                    padding: 0 0 24px;
                    border-bottom: 1px solid #272338;

                    @media screen and (max-width: 991px) {
                        margin: 0 0 16px;
                        padding: 0 0 16px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: none;
                    }

                    li {
                        width: calc(50% - 6px);
                        display: inline-flex;
                        gap: 4px 16px;

                        @media screen and (max-width: 991px) {
                            width: 100%;
                        }

                        @media screen and (max-width: 480px) {
                            flex-wrap: wrap;
                        }

                        label {
                            width: 127px;
                            min-width: 127px;

                            @media screen and (max-width: 991px) {
                                width: 150px;
                                min-width: 150px;
                            }

                            @media screen and (max-width: 480px) {
                                width: 100%;
                                min-width: 100%;
                            }
                        }

                        &:nth-child(2n) {
                            label {
                                width: 150px;
                                min-width: 150px;

                                @media screen and (max-width: 480px) {
                                    width: 100%;
                                    min-width: 100%;
                                }
                            }
                        }

                        p {
                            margin: 0;
                        }
                    }
                }

                strong {
                    color: #272338;
                    font-weight: 500;
                    display: inline-block;
                }

                + .pricing-inclusions-info {
                    margin-top: 16px;
                }

                &.pricing-inclusions-features {
                    margin-top: 16px;

                    ul {
                        gap: 8px 12px;

                        @media screen and (max-width: 767px) {
                            gap: 16px 12px;
                        }

                        li {
                            gap: 4px;
                            align-items: center;
                            position: relative;
                            min-height: 24px;
                            padding-left: 28px;
                            position: relative;

                            > img {
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }

        }

        .chit-chat-wrap {
            margin: 0 0 32px;
            @media screen and (max-width: 991px) {
                margin-bottom: 24px;
            }

            .chit-chat-box {
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                background: #FFF5EE;
                border: 1px solid #272338;
                border-radius: 16px;
                padding: 24px;
                max-width: 634px;

                @media screen and (max-width: 991px) {
                    padding: 20px;
                }
                @media screen and (max-width: 767px) {
                    padding: 16px;
                    font-size: 16px;
                    line-height: 22px;
                    max-width: 100%;
                }

                ul {
                    li {
                        margin: 0 0 16px;
                        padding: 0 9px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .chit-chat-item {
                    color: #fff;
                    background: #272338;
                    border-radius: 16px;
                    border: 1px solid #272338;
                    padding: 12px 18px;
                    position: relative;
                    width: 284px;
                    max-width: calc(100% - 26px);
                    text-align: left;
                    display: inline-block;
                    vertical-align: middle;

                    @media screen and (max-width: 767px) {
                        padding: 10px 16px;
                    }

                    svg {
                        position: absolute;
                        bottom: 5px;
                        left: -10px;
                        pointer-events: none;
                    }
                }

                .chit-chat-send {
                    text-align: right;

                    .chit-chat-item {
                        background: #EFB7A6;
                        color: #272338;
                        width: 356px;
                        max-width: calc(100% - 16px);

                        svg {
                            position: absolute;
                            right: -12px;
                            bottom: 5px;
                            left: auto;
                        }
                    }
                }
            }
        }

        .facilities-and-amenities {
            padding: 32px 0;
            margin: 0 0 32px;
            border-top: 1px solid #E6E6E6;
            border-bottom: 1px solid #E6E6E6;

            + .location-and-accommodation {
                padding-top: 0;
                border-top: none;
            }

            @media screen and (max-width: 991px) {
                padding: 24px 0;
                margin-bottom: 24px;
            }

            .details-section-title {
                margin: 0 0 16px;
            }

            .read-more-btn {
                display: none;
                margin: 16px 0 0;

                @media screen and (max-width: 767px) {
                    display: inline-flex;
                }
            }

            .facilities-and-amenities-list {
                margin-bottom: 32px;

                @media screen and (max-width: 991px) {
                    margin-bottom: 24px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                li {
                    width: calc(25% - 6px);
                    font-family: Aeonik, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    color: #4D4D4D;
                    gap: 4px;
                    align-items: center;
                    display: flex;

                    @media screen and (max-width: 991px) {
                        width: calc(33.33% - 6px);
                    }

                    @media screen and (max-width: 767px) {
                        font-size: 16px;
                        line-height: 22px;
                        gap: 8px;
                    }

                    @media screen and (max-width: 480px) {
                        width: 100%;
                    }

                    img {
                        min-width: 24px;
                        width: 24px;
                    }

                    &.disabled {
                        color: #999999;
                        text-decoration: line-through;

                        img {
                            filter: grayscale(1);
                            opacity: 0.9;
                        }
                    }

                }
            }
        }

        .location-and-accommodation {
            padding-top: 32px;
            border-top: 1px solid #E6E6E6;
            margin: 0 0 16px;

            @media screen and (max-width: 991px) {
                padding-top: 24px;
            }

            .accommodation-header {
                margin: 0 0 16px;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                display: flex;

                @media screen and (max-width: 991px) {
                    flex-wrap: wrap;
                }

                .details-section-title {
                    margin: 0;
                }

                .accommodation-header-action {
                    gap: 8px;
                    display: flex;

                    @media screen and (max-width: 480px) {
                        flex-direction: column;
                        width: 100%;
                    }

                    .secondary-btn {
                        gap: 8px;
                        padding: 9px 16px;
                        font-size: 14px;
                        line-height: 20px;

                        @media screen and (max-width: 1200px) {
                            padding: 9px 12px;
                        }

                        @media screen and (max-width: 991px) {
                            padding: 9px 16px;
                        }

                        @media screen and (max-width: 767px) {
                            padding: 11px 16px;
                            font-size: 16px;
                        }
                    }
                }
            }

            .location-temp-box {
                background: #272338;
                border-radius: 16px;
                padding: 32px 40px;
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: #CCCCCC;
                gap: 24px;
                justify-content: space-between;
                align-items: center;
                display: flex;

                @media screen and (max-width: 991px) {
                    padding: 24px 20px;
                    gap: 20px;
                }

                @media screen and (max-width: 767px) {
                    padding: 24px;
                }

                @media screen and (max-width: 480px) {
                    flex-direction: column;
                    gap: 24px;
                    font-size: 16px;
                    line-height: 22px;
                }

                p {
                    margin: 0;
                }

                .location-temp-info {
                    position: relative;
                    padding-left: 90px;
                    min-height: 74px;
                    display: inline-flex;
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: center;
                    gap: 6px;
                    width: 50%;

                    @media screen and (max-width: 991px) {
                        padding-left: 82px;
                        min-height: 70px;
                        width: 56%;
                    }

                    @media screen and (max-width: 480px) {
                        padding-left: 90px;
                        min-height: 74px;
                        width: 100%;
                    }

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;

                        @media screen and (max-width: 991px) {
                            width: 70px;
                            height: 70px;
                        }

                        @media screen and (max-width: 480px) {
                            width: 74px;
                            height: 74px;
                        }
                    }


                    .location-temp {
                        font-family: Livorno, serif;
                        font-size: 40px;
                        line-height: 48px;
                        font-weight: 400;
                        letter-spacing: -0.01em;
                        color: #fff;
                        white-space: nowrap;

                        @media screen and (max-width: 991px) {
                            font-size: 34px;
                            line-height: 40px;
                        }

                        @media screen and (max-width: 480px) {
                            font-size: 40px;
                            line-height: 48px;
                        }
                    }
                }

                .location-climate {
                    width: 50%;
                    color: #E6E6E6;

                    @media screen and (max-width: 991px) {
                        width: 46%;
                    }

                    @media screen and (max-width: 480px) {
                        width: 100%;
                    }

                    label {
                        display: block;
                        font-family: Aeonik, sans-serif;
                        font-size: 12px;
                        line-height: 14px;
                        font-weight: 400;
                        margin: 0 0 4px;
                        text-transform: uppercase;
                        color: #CCCCCC;

                        @media screen and (max-width: 480px) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }

        .details-map-box {
            border: 1px solid #272338;
            border-radius: 16px;
            overflow: hidden;
            margin-bottom: 32px;
            height: 468px;

            &:last-child {
                margin-bottom: 0;
            }

            iframe, img {
                border: none;
                outline: none;
                vertical-align: middle;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            @media screen and (max-width: 991px) {
                margin-bottom: 24px;
            }

            @media screen and (max-width: 767px) {
                margin: 0 -16px;
                border-radius: 0;
                border-left: none;
                border-right: none;
            }

            @media screen and (max-width: 480px) {
                height: calc(100dvh - 118px);
            }

        }

        .details-featured-in {
            padding-top: 32px;
            border-top: 1px solid #E6E6E6;

            @media screen and (max-width: 991px) {
                padding-top: 24px;
            }

            @media screen and (max-width: 767px) {
                border-top: none;
            }

            .details-section-title {
                margin-bottom: 10px;
            }

            .card-list {
                grid-template-columns: repeat(2, 1fr);

                @media screen and (max-width: 991px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                @media screen and (max-width: 767px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media screen and (max-width: 480px) {
                    grid-template-columns: repeat(1, 1fr);
                }
            }

            .read-more-btn {
                margin-top: 10px;
            }
        }

        //end
    }

    .details-right-panel {
        min-width: 307px;
        width: 307px;
        position: sticky;
        top: 96px;

        @media screen and (max-width: 991px) {
            top: 82px;
        }

        @media screen and (max-width: 767px) {
            top: auto;
            position: initial;
        }

        @media screen and (max-width: 991px) {
            min-width: 260px;
            width: 260px;
        }

        @media screen and (max-width: 767px) {
            width: 100%;
        }

        .details-quick-info {
            border-radius: 16px;
            border: 1px solid #272338;
            background: #FFF5EE;
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #272338;

            @media screen and (max-width: 767px) {
                border-radius: 0;
                border: none;
            }

            p {
                margin-bottom: 0;
            }

            .wedshed-approved {
                padding: 20px 24px 20px 105px;
                border-bottom: 1px solid #272338;
                position: relative;
                min-height: 75px;
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: #000000;
                align-items: center;
                display: flex;

                @media screen and (max-width: 991px) {
                    padding: 20px 20px 20px 90px;
                }

                @media screen and (max-width: 767px) {
                    padding: 20px 24px 20px 108px;
                    font-size: 16px;
                    line-height: 22px;
                    min-height: 90px;
                }

                > img {
                    position: absolute;
                    top: 0;
                    left: 29px;

                    @media screen and (max-width: 991px) {
                        left: 20px;
                    }

                    @media screen and (max-width: 767px) {
                        top: -2px;
                        left: 27px;
                        width: 64px;
                        height: 76px;
                    }
                }

                .info-tooltip {
                    display: inline-flex;
                    vertical-align: -3px;
                    margin-left: 5px;
                    cursor: pointer;
                    position: relative;

                    @media screen and (max-width: 480px) {
                        position: initial;
                    }

                    .info-tooltip-box {
                        display: none;
                        position: absolute;
                        top: calc(100% + 10px);
                        left: -40px;
                        border-radius: 16px;
                        padding: 16px;
                        background: #272338;
                        font-family: Aeonik, sans-serif;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 400;
                        color: #FFFFFF;
                        width: 344px;
                        z-index: 999;

                        @media screen and (max-width: 1520px) {
                            left: auto;
                            right: -40px;
                        }

                        @media screen and (max-width: 480px) {
                            top: calc(100% - 16px);
                            left: 10px;
                            right: 10px;
                            width: auto;
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }

                    &:hover {
                        .info-tooltip-box {
                            display: block;
                        }
                    }
                }

            }

            .pricing-info {
                padding: 25px 24px 25px 105px;
                border-bottom: 1px solid #272338;
                position: relative;
                min-height: 94px;
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: #272338;
                align-items: center;
                display: flex;

                @media screen and (max-width: 991px) {
                    padding: 10px 20px 10px 90px;
                }

                @media screen and (max-width: 767px) {
                    padding: 10px 24px 10px 108px;
                    font-size: 16px;
                    line-height: 22px;
                }

                .price-icon {
                    width: 80px;
                    height: 80px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    left: 22px;
                    bottom: 0;
                    margin: auto;

                    @media screen and (max-width: 991px) {
                        left: 8px;
                    }

                    @media screen and (max-width: 767px) {
                        left: 24px;
                    }
                }

                a, strong {
                    font-weight: 500;
                    color: #7093FF;
                    display: inline-block;
                    cursor: pointer;
                    transition: all 0.35s ease 0s;

                    &:hover {
                        color: #272338;
                    }
                }

                .pricing-from {
                    font-family: Livorno, sans-serif;
                    font-size: 40px;
                    line-height: 48px;
                    font-weight: 400;
                    letter-spacing: -0.01em;
                    color: #272338;

                    @media screen and (max-width: 991px) {
                        font-size: 32px;
                        line-height: 38px;
                    }

                    @media screen and (max-width: 767px) {
                        font-size: 40px;
                        line-height: 48px;
                    }

                    label {
                        font-family: Aeonik, sans-serif;
                        font-size: 12px;
                        line-height: 14px;
                        font-weight: 500;
                        color: #423B5E;
                        display: block;

                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                            line-height: 16px;
                            margin: 0 0 8px;
                        }
                    }
                }
            }

            .details-category-list {
                padding: 16px 24px;
                border-bottom: 1px solid #272338;
                gap: 8px;
                flex-wrap: wrap;
                display: flex;

                @media screen and (max-width: 991px) {
                    padding: 16px 20px;
                }

                @media screen and (max-width: 767px) {
                    padding: 16px 24px;
                }

                label {
                    font-family: Aeonik, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                    text-align: center;
                    color: #272338;
                    border-radius: 24px;
                    padding: 6px 12px;
                    border: 1px solid #272338;
                    cursor: pointer;
                    transition: all 0.35s ease 0s;
                    background: #fff;
                    margin: 0;

                    &:hover {
                        //background: #A9BEFF;
                        background: #FFE6D5;
                    }
                }
            }

            .details-features-info {
                padding: 16px 24px;
                border-bottom: 1px solid #272338;

                @media screen and (max-width: 991px) {
                    padding: 16px 12px;
                }

                @media screen and (max-width: 767px) {
                    padding: 16px 24px;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 28px 8px;

                    @media screen and (max-width: 991px) {
                        gap: 16px 8px;
                    }

                    @media screen and (max-width: 767px) {
                        gap: 28px 8px;
                    }

                    li {
                        width: calc(50% - 4px);
                        font-family: Aeonik, sans-serif;
                        font-size: 18px;
                        line-height: 25px;
                        font-weight: 500;
                        color: #272338;
                        white-space: nowrap;
                        align-items: center;
                        gap: 4px;
                        display: flex;

                        @media screen and (max-width: 991px) {
                            gap: 0;
                        }

                        @media screen and (max-width: 767px) {
                            gap: 10px;
                        }

                        > img {
                            min-width: 52px;
                            width: 52px;
                            height: 52px;
                            object-fit: contain;

                            @media screen and (max-width: 991px) {
                                min-width: 44px;
                                width: 44px;
                                height: 44px;
                            }

                            @media screen and (max-width: 767px) {
                                min-width: 52px;
                                width: 52px;
                                height: 52px;
                            }
                        }

                        label {
                            font-family: Aeonik, sans-serif;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 400;
                            color: #423B5E;
                            display: block;
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .quick-info-action {
                padding: 24px;
                color: #4D4D4D;

                @media screen and (max-width: 991px) {
                    padding: 20px;
                }

                @media screen and (max-width: 767px) {
                    display: none;
                }

                .primary-btn {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }

        .attention-box {
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            text-align: center;
            color: #fff;
            background: #272338;
            position: relative;
            border-radius: 16px;
            padding: 10px 16px;
            margin: 16px 0 0;
            @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 22px;
                border-radius: 0;
                margin-top: 0;
                overflow: hidden;
            }

            .little-star-icon {
                position: absolute;
                top: -8px;
                left: -12px;
                pointer-events: none;
                @media screen and (max-width: 767px) {
                    top: 2px;
                    left: 2px;
                }
            }

            .little-two-star-icon {
                position: absolute;
                right: -14px;
                bottom: -17px;
                pointer-events: none;
                @media screen and (max-width: 767px) {
                    right: 2px;
                    bottom: 2px;
                }
            }
        }
    }
}


//======================== Venue Articles Section Css Start ========================
.vendors-who-service {
    background: #FFE6D5;
    border-top: 1px solid #272338;
    padding: 40px 0 32px;

    @media screen and (max-width: 767px) {
        padding: 24px 0;
    }

    &.dusty-bg {
        background: #EFB7A6;
    }

    .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        margin: 0 0 18px;
        flex-wrap: wrap;

        .section-semi-title {
            font-size: 32px;
            line-height: 38px;
            margin: 0;

            @media screen and (max-width: 767px) {
                font-size: 26px;
                line-height: 31px;
            }
        }

        .secondary-btn {
            gap: 8px;

            @media screen and (min-width: 768px) {
                padding: 9px 16px;
                font-size: 14px;
                line-height: 20px;
            }

            @media screen and (max-width: 480px) {
                width: 100%;
            }

            img {
                position: relative;
                right: 0;
                transition: all 0.35s ease 0s;
            }

            &:hover {
                img {
                    right: -4px;
                }
            }

        }
    }
}

//======================== Venue Articles Section Css End ========================


// Quick Action section
.quick-action-wrap {
    background: linear-gradient(90deg, #94B3A5 50%, #A8AED6 50%);
    border-top: 1px solid #272338;
    position: relative;

    @media screen and (max-width: 767px) {
        background: transparent;
    }

    .container {
        display: flex;
        padding-left: 0;
        padding-right: 0;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    .quick-action-item {
        flex: 1;
        padding: 40px;

        &:nth-child(2) {
            background: #EFB7A6;
        }

        @media screen and (max-width: 1280px) {
            padding: 40px 16px;
        }

        @media screen and (max-width: 991px) {
            padding: 32px 16px;
        }

        @media screen and (max-width: 767px) {
            padding: 24px 16px;

            &:nth-child(1) {
                background: #94B3A5;
            }

            &:nth-child(3) {
                background: #A8AED6;
            }
        }

        + .quick-action-item {
            border-left: 1px solid #272338;

            @media screen and (max-width: 767px) {
                border: none;
                border-top: 1px solid #272338;
            }
        }

        h2, h3 {
            font-family: Aeonik, sans-serif;
            font-size: 22px;
            line-height: 26px;
            font-weight: 500;
            letter-spacing: -0.01em;
            color: #272338;
            margin: 0 0 24px;

            @media screen and (max-width: 767px) {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 16px;
            }
        }

        .quick-action-btn {
            gap: 16px;
            flex-wrap: wrap;
            display: flex;

            @media screen and (max-width: 767px) {
                gap: 8px;
            }
        }

        .secondary-btn {
            gap: 12px;

            @media screen and (max-width: 1000px) {
                padding: 11px 16px;
                gap: 8px;
            }

            img {
                position: relative;
                right: 0;
                transition: all 0.35s ease 0s;
            }

            &:hover {
                img {
                    right: -4px;
                }
            }

            &.plain-btn {
                background: transparent;
                border-color: transparent;
                //border-radius: 0;

                @media screen and (min-width: 1000px) {
                    &:hover {
                        background: #A9BEFF;
                        border-color: #272338;
                        //border-radius: 16px;
                    }
                }

                @media screen and (max-width: 1000px) {
                    padding: 0;
                    border: none;
                    border-radius: 0;
                    color: #272338;
                }

                @media screen and (max-width: 767px) {
                    padding: 11px 16px;
                    border: 1px solid transparent;
                    border-radius: 16px;
                    color: #272338;

                    &:hover {
                        background: #A9BEFF;
                        border-color: #272338;

                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    ::ng-deep .back-to-top {
        bottom: 87px !important;
    }
}
