import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    Output,
    PLATFORM_ID,
    ViewEncapsulation
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import lightGallery from 'lightgallery';
import {RouterModule} from '@angular/router';
import {CommonConstants, RoutesConstants} from '@coreconstant';
import {VenueService} from 'src/app/core/services/api-service/venue.service';
import {lastValueFrom} from 'rxjs';
import {ResponseHandlerService} from 'src/app/core/services/common-service/response-handler.service';
import {VendorService} from 'src/app/core/services/api-service/vendor.service';
import {SetImageDimensionsDirective} from 'src/app/core/directives/set-image-dimensions.directive';

@Component({
    selector: 'app-image-gallery',
    imports: [SetImageDimensionsDirective, RouterModule],
    templateUrl: './image-gallery.component.html',
    styleUrl: './image-gallery.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class ImageGalleryComponent {
    commonConstants = CommonConstants
    routesConstants = RoutesConstants
    images: any;
    @Input() slug?: string = '';
    @Input() type?: string = '';
    @Input() imageAltTag: any
    @Output() backToParent = new EventEmitter<void>();

    constructor(
        private el: ElementRef,
        @Inject(PLATFORM_ID) private platformId: Object,
        private _venueService: VenueService,
        private _vendorService: VendorService,
        private _responseHandlerService: ResponseHandlerService
    ) {
    }

    ngOnInit(): void {
        this.getImages();
    }

    goBack() {
        this.backToParent.emit();
    }

    imageGallery() {
        if (isPlatformBrowser(this.platformId)) {
            // Ensure this code runs only in the browser
            lightGallery(this.el.nativeElement.querySelector('#detailGallery'), {
                // controls: true,
                download: false,
                closable: true,
                plugins: [],
                speed: 500,
                getCaptionFromTitleOrAlt: false,
                mobileSettings: {
                    controls: true,
                    showCloseIcon: true,
                }
            });
        }
    }

    /**
     * Get Images of venue and vendor based on the slug and type
     */
    getImages() {
        var imageSlug = "vendor_images";
        var getListing = this._vendorService.getVendorAndImages(this.slug)
        if (this.type == this.routesConstants.VENUES) {
            imageSlug = "venue_images"
            getListing = this._venueService.getVenueAndImages(this.slug)
        }

        getListing
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"] && res["data"]) {
                this.images = res["data"][imageSlug]

                setTimeout(() => {
                    this.imageGallery()
                }, 0);
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    setDefaultImage(event: Event) {
        let image = this.commonConstants.DEFAULT_NO_IMAGE;
        (event.target as HTMLImageElement).src = image
    }
}
