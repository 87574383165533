<!--======================== banner-section Html Start ========================-->
<section class="banner-section">
    <div class="container">
        <div class="banner-section-inner">
            <div class="banner-content">
                <h1 class="page-title">From *no clue* to “we do.”</h1>
                <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION]" class="primary-btn">Start your planning</a>
            </div>
            @if(!isMobileView){
            <div class="image-gallery">
                <div class="column">
                    <img [src]="imagePathConstants.HOME + '/wedshed-banner-image-1.png?tr=w-250,h-342,q-100,lossless-1,f-webp,e-sharpen-50'" width="220" height="302" alt="Two grooms in black tuxedos celebrating with champagne, posing with a vintage Land Rover.">
                </div>
                <div class="column">
                    <img [src]="imagePathConstants.HOME + '/wedshed-banner-image-2.png?tr=w-250,h-324,q-100,lossless-1,f-webp,e-sharpen-50'" width="220" height="286" alt="Unique white open air wedding chapel hunter valley.">
                    <img [src]="imagePathConstants.HOME + '/wedshed-banner-image-3.png?tr=w-250,h-210,q-100,lossless-1,f-webp,e-sharpen-50'" width="220" height="186" alt="Bride and groom dancing with their wedding party on a lush green lawn.">
                </div>
                <div class="column">
                    <img [src]="imagePathConstants.HOME + '/wedshed-banner-image-4.png?tr=w-250,h-180,q-100,lossless-1,f-webp,e-sharpen-50'" width="220" height="158" alt="Black tie dressed bride and groom drinking champagne from champagne tower">
                    <img [src]="imagePathConstants.HOME + '/wedshed-banner-image-5.png?tr=w-250,h-456,q-100,lossless-1,f-webp,e-sharpen-50'" width="220" height="400" alt="Couple entering their wedding reception, bride wears a pink feathery dress">
                </div>
                <div class="column">
                    <img [src]="imagePathConstants.HOME + '/wedshed-banner-image-6.png?tr=w-250,h-280,q-100,lossless-1,f-webp,e-sharpen-50'" width="220" height="246" alt="Luxurious South Coast wedding venue Terara House alfresco micro wedding.">
                    <img [src]="imagePathConstants.HOME + '/wedshed-banner-image-7.png?tr=w-250,h-320,q-100,lossless-1,f-webp,e-sharpen-50'" width="220" height="282" alt="Lilypad Palm Beach a unique floating micro wedding venue celebrating with guests.">
                </div>
            </div>
            }
        </div>
    </div>
</section>
<!--======================== banner-section Html End ========================-->
<section class="plan-wrap">
    <div class="container">
        <div class="plan-header">
            <h2 class="section-title">Here for a good time.</h2>
            <p class="section-info">It’s a once-in-a-lifetime thing. So let’s make this party all about you both ❤️‍🔥. No matter the size, no matter the budget, we’re with you every step of the way.</p>
        </div>
    </div>

    <div class="plan-listing">
        <div class="container">
            <h3>
                <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION]" title="Venue">
                    <span class="plan-listing-image">
                        <img [src]="imagePathConstants.HOME + '/venues.png'" width="400" height="340" alt="Wedshed venues" loading="lazy" appSetImageDimensions>
                    </span>
                    <span class="plan-listing-title">Venues</span>
                </a>
            </h3>

            <h3>
                <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION]" title="Vendors">
                    <span class="plan-listing-image">
                        <img [src]="imagePathConstants.HOME + '/vendors.png'" width="400" height="340" alt="Wedshed vendors" loading="lazy" appSetImageDimensions>
                    </span>
                    <span class="plan-listing-title">Vendors</span>
                </a>
            </h3>

            <h3>
                <a [routerLink]="['/'+ routesConstants.WEDSPIRATION]" title="Advice & Ideas">
                    <span class="plan-listing-image">
                        <img [src]="imagePathConstants.HOME + '/wedspiration.png'" width="400" height="340" alt="Wedshed wedspiration" loading="lazy" appSetImageDimensions>
                    </span>
                    <span class="plan-listing-title">Advice & Ideas</span>
                </a>
            </h3>

            <h3>
                <a href="{{commonConstants.PLANNING_REGISTRY_LINK}}" rel="noopener noreferrer nofollow" title="Registry" target="_blank">
                    <span class="plan-listing-image">
                        <img [src]="imagePathConstants.HOME + '/registry.png'" width="400" height="340" alt="Wedshed registry" loading="lazy" appSetImageDimensions>
                    </span>
                    <span class="plan-listing-title">Registry</span>
                </a>
            </h3>

            <h3>
                <a href="{{commonConstants.OUR_BOOK_LINK}}" title="Our book" target="_blank" rel="noopener noreferrer nofollow">
                    <span class="plan-listing-image">
                        <img [src]="imagePathConstants.HOME + '/our-book.png'" width="400" height="340" alt="Wedshed our book" loading="lazy" appSetImageDimensions>
                    </span>
                    <span class="plan-listing-title">Our book</span>
                </a>
            </h3>

            <h3>
                <a href="{{commonConstants.PLANNING_WEDSHARE_LINK}}" title="Wedshare" target="_blank">
                    <span class="plan-listing-image">
                        <img [src]="imagePathConstants.HOME + '/wedshare.png'" width="400" height="340" alt=" Wedshed wedshare" loading="lazy" appSetImageDimensions>
                    </span>
                    <span class="plan-listing-title">Wedshare</span>
                </a>
            </h3>

            <h3>
                <a href="{{commonConstants.WEDSHED_STORE_LINK}}" rel="noopener noreferrer nofollow" title="Shop" target="_blank">
                    <span class="plan-listing-image">
                        <img [src]="imagePathConstants.HOME + '/wedshed-shop.png'" width="400" height="340" alt="Wedshed shop" loading="lazy" appSetImageDimensions>
                    </span>
                    <span class="plan-listing-title">Shop</span>
                </a>
            </h3>

            <h3>
                <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" title="Planning hub">
                    <span class="plan-listing-image">
                        <img [src]="imagePathConstants.HOME + '/planning-hub.png'" width="400" height="340" alt="Wedshed planning hub" loading="lazy" appSetImageDimensions>
                    </span>
                    <span class="plan-listing-title">Planning hub</span>
                </a>
            </h3>
        </div>
    </div>

</section>
<!--======================== Plan Section End ========================-->

<!--======================== Trending Html Start ========================-->
@if(trendingBlogs && trendingBlogs.length > 0) {
<section class="trending-section">
    <div class="container">
        <div class="trending-section-inner">
            <div class="left-side">
                <h2 class="section-title">Trending ✨</h2>
                <p class="section-info">Advice, articles, and inspiration via our blog <a class="link" [routerLink]="['/'+ routesConstants.WEDSPIRATION]">Advice & Ideas</a> to help you find your feet.</p>
            </div>
            <div class="right-side">
                <div class="trending-list">
                    @for (trendingBlog of trendingBlogs; track trendingBlog; let i = $index) {
                    <a class="trending-link" [routerLink]=" ['/'+ routesConstants.BLOG +'/'+ trendingBlog.blog_categories[0].slug + '/'+ trendingBlog.slug]">
                        <span class="trending-image"><img
                                [src]="trendingBlog.featureImagePath ? commonConstants.IMAGE_KIT_URL + trendingBlog.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                width="60" height="60" [alt]="trendingBlog.imageAltText?.trim() ? trendingBlog.imageAltText : trendingBlog.title"
                                loading="lazy" (error)="setDefaultImage($event)"
                                appSetImageDimensions></span>
                        <span class="trending-content">
                            @if(trendingBlog.blog_categories && trendingBlog.blog_categories.length > 0) {
                            <span class="label">{{trendingBlog.blog_categories[0].name}}</span>
                            }
                            <div class="trending-title">{{trendingBlog.title}}</div>
                        </span>
                    </a>
                    }
                </div>
            </div>
        </div>
    </div>
</section>
}
<!--======================== Trending Html End ========================-->

<!--======================== Services Info Section Html Start ========================-->
<section class="services-info-wrap planning-hub" id="planning-hub-section">
    <div class="container">
        <div class="services-info-inner">
            <div class="section-pagination">
                <span class="dot active" (click)="scrollToSection('planning-hub-section')"></span>
                <span class="dot" (click)="scrollToSection('check-out-gravy-section')"></span>
<!--                <span class="dot" (click)="scrollToSection('view-regions-section')"></span>-->
                <span class="dot" (click)="scrollToSection('view-our-venues-section')"></span>
            </div>
            <div class="services-info-left">
                <img [src]="imagePathConstants.HOME + '/wedshed-planning-hub.png'" width="998" height="816" alt="Planning hub" loading="lazy" appSetImageDimensions>
            </div>
            <div class="services-info-right">
                <h2 class="section-title">Tools so helpful you’ll want to marry them.</h2>
                <p class="section-info">Communicate with venues and vendors, budget for your day, access a full to-do
                    list and so much more.</p>
                <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" class="secondary-btn">Head to the Planning
                    Hub</a>
            </div>
        </div>
    </div>
</section>
<section class="services-info-wrap check-out-gravy" id="check-out-gravy-section">
    <div class="container">
        <div class="services-info-inner">
            <div class="section-pagination">
                <span class="dot" (click)="scrollToSection('planning-hub-section')"></span>
                <span class="dot active" (click)="scrollToSection('check-out-gravy-section')"></span>
<!--                <span class="dot" (click)="scrollToSection('view-regions-section')"></span>-->
                <span class="dot" (click)="scrollToSection('view-our-venues-section')"></span>
            </div>
            <div class="services-info-left">
                <img [src]="imagePathConstants.HOME + '/wedshed-registry.png'" width="998" height="816"
                    alt="Wedding registry" loading="lazy" appSetImageDimensions>
            </div>
            <div class="services-info-right">
                <h2 class="section-title">A digital yet heartfelt wedding registry</h2>
                <p class="section-info">Ask for anything (for free!) using Gravy - whether it’s a honeymoon fund, an IVF
                    kitty or even dogsitting.</p>
                <a href="{{commonConstants.PLANNING_REGISTRY_LINK}}" rel="noopener noreferrer nofollow" class="secondary-btn" target="_blank">Check out
                    Gravy</a>
            </div>
        </div>
    </div>
</section>
<!--<section class="services-info-wrap view-regions" id="view-regions-section">-->
<!--    <div class="container">-->
<!--        <div class="services-info-inner">-->
<!--            <div class="section-pagination">-->
<!--                <span class="dot" (click)="scrollToSection('planning-hub-section')"></span>-->
<!--                <span class="dot" (click)="scrollToSection('check-out-gravy-section')"></span>-->
<!--                <span class="dot active" (click)="scrollToSection('view-regions-section')"></span>-->
<!--                <span class="dot" (click)="scrollToSection('view-our-venues-section')"></span>-->
<!--            </div>-->
<!--            <div class="services-info-left">-->
<!--                <img [src]="imagePathConstants.HOME + '/wedshed-wedding-location.png'" width="998" height="816"-->
<!--                    alt="Bintangs to byron bliss" loading="lazy" appSetImageDimensions>-->
<!--            </div>-->
<!--            <div class="services-info-right">-->
<!--                <h2 class="section-title">From Bintangs to Byron bliss</h2>-->
<!--                <p class="section-info">Browse the most popular wedding regions to discover what each location can bring-->
<!--                    to your-->
<!--                    special day.</p>-->
<!--                <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION]" class="secondary-btn">View regions</a>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
<section class="services-info-wrap view-our-venues" id="view-our-venues-section">
    <div class="container">
        <div class="services-info-inner">
            <div class="section-pagination">
                <span class="dot" (click)="scrollToSection('planning-hub-section')"></span>
                <span class="dot" (click)="scrollToSection('check-out-gravy-section')"></span>
<!--                <span class="dot" (click)="scrollToSection('view-regions-section')"></span>-->
                <span class="dot active" (click)="scrollToSection('view-our-venues-section')"></span>
            </div>
            <div class="services-info-left rounded-0">
                <img [src]="imagePathConstants.HOME + '/wedshed-wedding-team.png'" class="border-0 rounded-0" width="998"
                    height="818" alt="Wedding dream team" loading="lazy" appSetImageDimensions>
            </div>
            <div class="services-info-right">
                <h2 class="section-title">Helping you find your wedding dream team</h2>
                <p class="section-info">Amazing, unique venues? The industry’s best vendors? Meet them right here.</p>
                <div class="right-side-action">
                    <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION]" class="secondary-btn">View our venues</a>
                    <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION]" class="primary-btn">Meet our vendors</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--======================== Services Info Section Html Start ========================-->

<!--======================== Featured in Section Start ========================-->
<section class="featured-wrap">
    <div class="container">
        <h2>FEATURED IN</h2>
        <div class="featured-logos">
            <img [src]="imagePathConstants.HOME + '/sydney-morning-herald.svg'" width="228" height="25" loading="lazy"
                alt="Sydney morning herald">
            <img [src]="imagePathConstants.HOME + '/today.svg'" width="41" height="31" alt="Today" loading="lazy">
            <img [src]="imagePathConstants.HOME + '/collective-hub.svg'" width="149" height="25" alt="Collective hub" loading="lazy">
            <img [src]="imagePathConstants.HOME + '/broadsheet.svg'" width="199" height="19" alt="Broadsheet" loading="lazy">
            <img [src]="imagePathConstants.HOME + '/buzzfeed.svg'" width="123" height="23" alt="Buzzfeed" loading="lazy">
            <img [src]="imagePathConstants.HOME + '/marie-claire.svg'" width="143" height="23" alt="Marie claire" loading="lazy">
            <img [src]="imagePathConstants.HOME + '/daily-mail.svg'" width="178" height="30" alt="Daily mail" loading="lazy">
            <img [src]="imagePathConstants.HOME + '/hello-may.svg'" width="135" height="20" alt="Hello may" loading="lazy">
        </div>
    </div>
</section>
<!--======================== Featured in Section End ========================-->

<!--======================== Let’s lift the lid Section Start ========================-->
@if (blogTags && blogTags.length > 0) {
<section class="lets-lift-wrap">
    <div class="container">
        <div class="lets-lift-header">
            <h2 class="section-title">Let’s lift the lid on...</h2>
            <p class="section-info">Planning a wedding can raise a few questions. We tackle them head-on so you can make
                decisions with confidence.</p>
        </div>
        <div class="lets-lift-links">
            @for (blogTag of blogTags; track blogTag; let j = $index) {
                <button [routerLink]="['/'+ routesConstants.WEDSPIRATION]" type="button" class="secondary-btn">{{ blogTag.name }}</button>
            }
        </div>
    </div>
</section>
}
<!--======================== Let’s lift the lid Section End ========================-->

<!--======================== planning your wedding Section Html Start ========================-->
<section class="planning-wedding">
    <div class="container">
        <div class="planning-wedding-inner">
            <div class="planning-wedding-content">
                <h2 class="section-title">Stress less, it’s <strong>free</strong> to start
                    planning your wedding</h2>
                <a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" class="primary-btn">Get started here</a>
            </div>
            <div class="right-side-image">
                <img [src]="imagePathConstants.HOME + '/wedshed-free-to-start-planning-your-wedding.png'" width="1082"
                    height="648" alt="Relax and start planning your wedding for free" appSetImageDimensions loading="lazy">

            </div>
        </div>
    </div>
</section>
<!--======================== planning your wedding Section Html End ========================-->
