import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideToastr } from 'ngx-toastr';
import { pendingRequestsInterceptor$ } from 'ng-http-loader';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes, withInMemoryScrolling({
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled'
        })),
        provideClientHydration(withEventReplay()),
        provideHttpClient(
            withFetch(), // ✅ Enables Fetch API
            withInterceptors([pendingRequestsInterceptor$])
        ),
        provideAnimationsAsync(),
        provideToastr({
            timeOut: 3000,
            // closeButton: true,
            maxOpened: 1,
            autoDismiss: true,
            preventDuplicates: true
        })
    ]
};
