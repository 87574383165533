import {Component, Inject, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {CommonConstants, RoutesConstants} from '@coreconstant';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {lastValueFrom} from 'rxjs';
import {SetImageDimensionsDirective} from 'src/app/core/directives/set-image-dimensions.directive';
import {DateFormatterPipe} from 'src/app/core/pipes/date-formatter.pipe';
import {BlogService} from 'src/app/core/services/api-service/blog.service';
import {GeneralService} from 'src/app/core/services/api-service/general.service';
import {ResponseHandlerService} from 'src/app/core/services/common-service/response-handler.service';
import {SeoService} from '../../core/services/common-service/seo.service';
import {SafeHtmlPipe} from 'src/app/core/pipes/safe-html.pipe';

@Component({
    selector: 'app-wedspiration-detail',
    imports: [SetImageDimensionsDirective, RouterModule, DateFormatterPipe, SafeHtmlPipe],
    templateUrl: './wedspiration-detail.component.html',
    styleUrl: './wedspiration-detail.component.scss',
    providers: [DateFormatterPipe, SafeHtmlPipe],
    encapsulation: ViewEncapsulation.None
})
export class WedspirationDetailComponent {
    commonConstants = CommonConstants
    routesConstants = RoutesConstants
    currentUrl: string;
    blogDetails: any;
    currentBlogId: any;
    categoryName: string = '';
    categorySlug: string = '';
    categoryId: any;
    slug: string;
    blogContents: any = [];
    blogTags: any = [
        {name: '👗 Short wedding dresses', slug: 'short-wedding-dresses'},
        {name: '😅 Managing conflict', slug: 'managing-conflict'},
        {name: '💩 Am I the a**hole?', slug: 'am-i-the-ahole'},
        {name: '🌞 Boho wedding inspiration', slug: 'boho-wedding-inspiration'},
        {name: '🍽️ Wedding food recommendations', slug: 'wedding-food-recommendations'},
        {name: '🐶 Pets at weddings', slug: 'pets-at-weddings'},
        {name: '✈️ Destination weddings', slug: 'destination-wedding'},
        {name: '💒 Small vs. big weddings', slug: 'small-vs-big-weddings'},
    ];
    sameCategoryBlogList: any = []
    relevantBlogList: any = []
    pinterestShareUrl: string = '';
    pageUrl: string = '';
    imageUrl: string = '';
    description: string = '';
    isPreview: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _modalService: NgbModal,
        private _blogService: BlogService,
        private _responseHandlerService: ResponseHandlerService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _seoService: SeoService,
        private _generalService: GeneralService
    ) {
        this.currentUrl = this._router.url;
    }

    setMetaTags() {
        let metaTagObject: any = {
            title: this.blogDetails['metaTitle'],
            description: this.blogDetails['metaDescription'],
            ogImageUrl: this.blogDetails?.featureImagePath ? this.blogDetails?.featureImagePath : '',
            ogWebUrl: this.routesConstants.BLOG + '/' + this.blogDetails['blog_categories'][0].slug + '/' + this.slug,
        }
        if (this.isPreview) {
            metaTagObject.robots = 'noindex, nofollow'
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    ngOnInit(): void {
        const params = this._activatedRoute.snapshot.params;
        if (params && params['slug']) {
            this.slug = params['slug']
        }
        if (params && params['preview'] && params['preview'] == 'preview') {
            this.isPreview = true;
        }

        // If only slug change then executes this
        this._activatedRoute.paramMap.subscribe(params => {
            this.slug = params.get('slug') || '';
            this.getBlogDetails()
        });
    }

    getBlogDetails() {
        var getDetails = this._blogService.getBlogDetails(this.slug)
        lastValueFrom(getDetails).then((res: any) => {
            if (res["status"]) {
                this.blogDetails = res["data"]
                if (this.blogDetails) {
                    if (!this.isPreview && this.blogDetails.blogStatusId != 1) {
                        this._router.navigate(['/' + this.routesConstants.WEDSPIRATION]);
                        return;
                    } else {
                        this.currentBlogId = this.blogDetails.id
                        if (this.blogDetails.blog_categories && this.blogDetails.blog_categories.length > 0) {
                            this.categoryName = this.blogDetails.blog_categories[0]?.name
                            this.categorySlug = this.blogDetails.blog_categories[0]?.slug
                            this.categoryId = this.blogDetails.blog_categories[0]?.id
                            this.sameCategoryBlogs()
                            this.getRelevantBlogs()
                        }
                        this.pageUrl = encodeURIComponent(this.commonConstants.FRONTEND_PREFIX_WITH_WWW + this.slug);
                        this.imageUrl = encodeURIComponent(this.blogDetails?.featureImagePath ? this.commonConstants.IMAGE_KIT_URL + this.blogDetails?.featureImagePath : '');
                        this.description = encodeURIComponent(this.blogDetails.title);
                        this.pinterestShareUrl = `http://pinterest.com/pin/create/button/?url=${this.pageUrl}&media=${this.imageUrl}&description=${this.description}`;
                        this.blogContents = []
                        if (this.blogDetails.blog_contents && this.blogDetails.blog_contents.length > 0) {
                            this.blogContents = this.blogDetails.blog_contents
                        }

                        // this.blogTags = []
                        // if (this.blogDetails.tags && this.blogDetails.tags.length > 0) {
                        //     this.blogTags = this.blogDetails.tags
                        // }
                    }
                    this.setMetaTags()
                }
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Get the same category blog data
     */
    sameCategoryBlogs() {
        var getDetails = this._blogService.getSameCategoryBlogs(this.categoryId, this.currentBlogId)
        lastValueFrom(getDetails).then((res: any) => {
            if (res["status"]) {
                this.sameCategoryBlogList = res["data"]
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Get the Articles you might like section blogs
     */
    getRelevantBlogs() {
        var getDetails = this._blogService.getRelevantBlogs(this.currentBlogId)
        lastValueFrom(getDetails).then((res: any) => {
            if (res["status"]) {
                this.relevantBlogList = res["data"]
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     *  Copy the URL to the clipboard
     */
    copyUrl() {
        const input = document.createElement('input');
        input.value = window.location.origin + this.currentUrl;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        this._responseHandlerService.showSuccessMessageGeneral(this.commonConstants.LINK_COPY);
    }

    setDefaultImage(event: Event) {
        let image = this.commonConstants.DEFAULT_NO_IMAGE;
        (event.target as HTMLImageElement).src = image
    }

    /**
     * Add http in the URL when not there
     * @param url
     * @returns
     */
    formatURL(url) {
        if (url) {
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                return 'https://' + url;
            }
        }
        return url;
    }
}
