//Banner section//
.page-banner-wrap {
    background: #423B5E;
    border-bottom: 1px solid #272338;
    overflow: hidden;

    .container {
        display: flex;
        gap: 76px;
        align-items: flex-end;
        justify-content: space-between;
        padding-left: 80px;
        padding-right: 80px;
        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @media screen and (max-width: 1200px) {
            gap: 60px;
        }
        @media screen and (max-width: 1100px) {
            gap: 24px;
        }
        @media screen and (max-width: 991px) {
            gap: 0;
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    p {
        margin: 0;
    }

    .left-content {
        padding: 100px 0;
        max-width: 490px;
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #E2E0EB;

        @media screen and (max-width: 1200px) {
            padding: 70px 0;
        }
        @media screen and (max-width: 991px) {
            padding: 48px 0;
            max-width: 42%;
        }
        @media screen and (max-width: 767px) {
            padding: 32px 0;
            max-width: 100%;
        }
    }

    h1 {
        font-family: Livorno, serif;
        font-size: 60px;
        line-height: 60px;
        font-weight: 400;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin: 0 0 16px;

        @media screen and (max-width: 1200px) {
            font-size: 52px;
            line-height: 54px;
        }
        @media screen and (max-width: 991px) {
            font-size: 40px;
            line-height: 48px;
            margin: 0 0 8px;
        }
    }

    .primary-btn {
        margin: 24px 0 0;

        @media screen and (min-width: 991px) {
            margin: 32px 0 0;
            border-color: transparent;
            font-size: 14px;
            padding: 9px 16px;
        }
    }

    .banner-image {
        width: 54.375%;
        max-width: 100%;

        @media screen and (max-width: 991px) {
            width: 100%;
        }

        @media screen and (max-width: 480px) {
            //height: 310px;
            position: relative;
            padding-bottom: 83%;
            .banner-image-inner {
                //width: calc(100% + 164px);
                //margin: 0 -90px -53px;
                position: absolute;
                top: 0;
                right: -74px;
                bottom: -53px;
                left: -94px;
            }
        }

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
            object-position: top left;
            @media screen and (max-width: 480px) {
                //width: calc(100% + 164px);
                //max-width: initial;
                max-height: initial;

            }
        }
    }
}

//How it works//
.how-it-works-wrap {
    overflow: clip;
    word-break: break-word;

    .container {
        padding-left: 80px;
        padding-right: 80px;
        display: flex;
        gap: 86px;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
            gap: 48px;
        }

        @media screen and (max-width: 1100px) {
            gap: 32px;
        }
    }

    .left-sticky-panel {
        padding: 52px 0;
        width: 307px;
        min-width: 307px;

        @media screen and (max-width: 100px) {
            width: 270px;
            min-width: 270px;
        }

        @media screen and (max-width: 991px) {
            display: none;
        }

        .left-sticky-menu {
            background: #FFF5EE;
            border: 1px solid #272338;
            border-radius: 16px;
            padding: 24px;
            position: sticky;
            top: 96px;

            ul {
                margin: 0 0 24px;
                padding: 0 0 24px;
                position: relative;
                font-family: Aeonik, sans-serif;
                font-size: 18px;
                line-height: 25px;
                font-weight: 400;
                color: #808080;

                &:before {
                    content: '';
                    position: absolute;
                    right: -24px;
                    bottom: 0;
                    left: -24px;
                    border-bottom: 1px solid #272338;
                    pointer-events: none;
                }

                li {
                    margin: 0 0 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a, span {
                        display: inline-block;
                        vertical-align: top;
                        color: #808080;
                        transition: all 0.25s ease 0s;
                        cursor: pointer;

                        &:hover {
                            color: #7093FF;
                        }
                    }

                    &.active {
                        a, span {
                            color: #272338;
                            font-weight: 500;
                        }
                    }
                }
            }

            .primary-btn {
                width: 100%;
                font-size: 14px;
                padding: 9px 16px;
            }
        }
    }

    .how-it-works-step {
        padding: 52px 0;

        @media screen and (max-width: 991px) {
            padding: 40px 0;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 0;
        }

        .section-title {
            margin: 0 0 24px;
            @media screen and (max-width: 480px) {
                margin: 0 0 32px;
                text-align: center;
            }
        }

        .how-it-works-list {
            display: grid;
            gap: 83px 60px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-auto-flow: row;
            @media screen and (max-width: 1100px) {
                gap: 40px 32px;
            }
            @media screen and (max-width: 767px) {
                gap: 32px 24px;
            }
            @media screen and (max-width: 480px) {
                display: block;
            }

            .how-it-works-item {
                grid-row: span 2 / span 2;
                font-family: Aeonik, sans-serif;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                color: #4D4D4D;

                &:first-child {
                    grid-row-end: 3;
                }

                &:nth-child(2) {
                    grid-row-start: 2;
                }

                &:nth-child(2n) {
                    margin-top: 31px;
                    @media screen and (max-width: 480px) {
                        margin-top: 0;
                    }
                }

                @media screen and (max-width: 480px) {
                    margin-bottom: 32px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .how-it-works-img {
                    border: 1px solid #272338;
                    border-radius: 16px;
                    overflow: hidden;
                    width: 79.02%;
                    min-width: 320px;
                    margin: 0 0 16px;
                    padding-bottom: 52.115%;
                    position: relative;

                    @media screen and (max-width: 1280px) {
                        padding-bottom: 53.7%;
                    }

                    @media screen and (max-width: 1200px) {
                        padding-bottom: 59.7%;
                    }

                    @media screen and (max-width: 1100px) {
                        width: 100%;
                        min-width: 100%;
                        padding-bottom: 66.15%;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        vertical-align: middle;
                        border-radius: 16px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                }

                h3 {
                    font-family: Livorno, serif;
                    font-size: 26px;
                    line-height: 31px;
                    font-weight: 400;
                    letter-spacing: -0.01em;
                    color: #272338;
                    margin: 0 0 8px;

                    a {
                        color: #272338;

                        &:hover {
                            color: #7093FF;
                        }
                    }
                }

                p {
                    margin: 0;
                }

                .link-btn {
                    font-weight: 500;
                    display: inline-flex;
                    gap: 8px;
                    align-items: center;
                    color: #7093FF;
                    margin: 8px 0 0;

                    img {
                        position: relative;
                        right: 0;
                        transition: all 0.25s ease 0s;
                    }

                    &:hover {
                        color: #272338;

                        img {
                            right: -3px;
                            filter: brightness(0);
                        }
                    }
                }

                .how-it-works-links {
                    margin-top: 8px;
                    gap: 8px 16px;
                    //justify-content: space-between;
                    //flex-wrap: wrap;
                    display: flex;
                    flex-direction: column;

                    .link-btn {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .what-we-offer {
        padding: 52px 0;
        position: relative;
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #4D4D4D;

        @media screen and (max-width: 991px) {
            padding: 40px 0;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: -100vw;
            bottom: 0;
            left: -100vw;
            background: #FFE6D5;
            border-top: 1px solid #272338;
            border-bottom: 1px solid #272338;
            z-index: -1;
            pointer-events: none;
        }

        .section-des {
            font-size: 18px;
            line-height: 25px;
            @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 22px;
            }
        }

        p {
            margin-bottom: 0;
        }

        ul {
            margin: 32px 0 0;
            @media screen and (max-width: 991px) {
                margin-top: 24px;
            }

            li {
                margin: 0 0 24px;
                position: relative;
                padding-left: 88px;
                min-height: 72px;
                gap: 2px;
                flex-direction: column;
                justify-content: center;
                display: flex;

                @media screen and (max-width: 480px) {
                    padding-left: 0;
                    min-height: initial;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                h3 {
                    font-family: Aeonik, sans-serif;
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 500;
                    color: #272338;
                    margin: 0;
                }

                .offer-list-icon {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #272338;
                    border-radius: 72px;
                    width: 72px;
                    height: 72px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @media screen and (max-width: 480px) {
                        width: 60px;
                        height: 60px;
                        position: relative;
                        top: auto;
                        left: auto;
                        margin: 0 0 8px;
                        img {
                            max-width: 44px;
                            max-height: 44px;
                            height: auto;
                            width: auto;
                        }
                    }
                }

                &:first-child, &:nth-child(5) {
                    .offer-list-icon {
                        background: #7093FF;
                    }
                }

                &:nth-child(2), &:nth-child(6) {
                    .offer-list-icon {
                        background: #EFB7A6;
                    }
                }

                &:nth-child(3), &:nth-child(7) {
                    .offer-list-icon {
                        background: #94B3A5;
                    }
                }

                &:nth-child(4), &:nth-child(8) {
                    .offer-list-icon {
                        background: #F8D066;
                    }
                }
            }
        }
    }

    .success-stories {
        padding: 52px 0;

        @media screen and (max-width: 991px) {
            padding: 40px 0;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 0;
        }

        .section-title {
            margin-bottom: 24px;
            @media screen and (max-width: 767px) {
                margin-bottom: 16px;
            }
        }

        p {
            margin-bottom: 0;
        }

        .success-stories-date {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #272338;
            margin: 10px 0 0;

            a {
                font-weight: 500;
            }
        }

        a {
            color: #7093FF;

            &:hover {
                color: #272338;
            }
        }

        .success-stories-item {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: #423B5E;
            background: #FFF5EE;
            padding: 16px;
            margin: 0 0 16px;
            border: 1px solid #272338;
            border-radius: 16px;
            gap: 16px;
            display: flex;

            @media screen and (max-width: 480px) {
                flex-direction: column-reverse;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .success-stories-img {
                width: 130px;
                min-width: 130px;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #272338;
                text-align: center;
                gap: 12px;
                flex-direction: column;
                align-items: center;
                display: inline-flex;
                @media screen and (max-width: 767px) {
                    width: 120px;
                    min-width: 120px;
                }
                @media screen and (max-width: 480px) {
                    font-size: 16px;
                    line-height: 22px;
                    width: 100%;
                    min-width: 100%;
                    padding: 0 0 0 72px;
                    position: relative;
                    text-align: left;
                    min-height: 60px;
                    gap: 4px;
                    justify-content: center;
                    align-items: flex-start;
                }

                img {
                    width: 80px;
                    height: 80px;
                    border: 1px solid #272338;
                    border-radius: 80px;
                    object-fit: cover;
                    @media screen and (max-width: 480px) {
                        width: 60px;
                        height: 60px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                    }
                }

                .success-stories-date {
                    display: none;
                    margin: 0;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;

                    a {
                        font-weight: 500;
                    }

                    @media screen and (max-width: 480px) {
                        display: block;
                    }
                }

                @media screen and (max-width: 480px) {
                    width: 100%;
                    min-width: 100%;
                }
            }

            .success-stories-info {
                padding-left: 32px;
                border-left: 1px solid #E6E6E6;
                min-height: 110px;
                flex-direction: column;
                justify-content: center;
                display: flex;

                @media screen and (max-width: 767px) {
                    padding-left: 24px;
                }

                @media screen and (max-width: 480px) {
                    padding: 0 0 16px;
                    border: none;
                    border-bottom: 1px solid #E6E6E6;
                    min-height: inherit;
                    .success-stories-date {
                        display: none;
                    }
                }

                h3 {
                    font-family: Aeonik, sans-serif;
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: 500;
                    color: #272338;
                    margin: 0 0 2px;
                }

            }
        }

        .show-more-btn {
            margin: 0;
            padding: 0;
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #7093FF;
            background: transparent;
            border: none;
            box-shadow: none;
            outline: none;
            gap: 4px;
            align-items: center;
            display: none;
            transition: all 0.35s ease 0s;

            @media screen and (max-width: 767px) {
                display: inline-flex;
                font-size: 16px;
                line-height: 22px;

                svg {
                    width: 32px;
                    height: 32px;
                }
            }

            svg {
                transition: all 0.35s ease 0s;

                path {
                    transition: all 0.35s ease 0s;
                }
            }

            &:hover {
                color: #661735;

                svg {
                    path {
                        stroke: #661735;
                    }
                }
            }

            &.show-less {
                svg {
                    transform: rotateX(180deg);
                }
            }
        }
    }

    .questions-answered {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #FFFFFF;
        padding: 52px 0;
        position: relative;
        word-break: break-word;

        @media screen and (max-width: 991px) {
            padding: 40px 0;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: -100vw;
            bottom: 0;
            left: -100vw;
            background: #272338;
            z-index: -1;
            pointer-events: none;
        }

        .section-title {
            margin: 0 0 24px;
            color: #fff;
        }

        .accordion-item {
            margin: 0 0 16px;
            padding: 0 0 16px;
            border: none;
            border-bottom: 1px solid #423B5E;
            border-radius: 0;
            background: transparent;
            @media screen and (max-width: 991px) {
                margin: 0 0 14px;
                padding: 0 0 14px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .accordion-button {
                font-family: Aeonik, sans-serif;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #FFFFFF;
                background: transparent;
                border: none;
                border-radius: 0;
                padding: 0 30px 0 0;
                box-shadow: none;
                position: relative;
                transition: all 0.35s ease 0s;
                @media screen and (max-width: 767px) {
                    line-height: 24px;
                }

                &:not(.collapsed) {
                    color: #FFE6D5;
                }

                &:after {
                    display: none;
                }

                .faqs-arrow {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.35s ease 0s;
                    transform: rotateX(180deg);
                    background-image: url("/assets/images/faq-down-color-icon.svg");
                    background-position: center center;
                    background-repeat: no-repeat;
                    @media screen and (min-width: 767px) {
                        background-size: 10px auto;
                    }
                }

                &.collapsed {
                    .faqs-arrow {
                        transform: none;
                    }
                }
            }

            .accordion-body {
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #FFFFFF;
                padding: 8px 0 0;
                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 2px;
                }
            }
        }

        .questions-answered-action {
            padding-top: 32px;
            gap: 16px 8px;
            flex-wrap: wrap;
            display: flex;
            @media screen and (max-width: 767px) {
                padding-top: 24px;
            }

            .secondary-btn {
                gap: 12px;
                border-color: transparent;

                img {
                    position: relative;
                    right: 0;
                    transition: all 0.35s ease 0s;
                }

                &:hover {
                    img {
                        right: -3px;
                    }
                }
            }
        }
    }
}

//Bottom Banner
.bottom-banner-wrap {
    background: #94B3A5;
    overflow: hidden;

    .container {
        padding-left: 80px;
        padding-right: 0;
        gap: 80px;
        align-items: center;
        display: flex;
        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            gap: 50px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
        }

        @media screen and (max-width: 1200px) {
            gap: 36px;
        }
        @media screen and (max-width: 1199px) {
            gap: 24px;
        }
        @media screen and (max-width: 991px) {
            padding-right: 16px;
            gap: 0;
            flex-direction: column-reverse;
        }

        @media screen and (max-width: 767px) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .bottom-banner-info {
        padding: 48px 0;
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #272338;
        flex: 1;

        @media screen and (max-width: 1199px) {
            padding: 32px 0;
        }

        @media screen and (max-width: 991px) {
            padding: 48px 0;
            flex: 0 0 100%;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 16px 48px;
        }

        .primary-btn {
            margin-top: 32px;
            @media screen and (max-width: 1100px) {
                margin-top: 24px;
            }
        }
    }

    .section-title {
        margin-bottom: 12px;
        @media screen and (max-width: 1199px) {
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 8px;
        }
        @media screen and (max-width: 767px) {
            font-size: 26px;
            line-height: 31px;
        }
    }

    p {
        margin: 0;
    }

    .bottom-banner-img {
        width: 53.33%;
        overflow: hidden;
        margin-bottom: -14px;
        @media screen and (max-width: 1100px) {
            width: 54%;
        }
        @media screen and (max-width: 991px) {
            width: 64%;
            text-align: center;
            margin-bottom: 0;
        }

        @media screen and (max-width: 767px) {
            width: 80%;
        }

        @media screen and (max-width: 480px) {
            width: calc(100% + 150px);
            margin: 0 -60px 0 -50px;
        }

        .bottom-banner-inner {
            padding-bottom: 61.501%;
            position: relative;
            @media screen and (max-width: 991px) {
                padding-bottom: 65%;
            }
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            object-fit: contain;
            object-position: top left;


            //width: 100%;
            //margin-bottom: -27px;
            //@media screen and (max-width: 991px) {
            //    width: 70%;
            //    margin-bottom: 0;
            //}
            //@media screen and (max-width: 767px) {
            //    width: 100%;
            //}
            //@media screen and (max-width: 480px) {
            //    width: calc(100% + 100px);
            //    max-width: inherit;
            //    margin-left: -60px;
            //    margin-right: -40px;
            //}
        }
    }
}

.mob-create-profile-btn {
    padding: 12px 16px;
    background: #FFFFFF;
    border-bottom: 1px solid #272338;
    position: sticky;
    top: 47px;
    z-index: 9;
    text-align: center;
    display: none;
    @media screen and (max-width: 480px) {
        display: block;
    }

    .primary-btn {
        width: 100%;
    }
}
