//Enquire with venue Modal
//::ng-deep.modal {
.venue-enquire {
    //max-width: 436px;
    //@media screen and (max-width: 767px) {
    //    max-width: 100%;
    //}

    .modal-header {
        .close-btn {
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .form-group {
        @media screen and (min-width: 767px) {
            margin-bottom: 12px;
        }

        textarea {
            &.form-control {
                height: 200px;
                @media screen and (max-width: 767px) {
                    height: 175px;
                }
            }
        }

    }

    .modal-body {
        @media screen and (min-width: 767px) {
            padding-bottom: 16px;
        }
    }

    .modal-footer {
        @media screen and (min-width: 767px) {
            flex-direction: row-reverse;
            border-top: none;
            padding-top: 0;
            .primary-btn, .secondary-btn {
                width: auto;
                flex: 1;
            }
        }

        .secondary-btn {
            display: inline-flex;
        }
    }
}

//}

.vendor-message-box {
    font-family: Aeonik, sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #423B5E;
    margin-bottom: 12px;
    border: 1px solid #272338;
    position: relative;
    padding: 12px 16px;
    border-radius: 16px;
    background: #FFF5EE;
    gap: 16px;
    display: flex;

    @media screen and (max-width: 767px) {
        margin-bottom: 24px;
    }

    strong {
        font-weight: 500;
        color: #272338;
    }

    .vendor-message-img {
        display: inline-flex;

        img {
            width: 42px;
            min-width: 42px;
            height: 42px;
            border: 1px solid #272338;
            border-radius: 50px;
            object-fit: cover;
            object-position: center;
            background: #FFF5EE;

            + img {
                margin-left: -16px;
            }
        }
    }

    p {
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

//Enquire with venue - Date Picker

//.datepicker-wrap {
//    background: #FFFFFF;
//    border: 1px solid #272338;
//    border-radius: 16px;
//    overflow: hidden;
//    font-family: Aeonik, sans-serif;
//    font-size: 16px;
//    line-height: 22px;
//    font-weight: 400;
//    color: #272338;
//    position: absolute;
//    top: calc(100% + 6px);
//    left: 0;
//    right: 0;
//    z-index: 9;
//
//    ngb-datepicker {
//        border: none;
//        outline: none;
//        width: 100%;
//        display: block;
//
//        ::ng-deep.ngb-dp-header {
//            padding: 16px;
//            background: transparent;
//            border: none;
//            border-bottom: 1px solid #272338;
//            font-family: Aeonik, sans-serif;
//            font-size: 16px;
//            line-height: 21px;
//            font-weight: 500;
//            color: #272338;
//
//            ngb-datepicker-navigation{
//                .ngb-dp-arrow{
//                    margin: 0;
//                }
//
//                .ngb-dp-navigation-select {
//                display: flex;
//                gap: 6px;
//
//                .form-select {
//                    padding: 0;
//                    background: #fff;
//                    appearance: none;
//                    border: none;
//                    border-radius: 0;
//                    width: auto;
//                    text-align: center;
//                    height: auto;
//                    min-width: inherit;
//                    font-family: Aeonik, sans-serif;
//                    font-size: 16px;
//                    line-height: 21px;
//                    font-weight: 500;
//                    color: #272338;
//
//                    &:first-child {
//                        text-align: right;
//                    }
//
//                    &:last-child {
//                        text-align: left;
//                    }
//                }
//            }
//            }
//        }
//    }
//
//
//    .datepicker-action {
//        font-family: Aeonik, sans-serif;
//        font-size: 14px;
//        line-height: 18px;
//        font-weight: 400;
//        color: #4D4D4D;
//        padding: 16px 16px 16px 24px;
//        border-top: 1px solid #272338;
//        border-radius: 0 0 16px 16px;
//        align-items: center;
//        gap: 16px;
//        justify-content: space-between;
//        display: flex;
//
//        span {
//            margin: 0 auto 0 0;
//        }
//
//        .secondary-btn, .primary-btn {
//            font-size: 14px;
//            line-height: 20px;
//            padding: 9px 16px;
//        }
//    }
//}


//.selected-dates {
//    margin-top: 20px;
//}
//
//.selected-dates ul {
//    list-style-type: none;
//    padding-left: 0;
//}
//
//.selected-dates li {
//    margin: 5px 0;
//}
//
//.custom-day {
//    display: block;
//    width: 100%;
//    height: 100%;
//    text-align: center;
//    padding: 0.5rem;
//}
//
//.custom-day.focused {
//    background-color: #e6e6e6;
//}
//
//.custom-day.bg-primary {
//    background-color: #007bff;
//    color: white;
//}
//
//.datepicker-container {
//    display: flex;
//    align-items: center;
//}
//
//.calendar-btn {
//    background: none;
//    border: none;
//    cursor: pointer;
//    font-size: 20px;
//}
