<section class="single-page-title">
    <div class="container">
        <h1 class="section-title">Terms for www.wedshed.com.au</h1>
        <p>Please read below terms and conditions (terms) for wedshed.com.au.</p>
    </div>
</section>

<section class="single-page-content cms">
    <div class="container">
        <h2 class="font-22">Terms for www.wedshed.com.au</h2>
        <p>This agreement is between you, the user and/or visitor to the website (whom we refer to as “you”, “your” in this document) and WedShed ABN 91 604 674 964 (we refer to ourselves as “us”/“we”/“our” in this document).</p>
        <p>By accessing and using this website you agree to be bound by these terms and conditions (Terms) which may be varied by us from time to time and whether or not you have registered with the site.</p>
        <h2 class="font-22">TERMS OF SERVICE</h2>
        <p>Wedshed is an online platform which connects vendors (suppliers), property owners and venues managers with users looking to rent a space or use a service.</p>
        <p>The site (<a href="https://www.wedshed.com.au" target="_blank">wedshed.com.au</a>) can be used to facilitate the listing of spaces, including but not limited to houses, apartments, buildings, private properties, sheds, warehouses, function centres, restaurants, wineries, estates, farms, rooftops and/or
            other properties and/or its immediate surroundings for the use of an event.</p>
        <p>Such spaces are included in listings on the site and services by owners.</p>
        <p>You may view listings as an unregistered visitor to the site and services; however, if you wish to make enquiry, we take a small amount of personal information, such as the visitor’s name and email.</p>
        <p>For venue owners to create a listing, you must first register to create a WedShed account.</p>
        <p>Services are accessible at www.wedshed.com.au, www.wedshed.co.nz, www.wedshed.com and any other websites through which WedShed makes their services available.</p>
        <p>By using the site, you agree to comply with and be legally bound by the terms and conditions, whether or not you become a registered member.</p>
        <p>WedShed reserves all rights in respect of access to and use of the site, services and all collective content. Please also read our <a href="https://www.wedshed.com.au/privacy-policy" target="_blank">Privacy Policy</a> carefully: www.wedshed.com.au/privacy-policy. Failure to use the site in accordance with these terms may subject you to civil and/or criminal proceedings and/or penalties.</p>
        <h2 class="font-22">1. DEFINITIONS</h2>
        <p class="mb-12"><strong>In these Terms, unless the context otherwise requires:</strong></p>
        <ul>
            <li><strong>Account</strong> means an account created by the user or by WedShed admin which may be accessible via the website and through which a user (venue manager or vendor) may use to update information.</li>
            <li><strong>Account Password</strong> means a form of letters, numerals and/or symbols which is issued or approved by us in relation to an account and which may be used to access and operate upon an account.</li>
            <li><strong>Booking Enquiry(s)/request to view</strong> means an enquiry made by a user via the website concerning the merchant, the venue spaces, vendors or the merchant services.</li>
            <li><strong>Collective Content</strong> means member content and WedShed content.</li>
            <li><strong>Content</strong> means text, graphics, images, music, software (excluding the Application), audio, video, information or other materials.</li>
            <li><strong>Guest</strong> means a user who requests from a venue manager or property owner a booking or enquiry via the site, application or services.</li>
            <li><strong>Property Owner</strong> means a member who has a listing via the site, application and services.</li>
            <li><strong>Listing</strong> means a space or business that is listed by a venue or business representative via the site, application, and services.</li>
            <li><strong>Member</strong> means a person who completes WedShed’s account registration process, including but not limited to business owner or venue manager and guests, as described under “Account Registration” below.</li>
            <li><strong>Member Content</strong> means all content that a member posts, uploads, publishes, submits or transmits to be made available through the site, application or services.</li>
            <li><strong>Privacy Policy</strong> means our privacy policy as amended by us from time to time and which is accessible from the website.</li>
            <li><strong>Their</strong> means either the venue manager, vendor or property owner, or a representative for that business.</li>
            <li><strong>Us / we / our</strong> / means WedShed and it’s associated entities or permitted assigns of which we have provided you with notice by any means including via the website.</li>
            <li><strong>User</strong> means a person in relation to user of website and/or booking enquiry or actual bookings.</li>
            <li><strong>Vendor</strong> means any supplier that is listed or advertised on the site.</li>
            <li><strong>Venue</strong> means the venue spaces provided by the venue manager or property owner that is listed or advertised on the site.</li>
            <li><strong>Venue Manager</strong> means a member who has a listing via the site, application and services and who manages that WedShed account.</li>
            <li><strong>Venue Services</strong> means the services provided by the venue.</li>
            <li><strong>Website</strong> means the site(s) accessible at <a href="https://www.wedshed.com.au/" target="_blank">www.wedshed.com.au</a>, www.wedshed.co.nz and www.wedshed.com.</li>
            <li><strong>WedShed Content</strong> means all content that WedShed makes available through the site, application, associated social media or services, including any content licensed or created for or from a third party.</li>
            <li><strong>You / yours</strong> means any natural person who accesses the website and may include, without limitation, a user.</li>
        </ul>

        <h2 class="font-22">2. USE OF WEBSITE AND ACCOUNT</h2>
        <ol>
            <li><p>You must use the website and any account created for your business only in accordance with the terms.</p>
                <p>You use the website and the account at your sole risk.</p>
                <p>You are responsible for all activity on the account created for your business on the website.</li>
            <li>You must not use the website for any purpose other than that for which it is intended to be used by users generally, or for any malicious purpose.</li>
            <li>You must not copy, distribute, commercialise or otherwise use any WedShed website content otherwise than as permitted by law, the terms, or by our consent.</li>
            <li>You must not access, or attempt to access the website or the account from any place or in any circumstance where such access may be illegal.</li>
        </ol>

        <h2 class="font-22">3. CREATING AND USING AN ACCOUNT</h2>
        <ol>
            <li>All accounts set up are for the purpose of the venue manager or business owner to manage information and communication relating to the venue and/or business.</li>
            <li>In order for us to enable an account to be created, you must first be approved as a WedShed venue or vendor</li>
            <li>If you know or suspect of any unauthorised use of your account, you must request cancellation or disablement of the account as soon as possible.</li>
            <li>You may request us to terminate or disable your account at any time.</li>
            <li>We may suspend, terminate or disable, an account for any reason. We reserve the right to terminate an account on the basis of irreconcilable differences of values pertaining to moral issues, brand values and discrimination, such as same-sex marriage.</li>
            <li>We may send, whether electronically or otherwise, administrative and promotional materials to you from time to time, unless you opt out of receiving such materials from us via the website or by unsubscribing.</li>
            <li>Unless compelled to do so by law, or in accordance with the terms, we will not disclose any personal information you provide to us otherwise than in accordance with our Privacy Policy which is accessible on the website.</li>
            <li>We do not warrant that either or both the account or the website will be available at any particular time.</li>
        </ol>

        <h2 class="font-22">4. BOOKING ENQUIRY AND BOOKINGS</h2>
        <ol>
            <li>
                <p>We operate the website on which the vendor services and venue spaces are listed and promoted.</p>
                <p>We act as an advertising platform for the vendor and venue spaces.</p>
                <p>We do not charge site visitors for the use of the website, but we may receive payment from the vendors and venue owners for or in respect of the listing.</p>
            </li>
            <li>Without limitation of any other right we may have, we reserve the right to remove from the website any listing of a vendor or venue space.</li>
            <li>Subject to this clause, you may make a booking enquiry or viewing request of a venue or vendor via the website. We reserve the right to refuse such enquiries or requests for any reason.</li>
            <li>In order to make an enquiry via our website, you may be required to provide us with such personal information as we may require including your name and a valid email address.</li>
            <li>An enquiry made may result in the venue or vendor making an offer to you to provide services, including making available the venue space.</li>
            <li>
                <p>Any offer made by a venue or vendor in response to an enquiry for services, is made by the venue manager or representative or vendor or to their terms unless otherwise stated.</p>
                <p>If you accept an offer by a venue manager or representative or vendor in respect of their services, including the making available of the venue spaces, or the vendor’s time, you will enter into a contract directly with the such party and will be bound by their terms.</p>
            </li>
            <li>We may, as an advertising platform for the venue or vendor, correspond with you concerning their services, including but not limited to the making of offer(s) to provide you with venue or vendor services or venue spaces.</li>
            <li>All bookings made with advertising partners of WedShed listed on the website are made directly with the partner itself. WedShed does not hold responsibility in the event that a service contract is broken or that the service provider behaves in a regard that is not in alignment with the expectations of the couple/person engaging their services.</li>
        </ol>

        <h2 class="font-22">5. VENUE SPACES &amp; SERVICES</h2>
        <ol>
            <li>You as a site visitor agree and acknowledge that the venue (venue manager, owner or representative) is and remains wholly and solely responsible for making available the venue spaces and providing the venue services, and we do not assume any responsibility or liability in this regard.</li>
            <li>
                <p>You as a venue manager, owner or representative acknowledge and agree that you are responsible for the content provided to us that represents your listing, maintenance and updating of any and all listings posted.</p>
                <p>Accordingly, you represent and warrant that any listing posted and the booking of a space in a listing you post (i) will not breach any agreements you have entered into with any third parties, and (ii) will (a) be in compliance with all applicable laws, tax requirements, insurance requirements, and rules and regulations that may apply to any venue included in a listing posted (including having all required permits, licenses, insurances and registrations), and (b) not conflict with the rights of third parties.</p>
                <p>WedShed assumes no responsibility for a venue’s compliance with any agreements with or duties to third parties, applicable laws, rules and regulations.</p>
                <p>WedShed reserves the right, at any time and without prior notice, to remove or disable access to any listing for any reason, including listings that WedShed, in its sole discretion, considers to be objectionable for any reason, in violation of these terms, or otherwise harmful to the site or services, but shall not be liable for any consequences due to its failure to remove such access.</p>
            </li>
            <li>
                <p>If you are a venue owner, manager or representative, you understand and agree that Wedshed does not act as an insurer or as your contracting agent and any booking requested or made using the site or services must not and cannot be construed as the creation of a contract between WedShed and the owner, or WedShed and the couple.</p>
                <p>Regulating the renting of and use of your venue in terms of an agreement, remains the responsibility of the venue itself and WedShed shall not be party to thereto.</p>
                <p>Notwithstanding the foregoing, WedShed serves only as either the facilitator of such rental agreements through its role as an advertising and marketing platform.</p>
            </li>
            <li>We make no representation or warranty as to the quality, merchantability, fitness for purpose, correspondence to description, or suitability of any venue or vendor services, or venue spaces.</li>
            <li>Any listing of a venue or vendor appearing on the website is intended to be indicative only and is subject to change without notice.</li>
            <li>
                <p>Any information on the website is, and is intended to be, general in nature, and should not be relied upon without first making such independent enquiries as to the accuracy of the information as may be appropriate.</p>
                <p>Any photographs or other visual depictions are intended to be indicative only.</p>
                <p>Whilst we take every opportunity to verify the information provided to us by the venue or vendor, we rely on the venue or vendor to provide us with information that is accurate and we do not accept liability for errors or inaccuracies in the information provided to us by our advertising partners.</p>
            </li>
            <li>The venue provides the venue services and makes available the venue spaces pursuant to the venue terms, and the user is responsible for complying with, and ensuring any other persons who may attend a user event comply with, the venue terms.</li>
            <li>WedShed does not take any responsibility and cannot be held accountable for use of images in a venue profile where there has not been authorisation from photographer. It is the sole responsibility of the venue to provide images that have been approved from photographers.</li>
        </ol>

        <h2 class="font-22">6. VENDOR SERVICES</h2>
        <ol>
            <li>You agree and acknowledge that the vendor (supplier) is and remains wholly and solely responsible for making available their time and providing the vendor services, and we do not assume any responsibility or liability in this regard.</li>
            <li>
                <p>You acknowledge and agree that you are responsible for the content provided to us that represents your listing, maintenance and updating any and all listings posted.</p>
                <p>Accordingly, you represent and warrant that any listing posted (i) will not breach any agreements you have entered into with any third parties, and (ii) will (a) be in compliance with all applicable laws, tax requirements, and rules and regulations that may apply to your business included in a listing posted (including having all required permits, licenses and registrations), and (b) not conflict with the rights of third parties.</p>
                <p>WedShed assumes no responsibility for the vendor’s compliance with any agreements with or duties to third parties, applicable laws, rules and regulations.</p>
                <p>WedShed reserves the right, at any time and without prior notice, to remove or disable access to any listing for any reason, including listings that WedShed, in its sole discretion, considers to be objectionable for any reason, in violation of these terms, or otherwise harmful to the site or services, but shall not be liable for any consequences due to its failure to remove such access.</p>
            </li>
            <li>
                <p>If you are a vendor, you understand and agree that Wedshed does not act as an insurer or as your agent and any booking requested or made using the site or services must not and cannot be construed as the creation of a contract between WedShed and your business.</p>
                <p>Regulating the use of your services in terms of an agreement, remains the responsibility of the youself – the vendor – and WedShed shall not be party to thereto. Notwithstanding the foregoing, WedShed serves only as the facilitator through its role as an advertising and marketing platform.</p></li>
            <li>We make no representation or warranty as to the quality, merchantability, fitness for purpose, correspondence to description, or suitability of any services you provide.</li>
            <li>Any listing of vendor services appearing on the website is intended to be indicative only and is subject to change without notice.</li>
            <li>
                <p>Any information on the website is, and is intended to be, general in nature, and should not be relied upon without first making such independent enquiries as to the accuracy of the information as may be appropriate.</p>
                <p>Any photographs or other visual depictions are intended to be indicative only.</p>
                <p>Whilst we take every opportunity to verify the information provided to us by the vendor, we rely on the vendor to provide us with information that is accurate and we do not accept liability for errors or inaccuracies in the information provided to us by the vendor.</p>
            </li>
            <li>The vendor provides the vendor services and makes available the vendor’s time pursuant to the vendor terms, and the user is responsible for complying with, and ensuring any other persons who may attend a user event comply with, the vendor terms.</li>
        </ol>

        <h2 class="font-22">7. LIMITATION OF LIABILITY</h2>
        <ol>
            <li>Subject to this clause 7, and to the extent permitted by law, we exclude all implied warranties and conditions which might otherwise apply to these terms.</li>
            <li>Subject to this clause 7, and to the extent permitted by law, we and our employees, contractors or agents accept no liability arising out of or in connection with the website, the terms, the vendor, the venue or the venue services, including the venue spaces, whether arising at law, in equity or by virtue of any statute, except to the extent that the liability arises solely from the gross negligence of us, our employees, contractors or agents, in which case our liability, including liability for our failure to comply with a guarantee, shall be limited to, as we determine:
                <ul>
                    <li>the supply of the services pursuant to these terms again; or</li>
                    <li>the payment of the cost of having the services pursuant to these terms supplied again.</li>
                </ul>
            </li>
            <li>Nothing in this clause 7 shall be read or applied so as to purport to exclude, restrict or modify, or have the effect of excluding, restricting or modifying, the application of all or any of the provisions of the Competition and Consumer Act 2010 or any relevant State Act or Territorial Ordinance which by law cannot be excluded, restricted or modified.</li>
            <li>You indemnify us against any loss, damage or injury we incur or sustain as a direct or indirect result of breach of the terms, vendor terms or venue terms by you.</li>
        </ol>

        <h2 class="font-22">8. FINANCIAL TERMS FOR WEDSHED VENUE OWNERS OR VENUES AND VENDORS</h2>
        <ol>
            <li>If you are an venue owner, manager or representative, or a vendor listed on WedShed, and a booking is requested for your venue via our enquiry form, you will be required to respond to this enquiry form and provide all transactional services with the user/couple independent of WedShed.</li>
            <li>
                <p>WedShed reserves the right to terminate a partnership at any time for any reason that WedShed, in its sole discretion, considers to be objectionable for any reason, in violation of these terms, or otherwise harmful to the site or services reputation and business values.</p>
                <p>At the time of termination, WedShed reserves the right to offer a full or partial refund for the investment made with the platform, dependent on circumstances.</p>
            </li>
            <li>
                <p>If a venue or vendor has entered into a partnership with WedShed and payment is not received for the services the platform provides, WedShed will remove the venue or vendor from the site until payment is made.</p>
                <p>If WedShed has fulfilled part of the partnership agreement yet payment is still not made, legal proceedings to recover the owed sums may be undertaken.</p>
            </li>
        </ol>

        <h2 class="font-22">9. FINANCIAL TERMS FOR USERS</h2>
        <ol>
            <li>
                <p>Venue owners, managers or representatives are solely responsible for honoring any confirmed bookings and making available any space reserved through the site and services.</p>
                <p>Users who choose to enter into a transaction with a venue or vendor agree and understand that they may be required to enter into an agreement with the venue or vendor.</p>
            </li>
            <li>Users will be solely responsible for performing the obligations in terms of any such agreements, that Wedshed is not a party to such agreements, and that, with the exception of its payment obligations hereunder, WedShed disclaims all liability arising from or related to any such agreements.</li>
            <li>The venue or vendor has the option to display fees via their profile to the user. The venue or vendor reserves the right to change these fees at their discretion.</li>
            <li>As an <a href="https://www.airbnb.com.au/associates?from=embeddable" target="_blank" rel="noopener noreferrer nofollow">Airbnb Associate</a>, Wedshed may earn a fee when you click any link with refernces Airbnb.</li>
        </ol>

        <h2 class="font-22">10. CANCELLATION BY VENUE, VENDOR OR USER</h2>
        <ol>
            <li>If, as a user, you cancel your requested booking prior to your event date at the venue, the cancellation policy of the venue representative or the vendor will apply to such cancellation.</li>
            <li>If, as a venue or vendor, you cancel your partnership with WedShed and you are partnered under an annual package, a partial refund will be considered on a pro rata basis.</li>
            <li>If, as a venue or vendor, you cancel your partnership with WedShed and you are partnered under a month-by-month package, your profile will remain live on the site until the period of your last payment is complete.</li>
        </ol>

        <h2 class="font-22">11. GENERAL TERMS</h2>
        <ol>
            <li>As consideration for our promise to provide the website and the services pursuant to these terms, you promise to comply with these terms.</li>
            <li>If any of these terms are invalid or unenforceable it is to be read down so as to enable it to be valid and enforceable or otherwise severed so as to preserve the enforceability of the remaining terms.</li>
            <li>The terms contain the entire agreement between us and you and are governed by the laws of Australia.</li>
            <li>Your continued use of the website or the account indicates your acceptance of any amendment we make to these terms from time to time.</li>
            <li>The website may contain links to other websites, content or resources not provided by us. We do not necessarily endorse, sponsor or approve of any such websites, content or resources and accept no responsibility for them.</li>
            <li>We may vary the terms at any time without notice.</li>
            <li>Notwithstanding clause 3.7, if we determine to transfer, whether by sale or otherwise, any of the assets of the business through which we operate the website or otherwise provide related services, or any interest we may have in any entity which carries on such business, we may assign all our rights, title and interest in this agreement to the transferee which will include all details in relation to any you and the account.</li>
            <li>We reserve all our rights to the extent that they are not reserved by the terms.</li>
        </ol>
    </div>
</section>
