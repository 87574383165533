.header-wrap {
    position: sticky;
    top: 0;
    z-index: 990;

    &.header-sticky {
        //.main-search-box {
        //    display: none;
        //}

        @media screen and (max-width: 767px) {
            .main-search-panel {
                height: calc(100dvh - 47px);
            }
        }
    }
}

.top-strip {
    background: #661735;
    padding: 11px 0 10px;
    text-align: center;
    border-bottom: 1px solid #6D629D;
    position: relative;
    z-index: 7;

    .top-strip-info {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #FFFFFF;
        text-align: center;
        gap: 8px;
        text-decoration: none;
        vertical-align: top;
        align-items: center;
        display: inline-flex;

        @media screen and (max-width: 380px) {
            font-size: 13px;
        }
    }
}

.main-menu-wrap {
    background: #A8AED6;
    padding: 10px 0 9px;
    border-bottom: 1px solid #6D629D;
    position: relative;

    > .container {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        align-items: center;
        position: initial;
        padding-left: 80px;
        padding-right: 80px;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 8px 0 7px;
    }

    .logo {
        min-width: 136px;
        display: inline-block;

        img {
            height: auto;
        }

        @media screen and (max-width: 991px) {
            min-width: 116px;
            width: 116px;
        }

        @media screen and (max-width: 767px) {
            min-width: 136px;
            width: 136px;
        }
    }

    .navbar-toggler {
        display: none;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 0;
        background: transparent;
        cursor: pointer;
        outline: none;
        //margin-right: 38px;

        span {
            display: block;
            width: 20px;
            background-color: #242424;
            height: 2px;
            position: relative;
            border-radius: 20px;
            transition: all 0.25s ease 0s;

            &:after,
            &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                display: block;
                width: 100%;
                background-color: #242424;
                height: 2px;
                border-radius: 20px;
                transition: all 0.25s ease 0s;
            }

            &:after {
                bottom: -7px;
            }

            &:before {
                top: -7px;
            }
        }

        &:not(.collapsed) {
            span {
                background-color: transparent;

                &:before {
                    transform: rotate(45deg);
                    top: 0;
                    bottom: 0;
                }

                &:after {
                    transform: rotate(-45deg);
                    top: 0;
                    bottom: 0;
                }
            }
        }


        @media screen and (max-width: 767px) {
            display: inline-flex;
        }
    }

    .main-menu-inner {
        gap: 16px 31px;
        justify-content: flex-end;
        align-items: center;
        display: flex;

        @media screen and (min-width: 1299px) {
            min-width: 579px;
        }

        @media screen and (max-width: 1280px) {
            gap: 16px 24px;
        }

        @media screen and (max-width: 1199px) {
            gap: 16px 16px;
        }

        @media screen and (max-width: 991px) {
            gap: 16px 10px;
        }

        &.search-box-open {
            .navbar-collapse {
                display: none;
            }

            .menu-action {
                .main-search-box {

                    .search-icon-btn {
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                    }

                    form {
                        opacity: 1;
                        visibility: visible;
                        width: 269px;
                        overflow: visible;

                        @media screen and (max-width: 1199px) {
                            width: 200px;
                        }
                    }

                    .form-control {
                        padding-right: 36px;
                        padding-left: 52px;
                    }
                }
            }
        }
    }

    .navbar-collapse {
        display: block;

        &.show {
            display: block;
        }

        .header-btn {
            border-top: 1px solid #272338;
            padding: 16px;
            gap: 16px;
            justify-content: flex-end;
            align-items: center;
            display: none;
        }

        @media screen and (max-width: 767px) {
            display: none;
            background: #CDD1EE;
            position: absolute;
            //top: 100%;
            top: 47px;
            left: 0;
            right: 0;
            border-top: 1px solid #272338;
            border-bottom: 1px solid #272338;
            padding: 0;
            z-index: 9;

            .header-btn {
                display: flex;
            }
        }
    }

    .main-menu {
        gap: 16px 25px;
        display: flex;
        margin: 0;
        padding: 0;
        list-style-type: none;

        @media screen and (max-width: 1280px) {
            gap: 16px 16px;
        }

        @media screen and (max-width: 1200px) {
            gap: 10px;
        }

        @media screen and (max-width: 991px) {
            gap: 10px 8px;
        }

        @media screen and (max-width: 767px) {
            display: block;
            padding: 24px 16px;

            > li {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .menu-link {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            color: #272338;
            text-decoration: none;
            padding: 11px 16px;
            display: inline-block;
            vertical-align: middle;
            border: 1px solid transparent;
            border-radius: 16px;
            cursor: pointer;
            -webkit-transition: all 0.35s ease 0s;
            transition: all 0.35s ease 0s;

            &:hover, &.show {
                border-color: #272338;
                background: #94B3A5;
            }

            &.active {
                border-color: #272338;
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 2px;
                background: #272338;
                width: 0;
                transition: all 0.35s ease 0s;
                display: none;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 24px;
                bottom: 0;
                margin: auto;
                width: 13px;
                height: 10px;
                background-image: url("/assets/images/arrow-right-icon.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                transition: all 0.35s ease 0s;
                opacity: 0;
                border: none;
                vertical-align: middle;
                display: none;
            }

            @media screen and (max-width: 991px) {
                font-size: 14px;
                padding: 8px 10px;
                border-radius: 10px;
            }

            @media screen and (max-width: 767px) {
                font-size: 16px;
                padding: 0 24px 4px 0;
                border-radius: 0;
                border: none;
                background: transparent !important;
                position: relative;

                &:before {
                    display: block;
                }

                &:after {
                    display: inline-flex !important;
                }

                &:hover,
                &.active {
                    font-weight: 700;
                    color: #272338;

                    &:before {
                        width: calc(100% - 24px);
                    }

                    &:after {
                        right: 0;
                        opacity: 1;
                    }
                }
            }
        }

        .dropdown {
            position: initial;
        }

        .dropdown-toggle {
            &:after {
                display: none;
            }
        }

        .dropdown-menu {
            background: #CDD2EE;
            position: absolute;
            inset: 100% 0 auto 0 !important;
            width: 100%;
            transform: none !important;
            border: none;
            border-top: 2px solid #8A81B1;
            border-bottom: 2px solid #8A81B1;
            border-radius: 0;
            box-shadow: none;
            outline: none;
            padding: 0;
            display: none;

            &.show {
                display: block;
            }

            @media screen and (max-width: 767px) {
                top: 0 !important;
                border: none;
                height: calc(100dvh - 48px);
                overflow: auto;
            }

            .mob-back-link {
                padding: 11px 48px;
                border-bottom: 1px solid #272338;
                font-family: Aeonik, sans-serif;
                font-size: 18px;
                font-weight: 700;
                line-height: 25px;
                text-align: center;
                position: relative;
                display: none;
                cursor: pointer;

                @media screen and (max-width: 767px) {
                    display: block;
                }

                .mob-back-btn {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    left: 16px;
                    top: 8px;
                    height: 32px;
                    width: 32px;
                    cursor: pointer;
                }
            }

            .dropdown-menu-container {
                width: 1440px;
                max-width: 100%;
                padding: 0;
                margin: 0 auto;
                display: flex;

                @media screen and (max-width: 767px) {
                    flex-direction: column;
                    flex-wrap: wrap;
                }
            }

            .dropdown-menu-links {
                flex: 1;
                padding: 24px 24px 20px 80px;

                @media screen and (max-width: 1366px) {
                    padding-left: 40px;
                }

                @media screen and (max-width: 1280px) {
                    padding-left: 16px;
                }

                @media screen and (max-width: 767px) {
                    padding: 24px 16px;
                    border-bottom: 1px solid #272338;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    outline: none;

                    li {
                        font-family: Aeonik, sans-serif;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 500;
                        color: #272338;
                        margin: 0 0 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            color: #272338;
                            text-decoration: none;
                            font-weight: 500;
                            display: inline-block;
                            vertical-align: top;
                            padding-bottom: 4px;
                            padding-right: 24px;
                            position: relative;

                            &:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                height: 2px;
                                background: #272338;
                                width: 0;
                                transition: all 0.35s ease 0s;
                                opacity: 0;
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 24px;
                                bottom: 0;
                                margin: auto;
                                width: 13px;
                                height: 10px;
                                background-image: url("/assets/images/arrow-right-icon.svg");
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                transition: all 0.35s ease 0s;
                                opacity: 0;
                                display: inline-flex;
                            }

                            &:hover,
                            &.active {
                                font-weight: 700;
                                color: #272338;

                                &:before {
                                    width: calc(100% - 24px);
                                    opacity: 1;
                                }

                                &:after {
                                    right: 0;
                                    opacity: 1;
                                }
                            }

                        }
                    }
                }
            }

            .dropdown-menu-box {
                width: 33.82%;
                border-left: 2px solid #8A81B1;
                transition: all 0.35s ease 0s;

                @media screen and (max-width: 767px) {
                    border: none;
                    border-bottom: 1px solid #272338;
                    width: 100%;
                }

                .menu-box-title {
                    font-family: Aeonik, sans-serif;
                    font-size: 16px;
                    line-height: 20px;
                    padding-bottom: 4px;
                    padding-right: 24px;
                    font-weight: 500;
                    color: #272338;
                    margin: 0 0 13px;
                    transition: all 0.35s ease 0s;
                    position: relative;
                    display: inline-flex;

                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: 2px;
                        background: #272338;
                        width: 0;
                        transition: all 0.35s ease 0s;
                        opacity: 0;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 24px;
                        bottom: 0;
                        margin: auto;
                        width: 13px;
                        height: 10px;
                        background-image: url("/assets/images/arrow-right-icon.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        transition: all 0.35s ease 0s;
                        opacity: 0;
                        display: inline-flex;
                    }
                }

                a {
                    display: block;
                    padding: 24px 24px 22px;

                    @media screen and (max-width: 767px) {
                        padding: 24px 16px;
                    }

                    > span {
                        display: block;
                        font-family: Aeonik, sans-serif;
                        font-size: 12px;
                        line-height: 15px;
                        font-weight: 500;
                        color: #574E7E;
                        margin: 0 0 4px;
                        text-transform: uppercase;
                        transition: all 0.35s ease 0s;
                    }

                    &:hover,
                    &.active {
                        background: #94B3A5;

                        label {
                            color: #272338;
                        }

                        .menu-box-title {
                            font-weight: 700;
                            color: #272338;

                            &:before {
                                width: calc(100% - 24px);
                                opacity: 1;
                            }

                            &:after {
                                right: 0;
                                opacity: 1;
                            }
                        }
                    }
                }

                .dropdown-menu-box-img {
                    //height: 300px;
                    border-radius: 16px;
                    border: 1px solid #272338;
                    position: relative;
                    padding-bottom: 68.193%;

                    //@media screen and (max-width: 767px) {
                    //    height: 213px;
                    //}

                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 16px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }
    }

    .menu-action {
        gap: 16px;
        justify-content: flex-end;
        align-items: center;
        display: flex;

        @media screen and (max-width: 1200px) {
            gap: 10px;
        }

        @media screen and (max-width: 991px) {
            gap: 8px;
        }

        @media screen and (max-width: 767px) {
            gap: 8px;

            .header-btn {
                display: none;
            }
        }

        .main-search-box {
            display: block;
            min-height: 44px;
            min-width: 32px;
            position: relative;
            @media screen and (max-width: 1100px) {
                display: none;
            }

            form {
                //width: 269px;
                width: 0;
                overflow: hidden;
                opacity: 0;
                visibility: hidden;
                transition: all 0.35s ease 0s;
                margin: 0 0 0 auto;
            }

            .search-icon-btn {
                padding: 0;
                border: none;
                outline: none;
                background: transparent;
                cursor: pointer;
                display: inline-flex;
                width: 32px;
                height: 32px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
            }

            .form-control {
                padding-right: 0;
                padding-left: 0;
            }

        }

        .cart-btn {
            position: relative;
            border: none;
            outline: none;
            cursor: pointer;
            background: transparent;
            padding: 0;
            box-shadow: none;
            border-radius: 0;
            min-width: 30px;
            display: inline-flex;

            //@media screen and (max-width: 991px) {
            //  margin-left: 0;
            //}

            //@media screen and (max-width: 767px) {
            //  display: none;
            //}

            &:before {
                content: '';
                position: absolute;
                top: 1px;
                right: 1px;
                border-radius: 10px;
                width: 6px;
                height: 6px;
                background: #661735;
                display: none;
            }

            &.active {
                &:before {
                    display: block;
                }
            }
        }

        .mob-search-btn {
            padding: 0;
            border: none;
            outline: none;
            background: transparent;
            cursor: pointer;
            display: none;
            min-width: 32px;

            @media screen and (max-width: 1100px) {
                display: inline-flex;
            }
        }
    }

    .header-btn {
        gap: 8px;
        justify-content: flex-end;
        align-items: center;
        display: flex;

        @media screen and (max-width: 767px) {
            gap: 16px;
            flex-direction: column;
            justify-content: center;
        }

        .primary-btn,
        .secondary-btn {
            white-space: nowrap;

            @media screen and (max-width: 991px) {
                font-size: 14px;
                padding: 8px 12px;
                border-radius: 10px;
            }

            @media screen and (max-width: 767px) {
                font-size: 16px;
                padding: 11px 16px;
                border-radius: 16px;
                width: 100%;
            }
        }
    }
}

.main-search-box {
    position: relative;
    display: none;
    &.hide-search-box {
        display: none;
    }

    @media screen and (max-width: 1100px) {
        display: block;
    }

    form {
        position: relative;
        margin: 0;
    }

    .form-control {
        border: 1px solid #272338;
        border-radius: 16px;
        box-shadow: none;
        outline: none;
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #272338;
        height: auto;
        padding: 10px 36px 10px 52px;
        transition: all 0.35s ease 0s;

        &:focus {
            border-color: #7093FF;
            box-shadow: 0 0 0 1px #7093FF;
        }

        &::-webkit-input-placeholder {
            color: #999999;
        }

        &::-moz-placeholder {
            color: #999999;
        }

        &:-ms-input-placeholder {
            color: #999999;
        }

        &:-moz-placeholder {
            color: #999999;
        }

        &:focus {
            font-weight: 500;
        }
    }

    .search-btn {
        position: absolute;
        left: 18px;
        top: 9px;
        padding: 0;
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
        pointer-events: none;
        display: inline-flex;
    }

    .search-close-btn {
        position: absolute;
        top: 11px;
        right: 13px;
        padding: 0;
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
        z-index: 1;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        display: inline-flex;
    }

    &.mob-search-box {
        margin-top: 9px;
        padding: 16px 16px 8px;
        border-top: 1px solid #272338;
    }
}

.hide-search {
    display: none;
}

.main-search-panel {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background: #FFFBF8;
    border-top: 1px solid #272338;

    @media screen and (max-width: 767px) {
        height: calc(100dvh - 87px);
        padding-bottom: 52px;
        overflow-x: auto;
    }

    .loader {
        background: rgba(255, 251, 248, 0.7);
        backdrop-filter: blur(5px);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
        align-items: center;
        justify-content: center;
        display: flex;

        span {
            color: #7093FF;
            width: 7px;
            aspect-ratio: 1;
            border-radius: 50px;
            box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
            transform: translateX(-38px);
            animation: l21 0.6s infinite alternate linear;
            display: inline-block;
        }

        @keyframes l21 {
            50% {
                box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
            }
            100% {
                box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
            }
        }
    }
}
