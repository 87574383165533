import {
    Component,
    OnInit,
    Inject,
    PLATFORM_ID,
    HostListener,
    ViewChild,
    ElementRef,
    ViewEncapsulation
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import Splide from '@splidejs/splide';
import {GeneralService} from 'src/app/core/services/api-service/general.service';
import {ResponseHandlerService} from 'src/app/core/services/common-service/response-handler.service';
import {lastValueFrom} from 'rxjs';
import {CommonConstants, RoutesConstants} from '@coreconstant';
import {VenueVendorFooterComponent} from '../../venue-vendor-footer/venue-vendor-footer.component';
import {VenueService} from 'src/app/core/services/api-service/venue.service';
import {RequestParamModel} from 'src/app/core/models/request-param-model';
import {BlogService} from 'src/app/core/services/api-service/blog.service';
import {SetImageDimensionsDirective} from 'src/app/core/directives/set-image-dimensions.directive';
import {RouterModule} from '@angular/router';
import {SeoService} from '../../../core/services/common-service/seo.service';

@Component({
    selector: 'app-venue-list',
    imports: [VenueVendorFooterComponent, SetImageDimensionsDirective, RouterModule],
    templateUrl: './venue-list.component.html',
    styleUrl: './venue-list.component.scss',
    encapsulation: ViewEncapsulation.None
})

export class VenueListComponent implements OnInit {
    requestParamModelForFeatured!: RequestParamModel;
    requestParamModel!: RequestParamModel;
    commonConstants = CommonConstants
    routesConstants = RoutesConstants
    isSticky: boolean = false;
    isClassToggled: boolean = false;
    countryList = []
    stateList = []
    filterStateList = []
    selectedCountryIds = []
    selectedCountryNames = []
    firstSomeCountries = []
    selectedStateIds = []
    selectedStateNames = []
    firstSomeStates = []
    selectedRegionIds = []
    selectedRegionNames = []
    firstSomeRegions = []
    filterOpen: string = ''
    venueStyles = []
    selectedVenueStyle = ''
    featuredVenues = []
    venueList = []
    currentPage = 1
    pageCount;
    totalCount = 0;
    isFilterApply = false
    venueSplides: any
    splideInstances: any[] = [];
    venueInspirationList = []
    @ViewChild('parentSlider', {static: false}) parentSlider!: ElementRef;
    @ViewChild('filterListSlider', {static: false}) filterListSlider!: ElementRef;
    @ViewChild('venueFilterWrap', {static: true}) venueFilterWrap!: ElementRef;
    isRegionListEmpty: boolean = false;
    isGetResponse: boolean = false

    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        this.isSticky = window.scrollY > 10;
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event) {
        if (this.venueFilterWrap && !this.venueFilterWrap.nativeElement.contains(event.target as Node)) {
            this.filterOpen = '';
        }
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _generalService: GeneralService,
        private _responseHandlerService: ResponseHandlerService,
        private _venueService: VenueService,
        private _seoService: SeoService,
        private _blogService: BlogService
    ) {
        this.requestParamModelForFeatured = new RequestParamModel();
        this.requestParamModel = new RequestParamModel();
        this.requestParamModel.pageSize = this.commonConstants.PAGE_SIZE_COUNT
        this.requestParamModel.pageIndex = 1
        // this.requestParamModel.addDefaultFilter('isFeatured', '0', 'number');
        this.setMetaTags()
    }

    ngOnInit(): void {
        this.getCountries()
        this.getStates()
        this.getVenueStyles()
        this.getFeaturedVenueList()
        this.getVenueList()
        this.getVenueInspiration()
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Unique & Affordable Wedding Venues | Wedshed',
            description: 'Searching for the perfect wedding venue? WedShed connects couples with stunning locations across Australia—barns, estates, waterfronts, and more!',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.VENUE_NAVIGATION,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    /**
     * Venue style filter slider
     */
    venueStyleFilterSlider() {
        if (isPlatformBrowser(this.platformId) && this.filterListSlider && this.filterListSlider.nativeElement) {
            new Splide(this.filterListSlider.nativeElement, {
                gap: '16px',
                autoplay: false,
                drag: 'free',
                perMove: 1,
                autoWidth: true,
                pagination: false,
                focus: 0,
                omitEnd: true,
            }).mount();
        }
    }

    /**
     * Venue list slider
     */
    venueListImageSlider() {
        if (isPlatformBrowser(this.platformId)) {
            this.venueSplides = [];
            const splides = document.querySelectorAll('.box-slider');

            splides.forEach((element) => {
                if (!element.classList.contains('splide-initialized')) {
                    const splide = new Splide(element as HTMLElement, {
                        type: 'loop',
                    }).mount();

                    this.venueSplides.push(splide);

                    // Mark this element as initialized
                    element.classList.add('splide-initialized');
                }
            });
        }
    }

    /**
     * Featured Venue list slider
     */
    featuredVenueListSlider() {
        if (isPlatformBrowser(this.platformId)) {
            // Initialize the parent slider
            if (this.parentSlider && this.parentSlider.nativeElement) {
                if (!this.parentSlider.nativeElement.classList.contains('featured-splide-initialized')) {
                    const parentSlider = new Splide(this.parentSlider.nativeElement, {
                        type: 'slide',
                        perPage: 4,
                        pagination: false,
                        arrows: true,
                        drag: false,
                        gap: '5px',
                        perMove: 1,
                        breakpoints: {
                            480: {
                                type: 'loop',
                                perPage: 2,
                                perMove: 1,
                                autoWidth: true,
                            },
                            767: {
                                type: 'loop',
                                perPage: 2,
                                perMove: 1,
                                autoWidth: true,
                            },
                            991: {
                                type: 'loop',
                                perPage: 3,
                                perMove: 1,
                                autoWidth: true,
                            },
                            1024: {
                                type: 'loop',
                                perPage: 4,
                                perMove: 1,
                                autoWidth: true,
                            }
                        },
                    });

                    parentSlider.on('mounted', () => {
                        this.featuredVenueListImageSlider()
                    });

                    // Mount the parent slider
                    parentSlider.mount();

                    // Mark this element as initialized
                    this.parentSlider.nativeElement.classList.add('featured-splide-initialized');
                }
            }
        }
    }

    /**
     * Featured Venue list images slider
     */
    featuredVenueListImageSlider() {
        if (isPlatformBrowser(this.platformId)) {
            // Find and initialize all nested sliders after the parent slider is mounted
            document.querySelectorAll('.featured-image-slider').forEach((sliderElement, index) => {
                // Avoid initializing the parent slider again
                if ((sliderElement as HTMLElement).id !== 'parent-slider') {

                    // Check if an instance already exists, if yes, destroy it
                    if (this.splideInstances[index]) {
                        this.splideInstances[index].destroy();
                    }

                    const featuredSplide = new Splide(sliderElement as HTMLElement, {
                        type: 'loop',
                        perPage: 1,
                        pagination: true,
                        arrows: true,
                        perMove: 1,
                        // autoWidth: true,
                    }).mount();

                    // Store the instance for future reference
                    this.splideInstances[index] = featuredSplide;
                }
            });
        }

    }

    sliderReInit() {
        if (isPlatformBrowser(this.platformId)) {
            // if (this.venueSplides) {
            //     this.venueSplides.forEach((element) => {
            //         // element.destroy(); // Destroy existing instance
            //     });
            // }
            setTimeout(() => this.venueListImageSlider(), 100); // Reinitialize after a short delay
        }
    }

    /**
     * Get the country list
     */
    getCountries() {
        var getListing = this._generalService.getCountries()
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.countryList = res["data"]
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Get the state list
     */
    getStates() {
        var getListing = this._generalService.getStates()
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.stateList = res["data"]
                this.filterStateList = res["data"]
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Get the venue styles
     */
    getVenueStyles() {
        var getListing = this._generalService.getVenueStyles()
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.venueStyles = res["data"]
                setTimeout(() => {
                    this.venueStyleFilterSlider()
                })
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Change the country
     * @param country
     */
    onSelectCountry(country: any) {
        const index = this.selectedCountryIds.indexOf(country.id);
        if (index != -1) {
            this.selectedCountryIds.splice(index, 1);
        } else {
            this.selectedCountryIds.push(country.id);
        }

        this.selectedCountryNames = this.countryList
            .filter(country => this.selectedCountryIds.includes(country.id))
            .map(country => country.name);

        this.firstSomeCountries = this.selectedCountryNames.slice(0, this.commonConstants.MAX_SHOW_COUNT_FILTER);

        this.selectedStateIds = [], this.selectedStateNames = [], this.firstSomeStates = []
        this.selectedRegionIds = [], this.selectedRegionNames = [], this.firstSomeRegions = []
        this.isRegionListEmpty = false
    }

    /**
     * Change the state
     * @param state
     */
    onSelectState(state: any) {
        const index = this.selectedStateIds.indexOf(state.id);
        if (index != -1) {
            this.selectedStateIds.splice(index, 1);
        } else {
            this.selectedStateIds.push(state.id);
        }

        this.selectedStateNames = this.stateList
            .filter(state => this.selectedStateIds.includes(state.id))
            .map(state => state.name);

        this.firstSomeStates = this.selectedStateNames.slice(0, this.commonConstants.MAX_SHOW_COUNT_FILTER);

        this.selectedRegionIds = [], this.selectedRegionNames = [], this.firstSomeRegions = []
    }

    /**
     * Change the region
     * @param region
     */
    onSelectRegion(region: any) {
        const index = this.selectedRegionIds.indexOf(region.id);
        if (index != -1) {
            this.selectedRegionIds.splice(index, 1);
        } else {
            this.selectedRegionIds.push(region.id);
        }

        // this.selectedRegionNames = this.stateList.filter((state) =>
        //     state.regionList.filter((region) => this.selectedRegionIds.includes(region.id))
        // )
        //     .flatMap((state) =>
        //         state.regionList
        //             .filter((region) => this.selectedRegionIds.includes(region.id))
        //             .map((region) => region.name)
        //     );
        this.selectedRegionNames = this.stateList.reduce((result, state) => {
            const names = state.regionList
                .filter((region) => this.selectedRegionIds.includes(region.id))
                .map((region) => region.name);
            return result.concat(names);
        }, []);

        this.firstSomeRegions = this.selectedRegionNames.slice(0, this.commonConstants.MAX_SHOW_COUNT_FILTER);
    }

    toggleBtnClassForFilter(filterName: string): void {
        this.filterOpen = filterName

        if (filterName == 'state' || filterName == 'region') {
            if (this.selectedCountryIds.length > 0)
                this.filterStateList = this.stateList.filter(state => this.selectedCountryIds.includes(state.countryId))
            else
                this.filterStateList = this.stateList
        }

        if (filterName == 'region') {
            if (this.selectedStateIds.length > 0) {
                this.filterStateList = this.filterStateList.filter(state => this.selectedStateIds.includes(state.id))
                this.isRegionListEmpty = this.filterStateList.every(item => item.regionList.length === 0);
            }
        }
    }

    /**
     * Get the featured venues data
     */
    getFeaturedVenueList() {
        delete this.requestParamModelForFeatured.pageSize;
        this.requestParamModelForFeatured.addDefaultFilter('isFeatured', '1', 'number');

        var getListing = this._venueService.getVenueList(this.requestParamModelForFeatured)
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.featuredVenues = res["data"]["records"]
                setTimeout(() => {
                    this.featuredVenueListSlider()
                })
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Get All venue with filters
     */
    getVenueList(isLoadMore: boolean = false) {
        this.isFilterApply = false
        this.requestParamModel.removeDefaultFilter('isFeatured');
        if (this.requestParamModel && this.requestParamModel.filter != '') {
            this.isFilterApply = true
        } else {
            this.requestParamModel.addDefaultFilter('isFeatured', '0', 'number');
        }

        var getListing = this._venueService.getVenueList(this.requestParamModel)
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.isGetResponse = true
                let data = res["data"]
                this.currentPage = data["currentPage"]
                this.pageCount = data["pageCount"]
                this.totalCount = data["totalCount"]
                if (isLoadMore)
                    this.venueList = this.venueList.concat(data["records"])
                else
                    this.venueList = data["records"]

                this.sliderReInit()

                if (!this.isFilterApply && this.featuredVenues && this.featuredVenues.length > 0) {
                    setTimeout(() => {
                        this.featuredVenueListSlider()
                    })
                }
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Click on the load more and get the venue data
     */
    loadMoreVenues() {
        this.requestParamModel.pageIndex = this.requestParamModel.pageIndex + 1
        this.getVenueList(true)
    }

    /**
     * Venue style change
     * @param styleId
     */
    venueStyleChange(styleId: any) {
        event.preventDefault()
        this.selectedVenueStyle = styleId
        this.requestParamModel.pageIndex = 1

        this.requestParamModel.removeFilter('venueStylesId')
        this.requestParamModel.setFilter('venueStylesId', this.selectedVenueStyle, 'number')
        this.getVenueList()
    }

    /**
     * get venue data with the filter
     */
    searchData() {
        this.removeClass()
        this.filterOpen = ''
        this.requestParamModel.pageIndex = 1
        this.requestParamModel.removeFilter('countryId')
        this.requestParamModel.removeFilter('stateId')
        this.requestParamModel.removeFilter('regionId')

        if (this.selectedCountryIds.length > 0)
            this.requestParamModel.setFilter('countryId', this.selectedCountryIds, 'number')

        if (this.selectedStateIds.length > 0)
            this.requestParamModel.setFilter('stateId', this.selectedStateIds, 'number')

        if (this.selectedRegionIds.length > 0)
            this.requestParamModel.setFilter('regionId', this.selectedRegionIds, 'number')

        this.getVenueList()
    }

    /**
     * Used for mobile design
     */
    toggleClass(): void {
        this.isClassToggled = !this.isClassToggled;
    }

    /**
     * Used for mobile design
     */
    removeClass(): void {
        this.isClassToggled = false;
    }

    /**
     * Click on the clear all button
     */
    clearAll() {
        this.filterOpen = ''
        this.selectedCountryIds = [], this.selectedCountryNames = [], this.firstSomeCountries = []
        this.selectedStateIds = [], this.selectedStateNames = [], this.firstSomeStates = []
        this.selectedRegionIds = [], this.selectedRegionNames = [], this.firstSomeRegions = []
    }

    /**
     * Click on the Clear search
     */
    clearSearch() {
        event.preventDefault()
        this.requestParamModel.pageIndex = 1
        this.requestParamModel.filter = '';
        this.selectedVenueStyle = '';
        this.clearAll();
        this.getVenueList();
    }

    getVenueInspiration() {
        var getListing = this._blogService.getInspirationBlogs()
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.venueInspirationList = res["data"];
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    setDefaultImage(event: Event, type = 'image') {
        let image = this.commonConstants.DEFAULT_NO_IMAGE
        if (type == 'icon') {
            image = this.commonConstants.ALL_STYLE_ICON
        }
        (event.target as HTMLImageElement).src = image
    }
}
