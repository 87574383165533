//======================== Page Title Start ========================
.page-title-wrap {
    background: #94B3A5;
    border-bottom: 1px solid #272338;
    padding: 32px 0;
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #4D4D4D;
    text-align: center;
    position: sticky;
    top: 64px;
    z-index: 7;
    transition: all 0.35s ease 0s;

    @media screen and (max-width: 991px) {
        top: 58px;
    }

    @media screen and (max-width: 767px) {
        top: 48px;
        padding: 24px 0 16px;
        //z-index: 999;
    }

    &.page-title-sticky {
        padding: 24px 0;

        @media screen and (max-width: 767px) {
            padding: 16px 0;
        }

        .page-title-info {
            display: none;
        }
    }

    .page-title-info {
        margin: 0 auto 24px;
    }

    h1 {
        text-align: center;
        margin: 0 0 16px;
    }

    p {
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .device-filter-open {
        z-index: 999;
        transition: none;

        .main-filter-wrap {
            display: flex;
        }
    }
}

//======================== Page Title End ========================

//======================== Main Filter Start ========================
.filter-item-btn {
    padding: 15px 18px;
    width: 100%;
    border: none;
    outline: none;
    background: #fff;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: left;

    @media screen and (max-width: 767px) {
        border-radius: 16px;
    }

    .filter-btn-label {
        font-family: Aeonik, sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        display: flex;
        gap: 8px;
        text-transform: uppercase;
        color: #5F8B77;
        margin-bottom: 4px;

        span {
            font-weight: 400;
            color: #808080;
        }
    }

    .filter-btn-data {
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #808080;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        padding-right: 20px;
        position: relative;

        span {
            position: absolute;
            right: 0;
            top: 0;
            font-weight: 400;
            color: #808080;
        }


        &.selected {
            color: #272338;
            font-weight: 500;
        }
    }
}

.main-filter-wrap {
    margin: 0 auto;
    width: 950px;
    max-width: 100%;
    position: relative;
    display: flex;
    border: 1px solid #272338;
    border-radius: 16px;
    background: #94B3A5;

    &.filter-open {
        .filter-item {
            .filter-item-btn {
                opacity: 0.7;
            }
        }
    }

    @media screen and (max-width: 767px) {
        position: fixed;
        top: 0;
        margin: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        border-left: none;
        border-right: none;
        border-radius: 0;
        max-height: 100dvh;
        overflow: auto;
        display: none;
    }

    .main-filter-head {
        font-family: Aeonik, sans-serif;
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #272338;
        text-align: center;
        padding: 15px 50px;
        position: sticky;
        top: 0;
        background: #94B3A5;
        border-bottom: 1px solid #272338;
        z-index: 3;
        display: none;

        @media screen and (max-width: 767px) {
            display: block;
        }

        .filter-back-btn {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 16px;
            height: 32px;
            margin: auto;
            cursor: pointer;
            z-index: 1;
            display: inline-flex;
        }
    }

    .main-filter-inner {
        max-width: calc(100% - 52px);
        flex: 1;
        display: flex;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 10px;
            max-width: 100%;
            padding: 16px;
        }

        .filter-item {
            flex: 1;
            border-right: 1px solid #272338;
            width: 33.33%;
            max-width: 33.33%;

            @media screen and (max-width: 767px) {
                width: 100%;
                max-width: 100%;
                border: 1px solid #272338;
                border-radius: 16px;
            }

            @media screen and (min-width: 768px) {
                &:first-child {
                    border-radius: 16px 0 0 16px;

                    .filter-item-btn {
                        border-radius: 15px 0 0 15px;
                    }
                }
            }

            &.filter-show {
                background: #fff;

                .filter-item-btn {
                    opacity: 1;

                    @media screen and (max-width: 767px) {
                        border-radius: 16px 16px 0 0;
                    }
                }

                .filter-item-dropdown {
                    display: flex;
                }
            }
        }
    }

    .main-filter-action {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
            padding: 15px 16px;
            border-top: 1px solid #272338;
            position: sticky;
            bottom: 0;
            background: #94B3A5;
            z-index: 3;
        }

        .secondary-btn {
            display: none;
            background: transparent;
            border: 1px solid transparent;
            border-radius: 0;

            @media screen and (max-width: 767px) {
                display: inline-flex;
            }
        }

        .primary-btn {
            border-radius: 0 15px 15px 0;
            border: none;
            width: 52px;
            min-width: 52px;
            padding: 16px 0;
            height: 100%;

            span {
                display: none;
            }

            img {
                width: 16px;
                height: 16px;
                min-width: 17px;
                transition: all 0.35s ease 0s;
            }

            @media screen and (max-width: 767px) {
                border-radius: 16px;
                border: 1px solid #272338;
                width: inherit;
                min-height: inherit;
                padding: 10px 24px;
                height: auto;
                gap: 8px;

                span {
                    display: inline-block;
                }
            }
        }

    }

    .filter-item-dropdown {
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        left: 0;
        gap: 16px;
        background: #fff;
        border: 1px solid #272338;
        border-radius: 20px;
        padding: 24px;
        flex-wrap: wrap;
        display: none;

        @media screen and (max-width: 767px) {
            position: relative;
            top: auto;
            border: none;
            border-radius: 0 0 15px 15px;
            padding: 16px;
            gap: 12px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 16px;
                left: 16px;
                border-top: 1px solid #272338;
            }
        }

        .map-filter {
            min-height: 160px;
            padding: 16px;
            border-radius: 16px;
            border: 1px solid #272338;
            width: calc(25% - 12px);
            cursor: pointer;
            transition: all 0.35s ease 0s;
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            text-align: center;
            color: #272338;
            gap: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: flex;

            @media screen and (max-width: 767px) {
                padding: 16px 13px;
                min-height: 177px;
                width: calc(50% - 6px);
            }

            img {
                height: 72px;
                width: 72px;
                object-fit: contain;
            }

            &:hover {
                background: #FFF5EE;
            }

            &.selected {
                background: #FFE6D5;
            }
        }

        &.province-filter {
            .map-filter {
                //width: calc(14.315% - 14px);
                width: calc(16.72% - 14px);
                //text-transform: uppercase;

                @media screen and (max-width: 991px) {
                    width: calc(25% - 12px);
                }

                @media screen and (max-width: 767px) {
                    width: calc(25% - 9px);
                    min-height: 146px;
                    font-size: 14px;
                    line-height: 18px;
                }

                @media screen and (max-width: 575px) {
                    //width: calc(33.33% - 8px);
                    width: calc(50% - 6px);
                }
            }
        }

        &.region-filter {
            flex-direction: column;
            text-align: left;
            gap: 0;
            overflow: hidden;

            .region-title {
                font-family: Aeonik, sans-serif;
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
                color: #5F8B77;
                margin: 0 0 8px;
                text-transform: uppercase;
            }

            .filter-region-list {
                gap: 8px;
                flex-wrap: wrap;
                display: flex;
                margin: 0 0 16px;

                label {
                    font-family: Aeonik, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                    text-align: center;
                    color: #272338;
                    border-radius: 24px;
                    padding: 6px 12px;
                    border: 1px solid #272338;
                    cursor: pointer;
                    transition: all 0.35s ease 0s;

                    &.selected {
                        background: #272338;
                        color: #fff;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .filter-next-btn {
            margin: 4px 0 0;
            width: 100%;
            display: none;

            @media screen and (max-width: 767px) {
                display: block;
            }

            .secondary-btn {
                gap: 8px;
                width: 100%;
                padding: 9px 16px;

                img {
                    animation: arrowMove 2s infinite;
                }

                @keyframes arrowMove {

                    0%,
                    20%,
                    50%,
                    80%,
                    100% {
                        transform: translateY(0);
                    }

                    40% {
                        transform: translateY(-10px);
                    }

                    60% {
                        transform: translateY(-5px);
                    }
                }
            }
        }

        .region-filter-inner {
            gap: 0;
            flex-direction: column;
            text-align: left;
            display: flex;

            @media screen and (min-width: 991px) {
                max-height: 400px;
                margin: -24px;
                padding: 24px;
                overflow: auto;
            }

            @media screen and (min-width: 1300px) {
                max-height: 210px;
            }

            @media screen and (min-width: 1366px) {
                max-height: 320px;
            }

            @media screen and (min-width: 1400px) {
                max-height: 400px;
            }

            + .filter-next-btn {
                margin-top: 16px;
            }
        }
    }
}

.main-mob-filter {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    gap: 8px;
    justify-content: center;
    display: none;

    @media screen and (max-width: 767px) {
        display: flex;
    }

    @media screen and (max-width: 480px) {
        min-width: inherit;
        width: 100%;
    }

    .filter-item-btn {
        border: 1px solid #272338;
        border-radius: 16px;
        padding-top: 11px;
        padding-bottom: 11px;
        max-width: calc(100% - 72px);
        flex: 1;
    }

    .secondary-btn {
        padding: 0;
        width: 64px;
        min-width: 64px;
        position: relative;
        background: #fff;

        &::before {
            content: '';
            position: absolute;
            top: 8px;
            right: 8px;
            width: 10px;
            height: 10px;
            border-radius: 20px;
            background: #661735;
            display: none;
        }

        &.active {
            &::before {
                display: block;
            }
        }
    }

    &.selected {
        .filter-item-btn {
            .filter-btn-label {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                color: #272338;
                text-transform: none;
                margin-bottom: 2px;
            }

            .filter-btn-data {
                font-size: 14px;
                line-height: 18px;
                color: #4D4D4D;
            }
        }

        .secondary-btn {
            &::before {
                display: block;
            }
        }
    }
}

.filter-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.5);
    z-index: 6;
    display: none;

    &.show {
        display: block;
    }
}

//======================== Main Filter End ========================

//======================== Tabbing and Filter Section Css Start ========================
.tabbing-filter {
    border-bottom: 1px solid #272338;
    min-height: 83px;

    .tabbing-filter-inner {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 20px;

        .filter-slider {
            padding: 0 30px;
            width: calc(100% - 200px);
            flex: 1;

            @media screen and (max-width: 767px) {
                padding: 0 22px;
            }
        }

        .splide {
            .splide__list {
                //padding: 0 35px !important;
            }

            .link {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                flex-direction: column;
                padding: 24px 16px 16px;
                position: relative;
                //border-bottom: 2px solid transparent;

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: auto;
                    right: 0;
                    height: 2px;
                    width: 0;
                    background: #7093FF;
                    transition: all 0.35s ease 0s;
                }

                .icon {
                    width: 24px;
                    height: 24px;

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: contain;
                        object-position: center;
                        transition: all 0.35s ease 0s;
                        filter: invert(30%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(50%) contrast(100%);
                    }
                }

                span {
                    font-family: Aeonik, sans-serif;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 400;
                    color: #272338;
                }

                &:hover, &.active {
                    //border-bottom-color: #7093FF;

                    &:before {
                        width: 100%;
                        right: auto;
                        left: 0;
                    }

                    .icon {
                        img {
                            //filter: invert(53%) sepia(82%) saturate(354%) hue-rotate(194deg) brightness(94%) contrast(92%);
                            filter: none;
                        }
                    }
                }

                &.active {
                    span {
                        font-weight: 500;
                    }
                }
            }

            .splide__arrows {
                .splide__arrow {
                    &:disabled {
                        opacity: 0 !important;
                    }
                }
            }
        }

        .right-side-action-btn {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 25px 0 18px;

            .secondary-btn {
                padding: 9px 24px;
                display: inline-flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
}

//======================== Tabbing and Filter Section Css End ========================

//Search Results not Found
.search-results-not-found {
    padding: 52px 0;
    //border-bottom: 1px solid #272338;

    .clear-search-btn {
        color: #272338;
    }
}

//Showing Results
.showing-results-wrap {
    padding: 8px 0;
    background: #fff;
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #4D4D4D;
    border-bottom: 1px solid #272338;

    .container {
        align-items: center;
        justify-content: space-between;
        display: flex;
    }

    .secondary-btn {
        font-size: 14px;
        line-height: 20px;
        border-radius: 16px;
        border-color: transparent;
        padding: 9px 16px;
        background: transparent;

        &:hover {
            background: #F1F5FF;
            border-color: #A9BEFF;
        }
    }
}

//======================== Featured Venues Section Css Start ========================
.featured-venues-section {
    background: #FFE6D5;
    border-bottom: 1px solid #272338;
    padding: 32px 0;
    min-height: 494px;

    @media screen and (max-width: 1440px) {
        min-height: 492px;
    }

    @media screen and (max-width: 1366px) {
        min-height: 473px;
    }

    @media screen and (max-width: 1280px) {
        min-height: 464px;
    }

    @media screen and (max-width: 1200px) {
        min-height: 444px;
    }

    @media screen and (max-width: 1100px) {
        min-height: 416px;
    }

    @media screen and (max-width: 767px) {
        padding: 24px 0;
    }

    @media screen and (max-width: 360px) {
        min-height: 410px;
    }

    @media screen and (max-width: 350px) {
        min-height: 380px;
    }

    .section-semi-title {
        margin: 0 0 18px;

        @media screen and (max-width: 767px) {
            margin: 0 0 10px;
        }
    }

    .card-list {
        @media screen and (max-width: 991px) {
            display: flex;
            align-items: center;
            overflow: auto;

            .card-main {
                min-width: 250px;

            }
        }
    }

    //#parent-slider {
    //    @media screen and (max-width: 991px) {
    //        .splide__track {
    //            padding: 0 10px !important;
    //            margin: 0 -16px;
    //        }
    //    }
    //}
}

//======================== Featured Venues Section Css End ========================

//======================== Venues Section Css Start ========================
.venues-section {
    padding: 40px 0;
}

//======================== Venues Section Css End ========================

//======================== Venue Articles Section Css Start ========================
.venues-articles-section {
    background: #FFE6D5;
    border-top: 1px solid #272338;
    border-bottom: 1px solid #272338;
    padding: 32px 0 48px;

    @media screen and (max-width: 767px) {
        padding: 24px 0;
    }

    &.dusty-bg {
        background: #EFB7A6;
    }

    .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        margin: 0 0 10px;
        flex-wrap: wrap;

        .section-semi-title {
            font-size: 32px;
            line-height: 38px;
            margin: 0;

            @media screen and (max-width: 767px) {
                font-size: 26px;
                line-height: 31px;
            }
        }

        .secondary-btn {
            gap: 8px;

            @media screen and (min-width: 768px) {
                padding: 9px 16px;
                font-size: 14px;
                line-height: 20px;
            }

            img {
                position: relative;
                right: 0;
                transition: all 0.35s ease 0s;
            }

            &:hover {
                img {
                    right: -3px;
                }
            }
        }
    }

    .card-list {
        //grid-template-columns: repeat(4, 1fr);
        //
        //@media screen and (max-width: 1240px) {
        //    grid-template-columns: repeat(3, 1fr);
        //}
        //
        //@media screen and (max-width: 920px) {
        //    grid-template-columns: repeat(2, 1fr);
        //}
        //
        //@media screen and (max-width: 640px) {
        //    grid-template-columns: repeat(1, 1fr);
        //}


        .card-main {
            .slider-custom {
                margin: 0 0 12px;
            }

            &:hover {
                border-color: transparent;
                background: transparent;
            }
        }
    }
}

//======================== Venue Articles Section Css End ========================
