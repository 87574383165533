<section class="breadcrumb-wrap">
    <div class="container">
        <ul>
            <li><a [routerLink]="['/'+ routesConstants.WEDSPIRATION]">Advice & Ideas</a></li>
            <li><img src="assets/images/chevron-right-icon.svg" width="6" height="10" alt="chevron-right-icon"></li>
            @if (categoryName) {
                <li><a [routerLink]="['/'+ routesConstants.WEDSPIRATION + '/' + categorySlug]">{{ categoryName }}</a>
                </li>
                <li><img src="assets/images/chevron-right-icon.svg" width="6" height="10" alt="chevron-right-icon"></li>
            }
            <li>{{ blogDetails?.title }}</li>
        </ul>
    </div>
</section>

<section class="blog-mob-header">
    <span class="blog-back-btn" [routerLink]="['/'+ routesConstants.WEDSPIRATION]">
        <img src="assets/images/back-circle-icon.svg" width="32" height="32" alt="back-circle-icon">
    </span>
    {{ categoryName }}
</section>

<section class="blog-details-banner">
    <div class="container">
        <div class="left-side-panel">
            <span class="sub-title">{{ categoryName }}</span>
            <h1 class="section-title">{{ blogDetails?.title }}</h1>
            <div class="section-content" [innerHTML]="blogDetails?.excerpt"></div>
        </div>
        <div class="right-side-panel">
            <img
                [src]="blogDetails?.featureImagePath ? commonConstants.IMAGE_KIT_URL + blogDetails?.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                width="665" height="360"
                [alt]="blogDetails?.imageAltText && blogDetails?.imageAltText.trim() !== '' ? blogDetails?.imageAltText : blogDetails?.title"
                (error)="setDefaultImage($event)"
                appSetImageDimensions>
        </div>
    </div>
</section>

<section class="blog-details-wrap">
    <div class="container">
        <div class="left-side-panel">
            <div class="blog-sidebar-card">
                <div class="card-section">
                    <div class="card-title">ABOUT</div>
                    <div class="card-details-wrap">
                        <span class="card-label"><img src="assets/images/favourite-heart-icon.svg" width="24"
                                                      height="24"
                                                      alt="favourite-heart-icon">Words by {{ blogDetails?.author }}</span>
                        <span class="card-label"><img src="assets/images/calendar-icon.svg" width="24" height="24"
                                                      alt="calendar-icon">{{ blogDetails | dateFormatter }}</span>
                    </div>
                </div>
                <div class="card-section">
                    <div class="card-title">SHARE
                        <!-- Not in current Phase -->
                        <!-- + SAVE -->
                    </div>
                    <div class="head-info-action">
                        <!-- Not in current Phase -->
                        <!-- <a href="#" class="secondary-btn icon-btn">
                            <img src="assets/images/forward-icon.svg" width="20" height="20" alt="forward-icon">
                        </a> -->
                        <a [href]="pinterestShareUrl" target="_blank" class="secondary-btn icon-btn">
                            <img src="assets/images/pinterest-icon-dark.svg" width="20" height="20"
                                 alt="pinterest-icon">
                        </a>
                        <button type="button" class="secondary-btn icon-btn" (click)="copyUrl()">
                            <img src="assets/images/link-icon.svg" width="24" height="24" alt="link-icon">
                        </button>
                        <!-- Not in current Phase -->
                        <!-- <a href="#" class="secondary-btn icon-btn">
                            <img src="assets/images/heart-icon-solid.svg" width="20" height="20" alt="heart-icon">
                        </a> -->
                    </div>
                </div>
                <!-- Not in current Phase -->
                <!-- <div class="card-section">
                    <div class="card-label-wrap">
                        <button type="button" class="secondary-btn">Short wedding dress 👗</button>
                        <button type="button" class="secondary-btn">Sydney wedding 🇦🇺</button>
                        <button type="button" class="secondary-btn">Mix-gender wedding party 💓</button>
                    </div>
                </div> -->
            </div>
            <!-- blogDetails?.blog_advertisements && blogDetails?.blog_advertisements.imagePath -->
            @if (blogDetails?.isAdvertisementShow == true) {
                <div class="blog-sidebar-ads">
                    <a [href]="formatURL(blogDetails?.blog_advertisements?.link)" target="_blank">
                        <!-- <img [src]="commonConstants.IMAGE_KIT_URL + blogDetails?.blog_advertisements.imagePath" width="271" height="481" alt="advertisement" (error)="setDefaultImage($event)" appSetImageDimensions loading="lazy">-->
                        <img [src]="commonConstants.IMAGE_KIT_URL + blogDetails?.blog_advertisements.imagePath"
                             width="271"
                             height="481" alt="advertisement" (error)="setDefaultImage($event)" loading="lazy">
                    </a>
                </div>
            }

            <!-- Not in current Phase -->
            <!-- <ul class="blog-card-list">
                <li class="card-wrap">
                    <div class="card-img">
                        <img src="assets/images/venue-icon.png" width="32" height="33" alt="VENUE">
                    </div>
                    <div class="card-details">
                        <span class="label">VENUE</span>
                        <a href="#" class="link">Hillview Homestead</a>
                        <span class="card-dec">A wedshed venue</span>
                    </div>
                </li>
                <li class="card-wrap">
                    <div class="card-img">
                        <img src="assets/images/camara-icon.png" width="32" height="32" alt="PHOTOGRAPHER">
                    </div>
                    <div class="card-details">
                        <span class="label">PHOTOGRAPHER</span>
                        <span class="card-content">Two of A Kind Weddings</span>
                    </div>
                </li>
                <li class="card-wrap">
                    <div class="card-img">
                        <img src="assets/images/wedding-style-icon.png" width="32" height="32" alt="WEDDING STYLE">
                    </div>
                    <div class="card-details">
                        <span class="label">WEDDING STYLE</span>
                        <span class="card-content">Cocktail</span>
                    </div>
                </li>
                <li class="card-wrap">
                    <div class="card-img">
                        <img src="assets/images/guest-icon.png" width="32" height="32" alt="GUEST COUNT">
                    </div>
                    <div class="card-details">
                        <span class="label">GUEST COUNT</span>
                        <span class="card-content">300-ish</span>
                    </div>
                </li>
                <li class="card-wrap">
                    <div class="card-img">
                        <img src="assets/images/colorful-heart-icon.png" width="32" height="32" alt="AISLE SONG">
                    </div>
                    <div class="card-details">
                        <span class="label">AISLE SONG</span>
                        <span class="card-content">The girl · City and Colour</span>
                    </div>
                </li>
                <li class="card-wrap">
                    <div class="card-img">
                        <img src="assets/images/budget-icon.png" width="32" height="32" alt="BUDGET">
                    </div>
                    <div class="card-details">
                        <span class="label">BUDGET</span>
                        <span class="card-content">$30k</span>
                    </div>
                </li>
                <li class="card-wrap">
                    <div class="card-img">
                        <img src="assets/images/first-dance-icon.png" width="32" height="32" alt="FIRST DANCE TRACK">
                    </div>
                    <div class="card-details">
                        <span class="label">FIRST DANCE TRACK</span>
                        <span class="card-content">In Spite of Ourselves Song · Iris DeMent and John Prine</span>
                    </div>
                </li>
                <li class="card-wrap">
                    <div class="card-img">
                        <img src="assets/images/happy-icon.png" width="32" height="32" alt="STAND-OUT VENDORS">
                    </div>
                    <div class="card-details">
                        <span class="label">STAND-OUT VENDORS</span>
                        <span class="card-content">Two of A Kind Weddings · Photography Flour & Stone · Florals Barbara
                            Dias · Live painting</span>
                    </div>
                </li>
            </ul> -->
        </div>
        <div class="blog-details-content">
            @if (blogContents && blogContents.length > 0) {
                @for (blogContent of blogContents; track blogContent; let i = $index) {
                    @if (blogContent.type == 'paragraph') {
                        <div [innerHTML]="blogContent.content | safeHtml" class="blog-details-desc"></div>
                    }
                    @if (blogContent.type == 'image' && blogContent.blog_contents_images &&
                    blogContent.blog_contents_images.length > 0) {
                        <div class="blog-details-image">
                            @for (image of blogContent.blog_contents_images; track image) {
                                @if (blogContent.blog_contents_images.length > 1) {
                                    <img
                                        [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath +'?tr=w-360,q-100,lossless-1,f-webp,e-sharpen-50' : commonConstants.DEFAULT_NO_IMAGE"
                                        width="737" height="737"
                                        alt="{{image.imageText != null ? image.imageText :'Blog Image'}}"
                                        (error)="setDefaultImage($event)" loading="lazy">
                                } @else {
                                    <img
                                        [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath +'?tr=w-737,q-100,lossless-1,f-webp,e-sharpen-50' : commonConstants.DEFAULT_NO_IMAGE"
                                        width="737" height="737"
                                        alt="{{image.imageText != null ? image.imageText :'Blog Image'}}"
                                        (error)="setDefaultImage($event)" loading="lazy">
                                }
                            }
                        </div>
                    }
                }
            }
        </div>
    </div>
</section>

<!--======================= Start Other Articles HTML =======================-->
<section class="other-articles-wrap blogs-list">
    <div class="container">
        @if (sameCategoryBlogList && sameCategoryBlogList.length > 0) {
            <div class="section-header">
                <h2 class="section-semi-title">Other {{ categoryName }}</h2>
                <a type="button" class="secondary-btn"
                   [routerLink]="['/'+ routesConstants.WEDSPIRATION + '/' + categorySlug]">View all {{ categoryName }}
                    <img src="assets/images/arrow-right.svg" width="21" height="20" alt="Arrow Right">
                </a>
            </div>
            <div class="card-list">
                @for (sameCategoryBlog of sameCategoryBlogList; track sameCategoryBlog; let k = $index) {
                    <div class="card-main">
                        <div class="slider-custom">
                            <a [routerLink]="['/'+ routesConstants.BLOG+ '/'+ categorySlug + '/'+ sameCategoryBlog?.slug]"
                               class="image-box">
                                <img
                                    [src]="sameCategoryBlog.featureImagePath ? commonConstants.IMAGE_KIT_URL + sameCategoryBlog.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                    width="256" height="250"
                                    [alt]="sameCategoryBlog?.imageAltText && sameCategoryBlog?.imageAltText.trim() !== '' ? sameCategoryBlog?.imageAltText : sameCategoryBlog?.title"
                                    (error)="setDefaultImage($event)"
                                    appSetImageDimensions>
                            </a>
                        </div>
                        <a [routerLink]="['/'+ routesConstants.BLOG+ '/'+ categorySlug + '/'+ sameCategoryBlog?.slug]"
                           class="card-details">
                            @if (sameCategoryBlog.blog_categories && sameCategoryBlog.blog_categories.length > 0) {
                                <span class="card-label">{{ sameCategoryBlog.blog_categories[0].name }}</span>
                            }
                            <h3 class="card-title-inner">{{ sameCategoryBlog.title }}</h3>
                            <span class="card-dec" [innerHTML]="sameCategoryBlog?.excerpt"></span>
                            <!-- Not in current Phase -->
                            <!-- <div class="card-category">
                                        <button type="button" class="secondary-btn">Inspiration and advice 💡</button>
                                        <button type="button" class="secondary-btn">Budget 💰</button>
                                    </div> -->
                        </a>
                    </div>
                }
            </div>
        }

        @if (relevantBlogList && relevantBlogList.length > 0) {
            <div class="section-header">
                <h2 class="section-semi-title">Articles you might like</h2>
            </div>
            <div class="card-list">
                @for (relevantBlog of relevantBlogList; track relevantBlog; ) {
                    <div class="card-main">
                        <div class="slider-custom">
                            <a [routerLink]="['/'+ routesConstants.BLOG+ '/'+ relevantBlog.blog_categories[0].slug + '/'+ relevantBlog?.slug]"
                               class="image-box">
                                <img
                                    [src]="relevantBlog.featureImagePath ? commonConstants.IMAGE_KIT_URL + relevantBlog.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                    width="256" height="250"
                                    [alt]="relevantBlog?.imageAltText && relevantBlog?.imageAltText.trim() !== '' ? relevantBlog?.imageAltText : relevantBlog?.title"
                                    (error)="setDefaultImage($event)" (error)="setDefaultImage($event)"
                                    appSetImageDimensions>
                            </a>
                        </div>
                        <a [routerLink]="['/'+ routesConstants.BLOG+ '/'+ relevantBlog.blog_categories[0].slug + '/'+ relevantBlog?.slug]"
                           class="card-details">
                            @if (relevantBlog.blog_categories && relevantBlog.blog_categories.length > 0) {
                                <span class="card-label">{{ relevantBlog.blog_categories[0].name }}</span>
                            }

                            <h3 class="card-title-inner">{{ relevantBlog.title }}</h3>
                            <span class="card-dec" [innerHTML]="relevantBlog?.excerpt"></span>
                            <!-- Not in current Phase -->
                            <!-- <div class="card-category">
                                        <button type="button" class="secondary-btn">Inspiration and advice 💡</button>
                                        <button type="button" class="secondary-btn">Budget 💰</button>
                                    </div> -->
                        </a>
                    </div>
                }
            </div>
        }
    </div>
</section>
<!--======================= END Other Articles HTML =======================-->

<!--======================== Let’s lift the lid Section Start ========================-->
@if (blogTags && blogTags.length > 0) {
    <section class="more-ideas-wrap">
        <div class="container">
            <div class="lets-lift-header">
                <h2 class="section-title">Looking for more ideas?</h2>
                <p class="section-info">We’ve brought together advice, articles, and inspiration on our
                    <strong>Advice & Ideas</strong> blog to help you find your feet.
                </p>
            </div>
            <div class="lets-lift-links">
                @for (blogTag of blogTags; track blogTag; let j = $index) {
                    <a [routerLink]="['/'+ routesConstants.WEDSPIRATION]"
                       class="secondary-btn">{{ blogTag.name }}</a>
                }
            </div>
        </div>
    </section>
}
<!--======================== Let’s lift the lid Section End ========================-->
