import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonConstants } from '@coreconstant';

@Injectable({
    providedIn: 'root'
})
export class ResponseHandlerService {

    constructor(private _toastrService: ToastrService) {
    }

    /**
     * Summary: This method is used to handle api response based on received code and return result.
     * @param input
     * @returns
     */
    handleAPIErrorResponse(input: any) {
        // Validate input
        if (!input || (typeof input == 'object' && Object.keys(input).length < 1))
            return false;

        // Handle response based on code
        if (input.name && input.name == "HttpErrorResponse")
            input = input.error;

        var code = input.code;
        switch (code) {
            case 201: // Handle 201, 500 error
            case 500: {
                if (typeof input.message == "string") {
                    this._toastrService.error(input.message);
                } else {
                    this._toastrService.error(CommonConstants.COMMON_ERROR_MESSAGE);
                }
            }
                break;
            case 502: {
                if (typeof input.message == "string") {
                    this._toastrService.error(input.message);
                } else {
                    this._toastrService.error(CommonConstants.COMMON_ERROR_MESSAGE);
                }
            }
                break;
            case 503: {
                if (typeof input.message == "string") {
                    this._toastrService.error(input.message);
                } else {
                    this._toastrService.error(CommonConstants.COMMON_ERROR_MESSAGE);
                }
            }
                break;
            case 404: {
                if (typeof input.message == "string") {
                    this._toastrService.error(input.message);
                } else {
                    this._toastrService.error(CommonConstants.COMMON_ERROR_MESSAGE);
                }
            }
                break;
            case 401: {
                if (typeof input.message == "string") {
                    this._toastrService.error(input.message);
                } else {
                    this._toastrService.error(CommonConstants.COMMON_ERROR_MESSAGE);
                }
            }
                break;
            case 400: {
                if (typeof input.message == "string") {
                    this._toastrService.error(input.message);
                } else {
                    this._toastrService.error(CommonConstants.COMMON_ERROR_MESSAGE);
                }
            }
                break;
            case 403: {
                if (typeof input.message == "string") {
                    this._toastrService.error(input.message);
                } else {
                    this._toastrService.error(CommonConstants.COMMON_ERROR_MESSAGE);
                }
            }
                break;
            case 422: // Handle 422 error
                {
                    if (typeof input.message == "string") {
                        this._toastrService.error(input.message);
                    } else {
                        // Validate input data
                        if (input.data && input.data.length < 1)
                            return false;

                        // Create message string
                        var messageString = "";
                        input.data.forEach((element: any) => {
                            messageString += "<div>" + element.message + "</div>";
                        });

                        // Show toast
                        this._toastrService.error(messageString, "",
                            { enableHtml: true });
                    }
                }
                break;
            default:
                break;
        }
    }

    /**
     * Summary: This method is used to handle success toast.
     * @param input
     */
    showSuccessResponseMessage(input) {
        // Validate input data
        if (!input)
            return false;

        // Show toast with message
        this._toastrService.success(input.message);
    }

    /**
     * Summary: This method is used to handle error toast.
     * @param input
     */
    showErrorResponseMessage(input) {
        // Validate input data
        if (!input)
            return false;

        // Show toast with message
        this._toastrService.error(input.message);
    }

    /**
     * Summary: This method is used to handle general message for error.
     * @param input
     */
    showErrorMessageGeneral(input: any) {
        this._toastrService.error(input);
    }

    /**
     * Summary: This method is used to handle general message for Success.
     * @param input
     */
    showSuccessMessageGeneral(input: any) {
        this._toastrService.success(input);
    }
}
