.wedspiration-banner-section {
    background: #FFE6D5;
    border-bottom: 1px solid #272338;
    padding: 32px 0;
    word-break: break-word;

    @media screen and (min-width: 1099px){
        min-height: 425px;
    }


    .banner-section-wrap {
        display: flex;
        align-items: center;
        gap: 64px;
        @media screen and (max-width: 1280px) {
            gap: 40px;
        }
        @media screen and (max-width: 1200px) {
            gap: 32px;
        }
        @media screen and (max-width: 991px) {
            gap: 24px;
        }
        @media screen and (max-width: 767px) {
            flex-direction: column-reverse;
        }

        .left-side {
            width: 665px;
            min-width: 665px;
            height: 360px;
            @media screen and (max-width: 1200px) {
                width: 600px;
                min-width: 600px;
            }
            @media screen and (max-width: 1100px) {
                width: 500px;
                min-width: 500px;
                height: 300px;
            }
            @media screen and (max-width: 991px) {
                width: 50%;
                min-width: 50%;
                height: 250px;
            }
            @media screen and (max-width: 767px) {
                width: 100%;
                min-width: 100%;
                height: auto;
            }

            img {
                width: 100%;
                height: 100%;
                max-height: 360px;
                object-fit: cover;
                object-position: center center;
                border: 1px solid #272338;
                border-radius: 20px;
                @media screen and (max-width: 767px) {
                    border-radius: 16px;
                    min-height: 226px;
                }
            }
        }

        .right-side {
            flex: 1;
            max-width: 544px;
            @media screen and (max-width: 767px) {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
            }

            p {
                margin: 0;
            }

            .section-title {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .sub-title {
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: #423B5E;
                display: block;
                margin: 0 0 4px;
                @media screen and (max-width: 767px) {
                    margin: 0 0 16px;
                }
            }

            .section-content {
                font-family: Aeonik, sans-serif;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                margin: 0 0 24px;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
                overflow: hidden;

                ::ng-deep p {
                    margin: 0;
                }
            }

            .secondary-btn {
                @media screen and (min-width: 768px) {
                    font-size: 14px;
                    padding: 9px 16px;
                }

                img {
                    position: relative;
                    right: 0;
                    transition: all 0.35s ease 0s;
                }

                &:hover {
                    img {
                        right: -4px;
                    }
                }
            }
        }
    }

    + .blog-listing-wrap {
        .blog-filter {
            border-top: none;
        }
    }
}

//======================= Trending css Start =======================
.trending-section {
    background: #EFB7A6;
    padding: 60px 0;

    @media screen and (max-width: 1280px) {
        padding: 50px 0;
    }

    @media screen and (max-width: 991px) {
        padding: 40px 0;
    }

    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    .trending-section-inner {
        display: flex;
        align-items: center;
        gap: 48px;
        padding: 0 0 0 40px;
        min-height: 196px;

        @media screen and (max-width: 1399px) {
            gap: 28px;
            padding: 0 0 0 40px;
        }

        @media screen and (max-width: 1360px) {
            padding: 0;
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
            gap: 24px;
        }

        .left-side {
            max-width: 375px;

            @media screen and (max-width: 1280px) {
                max-width: 345px;
            }

            @media screen and (max-width: 991px) {
                text-align: center;
            }

            @media screen and (max-width: 575px) {
                text-align: left;
                max-width: inherit;
            }

            .section-title {
                margin: 0 0 12px;
            }

            .link {
                font-weight: 500;
                text-decoration: none;
                color: #333333;
            }
        }

        .right-side {
            flex: 1;
            @media screen and (max-width: 991px) {
                width: 100%;
            }

            .trending-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 8px;

                @media screen and (max-width: 575px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                .trending-link {
                    display: inline-flex;
                    align-items: center;
                    gap: 16px;
                    border: 1px solid #272338;
                    padding: 16px;
                    border-radius: 16px;
                    text-decoration: none;
                    background-color: #FFFFFF;
                    transition: all 0.35s ease 0s;

                    &:hover {
                        background-color: #FFE6D5;

                        .trending-title {
                            text-decoration: underline;
                        }
                    }

                    .trending-image {
                        border: 1px solid #272338;
                        width: 60px;
                        height: 60px;
                        min-width: 60px;
                        border-radius: 60px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 60px;
                            object-fit: cover;
                            object-position: center center;
                        }
                    }

                    .trending-content {
                        display: inline-block;
                        flex: 1;

                        .label {
                            display: block;
                            font-family: Aeonik, sans-serif;
                            font-size: 12px;
                            line-height: 14px;
                            font-weight: 500;
                            margin: 0 0 4px;
                            color: #808080;
                        }

                        .trending-title {
                            font-family: Aeonik, sans-serif;
                            font-size: 16px;
                            line-height: 21px;
                            font-weight: 500;
                            color: #272338;
                            display: block;
                            margin: 0;
                            transition: all 0.35s ease 0s;
                        }
                    }
                }
            }
        }
    }
}

//======================= Trending css End =======================

//======================= Blog Filter CSS Start =======================
.blog-filter {
    border-top: 1px solid #272338;
    border-bottom: 1px solid #272338;
    position: sticky;
    top: 63px;
    z-index: 5;
    background: #FFFBF8;

    @media screen and (min-width: 992px){
        min-height: 84px;
    }

    @media screen and (max-width: 991px) {
        padding-top: 16px;
        top: 57px;
    }

    @media screen and (max-width: 991px) {
        top: 47px;
    }

    .container {
        justify-content: space-between;
        gap: 16px 0;
        display: flex;

        @media screen and (max-width: 991px) {
            flex-direction: column-reverse;
            padding: 0;
            //gap: 8px 0;
        }
    }

    .blog-filter-tabs {
        gap: 2px;
        display: flex;
        overflow: auto;
        @media screen and (max-width: 991px) {
            padding: 0 16px;
            border-top: 1px solid #272338;
        }

        @media screen and (max-width: 575px) {
            padding: 0;
        }

        .btn {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            padding: 24px 16px 16px;
            border-radius: 0;
            border: none;
            box-shadow: none;
            outline: none;
            cursor: pointer;
            font-family: Aeonik, sans-serif;
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            text-align: center;
            color: #272338;
            position: relative;
            transition: all 0.35s ease 0s;
            white-space: nowrap;

            @media screen and (max-width: 991px) {
                flex: auto;
                padding: 16px;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: auto;
                right: 0;
                height: 2px;
                width: 0;
                background: #7093FF;
                transition: all 0.35s ease 0s;
            }

            svg {
                path {
                    transition: all 0.35s ease 0s;
                }
            }

            img {
                width: 24px;
                min-width: 24px;
                height: 24px;
                min-height: 24px;
                transition: all 0.35s ease 0s;
                filter: invert(30%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(50%) contrast(100%);
            }

            &.active {
                font-weight: 500;
            }

            &.active,
            &:hover {
                &::before {
                    width: 100%;
                    right: auto;
                    left: 0;
                }

                svg {
                    path {
                        stroke: #7093FF;
                    }

                    &.heart-icon {
                        path {
                            stroke: none;
                            fill: #7093FF;
                        }
                    }
                }

                img {
                    filter: none;
                    //filter: invert(53%) sepia(82%) saturate(354%) hue-rotate(194deg) brightness(94%) contrast(92%);
                }

            }
        }
    }

    .blog-filter-search {
        position: relative;
        width: 270px;
        @media screen and (max-width: 991px) {
            flex: 1;
            width: auto;
        }
        @media screen and (max-width: 480px) {
            flex: 0 0 100%;
            width: 100%;
        }

        form {
            position: relative;
            margin: 0;
        }

        .form-control {
            border: 1px solid #272338;
            border-radius: 16px;
            box-shadow: none;
            outline: none;
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            color: #272338;
            height: auto;
            padding: 8px 36px 8px 52px;
            transition: all 0.35s ease 0s;

            @media screen and (max-width: 767px) {
                padding: 10px 36px 10px 50px;
                font-size: 16px;
            }

            &:focus {
                border-color: #7093FF;
                box-shadow: 0 0 0 1px #7093FF;
            }

            &::-webkit-input-placeholder {
                color: #999999;
            }

            &::-moz-placeholder {
                color: #999999;
            }

            &:-ms-input-placeholder {
                color: #999999;
            }

            &:-moz-placeholder {
                color: #999999;
            }

            &:focus {
                font-weight: 500;
            }
        }

        .search-btn {
            position: absolute;
            left: 18px;
            top: 7px;
            padding: 0;
            border: none;
            outline: none;
            background: transparent;
            cursor: pointer;
            pointer-events: none;
            display: inline-flex;
            @media screen and (max-width: 767px) {
                top: 9px;
                left: 16px;
            }
        }

        .search-close-btn {
            position: absolute;
            top: 9px;
            right: 13px;
            padding: 0;
            border: none;
            outline: none;
            background: transparent;
            cursor: pointer;
            z-index: 1;
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
            display: inline-flex;
            @media screen and (max-width: 767px) {
                top: 11px;
            }
        }
    }

    .blog-filter-action {
        gap: 8px;
        align-items: center;
        display: flex;
        @media screen and (min-width: 991px) {
            flex-direction: row-reverse;
        }
        @media screen and (max-width: 991px) {
            padding: 0 16px;
        }

        @media screen and (max-width: 480px) {
            flex-direction: column;
        }

        .clear-all {
            border-radius: 0;
            border-color: transparent;
            background: transparent;
            font-size: 14px;
            padding: 9px 16px;
            @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 22px;
                padding: 10px 16px;
            }
            @media screen and (max-width: 480px) {
                width: 100%;
            }

            &:hover {
                background: #F1F5FF;
                color: #272338;
                border-color: #A9BEFF;
                border-radius: 16px;
            }
        }

        .dropdown {
            @media screen and (max-width: 480px) {
                width: 100%;
            }

            .dropdown-toggle {
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                color: #4D4D4D;
                padding: 9px 36px 9px 16px;
                position: relative;
                background: #fff;
                text-align: left;
                width: 100%;

                @media screen and (max-width: 767px) {
                    padding: 10px 36px 10px 18px;
                    font-size: 16px;
                    line-height: 22px;
                }

                &::after {
                    display: none;
                }

                strong {
                    font-weight: 500;
                    color: #272338;
                }

                img {
                    position: absolute;
                    top: 0;
                    right: 16px;
                    bottom: 0;
                    height: 8px;
                    margin: auto;
                    transition: all 0.35s ease 0s;
                }

                &.show {
                    border-color: #7093FF;
                    box-shadow: 0 0 0 1px #7093FF;

                    img {
                        transform: rotateX(180deg);
                    }
                }
            }

            .dropdown-menu {
                background: #fff;
                border: 1px solid #272338;
                border-radius: 16px;
                margin: 4px 0 !important;
                min-width: 234px;
                padding: 16px;

                li {
                    font-family: Aeonik, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    color: #333333;
                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a, button {
                        padding: 0;
                        margin: 0;
                        color: #333333;
                        border: none;
                        background: transparent;
                        outline: none;
                        border-radius: 0;
                        display: flex;
                        width: 100%;
                        text-align: left;
                        font-weight: 400;
                        transition: all 0.35s ease 0s;

                        small {
                            font-size: 12px;
                            line-height: 14px;
                            font-weight: 400;
                            color: #808080;
                            margin-left: auto;
                        }

                        &:hover {
                            font-weight: 500;
                            color: #272338;
                        }
                    }
                }
            }
        }
    }
}

//======================= Blog Filter CSS END =======================

//======================= Blog Category Banner CSS Start =======================
.category-list-banner {
    background: #272338;
    padding: 32px 0;
    text-align: center;
    color: #FFFFFF;

    @media screen and (max-width: 767px) {
        padding: 16px 0;
        min-height: 66px;
    }

    .container {
        position: relative;
        padding-left: 80px;
        padding-right: 80px;
        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }
        @media screen and (max-width: 767px) {
            padding-left: 55px;
            padding-right: 55px;
        }
    }

    .back-btn {
        position: absolute;
        padding: 0;
        top: -24px;
        left: 80px;
        z-index: 1;

        @media screen and (max-width: 1360px) {
            left: 40px;
        }

        @media screen and (max-width: 1280px) {
            left: 16px;
        }

        @media screen and (max-width: 767px) {
            left: 16px;
            top: 2px;
        }

        a {
            font-size: 12px;
            line-height: 14px;
            color: #fff;
            vertical-align: middle;

            img {
                filter: brightness(100);
            }

            @media screen and (max-width: 767px) {
                width: 32px;
                height: 32px;
                font-size: 0;
                border: 1px solid #272338;
                border-radius: 40px;
                background: #fff;
                align-items: center;
                justify-content: center;
                img {
                    filter: none;
                }
                span {
                    display: none;
                }
            }


        }
    }

    .section-title {
        margin: 0;
        color: #FFFFFF;
        text-align: center;
    }
}

//======================= Blog Category Banner CSS END =======================

//======================= blog card Section css Start =======================
.blog-card-section {
    padding: 40px 0;
    @media screen and (max-width: 767px) {
        padding: 18px 0;
    }

    + .blog-card-section {
        padding-top: 0;
        margin-top: -35px;
        @media screen and (max-width: 767px) {
            margin-top: -13px;
        }
    }

    .card-list {
        .card-main {
            &:hover {
                background: transparent;
                border-color: transparent;
            }
        }
    }

    .see-more {
        margin-top: 34px;
        @media screen and (max-width: 767px) {
            margin: 18px 0 6px;
        }
    }
}

//======================= blog card Section css End =======================

//======================= featured-venues Section css Start =======================
.wedspiration-featured-section {
    background: #FFE6D5;
    padding: 48px 0;
    border-top: 1px solid #272338;
    border-bottom: 1px solid #272338;
    @media screen and (max-width: 767px) {
        padding: 32px 0 16px;
    }

    .section-header {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 24px;

        img {
            border-radius: 50%;
            border: 1px solid #272338;
        }

        .label {
            font-family: Aeonik, sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.4px;
            margin-bottom: 2px;
        }

        .section-semi-title {
            margin: 0;
        }
    }
}

//======================= featured-venues Section css End =======================

//======================= Enquire CTA Section css Start =======================
.enquire-cta {
    background: #94B3A5;

    .enquire-cta-info {
        .section-semi-title {
            color: #272338;
        }

        .enquire-cta-action {
            .secondary-btn {
                color: #272338;
            }

            .primary-btn {
                &:hover {
                    background: #272338;
                    color: #fff;
                    border-color: #272338;
                }
            }
        }
    }
}

//======================= Enquire CTA Section css END =======================
