import {Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import Splide from '@splidejs/splide';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {TerritoryService} from '../core/services/api-service/territory.service';
import {ResponseHandlerService} from '../core/services/common-service/response-handler.service';
import {lastValueFrom} from 'rxjs';
import {RequestParamModel} from '../core/models/request-param-model';
import {CommonConstants, RoutesConstants} from '@coreconstant';
import {SetImageDimensionsDirective} from '../core/directives/set-image-dimensions.directive';
import {SeoService} from '../core/services/common-service/seo.service';

@Component({
    selector: 'app-region-venue-list',
    imports: [SetImageDimensionsDirective, RouterModule],
    templateUrl: './region-venue-list.component.html',
    styleUrl: './region-venue-list.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class RegionVenueListComponent implements OnInit {

    slug: any;
    territoryDetails: any
    regionVenueList = []
    currentPage = 1
    pageCount;
    totalCount = 0;
    venueSplides: any
    requestParamModel!: RequestParamModel;
    commonConstants = CommonConstants;
    routesConstants = RoutesConstants
    isResponseReceived = false;
    constructor(@Inject(PLATFORM_ID) private platformId: object, private _seoService: SeoService, private _activatedRoute: ActivatedRoute, private _territoryService: TerritoryService, private _responseHandlerService: ResponseHandlerService) {
        this.requestParamModel = new RequestParamModel();
        this.requestParamModel.pageSize = this.commonConstants.PAGE_SIZE_COUNT
        this.requestParamModel.pageIndex = 1
    }

    ngOnInit(): void {
        const params = this._activatedRoute.snapshot.params;
        if (params && params['region']) {
            this.slug = params['region']
        }
        // If only slug change then executes this
        this._activatedRoute.paramMap.subscribe(params => {
            this.slug = params.get('region') || '';
            this.getTerritoryDetails();
        });
    }

    setMetaTags() {
        let metaTagObject = {
            title: this.territoryDetails['metaTitle'],
            description: this.territoryDetails['metaDescription'],
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.REGION_VENUE_NAVIGATION + '/' + this.slug,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    /**
     * Slider ReInit
     */
    sliderReInit() {
        if (isPlatformBrowser(this.platformId)) {
            if (this.venueSplides) {
                this.venueSplides.forEach((element) => {
                    // element.destroy(); // Destroy existing instance
                });
            }
            setTimeout(() => this.venueListImageSlider(), 100); // Reinitialize after a short delay
        }
    }

    /**
     * Venue list slider
     */
    venueListImageSlider() {
        if (isPlatformBrowser(this.platformId)) {
            this.venueSplides = [];
            const splides = document.querySelectorAll('.box-slider');

            splides.forEach((element) => {
                if (!element.classList.contains('splide-initialized')) {
                    const splide = new Splide(element as HTMLElement, {
                        type: 'loop',
                    }).mount();

                    this.venueSplides.push(splide);

                    // Mark this element as initialized
                    element.classList.add('splide-initialized');
                }
            });
        }
    }

    /**
     * Get All venue with filters
     */
    getTerritoryDetails(isLoadMore: boolean = false) {
        var getDetails = this._territoryService.getTerritoryDetails(this.slug, this.requestParamModel)
        lastValueFrom(getDetails).then((res: any) => {
            if (res["status"]) {
                this.isResponseReceived = true;
                let data = res["data"]
                this.currentPage = data["currentPage"]
                this.pageCount = data["pageCount"]
                this.totalCount = data["totalCount"]
                this.territoryDetails = data["territoryDetail"]
                if (isLoadMore)
                    this.regionVenueList = this.regionVenueList.concat(data["records"])
                else
                    this.regionVenueList = data["records"]

                this.setMetaTags()
                setTimeout(() => this.sliderReInit(), 100); // Reinitialize after a short delay
            } else {
                this.isResponseReceived = true;
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG);
            }
        }, (error: any) => {
            this.isResponseReceived = true;
            this._responseHandlerService.handleAPIErrorResponse(error);
        });
    }

    /**
     * Click on the load more and get the venue data
     */
    loadMoreVenues() {
        this.requestParamModel.pageIndex = this.requestParamModel.pageIndex + 1;
        this.getTerritoryDetails(true);
    }

    setDefaultImage(event: Event) {
        let image = this.commonConstants.DEFAULT_NO_IMAGE;
        (event.target as HTMLImageElement).src = image
    }
}
