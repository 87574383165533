<!--Back Button Start-->
<section class="back-btn">
    <div class="container">
        <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION]" type="button">
            <img src="assets/images/arrow-left.svg" width="24" height="24" alt="arrow-left">
            <span>Back</span>
        </a>
    </div>
</section>
<!--Back Button END-->

@if(!isSubmitAndShowSuccess){
<section class="contact-us-header">
    <div class="container">
        <h1 class="section-title">Get in touch 👋</h1>
        <p>Drop us a note below and we'll respond to your message as soon as we can.</p>
    </div>
</section>

<section class="contact-us-form">
    <form [formGroup]="getInTouchDetailForm">
        <div class="container">
            <div class="contact-us-form-box">
                <div class="form-group-row">
                    <div class="form-group">
                        <label>First name</label>
                        <input class="form-control" type="text" placeholder="Enter first name"
                            formControlName="contactName">
                        @if (submitted) {
                        @if (getInTouchDetailForm.controls['contactName'].hasError('required')) {
                        <div class="error">
                            First name is required
                        </div>
                        }
                        @if (!getInTouchDetailForm.controls['contactName'].hasError('required') &&
                        getInTouchDetailForm.controls['contactName'].hasError('whitespace')) {
                        <div class="error">
                            Field value cannot be empty or contain only whitespace.
                        </div>
                        }
                        }
                    </div>
                    <div class="form-group">
                        <label>Mobile number</label>
                        <!-- <input class="form-control" type="text" placeholder="000 000 000"
                            formControlName="contactNumber"> -->
                        <div class="mobile-no-input">
                            <ng-select [clearable]="false" [items]="countryCodes.CODE" bindLabel="name"
                                class="form-control-ng-select" bindValue="dialCode" formControlName="countryCode">
                                <!-- Custom template for displaying selected value (show emoji) -->
                                <ng-template ng-label-tmp let-item="item">
                                    {{ item.emoji }}
                                </ng-template>
                                <!-- Custom template for dropdown list (show name) -->
                                <ng-template ng-option-tmp let-item="item">
                                    {{ item.name }}
                                </ng-template>
                            </ng-select>

                            <input type="text" formControlName="contactNumber" class="form-control"
                                [imask]="{mask: '0000 000 000',lazy:false}" [unmask]="true">
                        </div>
                        @if (submitted) {
                        @if (getInTouchDetailForm.controls['contactNumber'].hasError('required')) {
                        <div class="error">Mobile number is required</div>
                        }
                        @if (getInTouchDetailForm.controls['contactNumber'].hasError('pattern')) {
                        <div class="error">Must be a valid mobile number</div>
                        }
                        }
                    </div>
                </div>

                <div class="form-group-row">
                    <div class="form-group">
                        <label>Email address</label>
                        <input class="form-control" type="text" placeholder="Enter email"
                            formControlName="contactEmail">
                        @if (submitted) {
                        @if (getInTouchDetailForm.controls['contactEmail'].hasError('required')) {
                        <div class="error">
                            Email is required
                        </div>
                        }
                        @if (getInTouchDetailForm.controls['contactEmail'].hasError('pattern')) {
                        <div class="error">
                            Please enter a valid email
                        </div>
                        }
                        }
                    </div>
                    <div class="form-group">
                        <label>What is the reason for the enquiry?</label>
                        <ng-select [items]="enquiryReasons" bindLabel="name" class="form-control-ng-select"
                            bindValue="id" placeholder="Add Reason" formControlName="enquiryReasonId">
                        </ng-select>
                        @if (submitted) {
                        @if (getInTouchDetailForm.controls['enquiryReasonId'].hasError('required')) {
                        <div class="error">
                            Enquiry reason is required
                        </div>
                        }
                        }
                    </div>
                </div>

                <div class="form-group-row">
                    <div class="form-group">
                        <label>Send a message</label>
                        <textarea class="form-control" placeholder="Type your message here"
                            formControlName="contactMessage"></textarea>
                        @if (submitted) {
                        @if (getInTouchDetailForm.controls['contactMessage'].hasError('required')) {
                        <div class="error">
                            Message is required
                        </div>
                        }
                        }
                    </div>
                </div>
                <div class="form-group-row">
                    <div class="form-group">
                        <re-captcha
                            (resolved)="onCaptchaResolved($event)"
                            [siteKey]="commonConstants.GOOGLE_CAPTCHA_KEY">
                        </re-captcha>
                        @if (submitted) {
                            @if (getInTouchDetailForm.controls['recaptcha'].hasError('required')) {
                                <div class="error">
                                    Please verify the CAPTCHA.
                                </div>
                            }
                        }
                    </div>
                </div>

                <div class="contact-us-action">
                    <button type="button" class="primary-btn" (click)="submit()">Send message</button>
                </div>
            </div>
        </div>
    </form>
</section>
}@else{
<section class="contact-us-message-sent">
    <section class="contact-us-header">
        <div class="container">
            <h1 class="section-title">Get in touch 👋</h1>
        </div>
    </section>
    <div class="container">
        <div class="contact-message-icon">
            <img src="assets/images/mail-sent-icon.png" width="68" height="69" alt="mail-box-icon">
        </div>
        <h2 class="section-title">Message sent</h2>
        <p>Thank you for your enquiry. We’ll be in touch with you soon.</p>
        <p>All the best,<br /> Mel and Amy 👋</p>
    </div>
</section>
}
<!--Get in touch - Message sent END-->

<!--FAQs section-->
<section class="faqs-cta">
    <div class="container">
        <h2 class="section-title">Wondering if your question is frequently asked?</h2>
        <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION]" class="primary-btn">View FAQs</a>
    </div>
</section>

<!--Wedding Planning section-->
<section class="wedding-plan-cta">
    <div class="container">
        <img src="assets/images/book-icon.svg" width="43" height="40" alt="book-icon">
        <p>Click here to receive your free e-book: <strong>7 simple steps to kickstart your wedding planning</strong></p>
        <img src="assets/images/book-icon.svg" width="43" height="40" alt="book-icon">
    </div>
</section>
