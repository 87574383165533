<section class="search-results-head">
    <div class="container">
        <h1 class="section-title">Search results</h1>
        <div class="search-tabs">
            <button type="button" class="btn" [class.active]="moduleName == commonConstants.MODULE_ALL"
                    (click)="changeTab(commonConstants.MODULE_ALL)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.51 17.9199V19.0601C14.51 19.3253 14.4046 19.5796 14.2171 19.7671C14.0296 19.9546 13.7752 20.0601 13.51 20.0601H6.51001C6.24479 20.0601 5.99045 19.9546 5.80292 19.7671C5.61538 19.5796 5.51001 19.3253 5.51001 19.0601V8.68994C5.51001 8.42472 5.61538 8.17045 5.80292 7.98291C5.99045 7.79537 6.24479 7.68994 6.51001 7.68994H10.69L8.69 15.3101C8.62289 15.566 8.65996 15.8382 8.79303 16.0669C8.9261 16.2956 9.14436 16.4622 9.40002 16.5303L14.51 17.9199Z"
                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M20.02 7.62975L17.33 17.6298C17.2619 17.8854 17.0954 18.1041 16.8667 18.2372C16.638 18.3703 16.3659 18.4068 16.11 18.3397L14.51 17.91L9.34002 16.5199C9.08436 16.4518 8.8661 16.2852 8.73302 16.0565C8.59995 15.8278 8.56288 15.5561 8.62999 15.3002L10.63 7.68005L11.27 5.28015C11.3047 5.15337 11.3641 5.03435 11.4448 4.93054C11.5254 4.82672 11.6257 4.74018 11.7399 4.67516C11.8542 4.61015 11.9801 4.56793 12.1105 4.55163C12.241 4.53533 12.3733 4.54505 12.5 4.57995L19.27 6.39001C19.4024 6.41911 19.5274 6.47465 19.6376 6.55358C19.7478 6.63251 19.8408 6.73302 19.9109 6.84899C19.9811 6.96497 20.027 7.09415 20.0457 7.22839C20.0644 7.36262 20.0557 7.499 20.02 7.62975Z"
                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                All ({{ totalResultCount }})
            </button>
            <button type="button" class="btn" [class.active]="moduleName == commonConstants.MODULE_VENUES"
                    (click)="changeTab(commonConstants.MODULE_VENUES)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.0454 5.84399L6.04541 9.66431C5.9244 9.75746 5.82633 9.87698 5.75873 10.0139C5.69112 10.1509 5.65576 10.3016 5.6554 10.4543V18.1541C5.6554 18.4193 5.76077 18.6735 5.9483 18.8611C6.13584 19.0486 6.39018 19.1541 6.6554 19.1541H16.6554C16.9206 19.1541 17.175 19.0486 17.3625 18.8611C17.55 18.6735 17.6554 18.4193 17.6554 18.1541V10.4543C17.655 10.3016 17.6197 10.1509 17.5521 10.0139C17.4845 9.87698 17.3864 9.75746 17.2654 9.66431L12.2654 5.84399C12.0905 5.70939 11.8761 5.63647 11.6554 5.63647C11.4347 5.63647 11.2203 5.70939 11.0454 5.84399V5.84399Z"
                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.22534 15.8743H14.1154" stroke="#4D4D4D" stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
                Venues ({{ venuesCount }})
            </button>
            <button type="button" class="btn" [class.active]="moduleName == commonConstants.MODULE_VENDORS"
                    (click)="changeTab(commonConstants.MODULE_VENDORS)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17.45 3.79004V7.51025C17.4806 8.20499 17.3703 8.8984 17.1255 9.54932C16.8808 10.2002 16.5068 10.7949 16.026 11.2974C15.5452 11.7998 14.9676 12.2 14.3281 12.4731C13.6886 12.7463 13.0005 12.8867 12.305 12.8867C11.6096 12.8867 10.9214 12.7463 10.2819 12.4731C9.64241 12.2 9.06482 11.7998 8.58404 11.2974C8.10327 10.7949 7.72924 10.2002 7.48449 9.54932C7.23975 8.8984 7.12939 8.20499 7.16003 7.51025V3.79004L9.74005 5.8999L12.31 3.79004L14.89 5.8999L17.45 3.79004Z"
                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M5.55992 13.3301C7.2322 13.502 8.80051 14.2227 10.0199 15.3799C9.98896 15.8018 9.85629 16.2103 9.63322 16.5698C9.41015 16.9293 9.10331 17.2292 8.73899 17.4443C8.37468 17.6594 7.9637 17.783 7.54118 17.8047C7.11866 17.8264 6.6972 17.7455 6.31278 17.5688C5.92837 17.3922 5.59246 17.1248 5.33378 16.79C5.0751 16.4553 4.90135 16.063 4.82737 15.6465C4.75339 15.2299 4.78134 14.8018 4.90891 14.3984C5.03649 13.9951 5.25986 13.6283 5.55992 13.3301V13.3301Z"
                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M19.05 13.3301C17.3588 13.5009 15.7743 14.2361 14.5516 15.417C13.329 16.5979 12.5394 18.1556 12.3099 19.8398C13.9813 19.6571 15.5473 18.9343 16.77 17.7803C18.0095 16.5961 18.8129 15.0279 19.05 13.3301V13.3301Z"
                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Vendors ({{ vendorsCount }})
            </button>
            <button type="button" class="btn" [class.active]="moduleName == commonConstants.MODULE_ARTICLES"
                    (click)="changeTab(commonConstants.MODULE_ARTICLES)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.0599 17.8101C10.7085 16.9722 9.15002 16.5283 7.55994 16.5283C5.96986 16.5283 4.41136 16.9722 3.05994 17.8101V6.72998C4.41136 5.89212 5.96986 5.44824 7.55994 5.44824C9.15002 5.44824 10.7085 5.89212 12.0599 6.72998V17.8101Z"
                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M12.0599 17.8101C13.4114 16.9722 14.9699 16.5283 16.5599 16.5283C18.15 16.5283 19.7085 16.9722 21.0599 17.8101V6.72998C19.7085 5.89212 18.15 5.44824 16.5599 5.44824C14.9699 5.44824 13.4114 5.89212 12.0599 6.72998V17.8101Z"
                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.79004 9.37988H9.31995" stroke="#4D4D4D" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M5.79004 11.3799H9.31995" stroke="#4D4D4D" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M5.79004 13.3799H9.31995" stroke="#4D4D4D" stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
                Articles ({{ blogsCount }})
            </button>
        </div>
    </div>
</section>

@if (isGetResponse) {
    <!--No Result Found-->
    @if (selectedModuleCount == 0) {
        <section class="search-results-not-found">
            <div class="container">
                <p class="emoji-icon">😞</p>
                <p>There were no {{ moduleName !== commonConstants.MODULE_ALL ? moduleName.slice(0, -1) : '' }} results
                    for
                    <strong>'{{ searchKeyword }}'</strong>
                </p>
                <!-- Not in current Phase -->
                <!-- <a href="javascript:void(0)" class="clear-search-btn">Clear search</a> -->
            </div>
        </section>
    } @else {
        @if ((moduleName == commonConstants.MODULE_ALL || moduleName == commonConstants.MODULE_VENUES) && venues.length > 0) {
            <section class="search-results-list">
                <div class="container">
                    <h2 class="section-semi-title">Venue results</h2>
                    <div class="card-list">
                        @for (venue of (moduleName == commonConstants.MODULE_ALL ?
                            venues.slice(0, commonConstants.ALL_OPTION_PAGE_SIZE_COUNT) : venues); track venue; let i =
                            $index) {
                            <div class="card-main">
                                <div class="slider-custom">
                                    <!-- Not in current Phase -->
                                    <!-- <button type="button" class="heart-icon">
                                        <img src="assets/images/heart-icon-solid.svg" width="26" height="26" alt="heart-icon">
                                    </button> -->
                                    @if (venue.venue_images && venue.venue_images.length > 0) {
                                        <div class="slider-inner">
                                            <div class="splide box-slider">
                                                <div class="splide__track">
                                                    <ul class="splide__list">
                                                        @for (image of venue.venue_images; track image; let j = $index) {
                                                            <li class="splide__slide">
                                                                <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ venue.slug]"
                                                                   class="image-box">
                                                                    <img
                                                                        [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                                        width="256" height="250" [alt]="venue.venueName"
                                                                        (error)="setDefaultImage($event)"
                                                                        appSetImageDimensions loading="lazy">
                                                                </a>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    } @else {
                                        <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ venue.slug]"
                                           class="image-box">
                                            <img src="{{commonConstants.DEFAULT_NO_IMAGE}}" width="256" height="250"
                                                 alt="image-not-found"
                                                 loading="lazy">
                                        </a>
                                    }
                                </div>
                                <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ venue.slug]"
                                   class="card-details">
                                    <div class="card-title">
                                        <span class="title">{{ venue.venueName }}</span>
                                        <!-- Not in current Phase -->
                                        <!-- <div class="right-side-rating">
                                            <span class="rating">4.5</span>
                                            <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                        </div> -->
                                    </div>
                                    @if (venue?.regions || venue?.states) {
                                        <span class="address">{{ venue?.regions?.name }},
                                            {{ venue?.states?.name }}</span>
                                    }
                                    <!-- Not in current Phase -->
                                    <!-- <div class="card-footer">
                                        <span class="price">From $5,000</span>
                                        <span class="dot">&middot;</span>
                                        <span class="guests">22 guests</span>
                                    </div> -->
                                </a>
                            </div>
                        }
                    </div>
                    @if (moduleName == commonConstants.MODULE_VENUES && venueCurrentPage != venuePageCount) {
                        <div class="see-more">
                            <button type="button" class="secondary-btn" (click)="loadMoreData(venueCurrentPage)">See
                                more results
                                <img src="assets/images/arrow-down.svg" width="25" height="24" alt="arrow-down">
                            </button>
                        </div>
                    }
                </div>
            </section>
        }

        <!--Search Vendor results List-->
        @if ((moduleName == commonConstants.MODULE_ALL || moduleName == commonConstants.MODULE_VENDORS) && vendors.length > 0) {
            <section class="search-results-list">
                <div class="container">
                    <h2 class="section-semi-title">Vendor results</h2>
                    <div class="card-list">
                        @for (vendor of (moduleName == commonConstants.MODULE_ALL ?
                            vendors.slice(0, commonConstants.ALL_OPTION_PAGE_SIZE_COUNT) : vendors); track vendor; let
                                j = $index) {
                            <div class="card-main">
                                <div class="slider-custom">
                                    <!-- Not in current Phase -->
                                    <!-- <label class="local-label">Local</label>
                                    <button type="button" class="heart-icon">
                                        <img src="assets/images/heart-icon-solid.svg" width="26" height="26" alt="Heart Icon"
                                            title="favorite">
                                    </button> -->
                                    @if (vendor.vendor_images && vendor.vendor_images.length > 0) {
                                        <div class="slider-inner">
                                            <div class="splide box-slider">
                                                <div class="splide__track">
                                                    <ul class="splide__list">
                                                        @for (image of vendor.vendor_images; track image; let j = $index) {
                                                            <li class="splide__slide">
                                                                <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]"
                                                                   class="image-box">
                                                                    <img
                                                                        [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                                        width="256" height="250" [alt]="vendor.serviceName"
                                                                        (error)="setDefaultImage($event)"
                                                                        appSetImageDimensions loading="lazy">
                                                                </a>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    } @else {
                                        <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]"
                                           class="image-box">
                                            <img src="{{commonConstants.DEFAULT_NO_IMAGE}}" width="256" height="250"
                                                 alt="image-not-found"
                                                 loading="lazy">
                                        </a>
                                    }
                                </div>
                                <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]"
                                   class="card-details">
                                    <div class="card-title">
                                        <span class="title">{{ vendor.serviceName }}</span>
                                        <!-- Not in current Phase -->
                                        <!-- <div class="right-side-rating">
                                            <span class="rating">4.5</span>
                                            <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                        </div> -->
                                    </div>
                                    @if (vendor?.regions || vendor?.states) {
                                        <span class="address">{{ vendor?.regions?.name }},
                                            {{ vendor?.states?.name }}</span>
                                    }

                                    @if (vendor.vendor_types && vendor.vendor_types.length > 0) {
                                        <div class="card-category">
                                            @for (type of vendor.vendor_types.slice(0,
                                                commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT); track type; let type_index = $index) {
                                                <button type="button" class="secondary-btn"
                                                        [ngbTooltip]="type?.name">{{ type?.name }}
                                                </button>
                                            }
                                            @if ((vendor.vendor_types.length - commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT) > 0) {
                                                <div class="care-more-category">+{{
                                                        vendor.vendor_types.length -
                                                        commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT
                                                    }}
                                                    <div class="care-more-category-box">
                                                        @for (remainingType of
                                                            vendor.vendor_types.slice(commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT); track
                                                            remainingType; ) {
                                                            <button type="button"
                                                                    class="secondary-btn">{{ remainingType?.name }}
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </a>
                            </div>
                        }
                    </div>
                    @if (moduleName == commonConstants.MODULE_VENDORS && vendorCurrentPage != vendorPageCount) {
                        <div class="see-more">
                            <button type="button" class="secondary-btn" (click)="loadMoreData(vendorCurrentPage)">See
                                more results
                                <img src="assets/images/arrow-down.svg" width="25" height="24" alt="arrow-down-icon">
                            </button>
                        </div>
                    }
                </div>
            </section>
        }

        <!--Search Article Results list-->
        @if ((moduleName == commonConstants.MODULE_ALL || moduleName == commonConstants.MODULE_ARTICLES) && blogs.length > 0) {
            <section class="article-results-list blogs-list">
                <div class="container">
                    <h2 class="section-semi-title">Article results</h2>
                    <div class="card-list">
                        @for (blog of (moduleName == commonConstants.MODULE_ALL ?
                            blogs.slice(0, commonConstants.ALL_OPTION_PAGE_SIZE_COUNT) : blogs); track blog; let k =
                            $index) {
                            <div class="card-main">
                                <div class="slider-custom">
                                    <a [routerLink]="['/'+ routesConstants.BLOG+ '/'+ blog.blog_categories[0].slug + '/'+ blog?.slug]" class="image-box">
                                        <img
                                            [src]="blog?.featureImagePath ? commonConstants.IMAGE_KIT_URL + blog?.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                            width="256" height="250" alt="blog-image" (error)="setDefaultImage($event)" [alt]="blog.imageAltText && blog.imageAltText.trim() !== '' ? blog.imageAltText : blog.title"
                                                appSetImageDimensions loading="lazy">
                                    </a>
                                </div>
                                <a [routerLink]="['/'+ routesConstants.BLOG+ '/'+ blog.blog_categories[0].slug + '/'+ blog?.slug]" class="card-details">
                                    @if (blog?.blog_categories && blog?.blog_categories.length > 0) {
                                        <span class="card-label">{{ blog.blog_categories[0].name }}</span>
                                    }
                                    <span class="card-title-inner">{{ blog?.title }}</span>
                                    <span class="card-dec" [innerHTML]="blog?.excerpt"></span>
                                    <!-- Not in current Phase -->
                                    <!-- <div class="card-category">
                                        <button type="button" class="secondary-btn">Dear DM 💌</button>
                                        <button type="button" class="secondary-btn">Cultural 👨‍👨‍👧‍👦</button>
                                        <button type="button" class="secondary-btn">Trends 🔥</button>
                                    </div> -->
                                </a>
                            </div>
                        }
                    </div>
                    @if (moduleName == commonConstants.MODULE_ARTICLES && blogCurrentPage != blogPageCount) {
                        <div class="see-more">
                            <button type="button" class="secondary-btn" (click)="loadMoreData(blogCurrentPage)">See more
                                results
                                <img src="assets/images/arrow-down.svg" width="25" height="24" alt="arrow-down-icon">
                            </button>
                        </div>
                    }
                </div>
            </section>
        }
    }
}

<!--Quick Action Box-->
<section class="quick-action-wrap">
    <div class="container">
        <div class="quick-action-item">
            <h3>Have some questions? 💭</h3>
            <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION]" type="button"
               class="secondary-btn">Check our FAQs <img src="assets/images/arrow-right-icon.svg" width="13"
                                                         height="10" alt="button-right-icon">
            </a>
        </div>
        <div class="quick-action-item">
            <h3>Want to reach out? 👋</h3>
            <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION]" type="button"
               class="secondary-btn">Contact us <img src="assets/images/arrow-right-icon.svg" width="13" height="10"
                                                     alt="button-right-icon"></a>
        </div>
    </div>
</section>
