import { CommonModule } from '@angular/common';
import {Component, HostListener, ViewEncapsulation} from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonConstants, ImagePathConstants, RoutesConstants } from '@coreconstant';
import { lastValueFrom } from 'rxjs';
import { SetImageDimensionsDirective } from 'src/app/core/directives/set-image-dimensions.directive';
import { RequestParamModel } from 'src/app/core/models/request-param-model';
import { FaqService } from 'src/app/core/services/api-service/faq.service';
import { ResponseHandlerService } from 'src/app/core/services/common-service/response-handler.service';
import { SeoService } from '../../core/services/common-service/seo.service';
import { SafeHtmlPipe } from 'src/app/core/pipes/safe-html.pipe';

@Component({
    selector: 'app-couples',
    imports: [RouterModule, CommonModule, SetImageDimensionsDirective, SafeHtmlPipe],
    templateUrl: './couples.component.html',
    styleUrl: './couples.component.scss',
    providers: [SafeHtmlPipe],
    encapsulation: ViewEncapsulation.None
})
export class CouplesComponent {
    isFooterVisible: boolean = false
    activeSection: string = 'howItWorks';
    commonConstants = CommonConstants
    routesConstants = RoutesConstants
    imagePathConstants = ImagePathConstants
    faqList: any;
    requestParamModel!: RequestParamModel;


    constructor(
        private _faqService: FaqService,
        private _seoService: SeoService,
        private _responseHandlerService: ResponseHandlerService
    ) {
        this.setMetaTags()
    }

    ngOnInit() {
        this.getFAQList()
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Stress-Free Wedding Planning for Modern Couples | Wedshed',
            description: 'Find the perfect wedding venue and vendors all in one place. Wedshed makes wedding planning easy, fun, and hassle-free for every couple!',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.HOW_IT_WORKS_COUPLES_NAVIGATION,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    /**
     * Highlight title when scrolling
     */
    @HostListener('window:scroll', [])
    onScroll() {
        const sections = ['howItWorks', 'whatWeOffer', 'successStories', 'faqs'];
        let currentSection = sections[0];

        for (const sectionId of sections) {
            const section = document.getElementById(sectionId);
            if (section) {
                const rect = section.getBoundingClientRect();
                if (rect.top <= window.innerHeight * 0.3 && rect.bottom >= window.innerHeight * 0.3) {
                    currentSection = sectionId;
                    break;
                }
            }
        }
        this.activeSection = currentSection;

        // If footer shows when scroll then manage the button for Mobile
        const footerSection = document.getElementById('footer-section');
        if (footerSection) {
            const rect = footerSection.getBoundingClientRect();
            this.isFooterVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        }
    }

    /**
     * Scroll to section when title is clicked
     * @param sectionId
     */
    scrollToSection(sectionId: string) {
        const section = document.getElementById(sectionId);
        const headerOffset = this.commonConstants.HEADER_OFFSET;
        if (section) {
            const elementPosition = section.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    }

    getFAQList() {
        this.requestParamModel = new RequestParamModel();
        this.requestParamModel.addDefaultFilter('faqCategoryId', this.commonConstants.COUPLES_FAQ, 'number')

        var getListing = this._faqService.getFaqList(this.requestParamModel)
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.faqList = res["data"].records;
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

}
