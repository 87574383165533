import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonBehaviorChangeService {
  private settingsSubject = new BehaviorSubject<any>(null);
  settings$ = this.settingsSubject.asObservable();

  constructor() { }

  setSystemSettings(data: any) {
    this.settingsSubject.next(data);
  }
}
