<!--Back Button Start-->
<section class="back-btn">
    <div class="container">
        <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION]" type="button">
            <img src="assets/images/arrow-left.svg" width="24" height="24" alt="Left arrow icon">
            <span>Back</span>
        </a>
    </div>
</section>
<!--Back Button END-->

<!--Submit a wedding Start-->
@if(!isSubmitAndShowSuccess){
<section class="contact-us-header">
    <div class="container">
        <h1 class="section-title">Submit a wedding 💍</h1>
        <p>We’re always on the hunt for gorgeous weddings to share with our couples. If you were married at or involved
            with an amazing celebration held at one of our Wedshed venues, we would absolutely love to have a look. Love
            stories in every shape or form are welcome here&nbsp;❤️</p>
    </div>
</section>
<section class="contact-us-form">
    <form action="" [formGroup]="submitWeddingForm">
        <div class="container">
            <div class="contact-us-form-box">
                <div class="form-group-row">
                    <div class="form-group">
                        <label>Your name</label>
                        <input class="form-control" type="text" placeholder="Enter your first name"
                            formControlName="name">
                        @if (submitted) {
                        @if
                        (submitWeddingForm.controls['name'].hasError('required'))
                        {
                        <div class="error">Name is required</div>
                        } @if
                        (!submitWeddingForm.controls['name'].hasError('required')
                        &&
                        submitWeddingForm.controls['name'].hasError('whitespace'))
                        {
                        <div class="error">
                            Field value cannot be empty or contain
                            only whitespace.
                        </div>
                        } }
                    </div>
                    <div class="form-group">
                        <label>Your partner’s name</label>
                        <input class="form-control" type="text" placeholder="Enter your partner’s first name"
                            formControlName="partnerName">
                        @if (submitted) {
                        @if
                        (submitWeddingForm.controls['partnerName'].hasError('required'))
                        {
                        <div class="error">Partner name is required</div>
                        } @if
                        (!submitWeddingForm.controls['partnerName'].hasError('required')
                        &&
                        submitWeddingForm.controls['partnerName'].hasError('whitespace'))
                        {
                        <div class="error">
                            Field value cannot be empty or contain
                            only whitespace.
                        </div>
                        } }
                    </div>
                </div>

                <div class="form-group-row">
                    <div class="form-group">
                        <label>Email address</label>
                        <input class="form-control" type="text" placeholder="Enter email"
                            formControlName="emailAddress">
                        @if (submitted) {
                        @if (submitWeddingForm.controls['emailAddress'].hasError('required')) {
                        <div class="error">
                            Email is required
                        </div>
                        }
                        @if (submitWeddingForm.controls['emailAddress'].hasError('pattern')) {
                        <div class="error">
                            Please enter a valid email
                        </div>
                        }
                        }
                    </div>
                </div>

                <div class="form-group-row">
                    <div class="form-group">
                        <label>Venue</label>
                        <input class="form-control" type="text" placeholder="Enter venue name"
                            formControlName="venueName">
                        @if (submitted) {
                        @if (submitWeddingForm.controls['venueName'].hasError('required')) {
                        <div class="error">
                            Venue name is required
                        </div>
                        }
                        }
                    </div>
                    <div class="form-group">
                        <label>Wedding photographer</label>
                        <input class="form-control" type="text" placeholder="Enter photographer’s name"
                            formControlName="photographerName">
                        @if (submitted) {
                        @if (submitWeddingForm.controls['photographerName'].hasError('required')) {
                        <div class="error">
                            Photographer name is required
                        </div>
                        }
                        @if
                        (!submitWeddingForm.controls['photographerName'].hasError('required')
                        &&
                        submitWeddingForm.controls['photographerName'].hasError('whitespace'))
                        {
                        <div class="error">
                            Field value cannot be empty or contain
                            only whitespace.
                        </div>
                        }
                        }
                    </div>
                </div>

                <div class="form-group-row">
                    <div class="form-group">
                        <label>Include a message</label>
                        <textarea class="form-control" placeholder="Type a message here"
                            formControlName="message"></textarea>
                    </div>
                </div>

                <div class="form-group-row">
                    <div class="form-group">
                        <label>Has this been published anywhere else?</label>
                        <div class="form-group-radio-2">
                            <label class="custom-radio">
                                <input type="radio" name="isPublishedAnywhere" formControlName="isPublishedAnywhere"
                                    [value]="true">
                                <span class="checkmark"></span>Yes
                            </label>
                            <label class="custom-radio">
                                <input type="radio" name="isPublishedAnywhere" formControlName="isPublishedAnywhere"
                                    [value]="false">
                                <span class="checkmark"></span>No
                            </label>
                        </div>
                        @if (submitted) {
                        @if (submitWeddingForm.controls['isPublishedAnywhere'].hasError('required')) {
                        <div class="error">
                            Please select an option
                        </div>
                        }
                        }
                    </div>
                </div>

                <div class="form-group-row">
                    <div class="form-group">
                        <label>If you’re not the photographer, do you have permission from the photographer to submit
                            this
                            wedding?</label>
                        <div class="form-group-radio-2">
                            <label class="custom-radio">
                                <input type="radio" name="isPermittedByPhotographer"
                                    formControlName="isPermittedByPhotographer" [value]="true">
                                <span class="checkmark"></span>Yes
                            </label>
                            <label class="custom-radio">
                                <input type="radio" name="isPermittedByPhotographer"
                                    formControlName="isPermittedByPhotographer" [value]="false">
                                <span class="checkmark"></span>No
                            </label>
                        </div>
                        @if (submitted) {
                        @if (submitWeddingForm.controls['isPermittedByPhotographer'].hasError('required')) {
                        <div class="error">
                            Please select an option
                        </div>
                        }
                        }
                    </div>
                </div>

                <div class="form-group-row">
                    <div class="form-group">
                        <label>Please share a link to a Pixieset or Dropbox containing no more than 150 wedding photos.
                            <span class="label-info">Images sized at 2000px wide (please). Ensure you supply a range of
                                photos that tell the story of the day. We want to see a mix of details, venue and people
                                shots.</span></label>
                        <input class="form-control" type="text" placeholder="Enter URL"
                            formControlName="photographLink">
                        @if (submitted) {
                        @if (submitWeddingForm.controls['photographLink'].hasError('required')) {
                        <div class="error">
                            Link is required
                        </div>
                        }
                        }
                    </div>
                </div>

                <div class="form-group-row">
                    <div class="form-group">
                        <label>Password to gallery (if required)</label>
                        <input type="password" class="form-control" placeholder="Enter password"
                            formControlName="linkPassword">
                    </div>
                </div>

                <div class="form-group-row">
                    <div class="form-group">
                        <re-captcha
                            (resolved)="onCaptchaResolved($event)"
                            [siteKey]="commonConstants.GOOGLE_CAPTCHA_KEY">
                        </re-captcha>
                        @if (submitted) {
                            @if (submitWeddingForm.controls['recaptcha'].hasError('required')) {
                                <div class="error">
                                    Please verify the CAPTCHA.
                                </div>
                            }
                        }
                    </div>
                </div>

                <div class="contact-us-action">
                    <button type="button" class="primary-btn" (click)="submit()">Send message</button>
                </div>
            </div>
        </div>
    </form>
</section>
}
<!--Submit a wedding END-->

<!--Submit a wedding - Message sent Start-->
@else{
<section class="contact-us-message-sent">
    <div class="container">
        <div class="contact-message-icon">
            <img src="assets/images/mail-sent-icon.png" width="68" height="69" alt="Mail box icon">
        </div>
        <h2 class="section-title">Message sent</h2>
        <p>Thank you for your real wedding submission. We’ll be in touch with you soon regarding your submission’s
            progress.</p>
        <p>All the best,<br /> Mel and Amy 👋</p>
    </div>
</section>
}
<!--Submit a wedding - Message sent END-->

<!--FAQs section-->
<section class="faqs-cta">
    <div class="container">
        <h2 class="section-title">Wondering if your question is frequently asked?</h2>
        <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION]" class="primary-btn">View FAQs</a>
    </div>
</section>

<!--Wedding Planning section-->
<section class="wedding-plan-cta">
    <div class="container">
        <img src="assets/images/book-icon.svg" width="43" height="40" alt="Book icon">
        <p>Click here to receive your free e-book: <strong>7 simple steps to kickstart your wedding planning</strong></p>
        <img src="assets/images/book-icon.svg" width="43" height="40" alt="Book icon">
    </div>
</section>
