export class ApiUrlConstants {
    public static WEDSHED_FRONT_BASE_URL = "/api/v1";
    public static WEDSHED_COMMON_BASE_URL = "/api/v1/common-functions";
    public static WEDSHED_ADMIN_BASE_URL = "/api/v1/admin-console";

    public static AUTH_BASE_URL = this.WEDSHED_FRONT_BASE_URL + "/auth";
    public static BLOG_BASE_URL = this.WEDSHED_FRONT_BASE_URL + "/blog";
    public static VENUE_BASE_URL = this.WEDSHED_FRONT_BASE_URL + "/venue";
    public static VENDOR_BASE_URL = this.WEDSHED_FRONT_BASE_URL + "/vendor";
    public static TERRITORY_BASE_URL = this.WEDSHED_FRONT_BASE_URL + "/territory";
    public static HUB_BASE_URL = this.WEDSHED_FRONT_BASE_URL + "/hub";
    public static GENERAL_BASE_URL = this.WEDSHED_FRONT_BASE_URL + "/general";
    public static COMMON_BASE_URL = this.WEDSHED_COMMON_BASE_URL;
    public static SEARCH_BASE_URL = this.WEDSHED_FRONT_BASE_URL + "/search";
    public static FAQ_BASE_URL = this.WEDSHED_FRONT_BASE_URL + "/faq";

}
