::ng-deep body {
    background: #272338;
}

.single-header {
    width: 100%;
    background: #A8AED6;
    padding: 19px 16px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid #6D629D;

    a {
        display: inline-block;
        vertical-align: middle;
    }
}

.single-wrap {
    font-family: Aeonik, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    background: #272338;
    margin: 0;
    padding: 30px 15px;
    width: 100%;
    min-height: calc(100dvh - 64px);
    align-items: center;
    justify-content: center;
    display: flex;

    @media screen and (max-width: 767px) {
        padding: 16px 15px 30px;
    }

    @media screen and (max-width: 480px) {
        padding: 24px 15px;
    }

    .single-wrap-inner {
        text-align: center;
    }

    .oops-text {
        //font-family: Livorno, serif;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: #9BA4B5;
        position: relative;
        top: 8px;

        @media screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 22px;
            top: 6px;
        }
    }

    .single-title {
        font-family: Livorno, serif;
        font-size: 160px;
        line-height: 170px;
        font-weight: 700;
        color: #FFFFFF;
        margin: 0 0 10px;

        @media screen and (max-width: 1366px) {
            font-size: 140px;
            line-height: 150px;
        }

        @media screen and (max-width: 767px) {
            font-size: 120px;
            line-height: 130px;
        }
    }

    .single-subtitle {
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #FFFFFF;
        margin: 0;

        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .single-wrap-action {
        margin: 40px 0 0 0;
        text-align: center;

        @media screen and (max-width: 767px) {
            margin: 30px 0 0 0;
        }

        .primary-btn {
            //padding: 12px 24px;
            border-color: transparent;

            &:hover {
                border-color: #FFFFFF;
                color: #FFFFFF;
            }
        }
    }
}
