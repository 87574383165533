import { AbstractControl, FormControl, FormGroup, UntypedFormControl, ValidationErrors } from "@angular/forms";

var randomString = "sruDxjByg2k5TgNwg8AeNPmHkU7AZjkcwDwjff8OEO8fUTVYkd";

export function isValidValue(input) {
    if (input == "" || input == null || input == 'undefined' || typeof input == undefined)
        return false;

    return true;
};

/**
 * Summary: This method is used for no white space validator
 * @param control
 * @returns
 */
export function noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

/**
 * Summary: This method is used for white space validator
 * @param control
 * @returns
 */
export function cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
        return { cannotContainSpace: true }
    }

    return null;
}
