<div class="main-head-responsive">
    <span class="filter-back-btn" (click)="goBack()">
        <img src="assets/images/back-circle-icon.svg" width="32" height="32" alt="back-circle-icon">
    </span>
    <span class="back-btn-title">Back to {{ type == routesConstants.VENDORS ? 'vendor' : 'venue' }}</span>
</div>
<div class="container">
    <div class="back-btn">
        <a type="button" (click)="goBack()">
            <img src="assets/images/arrow-left.svg" width="24" height="24" alt="Arrow">
            Back
        </a>
    </div>

    @if(images && images.length > 0) {
    <div id="detailGallery" class="image-gallery">
        @for (image of images; track image; let i = $index) {
        <a class="gallery-item"
            href="{{image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE}}">
            <img [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                (error)="setDefaultImage($event)" alt={{imageAltTag}} appSetImageDimensions loading="lazy" />
        </a>
        }
    </div>
    }
</div>
