import {Component, OnInit, Inject, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {isPlatformBrowser, Location} from '@angular/common';
import Splide from '@splidejs/splide';
import {BlogService} from 'src/app/core/services/api-service/blog.service';
import {ResponseHandlerService} from 'src/app/core/services/common-service/response-handler.service';
import {GeneralService} from 'src/app/core/services/api-service/general.service';
import {lastValueFrom} from 'rxjs';
import {CommonConstants, RoutesConstants} from '@coreconstant';
import {RequestParamModel} from 'src/app/core/models/request-param-model';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {environment} from 'src/environments/environment';
import {SetImageDimensionsDirective} from 'src/app/core/directives/set-image-dimensions.directive';
import {FormsModule} from '@angular/forms';
import {SeoService} from '../../core/services/common-service/seo.service';

@Component({
    selector: 'app-wedspiration-list',
    imports: [SetImageDimensionsDirective, RouterModule, FormsModule],
    templateUrl: './wedspiration-list.component.html',
    styleUrl: './wedspiration-list.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class WedspirationListComponent implements OnInit {
    requestParamModel!: RequestParamModel;
    routesConstants = RoutesConstants
    commonConstants = CommonConstants
    featureBlogList = [];
    trendingBlogs = [];
    blogList = [];
    blogCategories = [];
    selectedBlogCategoriesName: string = ''
    currentPage = 1
    pageCount;
    totalCount = 0;
    firstSegment: string;
    slug: string
    searchValue: string;
    featuredArticle = null
    searchTag: string;
    isShowClear: boolean = false
    businessPortalUrl = environment.businessPortalUrl
    isFilterApply = false
    isGetResponse: boolean = false

    showCloseSearchIcon: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _blogService: BlogService,
        private _responseHandlerService: ResponseHandlerService,
        private _generalService: GeneralService,
        private location: Location,
        private _seoService: SeoService,
        private _activatedRoute: ActivatedRoute
    ) {
        this.setMetaTags()
        this.requestParamModel = new RequestParamModel();
        this.requestParamModel.pageSize = CommonConstants.BLOG_PAGE_SIZE_COUNT
        this.requestParamModel.pageIndex = 1
        this.firstSegment = this.location.path();

        this._activatedRoute.queryParams.subscribe(params => {
            this.firstSegment = this.location.path().split('?')[0];
            this.searchTag = params['tag']

            this.requestParamModel.removeFilter('blogTag')
            if (this.searchTag)
                this.requestParamModel.setFilter('blogTag', this.searchTag, 'number')
        });
    }

    scrollToSection() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Wedding Planning Advice & Ideas | Wedshed',
            description: 'Explore expert wedding planning advice, creative ideas, and tips to make your big day unforgettable. Get inspired with Wedshed\'s comprehensive guides for brides and grooms.',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.WEDSPIRATION,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    ngOnInit(): void {
        const params = this._activatedRoute.snapshot.params;
        if (params && params['type']) {
            this.slug = params['type']
            this.setBlogsFilter()
        }
        this.getFeaturedBlog()
        this.getTrendingBlogs()
        this.getBlogsList(false, true)
        this.getFeatureBlogsList()
        this.getBlogsCategories()
    }

    sliderReInit() {
        if (isPlatformBrowser(this.platformId)) {

            // Initialize the parent slider
            const parentSlider = new Splide('#parent-slider', {
                type: 'slide',
                perPage: 4,
                pagination: false,
                arrows: true,
                drag: false,
                gap: '5px',
                perMove: 1,
                breakpoints: {
                    480: {
                        type: 'loop',
                        perPage: 2,
                        perMove: 1,
                        autoWidth: true,
                    },
                    767: {
                        type: 'loop',
                        perPage: 2,
                        perMove: 1,
                        autoWidth: true,
                    },
                    991: {
                        type: 'loop',
                        perPage: 3,
                        perMove: 1,
                        autoWidth: true,
                    },
                    1024: {
                        type: 'loop',
                        perPage: 4,
                        perMove: 1,
                        autoWidth: true,
                    }
                },
            });

            // Mount the parent slider
            parentSlider.mount();
        }
    }

    getFeaturedBlog() {
        var getFeaturedBlog = this._blogService.getFeaturedBlog()
        lastValueFrom(getFeaturedBlog).then((res: any) => {
            if (res["status"]) {
                this.featuredArticle = res["data"];
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Get Trending blogs
     */
    getTrendingBlogs() {
        var getListing = this._blogService.getTrendingBlogs()
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.trendingBlogs = res["data"];
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Get Blogs List
     */
    getBlogsList(isLoadMore: boolean = false, isPageLoad: boolean = false, isSearchActioned: boolean = false) {
        this.isFilterApply = false;

        var getBlogList = this._blogService.getBlogsList(this.requestParamModel);

        lastValueFrom(getBlogList).then((res: any) => {
            if (res["status"]) {
                this.isGetResponse = true;
                let data = res["data"];
                this.currentPage = data["currentPage"];
                this.pageCount = data["pageCount"];
                this.totalCount = data["totalCount"];

                if (isLoadMore)
                    this.blogList = this.blogList.concat(data["records"]);
                else
                    this.blogList = data["records"];
            }

            if (this.firstSegment == '/' + this.routesConstants.WEDSPIRATION)
                setTimeout(() => this.sliderReInit(), 100);

            if (this.currentPage == 1) {
                if (isSearchActioned && this.blogList.length > 0) {
                    const element = document.getElementById('blog-listing-wrap');
                    if (element) {
                        const elementPosition = element.getBoundingClientRect().top + window.scrollY;

                        // Adjust offset based on screen width
                        const offset = window.innerWidth <= 768 ? 250 : 100; // Mobile: 250px, PC: 100px

                        window.scrollTo({
                            top: elementPosition - offset,
                            behavior: 'smooth'
                        });
                    }
                } else {
                    this.scrollToSection();
                }
            }

        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error);
        });
    }


    /**
     * Get feature Blog List
     */
    getFeatureBlogsList() {
        var getFeatureBlogList = this._blogService.getFeatureBlogsList()
        lastValueFrom(getFeatureBlogList).then((res: any) => {
            if (res["status"]) {
                this.featureBlogList = res["data"];

                if (this.featureBlogList.length > 0) {
                    setTimeout(() => this.sliderReInit(), 500);
                }
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Click on the load more and get the blog data
     */
    loadMoreBlogs() {
        this.requestParamModel.pageIndex = this.requestParamModel.pageIndex + 1;
        this.getBlogsList(true);
    }

    /**
     * Get Trending blogs
     */
    getBlogsCategories() {
        var getBlogCategories = this._generalService.getBlogCategories()
        lastValueFrom(getBlogCategories).then((res: any) => {
            if (res["status"]) {
                this.blogCategories = res["data"];
                if (this.slug) {
                    this.selectedBlogCategoriesName = res["data"]?.find((category) => category?.slug == this.slug)?.name
                }
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Blog style change
     * @param styleId
     */
    blogCategoriesStyleChange(slug: any, name?: string) {
        // event.preventDefault()
        this.slug = slug
        this.selectedBlogCategoriesName = name == undefined ? '' : name
        this.requestParamModel.pageIndex = 1
        const url = slug ?
            `${this.routesConstants.WEDSPIRATION}/${slug}` :
            this.routesConstants.WEDSPIRATION;
        this.location.go(url)
        this.firstSegment = this.location.path();
        this.setBlogsFilter()

        this.getBlogsList(false,false,true)
    }

    /**
     * Set blog Filter
     */
    setBlogsFilter() {
        this.requestParamModel.removeFilter('blogCategorySlug')
        this.requestParamModel.setFilter('blogCategorySlug', this.slug, 'number')
    }

    searchBlogs() {
        if(this.slug){
            this.requestParamModel.removeFilter('blogCategorySlug')
            this.requestParamModel.setFilter('blogCategorySlug', this.slug, 'number')
        }
        this.isShowClear = false
        this.requestParamModel.removeFilter('search')
        this.requestParamModel.setFilter('search', this.searchValue, 'number')

        if (this.requestParamModel && this.requestParamModel.filter != '') {
            this.isFilterApply = true
        }

        if (this.searchValue) {
            this.isShowClear = true
        }
        this.getBlogsList(false, false, true);
    }

    clearAll() {
        this.showCloseSearchIcon = false;
        this.searchValue = ''
        this.searchBlogs()
    }

    setDefaultImage(event: Event) {
        let image = this.commonConstants.DEFAULT_NO_IMAGE;
        (event.target as HTMLImageElement).src = image
    }

    searchKeywords(event) {
        const value = event.target.value;
        this.showCloseSearchIcon = true
        if (value.length == 0) {
            this.showCloseSearchIcon = false
        }
    }

    clearSearchBox() {
        this.searchValue = ''
        this.showCloseSearchIcon = false
        if (this.isShowClear) {
            this.searchBlogs()
        }
    }
}
