import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {isValidValue} from './common.service';
import {CommonConstants} from '@coreconstant';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SeoService {
    commonConstants = CommonConstants

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private _meta: Meta,
        private _title: Title,
        private router: Router
    ) {
        // Automatically set Canonical URL on every route change
        this.router.events.subscribe(() => {
            this.setCanonicalURL();
        });
    }

    setMetaTags(metaObject) {

        if (isValidValue(metaObject.title)) {
            this._title.setTitle(metaObject.title);
            this._meta.updateTag({property: 'og:title', content: metaObject.title});
            this._meta.updateTag({property: 'twitter:title', content: metaObject.title,});
        }
        if (isValidValue(metaObject.description)) {
            this._meta.updateTag({name: 'description', content: metaObject.description});
            this._meta.updateTag({property: 'og:description', content: metaObject.description})
            this._meta.updateTag({property: 'twitter:description', content: metaObject.description});
        }

        if (isValidValue(metaObject.ogImageUrl)) {
            this._meta.updateTag({
                property: 'og:image',
                content: this.commonConstants.IMAGE_KIT_URL + metaObject.ogImageUrl,
            });

            this._meta.updateTag({
                property: 'twitter:image',
                content: this.commonConstants.IMAGE_KIT_URL + metaObject.ogImageUrl,
            });
        }

        if (isValidValue(metaObject.ogWebUrl)) {
            this._meta.updateTag({
                property: 'og:url',
                content: this.commonConstants.FRONTEND_PREFIX_WITH_WWW + metaObject.ogWebUrl,
            });
            this._meta.updateTag({
                property: 'twitter:url',
                content: this.commonConstants.FRONTEND_PREFIX_WITH_WWW + metaObject.ogWebUrl,
            });
        }

        if (isValidValue(metaObject.robots)) {
            this._meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
        }
        // this.setCanonicalURL();
    }

    setCanonicalURL() {
        const currentUrl = this.commonConstants.FRONTEND_PREFIX_WITH_WWW + this.router.url.replace(/^\/+/, '');
        let link: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null;
        if (link === null) {
            link = this.document.createElement('link');
            link.setAttribute('rel', 'canonical');
            this.document.head.appendChild(link);
        }
        link.setAttribute('href', currentUrl);
    }
}
