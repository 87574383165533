//Contact us Main Title/Banner
.contact-us-title {
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    color: #333333;
    padding: 48px 0 24px;
    border-bottom: 1px solid #272338;
    @media screen and (max-width: 991px) {
        padding: 40px 0 24px;
    }
    @media screen and (max-width: 767px) {
        padding: 24px 0;
    }

    .section-title {
        font-size: 40px;
        line-height: 48px;
        margin: 0 0 16px;
        @media screen and (max-width: 991px) {
            margin-bottom: 8px;
        }
    }

    p {
        margin: 0;
    }
}

//Contact us Wrap
.contact-us-wrap {
    padding: 64px 0;
    @media screen and (max-width: 991px) {
        padding: 40px 0;
    }
    @media screen and (max-width: 767px) {
        padding: 32px 0;
    }

    .container {
        padding-left: 16px;
        padding-right: 16px;
        max-width: 1160px;
    }

    .contact-us-inner {
        gap: 16px;
        flex-wrap: wrap;
        display: flex;
        @media screen and (max-width: 480px) {
            gap: 8px;
            flex-direction: column;
        }

        .secondary-btn {
            width: calc(33.33% - 11px);
            padding: 11px 40px 11px 16px;
            justify-content: flex-start;
            position: relative;
            text-align: left;

            @media screen and (max-width: 991px) {
                width: calc(50% - 8px);
            }

            @media screen and (max-width: 480px) {
                width: 100%;
            }

            i {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 42px;
                width: 42px;
                height: 42px;
                border-radius: 70px;
                border: 1px solid #272338;
                font-size: 20px;
                line-height: 26px;
                font-style: normal;
            }

            span {
                display: inline-flex;
                align-items: center;
                gap: 16px;
            }

            img {
                position: absolute;
                height: 10px;
                top: 0;
                bottom: 0;
                right: 30px;
                margin: auto;
                opacity: 0;
                transition: all 0.35s ease 0s;
                opacity: 0;
                visibility: hidden;
            }

            &:hover {
                background: #FFE6D5;

                img {
                    right: 22px;
                    opacity: 1;
                    visibility: visible;
                }
            }

            &:first-child {
                i {
                    background: #661735;
                }
            }

            &:nth-child(2) {
                i {
                    background: #A8AED6;
                }
            }

            &:nth-child(3) {
                i {
                    background: #F8D066;
                }
            }

            &:nth-child(4) {
                i {
                    background: #E2763A;
                }
            }

            &:nth-child(5) {
                i {
                    background: #EFB7A6;
                }
            }

            &:nth-child(6) {
                i {
                    background: #6C92C7;
                }
            }
        }
    }
}

//FAQ's CSS
.faqs-cta {
    text-align: center;
    padding: 64px 0;
    background: #94B3A5;
    border-top: 1px solid #272338;
    border-bottom: 1px solid #272338;

    @media screen and (max-width: 991px) {
        padding: 48px 0;
    }

    .container {
        padding-right: 16px;
        padding-left: 16px;
        max-width: 746px;
        @media screen and (max-width: 991px) {
            max-width: 600px;
        }
    }

    .section-title {
        margin: 0 0 32px;
        @media screen and (max-width: 991px) {
            margin: 0 0 24px;
        }
        @media screen and (max-width: 767px) {
            margin: 0 0 16px;
        }
    }
}

//Wedding Planning CSS
.wedding-plan-cta {
    font-family: Aeonik, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    color: #333333;
    background: #A8AED6;
    padding: 24px 0;
    border-top: 1px solid #6D629D;

    .container {
        gap: 24px;
        align-items: center;
        justify-content: center;
        display: flex;
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    .section-title {
        margin: 0 0 32px;
    }

    strong, b {
        font-weight: 700;
    }

    p {
        margin: 0;
    }

    a {
        color: #333333;

        &:hover {
            color: #272338;
        }
    }
}

//Back Button
.back-btn {
    padding: 8px 0;
    position: relative;

    @media screen and (max-width: 767px) {
        padding: 0;
    }

    .container {
        padding-right: 80px;
        padding-left: 80px;
        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    button, a {
        vertical-align: top;

        @media screen and (max-width: 767px) {
            width: 32px;
            height: 32px;
            background: #FFFFff;
            border: 1px solid #272338;
            border-radius: 40px;
            align-items: center;
            justify-content: center;
            display: inline-flex;
            font-size: 0;
            position: absolute;
            top: 15px;
            left: 16px;
            z-index: 2;
            span {
                display: none;
            }

            img {
                left: 0;
            }
        }

    }
}

//Contact us Header
.contact-us-header {
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    color: #333333;
    padding: 0 0 24px;

    @media screen and (max-width: 767px) {
        border-bottom: 1px solid #272338;
        padding: 0 0 16px;
    }

    .container {
        padding-left: 16px;
        padding-right: 16px;
        max-width: 728px;
    }

    .section-title {
        //font-size: 40px;
        //line-height: 48px;
        margin: 0 0 16px;
        position: relative;
        @media screen and (max-width: 767px) {
            font-size: 26px;
            line-height: 32px;
            padding: 15px 58px;
            margin: 0 -16px 16px;
            border-bottom: 1px solid #272338;
        }
    }

    p {
        margin: 0;
    }
}

//Contact us Form
.contact-us-form {
    padding: 24px 0 64px;

    @media screen and (max-width: 991px) {
        padding: 16px 0 40px;
    }

    @media screen and (max-width: 767px) {
        padding: 24px 0;
    }

    .container {
        width: 698px;
        max-width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }

    .section-title {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 16px;
    }

    .contact-us-form-box {
        .form-group-row {
            display: flex;
            gap: 12px;
            @media screen and (max-width: 767px) {
                flex-direction: column;
                gap: 0;
            }

            .form-group {
                flex: 1;
            }
        }

        .form-group {
            textarea {
                &.form-control {
                    height: 200px;
                    @media screen and (max-width: 767px) {
                        height: 315px;
                    }
                }
            }

            .label-info {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                display: block;
                margin: 2px 0 4px;
                color: #4D4D4D;

                @media screen and (max-width: 767px) {
                    margin-top: 4px;
                }
            }
        }

        .form-group-radio-2 {
            margin: 12px 0 0;
            gap: 16px;
            flex-wrap: wrap;
            display: flex;

            .custom-radio {
                color: #4D4D4D;
            }
        }
    }

    .contact-us-action {
        margin-top: 8px;

        @media screen and (max-width: 767px) {
            margin-top: 0;
        }

        .primary-btn {
            width: 100%;

            @media screen and (min-width: 768px) {
                font-size: 16px;
                padding: 9px 16px;
            }
        }
    }
}

//Contact us Message Sent
.contact-us-message-sent {
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #4D4D4D;
    text-align: center;
    padding: 48px 0;

    @media screen and (max-width: 767px) {
        padding: 0 0 32px;
    }

    .contact-us-header {
        border-bottom: none;
        padding: 0;
        margin: 0 0 32px;
        display: none;

        .container {
            padding-left: 16px;
            padding-right: 16px;
        }

        @media screen and (max-width: 767px) {
            display: block;
            .section-title {
                margin: 0 -16px 0;
            }
        }
    }

    .container {
        padding-left: 16px;
        padding-right: 16px;
        max-width: 505px;

        @media screen and (max-width: 767px) {
            padding-left: 32px;
            padding-right: 32px;
        }
    }

    .contact-message-icon {
        text-align: center;
        margin: 0 0 24px;

        img {
            width: 68px;
            height: 69px;
        }
    }

    .section-title {
        margin: 0 0 16px;
    }

    p {
        margin: 0;

        + p {
            margin-top: 22px;
        }
    }
}

//Subscribe to our newsletter - Modal and Subscribed Modal
::ng-deep.modal {
    .newsletter-wrap {
        max-width: 667px;
        @media screen and (max-width: 767px) {
            max-width: 100%;
        }

        .modal-header {
            .modal-title {
                font-family: Livorno, serif;
                font-size: 32px;
                font-weight: 400;
                line-height: 38px;
                letter-spacing: -0.01em;
            }

            .close-btn {
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }

        .form-group-row {
            display: flex;
            gap: 12px;

            @media screen and (max-width: 767px) {
                flex-direction: column;
            }

            .form-group {
                flex: 1;
            }
        }

        .form-group {
            @media screen and (min-width: 767px) {
                margin-bottom: 12px;
            }

            textarea {
                &.form-control {
                    height: 200px;
                    @media screen and (max-width: 767px) {
                        height: 175px;
                    }
                }
            }

        }

        .modal-body {
            @media screen and (min-width: 767px) {
                padding-bottom: 16px;
            }
        }

        .modal-footer {
            gap: 16px;

            .secondary-btn {
                display: inline-flex;
            }

            @media screen and (min-width: 767px) {
                flex-direction: row-reverse;
                .primary-btn, .secondary-btn {
                    width: auto;
                    flex: 1;
                    padding: 11px 16px;
                }
            }


        }
    }

    .subscribed-wrap {
        max-width: 667px;
        text-align: center;
        @media screen and (max-width: 767px) {
            max-width: 100%;
        }

        .modal-body {
            text-align: center;
            padding-top: 48px;
            padding-bottom: 42px;

            .subscribed-info {
                font-family: Aeonik, sans-serif;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                color: #4D4D4D;
                max-width: 508px;
                margin: 0 auto;
            }

            .newsletter-icon {
                margin: 0 0 12px;
            }

            h2 {
                font-family: Livorno, serif;
                font-size: 32px;
                line-height: 38px;
                font-weight: 400;
                color: #272338;
                letter-spacing: -0.01em;
                margin: 0 0 12px;
            }

            p {
                margin: 0;

                + p {
                    margin-top: 22px;
                }
            }
        }
    }
}

