<section class="faqs-header">
    <div class="container">
        <h1 class="section-title">FAQs</h1>
        <p>A handful of no-nonsense answers to some very reasonable, possibly burning questions.</p>
    </div>
</section>

@if(isGetResponse) {
@if(faqCategoriesAndData && faqCategoriesAndData.length > 0) {
<section class="faqs-wrap">
    <div class="container">
        <div class="faqs-left-panel">
            @for (category of faqCategoriesAndData; track category;) {
            @if(category?.types && category?.types.length > 0) {
            <div class="faqs-group" [class.faqs-group-open]="activeCategory == category.name"
                (click)="toggleCategory(category.name)">
                <h2>{{category.name}}</h2>
                <ul>
                    @for (type of category?.types; track type;) {
                    <li>
                        <span (click)="scrollToSection('type'+type.id)"  [class.active]="activeSection === 'type'+type.id">{{type.name}}</span>
                    </li>
                    }
                </ul>
            </div>
            }
            }
        </div>

        <div class="faqs-right-panel">
            @if(faqTypesAndData && faqTypesAndData.length > 0) {
            <div id="faqsList" class="accordion">
                @for (type of faqTypesAndData; track type) {
                <h3 [id]="'type' + type.id">{{type.name}}</h3>
                @if(type.faqs && type.faqs.length > 0) {
                @for (faq of type.faqs; track faq) {
                <div class="accordion-item">
                    <h4 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#faq' + faq.id" aria-expanded="true"
                            [attr.aria-controls]="'faq' + faq.id">
                            {{faq.faqQuestion}}
                            <span class="faqs-arrow"></span>
                        </button>
                    </h4>
                    <div [id]="'faq' + faq.id" class="accordion-collapse collapse" data-bs-parent="#faqsList">
                        <div class="accordion-body" [innerHTML]="faq.faqAnswer | safeHtml"></div>
                    </div>
                </div>
                }
                }
                }
            </div>
            } @else {
            <div class="search-results-not-found">
                <p class="emoji-icon">😞</p>
                <p class="search-title">Uh oh</p>
                <p>There were no data for FAQ</p>
            </div>
            }
        </div>
    </div>
</section>
} @else {
<section class="search-results-not-found">
    <div class="container">
        <p class="emoji-icon">😞</p>
        <p class="search-title">Uh oh</p>
        <p>There were no data for FAQ</p>
    </div>
</section>
}
}

<section class="faqs-contact-wrap">
    <div class="container">
        <div class="faqs-contact-info">
            <h2 class="section-title">Have questions we haven’t answered?</h2>
            <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION]" type="button"
                class="primary-btn">Contact us</a>
            <p>*Please keep in mind we get hundreds of requests every week.<br /> We’ll do our best to respond as
                quickly as possible!</p>
        </div>
        <div class="faqs-contact-img">
            <img src="assets/images/wedshed-contact-us.svg" width="325" height="186" alt="Wedshed contact">
        </div>
    </div>
</section>

<!--Wedding Planning section-->
<section class="wedding-plan-cta">
    <div class="container">
        <img src="assets/images/book-icon.svg" width="43" height="40" alt="Book icon">
        <p>Click here to receive your free e-book: <strong>7 simple steps to kickstart your wedding planning</strong></p>
        <img src="assets/images/book-icon.svg" width="43" height="40" alt="Book icon">
    </div>
</section>
