import {Component, HostListener, Inject, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {CommonConstants, RoutesConstants} from '@coreconstant';
import {BlogService} from '../core/services/api-service/blog.service';
import {ResponseHandlerService} from '../core/services/common-service/response-handler.service';
import {lastValueFrom} from 'rxjs';
import {SetImageDimensionsDirective} from '../core/directives/set-image-dimensions.directive';
import {ImagePathConstants} from '../core/constants/image-path-constants';
import {isPlatformBrowser} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SeoService} from '../core/services/common-service/seo.service';

@Component({
    selector: 'app-home',
    imports: [RouterModule, SetImageDimensionsDirective],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent {
    routesConstants = RoutesConstants
    commonConstants = CommonConstants
    imagePathConstants = ImagePathConstants
    trendingBlogs = [];
    isLoad: boolean = false;
    isMobileView: boolean = false

    blogTags: any = [
        {name: '👗 Short wedding dresses', slug: 'short-wedding-dresses'},
        {name: '😅 Managing conflict', slug: 'managing-conflict'},
        {name: '⛈ Planning for weather', slug: 'am-i-the-ahole'},
        {name: '📜 Wedding vows', slug: 'boho-wedding-inspiration'},
        {name: '🐭 Micro-weddings', slug: 'wedding-food-recommendations'},
        {name: '🐶 Pets at weddings', slug: 'pets-at-weddings'},
        {name: '🤔 Choosing a venue', slug: 'destination-wedding'},
        {name: '🎁 Wedding registry', slug: 'small-vs-big-weddings'},
    ];

    @HostListener('window:resize', [])
    onWindowResize() {
        if (isPlatformBrowser(this.platformId)) {
            this.isMobileView = window.innerWidth < 768;
        }
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private _blogService: BlogService,
        private _responseHandlerService: ResponseHandlerService,
        private _seoService: SeoService,
    ) {
        this.onWindowResize()
        this.setMetaTags()
    }

    ngOnInit() {
        this.getTrendingBlogs()
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Find Your Perfect Wedding Venue | Wedshed',
            description: 'Searching for the ideal wedding venue? WedShed connects couples with unique spaces across Australia. Start planning your big day today!',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.HOME_NAVIGATION,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    /**
     * Scroll to particular section
     * @param sectionId
     */
    scrollToSection(sectionId: any) {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }

    /**
     * Get Trending blogs
     */
    getTrendingBlogs() {
        var getListing = this._blogService.getTrendingBlogs()
        lastValueFrom(getListing).then((res: any) => {
            if (res["status"]) {
                this.trendingBlogs = res["data"];
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    setDefaultImage(event: Event) {
        let image = this.commonConstants.DEFAULT_NO_IMAGE;
        (event.target as HTMLImageElement).src = image
    }
}
