import {
    Component,
    inject,
    Inject,
    OnInit,
    PLATFORM_ID,
    TemplateRef,
    ElementRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import Splide from '@splidejs/splide';
import { NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { VenueVendorFooterComponent } from '../../venue-vendor-footer/venue-vendor-footer.component';
import { EnquireVenueVendorComponent } from 'src/app/modal/enquire-venue-vendor/enquire-venue-vendor.component';
import { CommonConstants, RoutesConstants } from '@coreconstant';
import { ResponseHandlerService } from 'src/app/core/services/common-service/response-handler.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { VendorService } from 'src/app/core/services/api-service/vendor.service';
import { lastValueFrom } from 'rxjs';
import { SetImageDimensionsDirective } from 'src/app/core/directives/set-image-dimensions.directive';
import { ImagePathConstants } from 'src/app/core/constants/image-path-constants';
import { SeoService } from '../../../core/services/common-service/seo.service';
import { SafeHtmlPipe } from 'src/app/core/pipes/safe-html.pipe';
import {ImageGalleryComponent} from '../../image-gallery/image-gallery.component';
import {Meta} from '@angular/platform-browser';

@Component({
    selector: 'app-vendor-detail',
    imports: [VenueVendorFooterComponent, SetImageDimensionsDirective, RouterModule, NgbTooltipModule, SafeHtmlPipe, ImageGalleryComponent],
    templateUrl: './vendor-detail.component.html',
    styleUrls: ['./vendor-detail.component.scss', '../../venues/venue-detail/venue-detail.component.scss'],
    providers: [SafeHtmlPipe],
    encapsulation: ViewEncapsulation.None
})

export class VendorDetailComponent implements OnInit {
    commonConstants = CommonConstants
    routesConstants = RoutesConstants
    imagePathConstants = ImagePathConstants
    currentUrl: any
    vendorDetails: any
    slug: any
    localVendorList: any
    firstImage: any
    remainingImages: any = [];
    remainingAndDefaultImages: any = []
    moreImageCount: number;
    isAboutExpanded: boolean = false;
    isOwnerDescExpanded: boolean = false;
    isFeaturedDataExpanded: boolean = false;
    isMoreOwnerDesc: boolean = false;
    isMoreAboutDesc: boolean = false;
    @ViewChild('aboutContainer', { static: false }) aboutContainer!: ElementRef;
    @ViewChild('ownerDescContainer', { static: false }) ownerDescContainer!: ElementRef;
    isGalleryLoaded: boolean = false;
    isPreview: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _modalService: NgbModal,
        private _vendorService: VendorService,
        private _responseHandlerService: ResponseHandlerService,
        private _activatedRoute: ActivatedRoute,
        private _seoService: SeoService,
        private _router: Router,
    ) {
        this.currentUrl = this._router.url;
    }

    private modalService = inject(NgbModal);

    ngOnInit(): void {
        this.getRemainingImages()
        const params = this._activatedRoute.snapshot.params;
        if (params && params['slug']) {
            this.slug = params['slug']
        }
        if (params && params['preview'] && params['preview'] == 'preview') {
            this.isPreview = true;
        }
        // If only slug change then executes this
        this._activatedRoute.paramMap.subscribe(params => {
            this.slug = params.get('slug') || '';
            this.getVendorDetails()
            this.getLocalVendorCategories()
        });

    }

    setMetaTags() {
        let metaTagObject:any = {
            title: this.vendorDetails['metaTitle'],
            description: this.vendorDetails['metaDescription'],
            ogImageUrl: (this.firstImage?.imagePath ? this.firstImage.imagePath : ''),
            ogWebUrl: this.routesConstants.VENDOR_NAVIGATION  + '/' + this.slug,
        }
        if(this.isPreview){
            metaTagObject.robots = 'noindex, nofollow'
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    // ngAfterViewInit() {
    //     setTimeout(() => {
    //         this.checkDescContent()
    //     })
    // }

    checkDescContent() {
        if (this.ownerDescContainer) {
            const element = this.ownerDescContainer.nativeElement;
            this.isMoreOwnerDesc = element.scrollHeight > element.clientHeight
        }

        if (this.aboutContainer) {
            const element = this.aboutContainer.nativeElement;
            this.isMoreAboutDesc = element.scrollHeight > element.clientHeight
        }
    }

    getVendorDetails() {
        var getDetails = this._vendorService.getVendorDetails(this.slug)
        lastValueFrom(getDetails).then((res: any) => {
            if (res["status"]) {
                this.vendorDetails = res["data"]
                if (this.vendorDetails) {
                    if(!this.isPreview && this.vendorDetails.statusTypeId != 3){
                        this._router.navigate(['/' + this.routesConstants.VENDOR_NAVIGATION]);
                        return;
                    }else{
                        if (this.vendorDetails.vendor_images && this.vendorDetails.vendor_images.length > 0) {
                            this.firstImage = this.vendorDetails.vendor_images[0]
                            this.remainingImages = this.vendorDetails.vendor_images.slice(1);
                            this.moreImageCount = this.vendorDetails.vendor_images.length - this.commonConstants.DEFAULT_SHOW_IMAGES_VENUE_VENDOR
                        }
                        this.getRemainingImages()
                        setTimeout(() => {
                            this.checkDescContent()
                        })
                    }
                }
                this.setMetaTags()
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Used to show the remaining and default images
     * @returns
     */
    getRemainingImages() {
        this.remainingAndDefaultImages = []
        this.remainingAndDefaultImages = [...this.remainingImages];

        // Add default images if less than 4
        while (this.remainingAndDefaultImages.length < 4) {
            this.remainingAndDefaultImages.push({
                imagePath: this.commonConstants.DEFAULT_NO_IMAGE,
                id: -this.remainingAndDefaultImages.length,
            });
        }
        this.remainingAndDefaultImages = this.remainingAndDefaultImages.slice(0, 4);
    }

    /**
     * Get Other local vendor categories
     */
    getLocalVendorCategories() {
        var getLocalVendor = this._vendorService.getLocalVendorCategories(this.slug)
        lastValueFrom(getLocalVendor).then((res: any) => {
            if (res["status"]) {
                this.localVendorList = res["data"]
                setTimeout(() => {
                    this.vendorImageSlider()
                })
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Image slider of the vendor list
     */
    vendorImageSlider() {
        if (isPlatformBrowser(this.platformId)) {
            const splides = document.querySelectorAll('.box-slider');

            splides.forEach((element) => {
                new Splide(element as HTMLElement, {
                    type: 'loop',
                }).mount();
            });
        }
    }

    /**
     * Copy the URL to the clipboard
     */
    copyUrl() {
        const input = document.createElement('input');
        input.value = window.location.origin + this.currentUrl;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        this._responseHandlerService.showSuccessMessageGeneral(this.commonConstants.LINK_COPY)
    }

    openVerticallyCentered(content: TemplateRef<any>, modalClass: string) {
        this.modalService.open(content, { centered: true, modalDialogClass: modalClass });
    }

    openEnquireVenueModal(title: any) {
        const addUpdateBlogCategoryModal = this._modalService.open(EnquireVenueVendorComponent, {
            backdrop: 'static',
            keyboard: false,
            size: 'md'
        });
        addUpdateBlogCategoryModal.componentInstance.title = title;
        addUpdateBlogCategoryModal.componentInstance.owner_id = this.vendorDetails.ownerId;
        addUpdateBlogCategoryModal.componentInstance.vendorId = this.vendorDetails.id;

        addUpdateBlogCategoryModal.componentInstance.venueName = this.vendorDetails?.serviceName;

        let ownerName = this.vendorDetails?.servicePostedBy && this.vendorDetails?.servicePostedBy != ''
            ? this.vendorDetails?.servicePostedBy : this.vendorDetails?.users?.firstName + ' ' + this.vendorDetails?.users?.lastName
        addUpdateBlogCategoryModal.componentInstance.ownerName = ownerName;
        addUpdateBlogCategoryModal.componentInstance.listingLogo = this.vendorDetails?.listingLogo;

        addUpdateBlogCategoryModal.result.then(
            (result) => {
                if (result?.status) {
                    // this.getBlogCategoryList();
                }
            }
        );
    }

    /**
     * For About content
     */
    toggleContent() {
        this.isAboutExpanded = !this.isAboutExpanded;
    }

    /**
     * For Owner content
     */
    toggleOwnerContent() {
        this.isOwnerDescExpanded = !this.isOwnerDescExpanded;
    }

    /**
     * For Featured data
     */
    toggleFeaturedData() {
        this.isFeaturedDataExpanded = !this.isFeaturedDataExpanded;
    }

    toggleGallery(flag: boolean) {
        this.isGalleryLoaded = flag;
    }

    setDefaultImage(event: Event) {
        let image = this.commonConstants.DEFAULT_NO_IMAGE;
        (event.target as HTMLImageElement).src = image
    }
}
