import { PaginationModel } from "./pagination-model";

export class RequestParamModel {
    pageIndex = 0;
    pageSize = 20;
    sort?: string;
    asc = true;
    totalCount?: number;
    filter?: string = '';
    defaultFilter = "";
    sorts?: string;
    columnProperty: any;
    keywords: any;
    pageCount?: any;
    moduleName?: string;

    constructor(filter = null) {
        this.pageIndex = 0;
        this.pageSize = 20;
        if (filter) this.filter = filter
    }

    update<T>(pagedList: PaginationModel<T>) {
        this.pageSize = pagedList.pageSize;
        this.totalCount = pagedList.totalCount;
        this.pageIndex = pagedList.currentPage;
    }

    addDefaultFilter(field: string, value: any, datatype: string) {
        if (field && value) {
            if (datatype !== "number") {
                this.defaultFilter += "&filter[" + field + "]" + "[like]" + "=" + value;
            } else {
                this.defaultFilter += "&filter[" + field + "]" + "=" + value;
            }
        }
    }

    /**
     * Set filter for list
     * @param field
     * @param value
     * @param datatype
     */
    setFilter(field: string, value: any, datatype: string) {
        if (field && value) {
            if (datatype !== "number") {
                this.filter += "&filter[" + field + "]" + "[like]" + "=" + value;
            } else {
                this.filter += "&filter[" + field + "]" + "=" + value;
            }
        }
    }

    /**
     * Remove filter
     * @param field
     * @param newData
     */
    removeFilter(field: string) {
        if (field && this.filter) {
            var filterArray = this.filter.split('&');
            if (filterArray.length > 0) {
                filterArray.forEach(function (value, index) {
                    if (value.includes(field))
                        filterArray.splice(index);
                });

                this.filter = filterArray.join("&");
            }
        }
    }

    removeDefaultFilter(field: string) {
        var removeFilterIndex: any = [];
        if (field && this.defaultFilter) {
            var defaultFilterArray = this.defaultFilter.split('&');
            if (defaultFilterArray.length > 0) {
                defaultFilterArray.forEach(function (value, index) {
                    if (value.includes(field)) {
                        // removeFilterIndex.push(index);
                        defaultFilterArray.splice(index);
                    }
                });

                this.defaultFilter = defaultFilterArray.join("&");
            }
        }
    }

    getParams(): string {
        let param = "";

        if (this.defaultFilter) {
            param += this.defaultFilter;
        }

        if (this.filter) {
            param += this.filter;
        }
        if (this.sorts) {
            param += this.sorts;
        }
        if (this.pageIndex) {
            param += "&page=" + this.pageIndex;
        }
        if (this.pageSize) {
            param += "&pageSize=" + this.pageSize;
        }
        if (this.keywords) {
            param += "&keywords=" + this.keywords;
        }
        if (this.moduleName) {
            param += "&moduleName=" + this.moduleName;
        }
        if (param) {
            param = param.replace("&", "?");
        }
        return param;
    }
}
