@if (isGalleryLoaded) {
    <app-image-gallery [slug]="slug" [type]="routesConstants.VENDORS" [imageAltTag]="vendorDetails.serviceName" (backToParent)="toggleGallery(false)"></app-image-gallery>
} @else {
<section class="breadcrumb-wrap">
    <div class="container">
        <ul>
            <li><a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION]">Vendors</a></li>
            <li><img src="assets/images/chevron-right-icon.svg" width="6" height="10" alt="chevron-right-icon"></li>
            <li>{{vendorDetails?.serviceName}}</li>
        </ul>
    </div>
</section>

<section class="details-head-wrap">
    <div class="container">
        <div class="details-head-info">
            <div class="details-head-info-left">
                <h1 class="section-title">{{vendorDetails?.serviceName}}
                    @if(vendorDetails?.isFeatured) {
                    <label class="featured-label">Featured</label>
                    }
                </h1>
                <ul>
                    @if(vendorDetails?.regions?.name && vendorDetails?.states?.name && vendorDetails?.countries?.name) {
                    <li><img src="assets/images/location-icon.svg" width="24" height="24" alt="chevron-right-icon">
                        <span class="location-link">{{vendorDetails?.regions?.name}},
                            {{vendorDetails?.states?.name}},
                            {{vendorDetails?.countries?.name}}</span>
                    </li>
                    }
                    @if(vendorDetails?.message) {
                    <li><img src="assets/images/place-icon.svg" width="24" height="24"
                            alt="chevron-right-icon">{{vendorDetails?.message}}
                    </li>
                    }
                </ul>
            </div>
            <div class="details-head-info-action">
                <!-- Not in current Phase -->
                <!-- <button type="button" class="secondary-btn icon-btn">
                    <img src="assets/images/forward-icon.svg" width="20" height="20" alt="forward-icon">
                </button> -->
                <button type="button" class="secondary-btn icon-btn" (click)="copyUrl()">
                    <img src="assets/images/link-icon.svg" width="24" height="24" alt="link-icon">
                </button>
                <!-- Not in current Phase -->
                <!-- <button type="button" class="secondary-btn saved"
                    (click)="openVerticallyCentered(signup, 'auth-modal')">
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.31004 0.515166H9.31182L9.35104 0.515174L9.35391 0.515158C9.8098 0.512543 10.2615 0.600183 10.6827 0.772835C11.1012 0.944433 11.4809 1.19652 11.8001 1.5143C12.4269 2.18719 12.7913 3.05866 12.829 3.97264L12.8291 3.97531C12.8985 5.46691 12.1053 6.8495 11.0842 7.99484C10.0708 9.13167 8.89601 9.96371 8.33784 10.3481L8.33583 10.3495C7.90343 10.6505 7.54867 10.8774 7.30404 11.0142L7.11155 11.1033L7.02417 11.1322C6.98014 11.146 6.93527 11.1572 6.88982 11.1655C6.85582 11.1674 6.82172 11.167 6.78777 11.1642L6.76714 11.1624H6.74645H6.58168H6.56098L6.54035 11.1642C6.50641 11.167 6.4723 11.1674 6.4383 11.1655C6.39284 11.1572 6.34796 11.146 6.30391 11.1322L6.21657 11.1033L6.02691 11.0155C5.78369 10.8757 5.42075 10.6474 5.00356 10.3519L5.00357 10.3519L4.99911 10.3488C4.4182 9.94668 3.23472 9.11506 2.22177 7.98256C1.2018 6.84221 0.419933 5.46978 0.506599 3.98111L0.506671 3.98112L0.50702 3.97264C0.54469 3.05867 0.909061 2.18723 1.53578 1.51437C2.16481 0.888487 3.01314 0.525302 3.90579 0.501273C4.80122 0.477169 5.67086 0.796254 6.33414 1.39108L6.66783 1.69034L7.00164 1.39122C7.63366 0.824884 8.45677 0.512122 9.31004 0.515166Z"
                            stroke="#272338" />
                    </svg>
                    <span>Saved</span>
                </button> -->
                <button type="button" class="primary-btn" (click)="openEnquireVenueModal('vendor-enquire')">
                    Enquire with vendor
                </button>
            </div>
        </div>

        <!-- @if(firstImage) { -->
        <div class="details-top-gallery">
            <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION]" class="mob-back-to-list">
                <img src="assets/images/back-circle-icon.svg" width="32" height="32" alt="back-circle-icon">
            </a>

            <button (click)="toggleGallery(true)"
                class="secondary-btn">
                <img src="assets/images/image-icon.svg" width="20" height="20" alt="image-icon">
                @if(moreImageCount > 0) {
                <span>{{moreImageCount}} more</span>
                <span class="mob-img-counter">1 / {{vendorDetails?.vendor_images?.length}}</span>
                } @else {
                <span>View Images</span>
                }
            </button>

            <div class="details-gallery-left">
                <div class="details-gallery-img">
                    <img [src]="firstImage?.imagePath ? commonConstants.IMAGE_KIT_URL + firstImage.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                        width="654" height="523" alt={{vendorDetails?.serviceName}} (error)="setDefaultImage($event)"
                        appSetImageDimensions>
                </div>
            </div>

            <div class="details-gallery-right">
                @if(remainingAndDefaultImages && remainingAndDefaultImages.length > 0) {
                <ul>
                    @for (image of remainingAndDefaultImages; track image; let img = $index) {
                    <li>
                        <!-- <span class="video-icon"><img src="assets/images/video-icon.svg" alt="video-icon"></span> -->
                        <div class="details-gallery-img">
                            <img [src]="image?.imagePath ? (image?.vendorId ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE) : commonConstants.DEFAULT_NO_IMAGE"
                                width="294" height="253" alt={{vendorDetails?.serviceName}} (error)="setDefaultImage($event)"
                                appSetImageDimensions>
                        </div>
                    </li>
                    }
                </ul>
                }
            </div>

        </div>
        <!-- } -->
    </div>
</section>

<section class="details-content-wrap">
    <div class="container">
        <div class="details-left-panel">
            @if(vendorDetails?.servicePostedBy || (vendorDetails?.ownerId && vendorDetails?.users?.firstName &&
            vendorDetails?.users?.lastName)) {
            <div class="user-review-box">
                <div class="user-review-info">
                    <img [src]="vendorDetails?.listingLogo ? commonConstants.IMAGE_KIT_URL + vendorDetails?.listingLogo : commonConstants.DEFAULT_USER_IMAGE"
                        width="126" height="126" alt="profile-image">
                    <span>
                        Service by {{vendorDetails?.servicePostedBy && vendorDetails?.servicePostedBy != ''
                        ? vendorDetails?.servicePostedBy :
                        vendorDetails?.users?.firstName +' '+
                        vendorDetails?.users?.lastName}}
                        <!-- Not in current Phase -->
                        <!-- <span class="approved-review">
                            Wedshed approved
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.0334 6.5332L8.36669 13.1999L5.03336 9.86654" stroke="#661735"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span> -->
                    </span>
                </div>
                <!-- Not in current Phase -->
                <!-- <div class="user-reviews">
                    <img src="assets/images/color-star-icon.svg" width="21" height="22" alt="color-star-icon">
                    4.9
                    <button type="button" class="more-review">145 reviews</button>
                </div> -->
            </div>
            }

            @if(vendorDetails?.vendorDescription) {
            <div class="details-content-box">
                <div class="details-section-title">About</div>
                <div class="details-content-desc" #aboutContainer [class.show-more-desc]="isAboutExpanded"
                    [innerHTML]="vendorDetails?.vendorDescription | safeHtml"></div>
                @if(isMoreAboutDesc) {
                <button class="read-more-btn" [class.read-less]="isAboutExpanded"
                    (click)="toggleContent()">{{isAboutExpanded ?
                    'Tell me less' : 'Tell me more'}}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.04 6.51953V18.2197" stroke="#7093FF" stroke-miterlimit="10"
                            stroke-linecap="round" />
                        <path d="M8.04004 14.2207L12.04 18.2207L16.04 14.2207" stroke="#7093FF" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button>
                }
            </div>
            }

            <!-- Not in current Phase -->
            <!-- <div class="pricing-inclusions">
                <h2 class="details-section-title">Pricing inclusions</h2>
                <div class="pricing-lock-box">
                    <img src="assets/images/lock-icon.svg" width="32" height="32" alt="lock-icon">
                    <h3 class="details-section-title">Want to view more?</h3>
                    <p>To view pricing breakdowns you will need to either sign in or create an account. We promise it’s
                        worth
                        it!</p>
                    <button type="button" class="primary-btn"
                        (click)="openVerticallyCentered(signup, 'auth-modal')">Sign up</button>
                </div>

                <div class="pricing-inclusions-info">
                    <ul>
                        <li><label>Venue hire</label>
                            <p>$6,000</p>
                        </li>
                        <li><label>Minimum spend</label>
                            <p>$6,000</p>
                        </li>
                        <li>
                            <label>Food packages</label>
                            <p>Sit down from $250 - $300<br /> Standing from $150 - $200</p>
                        </li>
                        <li>
                            <label>Beverage packages</label>
                            <p>From $120 - $220<br /> Corkage fee $5 per head</p>
                        </li>
                        <li>
                            <label>Elopements / micro weddings</label>
                            <p>Hire price from $2,000</p>
                        </li>
                    </ul>
                    <p><label>Additional hire items available for a fee</label></p>
                    <p>- Clean up the following day<br /> - Another item</p>
                </div>

                <div class="pricing-inclusions-info pricing-inclusions-features">
                    <h3>Venue features</h3>
                    <ul>
                        <li><img src="assets/images/tree-icon.svg" width="24" height="24" alt="tree-icon">
                            <strong>Ceremony
                                type:</strong> Outdoor
                        </li>
                        <li><img src="assets/images/watch-icon.svg" width="24" height="24" alt="watch-icon">
                            <strong>Event
                                time:</strong> 4 - 10 hours
                        </li>
                        <li><img src="assets/images/moon-icon.svg" width="24" height="24" alt="moon-icon">
                            <strong>Curfew:</strong>
                            10:30pm
                        </li>
                        <li><img src="assets/images/person-icon.svg" width="24" height="24" alt="person-icon">
                            <strong>Min-max
                                guests:</strong> 2 - 400
                        </li>
                        <li><img src="assets/images/money-icon.svg" width="24" height="24" alt="money-icon">
                            <strong>Price
                                per
                                head:</strong> $145 - $175
                        </li>
                        <li><img src="assets/images/clock-icon.svg" width="24" height="24" alt="clock-icon">
                            <strong>Additional
                                hire
                                hours:</strong> $20 per head/hour
                        </li>
                        <li><img src="assets/images/food-icon.svg" width="24" height="24" alt="food-icon">
                            <strong>Catering:</strong> In house or third party options
                        </li>
                    </ul>
                </div>

                <div class="pricing-inclusions-info">
                    <h3 class="details-section-title">Off peak pricing</h3>
                    <ul>
                        <li><label>Venue hire</label>
                            <p>$6,000
                        </li>
                        <li><label>Minimum spend</label>
                            <p>$6,000
                        </li>
                    </ul>

                    <h3 class="details-section-title">Peak pricing</h3>
                    <ul>
                        <li><label>Venue hire</label>
                            <p>$6,000
                        </li>
                        <li><label>Minimum spend</label>
                            <p>$6,000
                        </li>
                    </ul>
                    <ul>
                        <li><label>Food packages</label>
                            <p>Sit down from $250 - $300<br /> Standing from $150 - $200
                        </li>
                        <li><label>Beverage packages</label>
                            <p>From $120 - $220<br /> Corkage fee $5 per head
                        </li>
                        <li><label>Elopements / micro weddings </label>
                            <p>Hire price from $2,000
                        </li>
                    </ul>
                    <p><label>Additional hire items available for a fee</label></p>
                    <p>- Clean up the following day<br /> - Another item</p>
                </div>
            </div> -->
            @if(vendorDetails?.vendor_chit_chats?.length > 0){
            <div class="chit-chat-wrap">
                <h2 class="details-section-title">Chit Chat</h2>
                <div class="chit-chat-box">
                    <ul>
                        @for (item of vendorDetails?.vendor_chit_chats; track $index) {
                        <li>
                            <div class="chit-chat-item">
                                {{item.question}}
                                <svg width="23" height="22" viewBox="0 0 23 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.5844 19.0909C7.00741 18 13.2932 5.9091 15.7582 0C20.0719 0 26.9739 2.72728 20.0719 13.6364C11.4444 27.2728 -5.19436 20.4546 1.5844 19.0909Z"
                                        fill="#272338" />
                                </svg>
                            </div>
                        </li>
                        <li class="chit-chat-send">
                            <div class="chit-chat-item">
                                {{item.answer}}
                                <svg width="15" height="11" viewBox="0 0 15 11" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0 8.78988C9.15243 11.5679 17.6078 7.86142 11.7702 6.89493C8.80359 6.40376 5.62448 3.68274 2.75223 0.300049C2.65656 3.44198 1.65503 6.35603 0 8.78988Z"
                                        fill="#EFB7A6" />
                                    <path
                                        d="M3.05984 0.496094C5.9321 3.87879 9.11121 6.5998 12.0778 7.09097C17.9154 8.05746 9.46005 11.764 0.307617 8.98593"
                                        stroke="black" stroke-linejoin="round" />
                                </svg>
                            </div>
                            }
                    </ul>
                </div>
            </div>
            }
            @if(vendorDetails?.ownerDescription) {
            <div class="details-content-box">
                <h2 class="details-section-title">More about the vendor team</h2>
                <div class="details-content-desc" #ownerDescContainer [class.show-more-desc]="isOwnerDescExpanded"
                    [innerHTML]="vendorDetails?.ownerDescription | safeHtml"></div>
                @if(isMoreOwnerDesc) {
                <button class="read-more-btn" [class.read-less]="isOwnerDescExpanded"
                    (click)="toggleOwnerContent()">{{isOwnerDescExpanded ? 'Tell me less' :
                    'Tell me more'}}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.04 6.51953V18.2197" stroke="#7093FF" stroke-miterlimit="10"
                            stroke-linecap="round" />
                        <path d="M8.04004 14.2207L12.04 18.2207L16.04 14.2207" stroke="#7093FF" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button>
                }
            </div>
            }

            @if(vendorDetails?.blog_vendors && vendorDetails?.blog_vendors?.length > 0) {
            <div class="details-featured-in blogs-list">
                <h2 class="details-section-title">Featured in</h2>
                <div class="card-list">
                    @for (blog_vendor of vendorDetails?.blog_vendors; track blog_vendor; let k = $index) {
                    <div class="card-main" [class.d-none]="!isFeaturedDataExpanded && k > 1">
                        <div class="slider-custom">
                            <a [routerLink]=" ['/'+ routesConstants.BLOG+ '/'+ blog_vendor?.blogs?.blog_categories[0].slug + '/'+ blog_vendor?.blogs?.slug]"
                                class="image-box">
                                <img [src]="blog_vendor?.blogs?.featureImagePath ? commonConstants.IMAGE_KIT_URL + blog_vendor?.blogs?.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                    width="256" height="250" alt={{blog_vendor?.serviceName}} (error)="setDefaultImage($event)"
                                    appSetImageDimensions loading="lazy">
                            </a>
                        </div>
                        <a [routerLink]=" ['/'+ routesConstants.BLOG+ '/'+ blog_vendor?.blogs?.blog_categories[0].slug + '/'+ blog_vendor?.blogs?.slug]"
                            class="card-details">
                            @if(blog_vendor?.blogs?.blog_categories && blog_vendor?.blogs?.blog_categories.length >
                            0) {
                            <span class="card-label">
                                {{blog_vendor?.blogs?.blog_categories[0].name}}
                            </span>
                            }
                            <span class="card-title-inner">{{blog_vendor?.blogs?.title}}</span>
                            <span class="card-dec" [innerHTML]="blog_vendor?.blogs?.excerpt"></span>
                            <!-- Not in current Phase -->
                            <!-- <div class="card-category">
                                <button type="button" class="secondary-btn">Country wedding 🌾</button>
                                <button type="button" class="secondary-btn">Rural 🚜</button>
                            </div> -->
                        </a>
                    </div>
                    }
                </div>
                @if(vendorDetails?.blog_vendors.length > 2) {
                <button class="read-more-btn" [class.read-less]="isFeaturedDataExpanded"
                    (click)="toggleFeaturedData()">{{isFeaturedDataExpanded ?
                    'Show me less' : 'Show me more'}}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.04 6.51953V18.2197" stroke="#7093FF" stroke-miterlimit="10"
                            stroke-linecap="round" />
                        <path d="M8.04004 14.2207L12.04 18.2207L16.04 14.2207" stroke="#7093FF" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button>
                }
            </div>
            }
        </div>

        <div class="details-right-panel">
            <div class="details-quick-info">
                <div class="wedshed-approved">
                    <img [src]="imagePathConstants.PLANNING_VENUE_VENDOR + '/wedshed-shield.svg'" width="54" height="63"
                        alt="wedshed-shield">
                    <p>This vendor is Wedshed approved
                        <span class="info-tooltip">
                            <img src="assets/images/info-icon.svg" width="14" height="15" alt="info-icon">
                            <span class="info-tooltip-box">This vendor host is an official partner of Wedshed. Their
                                vendor information
                                is up-to-date.</span>
                        </span>
                    </p>
                </div>
                <!-- Not in current Phase -->
                <!-- <div class="pricing-info">
                    <i class="price-icon">
                        <img src="assets/images/price-icon.svg" width="56" height="52" alt="price-icon">
                    </i>
                    <p>Pricing available when you <strong (click)="openVerticallyCentered(signup, 'auth-modal')">sign
                            up</strong></p>
                    <div class="pricing-from" style="display:none;">
                        <label>From</label>
                        $35,000
                    </div>
                </div> -->
                @if(vendorDetails?.vendor_types && vendorDetails?.vendor_types.length > 0) {
                <div class="details-category-list">
                    @for (type of vendorDetails.vendor_types; track type; let k = $index) {
                    <label>{{type?.name}}</label>
                    }
                </div>
                }

                <div class="quick-info-action">
                    <button type="button" class="primary-btn" (click)="openEnquireVenueModal('vendor-enquire')">Enquire
                        with vendor
                    </button>
                    <p>This is an enquiry, you won’t be charged.</p>
                </div>
            </div>

            <!-- Not in current Phase -->
            <!-- <div class="attention-box">
                <img src="assets/images/little-star-icon.svg" width="15" height="15" alt="little-star-icon"
                    class="little-star-icon">
                This vendor is receiving a lot of attention
                <img src="assets/images/little-two-star-icon.svg" width="21" height="27" alt="little-two-star-icon"
                    class="little-two-star-icon">
            </div> -->
        </div>
    </div>
</section>


<!-- Not in current Phase -->
<!--======================= Featured Venues Section HTML Start =======================-->
<!-- <section class="featured-wrap">
    <div class="container">
        <h2 class="section-semi-title">Featured Venues</h2>
        <div id="parent-slider" class="splide">
            <div class="splide__track">
                <ul class="splide__list">
                    <li class="splide__slide">
                        <div class="card-main">
                            <div class="slider-custom">
                                <label class="featured-label">Featured</label>
                                <button type="button" class="heart-icon">
                                    <img src="assets/images/heart-icon-solid.svg" width="26" height="26"
                                        alt="Heart Icon" title="favorite">
                                </button>
                                <div class="slider-inner">
                                    <div class="splide featured-image-slider">
                                        <div class="splide__track">
                                            <ul class="splide__list">
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="#" class="card-details">
                                <div class="card-title">
                                    <h3 class="title">Uraidla & Summertown Show Society Hall</h3>
                                    <div class="right-side-rating">
                                        <span class="rating">4.5</span>
                                        <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                    </div>
                                </div>
                                <span class="address">Sydney, NSW</span>
                                <div class="card-footer">
                                    <span class="price">From $5,000</span>
                                    <span class="dot">&middot;</span>
                                    <span class="guests">22 guests</span>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li class="splide__slide">
                        <div class="card-main">
                            <div class="slider-custom">
                                <label class="featured-label">Featured</label>
                                <button type="button" class="heart-icon">
                                    <img src="assets/images/heart-icon-solid.svg" width="26" height="26"
                                        alt="Heart Icon" title="favorite">
                                </button>
                                <div class="slider-inner">
                                    <div class="splide featured-image-slider">
                                        <div class="splide__track">
                                            <ul class="splide__list">
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="#" class="card-details">
                                <div class="card-title">
                                    <h3 class="title">Uraidla & Summertown Show Society Hall</h3>
                                    <div class="right-side-rating">
                                        <span class="rating">4.5</span>
                                        <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                    </div>
                                </div>
                                <span class="address">Sydney, NSW</span>
                                <div class="card-footer">
                                    <span class="price">From $5,000</span>
                                    <span class="dot">&middot;</span>
                                    <span class="guests">22 guests</span>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li class="splide__slide">
                        <div class="card-main">
                            <div class="slider-custom">
                                <label class="featured-label">Featured</label>
                                <button type="button" class="heart-icon">
                                    <img src="assets/images/heart-icon-solid.svg" width="26" height="26"
                                        alt="Heart Icon" title="favorite">
                                </button>
                                <div class="slider-inner">
                                    <div class="splide featured-image-slider">
                                        <div class="splide__track">
                                            <ul class="splide__list">
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="#" class="card-details">
                                <div class="card-title">
                                    <h3 class="title">Uraidla & Summertown Show Society Hall</h3>
                                    <div class="right-side-rating">
                                        <span class="rating">4.5</span>
                                        <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                    </div>
                                </div>
                                <span class="address">Sydney, NSW</span>
                                <div class="card-footer">
                                    <span class="price">From $5,000</span>
                                    <span class="dot">&middot;</span>
                                    <span class="guests">22 guests</span>
                                </div>
                            </a>
                        </div>
                    </li>

                    <li class="splide__slide">
                        <div class="card-main">
                            <div class="slider-custom">
                                <label class="featured-label">Featured</label>
                                <button type="button" class="heart-icon">
                                    <img src="assets/images/heart-icon-solid.svg" width="26" height="26"
                                        alt="Heart Icon" title="favorite">
                                </button>
                                <div class="slider-inner">
                                    <div class="splide featured-image-slider">
                                        <div class="splide__track">
                                            <ul class="splide__list">
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="#" class="card-details">
                                <div class="card-title">
                                    <h3 class="title">Uraidla & Summertown Show Society Hall</h3>
                                    <div class="right-side-rating">
                                        <span class="rating">4.5</span>
                                        <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                    </div>
                                </div>
                                <span class="address">Sydney, NSW</span>
                                <div class="card-footer">
                                    <span class="price">From $5,000</span>
                                    <span class="dot">&middot;</span>
                                    <span class="guests">22 guests</span>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li class="splide__slide">
                        <div class="card-main">
                            <div class="slider-custom">
                                <label class="featured-label">Featured</label>
                                <button type="button" class="heart-icon">
                                    <img src="assets/images/heart-icon-solid.svg" width="26" height="26"
                                        alt="Heart Icon" title="favorite">
                                </button>
                                <div class="slider-inner">
                                    <div class="splide featured-image-slider">
                                        <div class="splide__track">
                                            <ul class="splide__list">
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="#" class="card-details">
                                <div class="card-title">
                                    <h3 class="title">Uraidla & Summertown Show Society Hall</h3>
                                    <div class="right-side-rating">
                                        <span class="rating">4.5</span>
                                        <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                    </div>
                                </div>
                                <span class="address">Sydney, NSW</span>
                                <div class="card-footer">
                                    <span class="price">From $5,000</span>
                                    <span class="dot">&middot;</span>
                                    <span class="guests">22 guests</span>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li class="splide__slide">
                        <div class="card-main">
                            <div class="slider-custom">
                                <label class="featured-label">Featured</label>
                                <button type="button" class="heart-icon">
                                    <img src="assets/images/heart-icon-solid.svg" width="26" height="26"
                                        alt="Heart Icon" title="favorite">
                                </button>
                                <div class="slider-inner">
                                    <div class="splide featured-image-slider">
                                        <div class="splide__track">
                                            <ul class="splide__list">
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                                <li class="splide__slide">
                                                    <a href="#" class="image-box">
                                                        <img src="assets/images/planning-hub-image.webp" width="256"
                                                            height="250" alt="...">
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="#" class="card-details">
                                <div class="card-title">
                                    <h3 class="title">Uraidla & Summertown Show Society Hall</h3>
                                    <div class="right-side-rating">
                                        <span class="rating">4.5</span>
                                        <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                    </div>
                                </div>
                                <span class="address">Sydney, NSW</span>
                                <div class="card-footer">
                                    <span class="price">From $5,000</span>
                                    <span class="dot">&middot;</span>
                                    <span class="guests">22 guests</span>
                                </div>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section> -->
<!--======================= Featured Venues Section HTML End =======================-->

<!--======================= Other local vendor categories HTML Start =======================-->
@if(localVendorList && localVendorList.length > 0) {
<section class="vendors-categories">
    <div class="container">
        <div class="section-header">
            <div class="section-header-info">
                <h2 class="section-semi-title">Other local vendors </h2>
<!--                <p>Vendors servicing <strong>Sydney</strong>, <strong>Hunter Valley Region</strong>, and <strong>South Coast</strong></p>-->
            </div>
            <a type="button" [routerLink]=" ['/'+ routesConstants.VENDOR_NAVIGATION]" [state]="{ region: {name: vendorDetails?.regions?.name, id: vendorDetails?.regions?.id} }" class="secondary-btn">View all
                local vendors
                <img src="assets/images/arrow-right.svg" width="21" height="20" alt="arrow-right-icon">
            </a>
        </div>
        <div class="card-list">
            @for (vendor of localVendorList; track vendor; let vendorIndex = $index) {
            <div class="card-main">
                <div class="slider-custom">
                    <!-- Not in current Phase -->
                    <!-- <label class="local-label">Local</label>
                    <button type="button" class="heart-icon">
                        <img src="assets/images/heart-icon-solid.svg" width="26" height="26" alt="Heart Icon"
                            title="favorite">
                    </button> -->
                    @if(vendor.vendor_images && vendor.vendor_images.length > 0)
                    {
                    <div class="slider-inner">
                        <div class="splide box-slider">
                            <div class="splide__track">
                                <ul class="splide__list">
                                    @for (image of vendor.vendor_images; track image; let j = $index) {
                                    <li class="splide__slide">
                                        <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]"
                                            class="image-box">
                                            <img [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                width="256" height="250" alt={{vendor?.serviceName}}
                                                (error)="setDefaultImage($event)" appSetImageDimensions loading="lazy">
                                        </a>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    } @else {
                    <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]" class="image-box">
                        <img [src]="commonConstants.DEFAULT_NO_IMAGE" width="256" height="250" alt="image-not-found"
                            loading="lazy">
                    </a>
                    }
                </div>
                <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]" class="card-details">
                    <div class="card-title">
                        <h3 class="title">{{vendor?.serviceName}}</h3>
                        <!-- Not in current Phase -->
                        <!-- <div class="right-side-rating">
                            <span class="rating">4.5</span>
                            <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                        </div> -->
                    </div>
                    @if(vendor?.regions || vendor?.states) {
                    <span class="address">{{vendor?.regions?.name}},
                        {{vendor?.states?.name}}</span>
                    }

                    @if(vendor?.vendor_types && vendor?.vendor_types.length > 0) {
                    <div class="card-category">
                        @for (type of vendor.vendor_types.slice(0,
                        commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT); track type;) {
                        <button type="button" class="secondary-btn" [ngbTooltip]="type?.name">{{type?.name}}</button>
                        }
                        @if((vendor?.vendor_types.length - commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT) > 0)
                        {
                        <div class="care-more-category">+{{vendor?.vendor_types.length -
                            commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT}}
                            <div class="care-more-category-box">
                                @for (remainingType of
                                vendor.vendor_types.slice(commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT); track
                                remainingType;) {
                                <button type="button" class="secondary-btn">{{remainingType?.name}}</button>
                                }
                            </div>
                        </div>
                        }
                    </div>
                    }
                </a>
            </div>
            }
        </div>
    </div>
</section>
}
<!--======================= Other local vendor categories HTML End =======================-->

<!--Quick Action Box-->
<section class="quick-action-wrap">
    <div class="container">
        <div class="quick-action-item">
            <h2>Procrastinate your planning by browsing some inspiration. ✨</h2>
            <a type="button" [routerLink]="['/'+ routesConstants.WEDSPIRATION]" class="secondary-btn">
                Browse Advice & Ideas
                <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="button-right-icon">
            </a>
        </div>
        <div class="quick-action-item">
            <h2>Excited and in the mood to explore destinations? 🌏</h2>
            <a type="button" [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION]" class="secondary-btn">
                Check out regions
                <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="button-right-icon">
            </a>
        </div>

        <div class="quick-action-item">
            <h2>Are you a vendor?<br /> Hi there. 👋</h2>
            <div class="quick-action-btn">
                <a type="button" [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" class="secondary-btn">
                    Sign up
                    <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="button-right-icon">
                </a>
                <a type="button" [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_VENUES_NAVIGATION]" class="secondary-btn plain-btn">How does it work?</a>
            </div>
        </div>
    </div>
</section>

<!--======================= Sign-up and Log-in Section HTML Start =======================-->
<app-venue-vendor-footer></app-venue-vendor-footer>
<!--======================= Sign-up and Log-in Section HTML End =======================-->
}
