import { environment } from "src/environments/environment";

export class ImagePathConstants {
    public static IMAGE_KIT_URL = environment.imageKitUrl;
    public static ASSETS = "wed-assets/"

    public static MENU = this.IMAGE_KIT_URL + this.ASSETS + "menu";
    public static HOME = this.IMAGE_KIT_URL + this.ASSETS + "home";
    public static PLANNING_VENUE_VENDOR = this.IMAGE_KIT_URL + this.ASSETS + "planning-venue-vendor";
    public static PLANNING_HUB = this.IMAGE_KIT_URL + this.ASSETS + "planning-hub";
    public static HOW_IT_WORKS = this.IMAGE_KIT_URL + this.ASSETS + "how-it-works";
}