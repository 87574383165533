<section class="page-title-wrap" [class.device-filter-open]="isClassToggled" [class.page-title-sticky]="isSticky">
    <div class="container">
        <div class="page-title-info">
            <h1 class="section-title">Find a venue</h1>
            <p>Start searching for a celebration-worthy space.</p>
        </div>

        <!-- Mobile Filter Start -->
        <div class="main-mob-filter" [class.selected]="isFilterApply">
            <button type="button" class="filter-item-btn" (click)="toggleClass()">
                <span class="filter-btn-label">{{
                        selectedCountryNames.length > 0 ?
                            firstSomeCountries.join(', ') : 'Venue Location'
                    }}
                    @if (selectedCountryNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                        <span>+{{ selectedCountryNames.length - firstSomeCountries.length }}</span>
                    }
                </span>
                <span class="filter-btn-data">
                    {{
                        selectedRegionNames.length > 0 ?
                            firstSomeRegions.join(', ') : 'Anywhere'
                    }}
                    @if (selectedRegionNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                        <span>+{{ selectedRegionNames.length - firstSomeRegions.length }}</span>
                    }
                </span>
            </button>

            <button class="secondary-btn" (click)="toggleClass()">
                <img src="assets/images/filter-list-icon.svg" width="28" height="28" alt="filter-list-icon">
            </button>
        </div>
        <!-- Mobile Filter End -->

        <!-- Main Filter Start -->
        <div #venueFilterWrap class="main-filter-wrap" [class.filter-open]="filterOpen !== ''">
            <div class="main-filter-head">
                <span class="filter-back-btn" (click)="removeClass()">
                    <img src="assets/images/back-circle-icon.svg" width="32" height="32" alt="back-circle-icon">
                </span>
                Venue search
            </div>

            <div class="main-filter-inner">
                <div class="filter-item" [class.filter-show]="filterOpen == 'country'">
                    <button type="button" class="filter-item-btn" (click)="toggleBtnClassForFilter('country')">
                        <span class="filter-btn-label">Country</span>
                        <span class="filter-btn-data"
                              [class.selected]="selectedCountryNames.length > 0">{{
                                selectedCountryNames.length > 0 ?
                                    firstSomeCountries.join(', ') : 'Select a country'
                            }}
                            @if (selectedCountryNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                                <span>+{{ selectedCountryNames.length - firstSomeCountries.length }}</span>
                            }</span>
                    </button>

                    <div class="filter-item-dropdown">
                        @for (country of countryList; track country; let j = $index) {
                            <div class="map-filter" [class.selected]="selectedCountryIds.includes(country.id)"
                                 (click)="onSelectCountry(country)">
                                <img
                                    [src]="country.imagePath ? commonConstants.IMAGE_KIT_URL + country.imagePath + '?tr=w-71,h-81,q-100' : commonConstants.DEFAULT_NO_IMAGE"
                                    (error)="setDefaultImage($event)" width="71" height="81" alt="{{country.name}}-map">
                                {{ country.name }}
                            </div>
                        }

                        <!-- Mobile Next Button -->
                        <div class="filter-next-btn" (click)="toggleBtnClassForFilter('state')">
                            <button type="button" class="secondary-btn">Next <img src="assets/images/arrow-down.svg"
                                                                                  width="25" height="24"
                                                                                  alt="arrow-down-icon"></button>
                        </div>
                    </div>
                </div>

                <div class="filter-item" [class.filter-show]="filterOpen == 'state'">
                    <button type="button" class="filter-item-btn" (click)="toggleBtnClassForFilter('state')">
                        <span class="filter-btn-label">State / PROVINCE</span>
                        <span class="filter-btn-data"
                              [class.selected]="selectedStateNames.length > 0">{{
                                selectedStateNames.length > 0 ?
                                    firstSomeStates.join(', ') : 'Select a state/province'
                            }}
                            @if (selectedStateNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                                <span>+{{ selectedStateNames.length - firstSomeStates.length }}</span>
                            }
                        </span>
                    </button>

                    <div class="filter-item-dropdown province-filter">
                        @if (filterStateList && filterStateList.length > 0) {
                            @for (state of filterStateList; track state; let j = $index) {
                                <div class="map-filter" [class.selected]="selectedStateIds.includes(state.id)"
                                     (click)="onSelectState(state)">
                                    <img
                                        [src]="state.imagePath ? commonConstants.IMAGE_KIT_URL + state.imagePath + '?tr=w-71,h-81,q-100' : commonConstants.DEFAULT_NO_IMAGE"
                                        (error)="setDefaultImage($event)" width="71" height="81"
                                        alt="{{state.name}}-map">
                                    {{ state.name }}
                                </div>
                            }
                        } @else {
                            <div>No state found in selected country</div>
                        }

                        <!-- Mobile Next Button -->
                        <div class="filter-next-btn" (click)="toggleBtnClassForFilter('region')">
                            <button type="button" class="secondary-btn">Next <img src="assets/images/arrow-down.svg"
                                                                                  width="25" height="24"
                                                                                  alt="arrow-down-icon"></button>
                        </div>
                    </div>
                </div>

                <div class="filter-item" [class.filter-show]="filterOpen == 'region'">
                    <button type="button" class="filter-item-btn" (click)="toggleBtnClassForFilter('region')">
                        <span class="filter-btn-label">Region</span>
                        <span class="filter-btn-data" [class.selected]="selectedRegionNames.length > 0">
                            {{
                                selectedRegionNames.length > 0 ?
                                    firstSomeRegions.join(', ') : 'Select a region'
                            }}
                            @if (selectedRegionNames.length > commonConstants.MAX_SHOW_COUNT_FILTER) {
                                <span>+{{ selectedRegionNames.length - firstSomeRegions.length }}</span>
                            }
                        </span>
                    </button>

                    <div class="filter-item-dropdown region-filter">
                        <div class="region-filter-inner">
                            @if (!isRegionListEmpty && filterStateList && filterStateList.length > 0) {
                                @for (state of filterStateList; track state; let j = $index) {
                                    @if (state.regionList && state.regionList.length > 0) {
                                        <div class="region-title">{{ state.name }}</div>
                                        <div class="filter-region-list">
                                            @for (region of state.regionList; track region; let k = $index) {
                                                <label [class.selected]="selectedRegionIds.includes(region.id)"
                                                       (click)="onSelectRegion(region)">{{ region.name }}</label>
                                            }
                                        </div>
                                    }
                                }
                            } @else {
                                <div>No region found in selected country/state</div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-filter-action">
                <button class="secondary-btn" (click)="clearAll()">Clear all</button>
                <button class="primary-btn" (click)="searchData()">
                    <img src="assets/images/search-white-icon.svg" width="16" height="17" alt="search-white-icon">
                    <span>Search</span>
                </button>
            </div>
        </div>
        <!-- Main Filter End -->

    </div>
</section>
<div class="filter-overlay" [class.show]="filterOpen !== ''"></div>
<!--======================= Page Title Section HTML End =======================-->

<!--======================= Tabbing and Filter Section HTML Start =======================-->
<section class="tabbing-filter">
    <div class="container">
        <div class="tabbing-filter-inner">
            <div class="filter-slider">
                <div #filterListSlider id="filterList" class="splide">
                    <div class="splide__track">
                        <ul class="splide__list">
                            <li class="splide__slide">
                                <a href="" class="link" [class.active]="selectedVenueStyle == ''"
                                   (click)="venueStyleChange('')">
                                    <span class="icon">
                                        <img src="{{commonConstants.ALL_STYLE_ICON}}" width="24" height="24"
                                             alt="All Icon" title="All" loading="lazy">
                                    </span>
                                    <span>All</span>
                                </a>
                            </li>
                            @for (venueStyle of venueStyles; track venueStyle; let i = $index) {

                                <li class="splide__slide">
                                    <a href="" class="link" [class.active]="selectedVenueStyle == venueStyle.id"
                                       (click)="venueStyleChange(venueStyle.id)">
                                    <span class="icon">
                                        <img
                                            [src]="venueStyle.imagePath ? commonConstants.IMAGE_KIT_URL + venueStyle.imagePath + '?tr=w-24,h-24,q-100' : commonConstants.ALL_STYLE_ICON"
                                            width="24" height="24" alt="{{venueStyle.name}} icon"
                                            title="{{venueStyle.name}}" loading="lazy"
                                            (error)="setDefaultImage($event, 'icon')">
                                    </span>
                                        <span>{{ venueStyle.name }}</span>
                                    </a>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Not in current Phase -->
            <!-- <div class="right-side-action-btn" style="display: none;">
                <button type="button" class="secondary-btn">Map
                    <img src="assets/images/map-icon.svg" width="20" height="20" alt="Map">
                </button>
                <button type="button" class="secondary-btn">Filter
                    <img src="assets/images/filter-list-icon.svg" width="20" height="20" alt="Filter">
                </button>
            </div> -->
        </div>
    </div>
</section>
<!--======================= Tabbing and Filter Section HTML End =======================-->

<!--======================= No Result Found HTML Start =======================-->
<!--======================= No Result Found HTML End =======================-->

<!-- Not in current Phase -->
<!--======================= Browse other options HTML Start =======================-->
<!-- <section class="browse-other-options" style="display: none;">
    <div class="container">
        <div class="section-semi-title"><span>BROWSE OTHER OPTIONS</span>Check out other venues in nearby regions</div>
        <p><a href="/">Sydney</a>, <a href="/">Hunter Valley Region</a>, and <a href="/">South Coast</a></p>
    </div>
</section> -->
<!--======================= Browse other options HTML End =======================-->

<!--======================= Showing Result HTML Start =======================-->
@if (isGetResponse) {
    @if (isFilterApply && totalCount == 0) {
        <section class="search-results-not-found">
            <div class="container">
                <p class="emoji-icon">😞</p>
                <p class="search-title">Uh oh</p>
                <p>There were no results for <strong>your search</strong></p>
                <a href="" class="clear-search-btn" (click)="clearSearch()">Clear search</a>
            </div>
        </section>
    }
    @if (isFilterApply && totalCount > 0) {
        <section class="showing-results-wrap">
            <div class="container">
                Showing {{ totalCount }} {{ totalCount == 1 ? 'venue' : 'venues' }}
                <button class="secondary-btn" (click)="clearSearch()">Clear search</button>
            </div>
        </section>
    }

    @if (totalCount == 0 && !isFilterApply) {
        <section class="search-results-not-found">
            <div class="container">
                <p class="emoji-icon">😞</p>
                <p class="search-title">Uh oh</p>
                <p>There were no results for venue</p>
            </div>
        </section>
    }
}
<!--======================= Showing Result HTML End =======================-->

<!--======================= Featured Venues Section HTML Start =======================-->
@if (!isFilterApply) {
    <section class="featured-venues-section">
        @if (featuredVenues && featuredVenues.length > 0) {
            <div class="container">
                <h2 class="section-semi-title">Featured Venues</h2>
                <div #parentSlider class="splide" id="parent-slider">
                    <div class="splide__track">
                        <ul class="splide__list">

                            @for (featuredVenue of featuredVenues; track featuredVenue; let i = $index) {
                                <li class="splide__slide">
                                    <div class="card-main">
                                        <div class="slider-custom">
                                            <label class="featured-label">Featured</label>
                                            <!-- Not in current Phase -->
                                            <!-- <button type="button" class="heart-icon" style="display: none;">
                                                <img src="assets/images/heart-icon-solid.svg" width="26" height="26"
                                                    alt="Heart Icon" title="favorite">
                                            </button> -->
                                            @if (featuredVenue.venue_images && featuredVenue.venue_images.length
                                            > 0) {
                                                <div class="slider-inner">
                                                    <div class="splide featured-image-slider">
                                                        <div class="splide__track">
                                                            <ul class="splide__list">
                                                                @for (image of featuredVenue.venue_images; track image; let j =
                                                                    $index) {
                                                                    <li class="splide__slide">
                                                                        <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ featuredVenue.slug]"
                                                                           class="image-box">
                                                                            <img
                                                                                [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                                                width="256" height="250"
                                                                                alt={{featuredVenue.venueName}}
                                                                                (error)="setDefaultImage($event)"
                                                                                appSetImageDimensions
                                                                                loading="lazy">
                                                                        </a>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            } @else {
                                                <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ featuredVenue.slug]"
                                                   class="image-box">
                                                    <img src="{{commonConstants.DEFAULT_NO_IMAGE}}" width="256"
                                                         height="250"
                                                         alt="image-not-found" loading="lazy">
                                                </a>
                                            }
                                        </div>
                                        <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ featuredVenue.slug]"
                                           class="card-details">
                                            <div class="card-title">
                                                <h3 class="title">{{ featuredVenue.venueName }}</h3>
                                                <!-- Not in current Phase -->
                                                <!-- <div class="right-side-rating" style="display: none;">
                                                    <span class="rating">4.5</span>
                                                    <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                                </div> -->
                                            </div>
                                            @if (featuredVenue?.regions || featuredVenue?.states) {
                                                <span class="address">{{ featuredVenue?.regions?.name }},
                                                    {{ featuredVenue?.states?.name }}</span>
                                            }
                                            <!-- Not in current Phase -->
                                            <!-- <div class="card-footer" style="display: none;">
                                                <span class="price">From $5,000</span>
                                                <span class="dot">&middot;</span>
                                                <span class="guests">22 guests</span>
                                            </div> -->
                                        </a>
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        }
    </section>
}
<!--======================= Featured Venues Section HTML End =======================-->

<!--======================= Venues Section HTML Start =======================-->
@if (venueList && venueList.length > 0) {
    <section class="venues-section">
        <div class="container">
            <div class="card-list">
                @for (venue of venueList; track venue; let i = $index) {
                    <div class="card-main">
                        <div class="slider-custom">
                            <!-- Not in current Phase -->
                            <!-- <button type="button" class="heart-icon">
                                <img src="assets/images/heart-icon-solid.svg" width="26" height="26" alt="Heart Icon"
                                    title="favorite">
                            </button> -->
                            @if (venue.venue_images && venue.venue_images.length > 0) {
                                <div class="slider-inner">
                                    <div class="splide box-slider">
                                        <div class="splide__track">
                                            <ul class="splide__list">
                                                @for (image of venue.venue_images; track image; let j = $index) {
                                                    <li class="splide__slide">
                                                        <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ venue.slug]"
                                                           class="image-box">
                                                            <img
                                                                [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                                width="256" height="250" alt={{venue.venueName}}
                                                                (error)="setDefaultImage($event)"
                                                                appSetImageDimensions loading="lazy">
                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            } @else {
                                <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ venue.slug]"
                                   class="image-box">
                                    <img src="{{commonConstants.DEFAULT_NO_IMAGE}}" width="256" height="250"
                                         alt="image-not-found"
                                         loading="lazy">
                                </a>
                            }

                        </div>
                        <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION + '/'+ venue.slug]"
                           class="card-details">
                            <div class="card-title">
                                <h2 class="title">{{ venue.venueName }}</h2>
                                <!-- Not in current Phase -->
                                <!-- <div class="right-side-rating">
                                    <span class="rating">4.5</span>
                                    <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                </div> -->
                            </div>
                            @if (venue?.regions || venue?.states) {
                                <span class="address">{{ venue?.regions?.name }},
                                    {{ venue?.states?.name }}</span>
                            }
                            <!-- Not in current Phase -->
                            <!-- <div class="card-footer">
                                <span class="price">From $5,000</span>
                                <span class="dot">&middot;</span>
                                <span class="guests">22 guests</span>
                            </div> -->
                        </a>
                    </div>
                }
            </div>
            @if (currentPage != pageCount) {
                <div class="see-more">
                    <button type="button" class="secondary-btn" (click)="loadMoreVenues()">See more venues
                        <img src="assets/images/arrow-down.svg" width="25" height="24" alt="arrow-down-icon">
                    </button>
                </div>
            }
        </div>
    </section>
}
<!--======================= Venues Section HTML End =======================-->

<!--======================= Venue Articles Section HTML Start =======================-->
@if (venueInspirationList && venueInspirationList.length > 0) {
    <section class="venues-articles-section blogs-list">
        <div class="container">
            <div class="section-header">
                <h2 class="section-semi-title">Need some venue inspiration?</h2>
                <!--<button type="button" class="secondary-btn" [routerLink]=" ['/'+ routesConstants.WEDSPIRATION]">View all
                    venue articles
                    <img src="assets/images/arrow-right.svg" width="21" height="20" alt="arrow-right-icon">
                </button>-->
            </div>
            <div class="card-list">
                @for (inspiration of venueInspirationList; track inspiration; let i = $index) {
                    <div class="card-main">
                        <div class="slider-custom">
                            <a [routerLink]=" ['/'+ routesConstants.BLOG+ '/'+ inspiration.blog_categories[0].slug + '/'+ inspiration.slug]"
                               class="image-box">
                                <img
                                    [src]="inspiration.featureImagePath ? commonConstants.IMAGE_KIT_URL + inspiration.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                    width="256" height="250" [alt]="inspiration?.imageAltText && inspiration.imageAltText.trim() !== ''
                                    ? inspiration.imageAltText
                                    : inspiration?.title"
                                    (error)="setDefaultImage($event)"
                                    appSetImageDimensions loading="lazy">
                            </a>
                        </div>
                        <a [routerLink]=" ['/'+ routesConstants.BLOG+ '/'+ inspiration.blog_categories[0].slug + '/'+ inspiration.slug]"
                           class="card-details">
                            @if (inspiration.blog_categories && inspiration.blog_categories.length > 0) {
                                <span class="card-label">{{ inspiration.blog_categories[0].name }}</span>
                            }
                            <h3 class="card-title-inner">{{ inspiration.title }}</h3>
                            <span class="card-dec" [innerHTML]="inspiration?.excerpt"></span>
                            <!-- Not in current Phase -->
                            <!-- <div class="card-category">
                                <button type="button" class="secondary-btn">Inspiration and advice 💡</button>
                                <button type="button" class="secondary-btn">Budget 💰</button>
                            </div> -->
                        </a>
                    </div>
                }
            </div>
        </div>
    </section>
}
<!--======================= Venue Articles Section HTML End =======================-->

<!--======================= Sign-up and Log-in Section HTML Start =======================-->
<app-venue-vendor-footer></app-venue-vendor-footer>
<!--======================= Sign-up and Log-in Section HTML End =======================-->
