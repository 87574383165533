import {Component, ViewEncapsulation} from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImagePathConstants, RoutesConstants } from "@coreconstant";
import { lastValueFrom } from 'rxjs';
import { FaqService } from 'src/app/core/services/api-service/faq.service';
import { ResponseHandlerService } from 'src/app/core/services/common-service/response-handler.service';
import { SeoService } from '../../core/services/common-service/seo.service';
import { SafeHtmlPipe } from 'src/app/core/pipes/safe-html.pipe';

@Component({
    selector: 'app-faqs',
    imports: [RouterModule, SafeHtmlPipe],
    templateUrl: './faqs.component.html',
    styleUrl: './faqs.component.scss',
    providers: [SafeHtmlPipe],
    encapsulation: ViewEncapsulation.None
})

export class FaqsComponent {
    routesConstants = RoutesConstants;
    imagePathConstants = ImagePathConstants;
    activeCategory: any = ''
    faqCategoriesAndData: any
    faqTypesAndData: any
    isGetResponse: boolean = false
    activeSection: string = '';

    constructor(
        private _faqService: FaqService,
        private _seoService: SeoService,
        private _responseHandlerService: ResponseHandlerService
    ) {
        this.setMetaTags()
    }

    ngOnInit() {
        this.getFaqData()
    }

    setMetaTags() {
        let metaTagObject = {
            title: 'Wedshed FAQs | Find, Book & Plan Your Dream Wedding Venue',
            description: 'Discover how Wedshed makes wedding planning effortless. Our FAQs answer all your venue-related questions to help you say.',
            ogImageUrl: '',
            ogWebUrl: this.routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION,
        }
        this._seoService.setMetaTags(metaTagObject)
    }

    /**
     * Get FAQs
     */
    getFaqData() {
        var getFaq = this._faqService.getTypeAndCategoryBasedFaqs()
        lastValueFrom(getFaq).then((res: any) => {
            if (res["status"]) {
                const faqs = res["data"];
                this.faqCategoriesAndData = this.formatFaq(faqs)
                if (this.faqCategoriesAndData && this.faqCategoriesAndData[0] && this.faqCategoriesAndData[0].name)
                    this.toggleCategory(this.faqCategoriesAndData[0].name)

                this.isGetResponse = true
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Format the FAQ based on the design
     * @param faqs
     * @returns
     */
    formatFaq(faqs) {
        const data = faqs

        const groupedArray = data.map(category => {
            const categoryName = category.name;

            // Group FAQs by type
            const typeMap = category.faqs.reduce((typeAcc, faq) => {
                const typeName = faq.faqTypeInfo.name;
                const typeId = faq.faqTypeInfo.id

                if (!typeAcc[typeId]) {
                    typeAcc[typeId] = {
                        id: typeId,
                        name: typeName,
                        faqs: []
                    };
                }

                typeAcc[typeId].faqs.push(faq);
                return typeAcc;
            }, {});

            const typesArray = Object.values(typeMap);

            return {
                name: categoryName,
                types: typesArray
            };
        });
        return groupedArray
    }

    /**
     * Click on the category name and shows their data
     * @param categoryName
     */
    toggleCategory(categoryName: any) {
        this.activeCategory = categoryName

        // Filter only active category data
        let filterData = this.faqCategoriesAndData.find((item: any) => item.name == categoryName);
        if (filterData) {
            this.faqTypesAndData = filterData.types
        }
    }

    /**
     * Scroll to section when type is clicked
     * @param sectionId
     */
    scrollToSection(sectionId: string) {
        const section = document.getElementById(sectionId);
        const headerOffset = 80;
        if (section) {
            const elementPosition = section.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
        this.activeSection  = sectionId;
    }
}
