<section class="venues-map-wrap">
    <div class="container">
        <div class="back-btn">
            <button type="button" (click)="goBack()">
                <img src="assets/images/arrow-left.svg" width="24" height="24" alt="arrow-left">
                <img src="assets/images/back-circle-icon.svg" width="32" height="32" alt="back-circle-icon"
                    class="mob-back-icon">
                <span>Back</span>
            </button>
            <span class="mob-back-text">Back to venue</span>
        </div>

        <div class="venues-map-head">
            <div class="venues-map-head-title">
                <h1 class="section-title">{{venueDetails?.venueName}}</h1>
                <p>Headed to a wedding at this amazing property? Lucky you.<br /> Use the map below to search for
                    neighbouring accommodation.</p>
            </div>

            <button type="button" class="secondary-btn" (click)="copyMapUrl()"><img src="assets/images/link-icon.svg"
                    width="24" height="24" alt="link-icon"> Copy map link</button>
        </div>

        @if(mapUrl) {
        <div class="venues-map-box">
            <iframe [src]="mapUrl" id="stay22-widget" width="100%" height="974" frameborder="0"></iframe>
        </div>
        }
    </div>
</section>
