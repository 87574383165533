import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ResponseHandlerService } from '../common-service/response-handler.service';
import { catchError, map } from "rxjs/operators";
import {RoutesConstants} from '@coreconstant';

export interface httpConfig {
    url?: any,
    method?: any,
    data?: any,
    filterParameters?: any,
    isRequestModelUsed?: boolean// true/false
    params?: HttpParams,
}

@Injectable({
    providedIn: 'root'
})

export class HttpService {
    backendBaseUrlApis = environment.backendBaseUrlApis;
    routesConstants = RoutesConstants
    constructor(
        private _router: Router,
        private _http: HttpClient,
        private _responseHandlerService: ResponseHandlerService,
    ) {
    }

    /**
     * Summary: This method handle request parameter and call request to manage API call.
     * @param requestParams
     * @returns
     */
    init(requestParams: httpConfig) {
        let headers: HttpHeaders = this.getHeaders(requestParams);
        return this.request(requestParams, headers).pipe(
            map(response => {
                return response;
            }, (error) => {
                return error;
            }),
            catchError((error: any) => {
                // Redirect to login if status is forbidden
                if (error["status"] == 401 || error.error["code"] == 401) {
                    return false
                } else if (error.error["code"] == 403 || error["status"] == 403 || error["status"] == 400 || error["status"] == 422 || error.error["code"] == 422 || error["status"] == 500 || error.error["code"] == 500 || error["status"] == 502 || error.error["code"] == 502 || error["status"] == 503 || error.error["code"] == 503) {
                    this._responseHandlerService.handleAPIErrorResponse(error.error);
                    return false
                } else if (error["status"] == 404 || error.error["code"] == 404) {
                    let requestUrl = error.url
                    if (requestUrl.includes('/venue/')){
                        this._router.navigate(['/' + this.routesConstants.VENUE_NAVIGATION]); // Redirect to global 404 page
                        return false
                    }else if(requestUrl.includes('/vendor/')){
                        this._router.navigate(['/' + this.routesConstants.VENDOR_NAVIGATION]);
                        return false
                    }else if(requestUrl.includes('/blog/')){
                        this._router.navigate(['/' + this.routesConstants.WEDSPIRATION]);
                        return false
                    }else{
                        this._router.navigate(['/404']); // Redirect to global 404 page
                        return false
                    }
                } else {
                    return error
                }
            }));
    }

    /**
     * Summary: This method send requst to backend API to perform operation.
     * @param requestParams
     * @param headers
     * @returns
     */
    request(requestParams: httpConfig, headers: HttpHeaders) {
        let requestUrl = requestParams.url

        if (requestParams.isRequestModelUsed) {
            requestUrl = this.getUrlWithFilterParams(requestParams.url, requestParams.filterParameters)
        }

        return this._http.request(requestParams.method, this.backendBaseUrlApis + requestUrl, {
            body: requestParams.data,
            params: requestParams.params,
            headers: headers
        });
    }

    /**
     * Summary: This method is used to set headers for http request.
     * @param configuration
     * @returns
     */
    private getHeaders(configuration: httpConfig): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        // let token = this._helperService.getLocalStorageData(LocalStorageConstants.LOGIN_USER_ACCESS_TOKEN_ADMIN);
        // if (token)
        //     headers = headers.append("Authorization", 'Bearer ' + token);
        return headers;
    }

    /**
     * THis method will return the URL string with filters
     * @param url
     * @param requestParamModel
     */
    getUrlWithFilterParams(url: string, requestParamModel?: any): string {
        let newURL: string = url;
        if (requestParamModel) {
            newURL += requestParamModel.getParams();
        }
        return newURL;
    }

}
