<div class="single-header">
    <a [routerLink]="['/' + routesConstants.HOME_NAVIGATION]"><img src="{{commonConstants.LOGO_PATH}}" width="124"
            height="52" alt="Logo"></a>
</div>

<div class="single-wrap">
    <div class="single-wrap-inner">
        <div class="oops-text">Oops!</div>
        <div class="single-title" data-content="404">
            404
        </div>
        <div class="single-subtitle">
            We can't find the page you're looking for.
        </div>
        <div class="single-wrap-action">
            <a [routerLink]="['/' + routesConstants.HOME_NAVIGATION]" class="primary-btn">Back to Home</a>
        </div>
    </div>
</div>