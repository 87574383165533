import { Injectable } from '@angular/core';
import { ApiUrlConstants } from '@coreconstant';
import { httpConfig, HttpService } from '../http-service/http.service';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  baseUrlApi = ApiUrlConstants.FAQ_BASE_URL;
  httpConfig: httpConfig;

  constructor(private _httpService: HttpService) { }

  /**
   * Get FAQ Lists
   * @param input 
   * @returns 
   */
  getFaqList(input) {
    this.httpConfig = {
      url: this.baseUrlApi,
      method: 'get',
      filterParameters: input,
      isRequestModelUsed: true,
    }
    return this._httpService.init(this.httpConfig);
  }

  /**
   * Get the Type and category based faqs
   * @returns 
   */
  getTypeAndCategoryBasedFaqs() {
    this.httpConfig = {
      url: this.baseUrlApi + '/type-category-based-faqs',
      method: 'get'
    }
    return this._httpService.init(this.httpConfig);
  }
}
