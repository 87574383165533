import {Component, Input, Output, SimpleChanges, EventEmitter, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {CommonConstants, RoutesConstants} from '@coreconstant';

@Component({
    selector: 'app-search-panel',
    imports: [],
    templateUrl: './search-panel.component.html',
    styleUrl: './search-panel.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class SearchPanelComponent {
    @Input() searchKeyword: string = '';
    @Input() searchData: any;
    @Output() closeSearch = new EventEmitter<any>();
    @Output() clearSearch = new EventEmitter<any>();
    totalResultCount: any;
    venuesCount = 0;
    vendorsCount = 0;
    blogsCount = 0;
    venues: any;
    vendors: any;
    blogs: any;
    currentRoute: string = '';
    routesConstants = RoutesConstants
    commonConstants = CommonConstants

    constructor(private _router: Router) {
        this.currentRoute = this._router.url.split('?')[0];
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = this._router.url.split('?')[0];
            }
        });
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['searchData']) {
            if (this.currentRoute != '/' + this.routesConstants.SEARCH) {
                this.venuesCount = 0
                this.vendorsCount = 0
                this.blogsCount = 0
                this.totalResultCount = 0

                if (this.searchData) {
                    this.venues = this.searchData?.venues?.records ?? []
                    this.vendors = this.searchData?.vendors?.records ?? []
                    this.blogs = this.searchData?.blogs?.records ?? []

                    if (this.searchData?.venues) {
                        this.venues = this.searchData?.venues?.records
                        this.venuesCount = this.searchData?.venues?.totalCount
                    }

                    if (this.searchData?.vendors) {
                        this.vendors = this.searchData?.vendors?.records
                        this.vendorsCount = this.searchData?.vendors?.totalCount
                    }

                    if (this.searchData?.blogs) {
                        this.blogs = this.searchData?.blogs?.records
                        this.blogsCount = this.searchData?.blogs?.totalCount
                    }
                }

                this.totalResultCount = this.venuesCount + this.vendorsCount + this.blogsCount
            }
        }
    }

    closeSearchEvent() {
        this.closeSearch.emit(true);
    }

    clearSearchEvent() {
        this.clearSearch.emit(true)
    }

    goToSearchPage(module: any) {
        this.closeSearchEvent()
        this._router.navigate([this.routesConstants.SEARCH], {queryParams: {type: module, search: this.searchKeyword}});
    }

    goToDetailPage(pageUrl: any, slug: any) {
        this.closeSearchEvent()
        this._router.navigate(['/' + pageUrl + '/' + slug]);
    }


    goToBlogDetailPage(pageUrl: any, categoryslug, slug: any){
        this.closeSearchEvent()
        this._router.navigate(['/' + pageUrl + '/' + categoryslug + '/' + slug]);
    }
}
