import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    PLATFORM_ID,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {SearchPanelComponent} from '../search-panel/search-panel.component';
import {CommonConstants, ImagePathConstants, RoutesConstants} from '@coreconstant';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, NavigationEnd, Router, RouterModule} from '@angular/router';
import {GeneralService} from 'src/app/core/services/api-service/general.service';
import {debounceTime, distinctUntilChanged, filter, fromEvent, lastValueFrom, map, Subject, tap} from 'rxjs';
import {ResponseHandlerService} from 'src/app/core/services/common-service/response-handler.service';
import {CommonBehaviorChangeService} from 'src/app/core/services/common-service/common-behavior-change.service';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {FormsModule} from '@angular/forms';

declare const bootstrap: any;

@Component({
    selector: 'app-header',
    imports: [SearchPanelComponent, RouterModule, CommonModule, FormsModule],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
    isSticky: boolean = false;
    routesConstants = RoutesConstants
    commonConstants = CommonConstants
    imagePathConstants = ImagePathConstants
    businessPortalUrl = environment.businessPortalUrl;
    firstSegment: string;
    settingData: any;
    headerURL: any;
    headerTitle: any;
    isHideSearchSection: boolean = true
    searchKeyword: string = ''
    venues: [];
    vendors: [];
    blogs: [];
    searchData: any;
    searchInput: any;
    currentRoute: string = '';
    @ViewChild('searchIcon') searchIcon!: ElementRef;
    @ViewChild('searchTextbox') searchTextbox!: ElementRef;
    @ViewChild('searchTextboxDesktop') searchTextboxDesktop!: ElementRef;
    @ViewChild('searchContainer') searchContainer!: ElementRef;
    isShowSearchIcon: boolean = false
    isMobileView: boolean = false
    isShowCancelIcon: boolean = false
    private searchSubject = new Subject<string>();
    isLoader: boolean = false
    isSearchOpen = false;

    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        this.isSticky = window.scrollY > 10;
    }

    @HostListener('window:resize', [])
    onWindowResize() {
        if (isPlatformBrowser(this.platformId)) {
            this.isMobileView = window.innerWidth < 1100;
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event) {
        // Check if the clicked element is outside the textbox and search section
        if (
            this.searchIcon &&
            !this.searchIcon.nativeElement.contains(event.target) &&
            this.searchTextbox &&
            !this.searchTextbox.nativeElement.contains(event.target) &&
            this.searchContainer &&
            !this.searchContainer.nativeElement.contains(event.target)
        ) {
            this.isHideSearchSection = true
            this.isSearchOpen = false
        }
    }

    constructor(
        private _router: Router,
        private _generalService: GeneralService,
        private _responseHandlerService: ResponseHandlerService,
        private _commonBehaviorChangeService: CommonBehaviorChangeService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private _changeDetectorRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute

    ) {
        this.onWindowResize()

        this._activatedRoute.queryParams.subscribe(params => {
            this.searchInput = params['search']
            this.searchKeyword = this.searchInput ?? ''
        });
    }

    ngOnInit(): void {
        this.searchSubject.pipe(
            debounceTime(500), // 500ms delay before API call
            distinctUntilChanged() // Prevents duplicate consecutive searches
        ).subscribe((searchText) => {
            if (searchText.length >= 3) {
                this.getSearchResult();
            }
        });

        this.currentRoute = this._router.url.split('?')[0];
        this.getSettings();
        this.handleActiveMenu();
        this._router.events.subscribe((event) => {
            this.handleActiveMenu();

            if (event instanceof NavigationEnd) {
                this.currentRoute = this._router.url.split('?')[0];
                if (this.currentRoute != '/' + this.routesConstants.SEARCH) {
                    this.searchInput = ''
                }
            }

        });
    }

    searchValue(event) {

        if (event.key === 'Backspace' || event.key === 'Delete') {
            this.searchValueChange(event); // Call API immediately on delete
        }
        this.isShowCancelIcon = false
        const value = event.target.value;
        if (value.length >= 3) {
            this.isShowCancelIcon = true
            this.searchValueChange(event)
        }
    }

    ngAfterViewInit() {
        //     // Create an observable for keyup events
        //     if (this.searchTextbox) {
        //         fromEvent(this.searchTextbox.nativeElement, 'keyup')
        //             .pipe(
        //                 map((event: any) => event),
        //                 tap((event: KeyboardEvent) => {
        //                     if (event.key === 'Backspace' || event.key === 'Delete') {
        //                         this.searchValueChange(event); // Call API immediately on delete
        //                     }
        //                     this.isShowCancelIcon = false
        //                     const value = (event.target as HTMLInputElement).value;
        //                     if (value.length >= 3) {
        //                         this.isShowCancelIcon = true
        //                     }
        //                 }),
        //                 filter((event: KeyboardEvent) => {
        //                     const value = (event.target as HTMLInputElement).value;
        //                     return event.key === 'Enter' || value.length >= 3;
        //                 }),
        //                 // debounceTime(3000),
        //                 distinctUntilChanged()
        //             )
        //             .subscribe((event: any) => {
        //                 this.searchValueChange(event)
        //             });
        //     }
    }

    /**
     * Manage the Active parent menu
     */
    handleActiveMenu() {
        const currentUrl = this._router.url;
        this.firstSegment = this.getFirstSegment(currentUrl);
        this.toggleMenu();
    }

    /**
     * Extract the first segment from the URL
     * @param url
     * @returns
     */
    getFirstSegment(url: string): string {
        const segments = url.split('/');
        return segments.length > 1 ? `/${segments[1]}` : '';
    }


    /**
     * Get the social icon urls from setting API
     */
    getSettings() {
        var getSettings = this._generalService.getSystemSettings()
        lastValueFrom(getSettings).then((res: any) => {
            if (res["status"]) {
                this.settingData = res["data"];
                const headerData = this.settingData.find((item: any) => item.section == 'header');
                this.headerURL = headerData?.url;
                this.headerTitle = headerData?.title;
                this._commonBehaviorChangeService.setSystemSettings(this.settingData);
            } else {
                this._responseHandlerService.showErrorMessageGeneral(res["message"])
            }
        }, (error: any) => {
            this._responseHandlerService.handleAPIErrorResponse(error)
        });
    }

    /**
     * Manage the toggle menu mobile view
     */
    toggleMenu(menu?: any) {
        if (isPlatformBrowser(this.platformId)) {
            const navbarMenu = document.getElementById('navbarMenu');
            if (navbarMenu) {
                const bsCollapse = new bootstrap.Collapse(navbarMenu, {
                    toggle: false
                });
                bsCollapse.hide()
            }
        }
    }

    searchBoxClick() {
        if (this.currentRoute != '/' + this.routesConstants.SEARCH)
            this.isHideSearchSection = false
    }

    searchValueChange(event: Event) {
        this.searchKeyword = this.searchInput;

        if (event['key'] == 'Enter') {
            this.closeSearchSection(true)

            let queryParams = {search: this.searchKeyword}
            if (this.currentRoute != '/' + this.routesConstants.SEARCH) {
                queryParams['type'] = this.commonConstants.MODULE_ALL
            }
            this._router.navigate([this.routesConstants.SEARCH], {
                relativeTo: this._activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge'
            });

        } else {
            if (this.currentRoute != '/' + this.routesConstants.SEARCH) {
                // if (this.searchKeyword)
                //   this.getSearchResult()
                if (this.searchKeyword.length >= 3) {
                    this.searchSubject.next(this.searchKeyword);
                }
            }
        }
    }

    getSearchResult() {
        if (this.searchKeyword.length > 0) {
            this.isLoader = true
            var getData = this._generalService.getSearchResult(this.searchKeyword)
            lastValueFrom(getData).then((res: any) => {
                if (res["status"]) {
                    this.searchData = res["data"];
                    this.isLoader = false
                } else {
                    this._responseHandlerService.showErrorMessageGeneral(res["message"] ?? this.commonConstants.SOMETHING_WENT_WRONG)
                }
            }, (error: any) => {
                this.isLoader = false
                this._responseHandlerService.handleAPIErrorResponse(error)
            });
        }
    }

    closeSearchSection(event: any) {
        this.isHideSearchSection = true
        this.isSearchOpen = false
    }

    clearSearchSection(event: any) {
        this.searchKeyword = ''
        this.searchInput = ''
        this.isHideSearchSection = true
    }

    searchToggleMobile() {
        this.isShowSearchIcon = !this.isShowSearchIcon
        this.closeSearchSection(true)
        // this._changeDetectorRef.detectChanges()
        // setTimeout(() => {
        //     this.searchTextbox?.nativeElement.focus(); // Focus after DOM update
        //     this.searchBoxClick()
        // }, 100);
    }

    /**
     * Click on the close icon of the search
     */
    clearSearchOnClose() {
        this.searchKeyword = ''
        this.isShowCancelIcon = false
        this.searchInput = ''
        this.searchData = []
        this.isHideSearchSection = true
        if (this.currentRoute == '/' + this.routesConstants.SEARCH) {
            this._router.navigate([this.routesConstants.SEARCH], {
                relativeTo: this._activatedRoute,
                queryParams: {type: this.commonConstants.MODULE_ALL, search: ''},
                queryParamsHandling: 'merge'
            });
        }
    }

    ngOnDestroy() {
        this.searchInput = ''
        this.searchSubject.unsubscribe();
    }

    manageSearchIcon() {
        this.isSearchOpen = !this.isSearchOpen;
        this._changeDetectorRef.detectChanges()
        setTimeout(() => {
            this.searchTextbox?.nativeElement.focus(); // Focus after DOM update
            this.searchBoxClick()
        }, 100);
    }

    stopDoubleClick(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }
}
