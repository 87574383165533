<footer class="footer-wrap" id="footer-section">
    <div class="container">
        <div class="footer-left-penal">
            <div class="footer-join-box-wrap">
                @if(footerTitle) {
                <p>{{footerTitle}}</p>
                }
                <div class="footer-join-box">
                    <form [formGroup]="joinForm">
                        <input class="form-control" type="text" placeholder="please@ndthankyou.com"
                            formControlName="email">
                        <button class="join-btn" type="button" (click)="onSubmit()"
                            [disabled]="this.joinForm.invalid">Join</button>
                        <!-- @if (submitted) {
                        @if (joinForm.controls['email']?.touched &&
                        joinForm.controls['email']?.hasError('required')) {
                        <div class="error">
                            Email address is required
                        </div>
                        }
                        @else if (joinForm.controls['email']?.touched &&
                        !joinForm.controls['email']?.hasError('required') &&
                        joinForm.controls['email']?.hasError('pattern')) {
                        <div class="error">
                            Email address is not a valid
                        </div>
                        }
                        } -->
                    </form>
                </div>

                <div class="footer-social-links">
                    @if(socialLinks['Facebook']) {
                    <a [href]="socialLinks['Facebook']" target="_blank" title="Facebook" target="_blank"
                        rel="noopener noreferrer nofollow">
                        <img src="assets/images/facebook-icon.svg" width="24" height="25" alt="Facebook icon">
                    </a>
                    }
                    @if(socialLinks['Instagram']) {
                    <a [href]="socialLinks['Instagram']" title="Instagram" target="_blank"
                        rel="noopener noreferrer nofollow">
                        <img src="assets/images/instagram-icon.svg" width="24" height="25" alt="Instagram icon">
                    </a>
                    }
                    @if(socialLinks['Pinterest']) {
                    <a [href]="socialLinks['Pinterest']" title="Pinterest" target="_blank"
                        rel="noopener noreferrer nofollow">
                        <img src="assets/images/pinterest-icon.svg" width="24" height="25" alt="Pinterest icon">
                    </a>
                    }
                    @if(socialLinks['Tiktok']) {
                    <a [href]="socialLinks['Tiktok']" title="TikTok" target="_blank" rel="noopener noreferrer nofollow">
                        <img src="assets/images/tiktok-icon.svg" width="24" height="25" alt="Tiktok icon">
                    </a>
                    }
                </div>
            </div>

            <div class="footer-logo">
                <a [routerLink]="['/'+ routesConstants.HOME_NAVIGATION]">
                    <img src="assets/images/wedshed-icon.svg" width="131" height="73" alt="Wedshed icon">
                </a>
            </div>
        </div>

        <div class="footer-right-panel">
            <div class="footer-right-panel-inner">
                <div class="footer-left-links">
                    <div class="footer-links">
                        <div class="footer-links-title">About</div>
                        <ul>
                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_ABOUT_NAVIGATION]"
                                    title="Our story">Our story</a></li>
                            <!--              <li><a href="" title="Shipping information">Shipping information</a></li>-->
                            <!--              <li><a href="" title="Return policy">Return policy</a></li>-->
                            <!--              <li><a href="" title="T&Cs">T&Cs</a></li>-->
                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION]"
                                    title="Contact us">Contact us</a></li>
                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION]"
                                    title="FAQ">FAQ</a></li>
                            <!--              <li><a href="" title="Privacy policy">Privacy policy</a></li>-->
                        </ul>
                    </div>

                    <div class="footer-links">
                        <div class="footer-links-title">Plan your wedding</div>
                        <ul>
                            <li><a [routerLink]="['/'+ routesConstants.HUB_NAVIGATION]" title="Couples Hub">Couples
                                    Hub</a></li>
                            <li><a href="{{commonConstants.WEDSHED_STORE_LINK}}" rel="noopener noreferrer nofollow" title="Shop" target="_blank">Shop</a>
                            </li>
                            <li><a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION]" title="Venues">Venues</a></li>
                            <li><a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION]" title="Vendors">Vendors</a>
                            </li>
                            <li><a [routerLink]="['/'+ routesConstants.WEDSPIRATION]"
                                    title="Advice & Ideas">Advice & Ideas</a></li>
                            <li><a href="{{commonConstants.PLANNING_REGISTRY_LINK}}" rel="noopener noreferrer nofollow" title="Wedding registry"
                                    target="_blank">Wedding registry</a></li>
                        </ul>
                    </div>

                    <div class="footer-links">
                        <div class="footer-links-title">Industry</div>
                        <ul>
                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_VENDORS_NAVIGATION]"
                                    title="How it works - Vendors">How it works - Vendors</a></li>
                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_VENUES_NAVIGATION]"
                                    title="How it works - Venues">How it works - Venues</a></li>
                            <li>
                                <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION]"
                                    class="primary-btn">Partner with us</a>
                            </li>
                            <li>
                                <a href="{{businessPortalUrl}}" class="secondary-btn" target="_blank" rel="noopener noreferrer nofollow">Business hub
                                    login</a>
                            </li>
                        </ul>
                    </div>

                    <div class="footer-links">
                        <div class="footer-links-title">Advice and support</div>
                        <ul>
                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_FAQS_NAVIGATION]"
                                    title="FAQs">FAQs</a></li>
                            <li><a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_CONTACT_US_NAVIGATION]"
                                    title="Contact us">Contact us</a></li>
                            <li><a [routerLink]="['/'+ routesConstants.SUBMIT_WEDDING]"
                                    title="Submit a Real Wedding">Submit a Real Wedding</a></li>
                        </ul>
                    </div>

                </div>

                <div class="footer-right-links">
                    @for (item of territoryList?.slice(0,5); track $index) {
                    @if(item?.territory_details?.length > 0) {
                    <div class="footer-link-item">
                        <div class="footer-links">
                            <div class="footer-links-title">{{item?.name}}</div>
                            <ul>
                                @for (data of item?.territory_details; track $index) {
                                <li>
                                    <a [routerLink]="['/' + routesConstants.REGION_VENUE_NAVIGATION + '/'+ data?.slug]"
                                        [title]="data?.title">{{data?.title}}</a>
                                </li>
                                }
                            </ul>
                        </div>
                    </div>
                    }
                    }
                </div>
                @if(territoryList?.length > 5){
                <div class="footer-right-links">
                    @for (item of territoryList?.slice(5); track $index) {
                    @if(item?.territory_details?.length > 0) {
                    <div class="footer-link-item">
                        <div class="footer-links">
                            <div class="footer-links-title">{{item?.name}}</div>
                            <ul>
                                @for (data of item?.territory_details; track $index) {
                                <li>
                                    <a [routerLink]="['/' + routesConstants.REGION_VENUE_NAVIGATION + '/'+ data?.slug]"
                                        [title]="data?.title">{{data?.title}}</a>
                                </li>
                                }
                            </ul>
                        </div>
                    </div>
                    }
                    }
                </div>
                }

                <!-- <div class="footer-link-item">
                        <div class="footer-links">
                            <div class="footer-links-title">Browse New South Wales</div>
                            <ul>
                                <li><a href="" title="Sydney wedding venues">Sydney wedding venues</a></li>
                                <li><a href="" title="South Coast wedding venues">South Coast wedding venues</a></li>
                                <li><a href="" title="Hunter Valley wedding venues">Hunter Valley wedding venues</a>
                                </li>
                                <li><a href="" title="Blue Mountains wedding venues">Blue Mountains wedding venues</a>
                                </li>
                                <li><a href="" title="Kangaroo Valley wedding venues">Kangaroo Valley wedding venues</a>
                                </li>
                            </ul>
                        </div>

                        <div class="footer-links">
                            <div class="footer-links-title">Browse Queensland</div>
                            <ul>
                                <li><a href="" title="Queensland wedding venues">Queensland wedding venues</a></li>
                                <li><a href="" title="Brisbane wedding venues">Brisbane wedding venues</a></li>
                                <li><a href="" title="Gold Coast wedding venues">Gold Coast wedding venues</a></li>
                                <li><a href="" title="Port Douglas wedding venues">Port Douglas wedding venues</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="footer-link-item">
                        <div class="footer-links">
                            <div class="footer-links-title">Browse Victoria</div>
                            <ul>
                                <li><a href="" title="Melbourne wedding venues">Melbourne wedding venues</a></li>
                                <li><a href="" title="Yarra Valley wedding venues">Yarra Valley wedding venues</a></li>
                            </ul>
                        </div>
                        <div class="footer-links">
                            <div class="footer-links-title">Browse Australian Capital Territory</div>
                            <ul>
                                <li><a href="" title="ACT wedding venues">ACT wedding venues</a></li>
                                <li><a href="" title="Canberra wedding venues">Canberra wedding venues</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="footer-link-item">
                        <div class="footer-links">
                            <div class="footer-links-title">Browse South Australia</div>
                            <ul>
                                <li><a href="" title="South Australia wedding venues">South Australia wedding venues</a>
                                </li>
                                <li><a href="" title="Adelaide wedding venues">Adelaide wedding venues</a></li>
                                <li><a href="" title="Kangaroo Island wedding venues">Kangaroo Island wedding venues</a>
                                </li>
                            </ul>
                        </div>
                        <div class="footer-links">
                            <div class="footer-links-title">Browse Northern Territory</div>
                            <ul>
                                <li><a href="" title="Northern Territory wedding venues">Northern Territory wedding
                                        venues</a></li>
                                <li><a href="" title="Darwin wedding venues">Darwin wedding venues</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="footer-link-item">
                        <div class="footer-links">
                            <div class="footer-links-title">Browse Western Australia</div>
                            <ul>
                                <li><a href="" title="Perth wedding venues">Perth wedding venues</a></li>
                            </ul>
                        </div>
                        <div class="footer-links">
                            <div class="footer-links-title">Browse International</div>
                            <ul>
                                <li><a href="" title="Bali wedding venues">Bali wedding venues</a></li>
                                <li><a href="" title="New Zealand wedding venues">New Zealand wedding venues</a></li>
                            </ul>
                        </div>
                    </div> -->
            </div>

            <div class="copy-right-info">
                &copy; WedShed {{currentYear}}. All rights reserved. <span><a
                        [routerLink]="['/'+ routesConstants.TERMS_CONDITION]" title="Terms of Service">Terms of
                        Service</a>. <a [routerLink]="['/'+ routesConstants.PRIVACY_POLICY]"
                        title="Privacy Policy">Privacy Policy</a>.</span>
            </div>
        </div>
    </div>
</footer>

<button class="back-to-top primary-btn" (click)="scrollToTop()" *ngIf="showButton" title="Back to Top">
    <img src="assets/images/arrow-top.svg" width="12" height="16" alt="Arrow up icon">
</button>
