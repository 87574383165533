//======================== Featured Venues Section Css Start ========================
.featured-wrap {
    background: #FFE6D5;
    border-bottom: 1px solid #272338;
    padding: 32px 0;

    @media screen and (max-width: 767px) {
        padding: 24px 0;
    }

    .section-semi-title {
        margin: 0 0 18px;

        @media screen and (max-width: 767px) {
            margin: 0 0 10px;
        }
    }

    .card-list {
        @media screen and (max-width: 991px) {
            display: flex;
            align-items: center;
            overflow: auto;

            .card-main {
                min-width: 250px;
            }
        }
    }
}

//======================== Featured Venues Section Css End ========================

//======================== Venue Articles Section Css Start ========================
.vendors-categories {
    padding: 40px 0 32px;

    @media screen and (max-width: 767px) {
        padding: 24px 0;
    }

    .section-header {
        font-family: Aeonik;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #272338;
        gap: 24px 16px;
        margin: 0 0 18px;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        display: flex;

        .section-semi-title {
            font-size: 32px;
            line-height: 38px;
            margin: 0;

            @media screen and (max-width: 767px) {
                font-size: 26px;
                line-height: 31px;
            }
        }

        .secondary-btn {
            gap: 8px;

            @media screen and (min-width: 768px) {
                padding: 9px 16px;
                font-size: 14px;
                line-height: 20px;
            }

            @media screen and (max-width: 480px) {
                width: 100%;
            }

            img {
                position: relative;
                right: 0;
                transition: all 0.35s ease 0s;
            }

            &:hover {
                img {
                    right: -4px;
                }
            }
        }

        p {
            margin: 4px 0 0;

            @media screen and (max-width: 767px) {
                margin-top: 8px;
            }
        }

        strong {
            font-weight: 500;
        }
    }
}

//======================== Venue Articles Section Css End ========================