@if (firstSegment === '/' + routesConstants.WEDSPIRATION) {
    <section class="wedspiration-banner-section">
        @if (featuredArticle != null) {
            <div class="container">
                <div class="banner-section-wrap">
                    <div class="left-side">
                        <img
                            [src]="featuredArticle.featureImagePath ? commonConstants.IMAGE_KIT_URL + featuredArticle.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                            width="665" height="360" alt="Wedding venue banner image" (error)="setDefaultImage($event)"
                            appSetImageDimensions>
                    </div>
                    <div class="right-side">
                        <span class="sub-title">FEATURED ARTICLE</span>
                        <h1 class="section-title">{{ featuredArticle.title }}</h1>
                        <div class="section-content" [innerHTML]="featuredArticle?.excerpt"></div>
                        <a type="button" class="secondary-btn"
                           [routerLink]="['/'+ routesConstants.BLOG +'/'+ featuredArticle.blog_categories[0].slug+ '/'+ featuredArticle.slug]">Read
                            more <img
                                src="assets/images/arrow-right.svg" width="21" height="20" alt="Right arrow icon">
                        </a>
                    </div>
                </div>
            </div>
        }
    </section>
}


<!--======================== Trending Html Start ========================-->
@if (firstSegment === '/' + routesConstants.WEDSPIRATION) {
    <section class="trending-section">
        <div class="container">
            <div class="trending-section-inner">
                <div class="left-side">
                    <h2 class="section-title">Trending ✨</h2>
                    <p class="section-info">We’ve brought together advice, articles and inspiration to help you find
                        your feet.</p>
                </div>
                <div class="right-side">
                    <div class="trending-list">
                        @if (trendingBlogs && trendingBlogs.length > 0) {
                            @for (trendingBlog of trendingBlogs; track trendingBlog; let i = $index) {
                                <a class="trending-link"
                                   [routerLink]="['/'+ routesConstants.BLOG +'/'+ trendingBlog.blog_categories[0].slug + '/'+ trendingBlog?.slug]">
                                    <span class="trending-image"><img
                                        [src]="trendingBlog.featureImagePath ? commonConstants.IMAGE_KIT_URL + trendingBlog.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                        width="60" height="60"
                                        [alt]="trendingBlog.imageAltText && trendingBlog.imageAltText.trim() !== '' ? trendingBlog.imageAltText : trendingBlog?.title"
                                        loading="lazy" (error)="setDefaultImage($event)" appSetImageDimensions></span>
                                    <span class="trending-content">
                                        @if (trendingBlog.blog_categories && trendingBlog.blog_categories.length > 0) {
                                            <span class="label">{{ trendingBlog.blog_categories[0].name }}</span>
                                        }
                                        <span class="trending-title">{{ trendingBlog.title }}</span>
                                    </span>
                                </a>
                            }
                        }
                    </div>
                </div>
            </div>
        </div>
    </section>
}
<!--======================== Trending Html End ========================-->

<!--======================== Back Html Start ========================-->
@if (firstSegment !== '/' + routesConstants.WEDSPIRATION) {
    <section class="category-list-banner">
        <div class="container">
            <div class="back-btn">
                <a [routerLink]="['/'+ routesConstants.WEDSPIRATION]" (click)="blogCategoriesStyleChange('')">
                    <img src="assets/images/arrow-left.svg" width="24" height="24" alt="Arrow left">
                    <span>Back</span>
                </a>
            </div>
            <h1 class="section-title">{{ selectedBlogCategoriesName }}</h1>
        </div>
    </section>
}
<!--======================== Back Html End ========================-->

@if (blogCategories && blogCategories?.length > 0) {
    <div class="blog-listing-wrap">
        <!--======================== Filter Section Start ========================-->
        <section class="blog-filter">
            <div class="container">
                <div class="blog-filter-tabs">
                    <button type="button" class="btn" [class.active]="selectedBlogCategoriesName == ''"
                            (click)="blogCategoriesStyleChange('')">
                        <img src="{{commonConstants.ALL_STYLE_ICON}}" width="24" height="24" alt="All icon" title="All"
                             loading="lazy">
                        All
                    </button>
                    @for (categories of blogCategories; track categories; let i = $index) {
                        <button type="button" class="btn" [class.active]="selectedBlogCategoriesName == categories.name"
                                (click)="blogCategoriesStyleChange(categories.slug, categories.name)">
                            <img
                                [src]="categories.imagePath ? commonConstants.IMAGE_KIT_URL + categories.imagePath + '?tr=w-24,h-24,q-100' : commonConstants.ALL_STYLE_ICON"
                                width="24" height="24" alt="{{categories.name}} Icon" title="{{categories.name}}"
                                loading="lazy"
                                (error)="setDefaultImage($event)">
                            {{ categories.name }}
                        </button>
                    }
                </div>

                <div class="blog-filter-action">
                    <div class="blog-filter-search">
                        <form role="search">
                            <button class="search-btn" type="button">
                                <img src="assets/images/search-icon.svg" width="24" height="25" alt="Search icon">
                            </button>
                            <input class="form-control" type="text" enterkeyhint="search"
                                   placeholder="Search articles here"
                                   aria-label="Search" (keydown.enter)="searchBlogs()" (keyup)="searchKeywords($event)"
                                   [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}">
                            @if (showCloseSearchIcon) {
                                <button class="search-close-btn" type="button" (click)="clearAll()">
                                    <img src="assets/images/close-icon.svg" width="13" height="13" alt="close-icon 11">
                                </button>
                            }
                        </form>
                    </div>
                    <!-- Not in current Phase -->
                    <!-- <div class="dropdown">
                        <button class="secondary-btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <strong>Sort by:</strong> Newest
                            <img src="assets/images/chevron-down-icon.svg" width="12" height="8" alt="chevron-down-icon">
                        </button>
                        <ul class="dropdown-menu">
                            <li><button type="button">Newest <small>(default)</small></button></li>
                            <li><button type="button">Oldest</button></li>
                        </ul>
                    </div> -->
                    @if (isShowClear) {
                        <button type="button" class="secondary-btn clear-all" (click)="clearAll()">Clear all</button>
                    }
                </div>
            </div>
        </section>
        <!--======================== Filter Section End ========================-->

        <!--======================= No Result Found HTML Start =======================-->
        @if (isGetResponse) {
            @if (totalCount == 0 && !isFilterApply) {
                <section class="search-results-not-found">
                    <div class="container">
                        <p class="emoji-icon">😞</p>
                        <p class="search-title">Uh oh</p>
                        <p>There were no results for <strong>your search</strong></p>
                    </div>
                </section>
            }
        }
        <!--======================= No Result Found HTML End =======================-->

        <!--======================= blog card Section HTML Start =======================-->
        @if (blogList && blogList.length > 0) {
            <section class="blog-card-section blogs-list" id="blog-listing-wrap">
                <div class="container">
                    <div class="card-list">
                        @for (blogs of blogList.slice(0, commonConstants.SHOW_FIRST_BLOG_COUNT); track blogs; let i = $index) {
                            <div class="card-main">
                                <div class="slider-custom">
                                    <a [routerLink]="['/'+ routesConstants.BLOG +'/'+ blogs.blog_categories[0].slug + '/'+ blogs?.slug]"
                                       class="image-box">
                                        <img
                                            [src]="blogs.featureImagePath ? commonConstants.IMAGE_KIT_URL + blogs.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                            width="256" height="250"
                                            [alt]="blogs.imageAltText && blogs.imageAltText.trim() !== '' ? blogs.imageAltText : blogs?.title"
                                            (error)="setDefaultImage($event)"
                                            appSetImageDimensions>
                                    </a>
                                </div>
                                <a [routerLink]="['/'+ routesConstants.BLOG +'/'+ blogs.blog_categories[0].slug + '/'+ blogs?.slug]"
                                   class="card-details">
                                    @for (categories of blogs.blog_categories; track categories; let j = $index) {
                                        @if (j == 0) {
                                            <span class="card-label">{{ categories.name }}</span>
                                        }
                                    }
                                    <h2 class="card-title-inner">{{ blogs.title }}</h2>
                                    <span class="card-dec" [innerHTML]="blogs?.excerpt"></span>
                                    <!-- Not in current Phase -->
                                    <!-- <div class="card-category">
                                            <button type="button" class="secondary-btn">Inspiration and advice 💡</button>
                                            <button type="button" class="secondary-btn">Budget 💰</button>
                                        </div> -->
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </section>
        }
        <!--======================= blog card Section HTML End =======================-->

        <!--======================= Featured Blogs Section HTML Start =======================-->
        @if (firstSegment === '/' + routesConstants.WEDSPIRATION && totalCount > 0) {
            @if (featureBlogList && featureBlogList.length > 0) {
                <section class="wedspiration-featured-section blogs-list">
                    <div class="container">
                        <div class="section-header">
                            <img src="assets/images/colorful-heart-icon.svg" width="48" height="48" alt="Heart icon">
                            <div class="">
                                <span class="label">YOU JUST MIGHT LIKE</span>
                                <h2 class="section-semi-title">The wedding dress edit</h2>
                            </div>
                        </div>
                        <div id="parent-slider" class="splide">
                            <div class="splide__track">
                                <ul class="splide__list">
                                    @for (featuredBlogs of featureBlogList; track featuredBlogs; let i = $index) {
                                        <li class="splide__slide">
                                            <div class="card-main">
                                                <div class="slider-custom">
                                                    <a [routerLink]="['/'+ routesConstants.BLOG+'/'+ featuredBlogs?.blog_categories?.[0].slug + '/'+ featuredBlogs?.slug]"
                                                       class="image-box">
                                                        <img
                                                            [src]="featuredBlogs.featureImagePath ? commonConstants.IMAGE_KIT_URL + featuredBlogs.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                            width="256" height="250"
                                                            [alt]="featuredBlogs.imageAltText && featuredBlogs.imageAltText.trim() !== '' ? featuredBlogs.imageAltText : featuredBlogs?.title"
                                                            (error)="setDefaultImage($event)"
                                                            appSetImageDimensions loading="lazy">
                                                    </a>
                                                </div>
                                                <a [routerLink]="['/'+ routesConstants.BLOG+'/'+ featuredBlogs?.blog_categories?.[0].slug + '/'+ featuredBlogs?.slug]"
                                                   class="card-details">
                                                    @for (categories of featuredBlogs.blog_categories; track categories; let j = $index) {
                                                        @if (j == 0) {
                                                            <span class="card-label">{{ categories.name }}</span>
                                                        }
                                                    }
                                                    <h3 class="card-title-inner">{{ featuredBlogs.title }}</h3>
                                                    <span class="card-dec" [innerHTML]="featuredBlogs?.excerpt"></span>
                                                    <!-- Not in current Phase -->
                                                    <!-- <div class="card-category">
                                                        <button type="button" class="secondary-btn">Inspiration and advice 💡</button>
                                                        <button type="button" class="secondary-btn">Budget 💰</button>
                                                    </div> -->
                                                </a>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            }
        }
        <!--======================= Featured Blogs Section HTML End =======================-->

        <!--======================= blog card Section HTML Start =======================-->
        @if (blogList && blogList.length > commonConstants.SHOW_FIRST_BLOG_COUNT) {
            <section class="blog-card-section blogs-list">
                <div class="container">
                    <div class="card-list">
                        @for (blogs of blogList.slice(commonConstants.SHOW_FIRST_BLOG_COUNT); track blogs; let i = $index) {
                            @if (blogList && blogList.length > commonConstants.SHOW_FIRST_BLOG_COUNT) {
                                <div class="card-main">
                                    <div class="slider-custom">
                                        <a [routerLink]="['/'+ routesConstants.BLOG+'/'+ blogs.blog_categories[0].slug + '/'+ blogs?.slug]"
                                           class="image-box">
                                            <img
                                                [src]="blogs.featureImagePath ? commonConstants.IMAGE_KIT_URL + blogs.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                width="256" height="250"
                                                [alt]="blogs.imageAltText && blogs.imageAltText.trim() !== '' ? blogs.imageAltText : blogs?.title"
                                                (error)="setDefaultImage($event)"
                                                appSetImageDimensions loading="lazy">
                                        </a>
                                    </div>
                                    <a [routerLink]="['/'+ routesConstants.BLOG+'/'+ blogs.blog_categories[0].slug + '/'+ blogs?.slug]"
                                       class="card-details">
                                        @for (categories of blogs.blog_categories; track categories; let j = $index) {
                                            @if (j == 0) {
                                                <span class="card-label">{{ categories.name }}</span>
                                            }
                                        }
                                        <h2 class="card-title-inner">{{ blogs.title }}</h2>
                                        <span class="card-dec" [innerHTML]="blogs?.excerpt"></span>
                                        <!-- Not in current Phase -->
                                        <!-- <div class="card-category">
                                                <button type="button" class="secondary-btn">Inspiration and advice 💡</button>
                                                <button type="button" class="secondary-btn">Budget 💰</button>
                                            </div> -->
                                    </a>
                                </div>
                            }
                        }
                    </div>
                    @if (currentPage != pageCount && blogList && blogList.length > 0) {
                        <div class="see-more">
                            <button type="button" class="secondary-btn" (click)="loadMoreBlogs()">See more articles
                                <img src="assets/images/arrow-down.svg" width="25" height="24" alt="Arrow down icon">
                            </button>
                        </div>
                    }
                </div>
            </section>
        }
        <!--======================= blog card Section HTML End =======================-->
    </div>
}

<!--======================= Enquire CTA Section HTML Start =======================-->
<!-- <section class="enquire-cta">
    <div class="container">
        <div class="enquire-cta-info">
            <h2 class="section-semi-title">✨ Save articles, budget, and plan well when you sign up</h2>
            <div class="enquire-cta-action">
                <a [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" class="primary-btn">Sign up</a>
                <a href="{{businessPortalUrl}}" class="secondary-btn" target="_blank" rel="noopener noreferrer nofollow">Log in</a>
            </div>
        </div>
    </div>
</section> -->
<!--======================= Enquire CTA Section HTML END =======================-->
