import { Injectable } from '@angular/core';
import { ApiUrlConstants } from '../../constants/api-url-constants';
import { httpConfig, HttpService } from '../http-service/http.service';

@Injectable({
    providedIn: 'root'
})
export class BlogService {

    baseUrlApi = ApiUrlConstants.BLOG_BASE_URL;
    httpConfig: httpConfig;

    constructor(
        private _httpService: HttpService
    ) {
    }

    /**
     * Get Trending Blogs
     * @param input
     * @returns
     */
    getTrendingBlogs() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-trending-blog',
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     *
     */
    getFeaturedBlog() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-featured-blog',
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get Trending Blogs
     * @param input
     * @returns
     */
    getInspirationBlogs() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-inspiration-blog',
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get Blogs List
     * @param input
     * @returns
     */
    getBlogsList(input) {
        this.httpConfig = {
            url: this.baseUrlApi,
            method: 'get',
            filterParameters: input,
            isRequestModelUsed: true,
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get feature Blogs List
     * @param input
     * @returns
     */
    getFeatureBlogsList() {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-feature-blog-list',
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get single blog details
     * @param slug
     * @returns
     */
    getBlogDetails(slug) {
        this.httpConfig = {
            url: this.baseUrlApi + '/' + slug,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get same category Blogs
     * @param categoryId
     * @param blogId
     * @returns
     */
    getSameCategoryBlogs(categoryId, blogId) {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-category-blog/' + categoryId + '/' + blogId,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get Relevant Blogs
     * @returns
     */
    getRelevantBlogs(blogId) {
        this.httpConfig = {
            url: this.baseUrlApi + '/get-relevant-blog/' + blogId,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }
}
