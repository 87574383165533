//Banner section//
.vendor-banner-wrap {
    background: #272338;
    border-bottom: 1px solid #272338;
    overflow: hidden;

    .container {
        gap: 83px;
        align-items: center;
        justify-content: space-between;
        padding-left: 80px;
        padding-right: 0;
        display: flex;
        min-height: 496px;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            min-height: 450px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            gap: 60px;
        }

        @media screen and (max-width: 1200px) {
            min-height: 380px;
        }
        @media screen and (max-width: 1100px) {
            gap: 24px;
        }
        @media screen and (max-width: 991px) {
            gap: 0;
            min-height: 330px;
        }

        @media screen and (max-width: 767px) {
            padding-left: 0;
            flex-direction: column;
            align-items: flex-start;
            min-height: initial;
        }
    }

    p {
        margin: 0;
    }

    .left-content {
        max-width: 495px;
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #E2E0EB;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 991px) {
            padding: 32px 0;
            max-width: 44.5%;
        }
        @media screen and (max-width: 767px) {
            padding: 32px 16px 0;
            max-width: 100%;
        }
    }

    h1 {
        font-family: Livorno, serif;
        font-size: 60px;
        line-height: 60px;
        font-weight: 400;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin: 0 0 16px;

        span {
            display: block;
            font-family: Livorno, serif;
        }

        @media screen and (max-width: 1280px) {
            font-size: 50px;
            line-height: 54px;
        }

        @media screen and (max-width: 991px) {
            font-size: 40px;
            line-height: 48px;
            margin: 0 0 8px;
        }
    }

    .primary-btn {
        margin: 24px 0 0;
        border-color: transparent;

        @media screen and (min-width: 991px) {
            margin: 32px 0 0;
            font-size: 14px;
            padding: 9px 16px;
        }

        &:hover {
            border-color: #FFFFFF;
        }
    }

    .banner-right-img {
        width: 784px;
        max-width: 100%;
        margin: -64px 0;

        @media screen and (max-width: 991px) {
            width: 100%;
        }

        @media screen and (max-width: 767px) {
            margin: -70px 0 0;
        }

        @media screen and (max-width: 480px) {
            position: relative;
            padding-bottom: 108.91%;
        }

        img {
            width: 100%;
            height: auto;
            @media screen and (max-width: 480px) {
                height: 100%;
                object-fit: cover;
                object-position: top center;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                //height: 100%;
                //object-fit: cover;
                //object-position: top center;

                //width: calc(100% + 164px);
                //max-width: initial;
                //max-height: initial;
                //margin: 0 -90px -53px;
            }
        }
    }
}

//How it works//
.how-it-works-wrap {
    overflow: clip;
    word-break: break-word;

    .container {
        padding-left: 80px;
        padding-right: 80px;
        display: flex;
        gap: 86px;

        @media screen and (max-width: 1360px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 16px;
            padding-right: 16px;
            gap: 48px;
        }

        @media screen and (max-width: 1100px) {
            gap: 32px;
        }
    }

    .left-sticky-panel {
        padding: 52px 0;
        width: 307px;
        min-width: 307px;

        @media screen and (max-width: 100px) {
            width: 270px;
            min-width: 270px;
        }

        @media screen and (max-width: 991px) {
            display: none;
        }

        .left-sticky-menu {
            background: #FFF5EE;
            border: 1px solid #272338;
            border-radius: 16px;
            padding: 24px;
            position: sticky;
            top: 96px;

            ul {
                margin: 0 0 24px;
                padding: 0 0 24px;
                position: relative;
                font-family: Aeonik, sans-serif;
                font-size: 18px;
                line-height: 25px;
                font-weight: 400;
                color: #808080;

                &:before {
                    content: '';
                    position: absolute;
                    right: -24px;
                    bottom: 0;
                    left: -24px;
                    border-bottom: 1px solid #272338;
                    pointer-events: none;
                }

                li {
                    margin: 0 0 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a, span {
                        display: inline-block;
                        vertical-align: top;
                        color: #808080;
                        transition: all 0.25s ease 0s;
                        cursor: pointer;

                        &:hover {
                            color: #7093FF;
                        }
                    }

                    &.active {
                        a, span {
                            color: #272338;
                            font-weight: 500;
                        }
                    }
                }
            }

            .primary-btn {
                width: 100%;
                font-size: 14px;
                padding: 9px 16px;
            }
        }
    }

    .how-it-works-step {
        padding: 52px 0;

        @media screen and (max-width: 991px) {
            padding: 40px 0;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 0;
        }

        .section-title {
            margin: 0 0 24px;
            @media screen and (max-width: 767px) {
                margin: 0 0 16px;
                text-align: center;
            }
        }

        .how-it-works-list {
            display: grid;
            gap: 41px 60px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-auto-flow: row;
            @media screen and (max-width: 1100px) {
                gap: 32px;
            }
            @media screen and (max-width: 767px) {
                gap: 32px 24px;
            }
            @media screen and (max-width: 480px) {
                display: block;
            }

            .how-it-works-item {
                grid-row: span 2 / span 2;
                font-family: Aeonik, sans-serif;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                color: #4D4D4D;

                &:first-child {
                    grid-row-end: 3;
                }

                &:nth-child(2) {
                    grid-row-start: 2;
                }

                @media screen and (max-width: 480px) {
                    margin-bottom: 32px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .how-it-works-img {
                    border-radius: 16px;
                    overflow: hidden;
                    //width: 79.02%;
                    width: 320px;
                    min-width: 320px;
                    margin: 0 0 16px 94px;
                    padding-bottom: 52.115%;
                    position: relative;

                    @media screen and (max-width: 1280px) {
                        padding-bottom: 53.7%;
                    }

                    @media screen and (max-width: 1200px) {
                        padding-bottom: 59.7%;
                        width: 100%;
                        min-width: 100%;
                        margin: 0 0 8px 0;
                    }

                    @media screen and (max-width: 1100px) {
                        padding-bottom: 66.15%;
                    }

                    @media screen and (max-width: 991px) {
                        margin-bottom: 12px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        vertical-align: middle;
                        border: 1px solid #272338;
                        border-radius: 16px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                }

                h3 {
                    font-family: Livorno, serif;
                    font-size: 26px;
                    line-height: 31px;
                    font-weight: 400;
                    letter-spacing: -0.01em;
                    color: #272338;
                    margin: 0 0 8px;
                    position: relative;

                    a {
                        color: #272338;

                        &:hover {
                            color: #7093FF;
                        }
                    }

                    span {
                        font-family: Livorno, serif;
                        font-weight: 400;
                        font-size: 100px;
                        line-height: 94px;
                        color: #EFB7A6;
                        position: absolute;
                        left: 0;
                        bottom: calc(100% + 12px);
                        display: inline-block;
                        @media screen and (max-width: 1200px) {
                            font-size: 80px;
                            line-height: 80px;
                            width: 100%;
                            display: block;
                            position: initial;
                            left: auto;
                            bottom: auto;
                            margin: 0 0 4px;
                        }

                        @media screen and (max-width: 991px) {
                            font-size: 70px;
                            line-height: 70px;
                        }
                    }
                }

                p {
                    margin: 0;
                }

                .link-btn {
                    font-weight: 500;
                    display: inline-flex;
                    gap: 8px;
                    align-items: center;
                    color: #7093FF;
                    margin: 8px 0 0;

                    img {
                        position: relative;
                        right: 0;
                        transition: all 0.25s ease 0s;
                    }

                    &:hover {
                        color: #272338;

                        img {
                            right: -3px;
                            filter: brightness(0);
                        }
                    }
                }
            }
        }
    }

    .what-sets-wrap {
        padding: 52px 0;
        position: relative;
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #4D4D4D;

        @media screen and (max-width: 991px) {
            padding: 40px 0;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: -100vw;
            bottom: 0;
            left: -100vw;
            background: #FFE6D5;
            border-top: 1px solid #272338;
            //border-bottom: 1px solid #272338;
            z-index: -1;
            pointer-events: none;
        }


        .section-title {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
        }

        ul {
            margin: 32px 0 0;
            @media screen and (max-width: 991px) {
                margin-top: 24px;
            }

            li {
                margin: 0 0 24px;
                position: relative;
                padding-left: 88px;
                min-height: 72px;
                gap: 2px;
                flex-direction: column;
                justify-content: center;
                display: flex;

                @media screen and (max-width: 480px) {
                    padding-left: 0;
                    min-height: initial;

                    &:first-child {
                        .offer-list-icon {
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                h3 {
                    font-family: Aeonik, sans-serif;
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 500;
                    color: #272338;
                    margin: 0;
                }

                .offer-list-icon {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #272338;
                    border-radius: 72px;
                    width: 72px;
                    height: 72px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @media screen and (max-width: 480px) {
                        width: 60px;
                        height: 60px;
                        position: relative;
                        top: auto;
                        left: auto;
                        margin: 0 0 6px;
                        img {
                            max-width: 44px;
                            max-height: 44px;
                        }
                    }
                }

                &:nth-child(6n + 1) {
                    .offer-list-icon {
                        background: #7093FF;
                    }
                }

                &:nth-child(6n + 2) {
                    .offer-list-icon {
                        background: #94B3A5;
                    }
                }

                &:nth-child(6n + 3) {
                    .offer-list-icon {
                        background: #F8D066;
                    }
                }

                &:nth-child(6n + 4) {
                    .offer-list-icon {
                        background: #EFB7A6;
                    }
                }

                &:nth-child(6n + 5) {
                    .offer-list-icon {
                        background: #A8AED6;
                    }
                }

                &:nth-child(6n + 6) {
                    .offer-list-icon {
                        background: #E2763A;
                    }
                }
            }
        }
    }

    .our-statistics-wrap {
        font-family: Aeonik, sans-serif;
        font-size: 17px;
        line-height: 21px;
        font-weight: 400;
        color: rgba(226, 224, 235, 0.8);
        padding: 52px 0;
        position: relative;

        @media screen and (max-width: 991px) {
            margin: 0 -16px;
            padding: 40px 16px;
            overflow: hidden;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 16px 90px;
            font-size: 16px;
            line-height: 22px;
        }

        @media screen and (max-width: 360px) {
            font-size: 14px;
            line-height: 20px;
        }

        @media screen and (max-width: 359px) {
            font-size: 13px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: -100vw;
            bottom: 0;
            left: -100vw;
            background: #272338;
            z-index: -1;
            pointer-events: none;
            border-top: 1px solid #7C89E2;
            @media screen and (max-width: 767px) {
                border-top: none;
                border-bottom: 1px solid #16141F;
            }
        }

        .section-title {
            margin: 0 0 48px;
            color: #fff;

            @media screen and (max-width: 991px) {
                margin: 0 0 32px;
            }

            @media screen and (max-width: 767px) {
                margin: 0 0 24px;
            }
        }

        p {
            margin: 0;
        }

        ul {
            max-width: 800px;
            flex-wrap: wrap;
            align-items: flex-start;
            display: flex;

            @media screen and (max-width: 1199px) {
                max-width: 750px;
            }

            @media screen and (max-width: 991px) {
                justify-content: space-between;
            }

            @media screen and (max-width: 767px) {
                max-width: 100%;
                gap: 30px 8px;
                justify-content: initial;
            }

            h3 {
                font-family: Livorno, serif;
                font-size: 44px;
                line-height: 55px;
                font-weight: 400;
                color: #fff;
                margin: 0 0 8px;

                @media screen and (max-width: 767px) {
                    margin: 0 0 4px;
                }
            }

            li {
                position: relative;
                white-space: nowrap;

                @media screen and (max-width: 767px) {
                    margin: 0 !important;
                    width: calc(50% - 4px);
                    &:nth-child(2n) {
                        text-align: right;
                        position: relative;
                        top: 55px;
                    }
                }

                .star-icon {
                    position: absolute;
                    pointer-events: none;
                }

                &:nth-child(1) {
                    margin: 16px 0 0 0;

                    h3 {
                        font-size: 80px;
                        line-height: 68px;
                        color: #A8AED6;

                        @media screen and (max-width: 1199px) {
                            font-size: 70px;
                            line-height: 58px;
                        }

                        @media screen and (max-width: 767px) {
                            font-size: 55px;
                            line-height: 55px;
                        }

                        @media screen and (max-width: 375px) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                    }

                    .star-icon {
                        width: 24px;
                        height: 24px;
                        top: calc(100% + 23px);
                        right: -3px;
                        @media screen and (max-width: 767px) {
                            display: none;
                        }
                    }
                }

                &:nth-child(2) {
                    margin: 58px 63px 52px 96px;

                    @media screen and (max-width: 1199px) {
                        margin: 58px 50px 52px 80px;
                    }

                    @media screen and (max-width: 1100px) {
                        margin: 48px 36px 42px 60px;
                    }

                    h3 {
                        font-size: 55px;
                        color: #7093FF;
                        margin-bottom: 4px;
                        @media screen and (max-width: 1199px) {
                            font-size: 50px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 55px;
                            line-height: 55px;
                        }
                        @media screen and (max-width: 375px) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                    }

                    .star-icon {
                        right: -17px;
                        bottom: calc(100% + 46px);
                        @media screen and (max-width: 767px) {
                            right: auto;
                            bottom: calc(100% + 65px);
                            left: 77px;
                        }
                    }
                }

                &:nth-child(3) {
                    margin: 38px 0 0 0;

                    h3 {
                        font-size: 65px;
                        color: #E2763A;
                        @media screen and (max-width: 1199px) {
                            font-size: 50px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 55px;
                            line-height: 55px;
                        }
                        @media screen and (max-width: 375px) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                    }

                    .star-icon {
                        width: 20px;
                        height: 20px;
                        top: calc(100% + 15px);
                        left: calc(100% + 66px);
                        @media screen and (max-width: 767px) {
                            width: 26px;
                            height: 26px;
                            top: auto;
                            left: auto;
                            bottom: 32px;
                            right: -24px;
                        }
                        @media screen and (max-width: 375px) {
                            right: 0;
                        }
                    }
                }

                &:nth-child(4) {
                    margin: 0 0 0 20px;

                    h3 {
                        font-size: 48px;
                        color: #EFB7A6;
                        margin-bottom: 4px;
                        @media screen and (max-width: 1199px) {
                            font-size: 45px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 55px;
                            line-height: 55px;
                        }
                        @media screen and (max-width: 375px) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                    }

                    .star-icon {
                        width: 24px;
                        height: 24px;
                        top: calc(100% + 61px);
                        left: 46px;
                        @media screen and (max-width: 767px) {
                            top: calc(100% + 59px);
                            left: 10px;
                        }
                    }
                }

                &:nth-child(5) {
                    margin: 12px 140px 47px 99px;
                    @media screen and (max-width: 1199px) {
                        margin: 12px 100px 47px 59px;
                    }
                    @media screen and (max-width: 1100px) {
                        margin: 12px 60px 37px 48px;
                    }

                    h3 {
                        font-size: 90px;
                        line-height: 76px;
                        color: #94B3A5;
                        @media screen and (max-width: 1199px) {
                            font-size: 80px;
                            line-height: 66px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 55px;
                            line-height: 55px;
                        }
                        @media screen and (max-width: 375px) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                    }

                    .star-icon {
                        width: 26px;
                        height: 26px;
                        bottom: 100%;
                        left: calc(100% + 48px);
                        @media screen and (max-width: 767px) {
                            display: none;
                        }
                    }
                }

                &:nth-child(6) {
                    margin: 12px 0 0 0;

                    h3 {
                        color: #A8AED6;
                        margin-bottom: 4px;
                        @media screen and (max-width: 1199px) {
                            font-size: 55px;
                            line-height: 55px;
                        }
                        @media screen and (max-width: 375px) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                    }
                }

                &:nth-child(7) {
                    margin: 0 224px 0 106px;

                    @media screen and (max-width: 1199px) {
                        margin: 0 180px 0 106px;
                    }

                    h3 {
                        font-size: 59px;
                        color: #E2763A;
                        @media screen and (max-width: 1199px) {
                            font-size: 55px;
                            line-height: 55px;
                        }
                        @media screen and (max-width: 375px) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                    }

                    .star-icon {
                        width: 26px;
                        height: 26px;
                        bottom: 14px;
                        left: calc(100% + 100px);
                        @media screen and (max-width: 1199px) {
                            left: calc(100% + 70px);
                        }
                        @media screen and (max-width: 767px) {
                            width: 28px;
                            height: 28px;
                            top: calc(100% + 2px);
                            bottom: auto;
                            left: -27px;
                        }
                    }
                }

                &:nth-child(8) {
                    margin: 7px 0 0 0;

                    h3 {
                        font-size: 80px;
                        line-height: 68px;
                        color: #7093FF;
                        @media screen and (max-width: 1199px) {
                            font-size: 70px;
                            line-height: 58px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 55px;
                            line-height: 55px;
                        }
                        @media screen and (max-width: 375px) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                    }

                    .star-icon {
                        width: 28px;
                        height: 28px;
                        bottom: 16px;
                        left: calc(100% + 70px);

                        @media screen and (max-width: 767px) {
                            width: 36px;
                            height: 36px;
                            bottom: 7px;
                            left: -62px;
                        }
                    }
                }
            }
        }
    }

    .success-stories {
        padding: 52px 0;

        @media screen and (max-width: 991px) {
            padding: 40px 0;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 0;
        }

        .section-title {
            margin-bottom: 24px;
            @media screen and (max-width: 767px) {
                margin-bottom: 16px;
            }
        }

        p {
            margin-bottom: 0;
        }

        .success-stories-date {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #272338;
            margin: 10px 0 0;

            a {
                font-weight: 500;
            }
        }

        a {
            color: #7093FF;

            &:hover {
                color: #272338;
            }
        }

        .success-stories-item {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: #423B5E;
            background: #FFF5EE;
            padding: 16px;
            margin: 0 0 16px;
            border: 1px solid #272338;
            border-radius: 16px;
            gap: 16px;
            display: flex;

            @media screen and (max-width: 480px) {
                flex-direction: column-reverse;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .success-stories-img {
                width: 130px;
                min-width: 130px;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #272338;
                text-align: center;
                gap: 2px;
                flex-direction: column;
                align-items: center;
                display: inline-flex;
                @media screen and (max-width: 767px) {
                    width: 120px;
                    min-width: 120px;
                }
                @media screen and (max-width: 480px) {
                    font-size: 16px;
                    line-height: 22px;
                    width: 100%;
                    min-width: 100%;
                    padding: 0 0 0 72px;
                    position: relative;
                    text-align: left;
                    min-height: 60px;
                    gap: 4px;
                    justify-content: center;
                    align-items: flex-start;
                }

                img {
                    width: 80px;
                    height: 80px;
                    border: 1px solid #272338;
                    border-radius: 80px;
                    object-fit: cover;
                    margin: 0 0 12px;
                    @media screen and (max-width: 480px) {
                        width: 60px;
                        height: 60px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                    }
                }

                .success-stories-date {
                    display: none;
                    margin: 0;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;

                    a {
                        font-weight: 500;
                    }

                    @media screen and (max-width: 480px) {
                        display: block;
                    }
                }

                @media screen and (max-width: 480px) {
                    width: 100%;
                    min-width: 100%;
                }
            }

            .success-stories-info {
                padding-left: 32px;
                border-left: 1px solid #E6E6E6;
                min-height: 110px;
                flex-direction: column;
                justify-content: center;
                display: flex;

                @media screen and (max-width: 767px) {
                    padding-left: 24px;
                }

                @media screen and (max-width: 480px) {
                    padding: 0 0 16px;
                    border: none;
                    border-bottom: 1px solid #E6E6E6;
                    min-height: inherit;
                    .success-stories-date {
                        display: none;
                    }
                }

                h3 {
                    font-family: Aeonik, sans-serif;
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: 500;
                    color: #272338;
                    margin: 0 0 2px;
                }

                .success-stories-desc {
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    &.show-more-desc {
                        display: block;
                    }

                    p {
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .show-more-btn {
                    display: inline-flex;
                    margin: 5px 0 0;
                }
            }
        }

        .show-more-btn {
            margin: 0;
            padding: 0;
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #7093FF;
            background: transparent;
            border: none;
            box-shadow: none;
            outline: none;
            gap: 4px;
            align-items: center;
            display: none;
            transition: all 0.35s ease 0s;

            @media screen and (max-width: 767px) {
                display: inline-flex;
                font-size: 16px;
                line-height: 22px;

                svg {
                    width: 32px;
                    height: 32px;
                }
            }

            svg {
                transition: all 0.35s ease 0s;

                path {
                    transition: all 0.35s ease 0s;
                }
            }

            &:hover {
                color: #661735;

                svg {
                    path {
                        stroke: #661735;
                    }
                }
            }

            &.show-less {
                svg {
                    transform: rotateX(180deg);
                }
            }
        }
    }

    .pricing-wrap {
        padding: 52px 0;
        position: relative;
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #4D4D4D;

        @media screen and (max-width: 991px) {
            padding: 40px 0;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: -100vw;
            bottom: 0;
            left: -100vw;
            background: #FFE6D5;
            border-top: 1px solid #272338;
            border-bottom: 1px solid #272338;
            z-index: -1;
            pointer-events: none;
        }

        .section-title {
            margin: 0 0 8px;
        }

        .section-des {
            font-size: 16px;
            line-height: 22px;
            color: #423B5E;
            margin: 0;
        }

        p {
            margin: 0;
        }

        .pricing-info {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
            margin: 24px 0 0;

            @media screen and (max-width: 767px) {
                margin: 16px 0 0;
                gap: 8px;
            }

            .pricing-info-item {
                width: calc(50% - 8px);
                padding: 20px 24px;
                border: 1px solid #272338;
                border-radius: 16px;
                background: #fff;
                font-family: Aeonik, sans-serif;
                font-size: 18px;
                line-height: 25px;
                font-weight: 400;
                color: #272338;
                position: relative;
                min-height: 132px;

                @media screen and (max-width: 767px) {
                    padding: 18px 24px;
                }

                @media screen and (max-width: 575px) {
                    width: 100%;
                }

                .most-popular {
                    display: inline-flex;
                    position: absolute;
                    top: 10px;
                    right: 12px;
                }

                h3 {
                    font-family: Livorno, serif;
                    font-weight: 400;
                    font-size: 50px;
                    line-height: 60px;
                    letter-spacing: -0.01em;
                    margin: 8px 0 0;
                    color: #661735;

                    span {
                        font-family: Livorno, serif;
                        font-size: 31px;
                        margin-right: 4px;
                        display: inline-block;
                        vertical-align: top;
                        color: rgba(102, 23, 53, 0.6);
                    }
                }

                .save {
                    color: #661735;
                }

                &.pricing-package-info {
                    width: 100%;
                    padding: 32px;

                    @media screen and (max-width: 767px) {
                        padding: 18px 24px;
                    }
                }

            }

            .pricing-package-info {
                ul {
                    margin: 16px 0 0;
                    padding: 0;
                    list-style-type: none;

                    li {
                        position: relative;
                        padding-left: 32px;
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            top: 1.5px;
                            left: 0;
                            height: 22px;
                            width: 22px;
                            background: #7093FF;
                            border-radius: 30px;
                            border: 1px solid #272338;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            top: 8.5px;
                            left: 7px;
                            width: 8px;
                            height: 8px;
                            border-radius: 2px;
                            background: #FFFFFF;
                            transform: rotate(45deg);
                        }

                        &:nth-child(8n + 2) {
                            &:before {
                                background: #F8D066;
                            }
                        }

                        &:nth-child(8n + 3) {
                            &:before {
                                background: #FF434D;
                            }
                        }

                        &:nth-child(8n + 4) {
                            &:before {
                                background: #E2763A;
                            }
                        }

                        &:nth-child(8n + 5) {
                            &:before {
                                background: #EFB7A6;
                            }
                        }

                        &:nth-child(8n + 6) {
                            &:before {
                                background: #A8AED6;
                            }
                        }

                        &:nth-child(8n + 7) {
                            &:before {
                                background: #94B3A5;
                            }
                        }

                        &:nth-child(8n + 8) {
                            &:before {
                                background: #661735;
                            }
                        }
                    }
                }
            }
        }

        + .questions-answered {
            border-top: none;
        }
    }

    .questions-answered {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #272338;
        padding: 52px 0;
        position: relative;
        word-break: break-word;

        @media screen and (max-width: 991px) {
            padding: 40px 0;
        }

        @media screen and (max-width: 767px) {
            padding: 32px 0;
        }

        .section-title {
            margin: 0 0 24px;
        }

        .accordion-item {
            margin: 0 0 16px;
            padding: 0 0 16px;
            border: none;
            border-bottom: 1px solid #272338;
            border-radius: 0;
            background: transparent;
            @media screen and (max-width: 991px) {
                margin: 0 0 14px;
                padding: 0 0 14px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .accordion-button {
                font-family: Aeonik, sans-serif;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #272338;
                background: transparent;
                border: none;
                border-radius: 0;
                padding: 0 30px 0 0;
                box-shadow: none;
                position: relative;
                transition: all 0.35s ease 0s;
                @media screen and (max-width: 767px) {
                    line-height: 24px;
                }

                &:after {
                    display: none;
                }

                .faqs-arrow {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.35s ease 0s;
                    transform: rotateX(180deg);
                    background-image: url("/assets/images/faq-down-icon.svg");
                    background-position: center center;
                    background-repeat: no-repeat;
                    @media screen and (min-width: 767px) {
                        background-size: 10px auto;
                    }
                }

                &.collapsed {
                    .faqs-arrow {
                        transform: none;
                    }
                }
            }

            .accordion-body {
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #272338;
                padding: 16px 24px;
                background: #FFEFE4;
                border-radius: 0 0 16px 16px;
                margin-top: 8px;
                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 22px;
                    padding: 12px 16px;
                    margin-bottom: 2px;
                }
            }
        }

        .questions-answered-action {
            padding-top: 32px;
            gap: 16px 8px;
            flex-wrap: wrap;
            display: flex;
            @media screen and (max-width: 767px) {
                padding-top: 24px;
            }

            .secondary-btn {
                gap: 12px;

                img {
                    position: relative;
                    right: 0;
                    transition: all 0.35s ease 0s;
                }

                &:hover {
                    img {
                        right: -3px;
                    }
                }
            }
        }
    }
}

//Bottom Banner
.join-vendors-wrap {
    background: #94B3A5;
    padding: 94px 0 80px;
    text-align: center;
    border-top: 1px solid #272338;

    @media screen and (max-width: 991px) {
        padding: 72px 0 64px;
    }

    .container {
        align-items: center;
        max-width: 746px;
    }

    .section-title {
        margin-bottom: 32px;
        @media screen and (max-width: 1100px) {
            font-size: 32px;
            line-height: 42px;
        }

        @media screen and (max-width: 991px) {
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 24px;
        }

        span {
            font-family: Livorno, serif;
            position: relative;

            img {
                position: absolute;
                left: -8px;
                max-width: initial;
                pointer-events: none;
            }

            .counting-icon {
                bottom: 100%;
                @media screen and (max-width: 767px) {
                    bottom: calc(100% - 2px);
                    left: -29px;
                }
            }

            .line-icon {
                top: 100%;
                width: 94px;
                @media screen and (max-width: 767px) {
                    top: calc(100% - 5px);
                    width: 78px;
                    transform: rotate(5deg);
                }
            }
        }
    }
}

.mob-create-profile-btn {
    padding: 12px 16px;
    background: #FFFFFF;
    border-bottom: 1px solid #272338;
    position: sticky;
    top: 47px;
    z-index: 9;
    text-align: center;
    display: none;
    @media screen and (max-width: 480px) {
        display: block;
    }

    .primary-btn {
        width: 100%;
    }
}



